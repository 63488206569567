import React, { Component } from 'react';
import Select, { createFilter } from 'react-select';
import intl from 'react-intl-universal';
import { Alert } from '../../components/Alert';
import Axios from '../../config/Axios';
import Page from '../default/Page';
import { checkModule } from '../../config/Permissions';
import url from '../../config/Url';

// import { RequisiteSection } from './RequisiteSection';

import './styles/requisite.scss';
import '../../assets/scss/froala-custom-popup.scss';
import FroalaEditorComponent from '../../config/FroalaEditorComponent';

import moment from 'moment';

const REQUISITE_NOT_FINISHED_VALUE = 27;
class Requisite extends Component {
  constructor(oProps) {
    super(oProps);
    this.alertWrapper = React.createRef();

    let dDeadline = '';
    if (oProps.oNorm && oProps.oNorm.norm_bdeadlineactive) {
      dDeadline = moment
        .parseZone(oProps.oNorm.norm_dactivationdeadline)
        .add(oProps.oNorm.norm_ndeadline, 'days')
        .format('YYYY-MM-DD');
    }

    this.state = {
      cEditorContent: '',
      bLoading: true,
      iLoading: false,
      oRequisite: {
        req_cname: '',
        requisite_section: [{ rs_cname: '', rs_cdescription: '', item_checklist: [], rs_norder: 1 }],
        req_nid: '',
        req_etitletype: '',
        norm_nid: oProps.nNormId,
        user_nid: oProps.oNorm ? oProps.oNorm.norm_responsibles[0].user.user_nid : '',
        req_ddeadline: dDeadline
      },
      oFilterOptionST: [
        {
          label: intl.get('sim'),
          value: true
        },
        {
          label: intl.get('nao'),
          value: false
        }
      ],
      SelectedoFilterOptionST: '',
      aRequisites: [],
      aUsers: []
    };
  }

  componentDidMount() {
    const { nId, history } = this.props;
    checkModule(history, 'quality-panel');

    return Promise.all([this.getRequisite(nId), this.getUsers(), this.getRequisites()]).then(() => {
      const { oRequisite, aRequisites } = this.state;
      const oInitialParentRequisite = aRequisites.filter(
        oFilteredRequisite => Number(oFilteredRequisite.value) === Number(oRequisite.req_nid)
      );

      this.setState({ bLoading: false, oInitialParentRequisite });
    });
  }

  handleChange = evt => {
    const { oRequisite } = this.state;
    oRequisite[evt.target.name] = evt.target.value;
    this.setState({ oRequisite });
  };

  isTeeType = () => {
    const { oRequisite } = this.state;
    return !oRequisite.req_nid_parent && (oRequisite.req_etitletype === 'T' || oRequisite.req_etitletype === true);
  };

  saveRequisite = evt => {
    evt.preventDefault();
    const { oRequisite, cEditorContent, aRequisites } = this.state;
    const { onSuccess } = this.props;
    let bAlreadyExists = false;

    aRequisites.forEach(oItem => {
      if (oItem.cLabel === oRequisite.req_cname) {
        bAlreadyExists = true;
      }
    });

    if (!this.isTeeType() && oRequisite.user_nid === null) {
      this.setState(
        {
          rcmpAlertMsg: (
            <Alert type="error" isOpen onCloseAlert={() => { }}>
              {intl.get('Requisite.responsavel_obrigatorio')}
            </Alert>
          )
        },
        () => this.alertWrapper?.current?.scrollIntoView()
      );
      return;
    }

    if (!oRequisite.req_nid && oRequisite.req_etitletype === '') {
      this.setState(
        {
          rcmpAlertMsg: (
            <Alert type="error" isOpen onCloseAlert={() => { }}>
              {intl.get('Requisite.possui_filhos_obrigatorio')}
            </Alert>
          )
        },
        () => this.alertWrapper?.current?.scrollIntoView()
      );
      return;
    }

    if (bAlreadyExists) {
      this.setState(
        {
          rcmpAlertMsg: (
            <Alert type="error" isOpen onCloseAlert={() => { }}>
              {intl.get('Requisite.already_exists')}
            </Alert>
          )
        },
        () => this.alertWrapper?.current?.scrollIntoView()
      );
    } else {
      this.setState({ iLoading: true });
      Axios.post('requisite', {
        ...oRequisite,
        req_cdescription: cEditorContent
      })
        .then(() => {
          this.setState(
            {
              iLoading: false
            },
            () => {
              onSuccess(intl.get('Requisite.cadastro_sucesso'));
            }
          );
        })
        .catch(oError =>
          this.setState(
            {
              iLoading: false,
              rcmpAlertMsg: (
                <Alert type="error" isOpen onCloseAlert={() => { }}>
                  {oError.msgErrors}
                </Alert>
              )
            },
            () => this.alertWrapper?.current?.scrollIntoView()
          )
        );
    }
  };

  updateRequisite = evt => {
    evt.preventDefault();
    this.setState({ iLoading: true });
    const { oRequisite, cEditorContent } = this.state;
    const { onSuccess } = this.props;

    if (!this.isTeeType() && oRequisite.user_nid === null) {
      this.setState(
        {
          rcmpAlertMsg: (
            <Alert type="error" isOpen onCloseAlert={() => { }}>
              {intl.get('Requisite.responsavel_obrigatorio')}
            </Alert>
          ),
          iLoading: false
        },
        () => this.alertWrapper?.current?.scrollIntoView()
      );
      return;
    }

    Axios.put(`requisite/${oRequisite.req_nid}`, {
      req_cname: oRequisite.req_cname,
      requisite_section: oRequisite.requisite_section,
      req_nid_parent: oRequisite.req_etitletype !== 'T' ? oRequisite.req_nid_parent : null,
      norm_nid: oRequisite.norm_nid,
      req_etitletype: oRequisite.req_etitletype ? true : false,
      req_cdescription: cEditorContent,
      user_nid: oRequisite.user_nid,
      req_ddeadline: oRequisite.req_ddeadline
    })
      .then(() => {
        this.setState(
          {
            iLoading: false
          },
          () => {
            onSuccess(intl.get('Requisite.edicao_sucesso'));
          }
        );
      })
      .catch(oError =>
        this.setState(
          {
            iLoading: false,
            rcmpAlert: (
              <Alert type="error" isOpen onCloseAlert={() => { }}>
                {oError.msgErrors}
              </Alert>
            )
          },
          () => this.alertWrapper?.current?.scrollIntoView()
        )
      );
  };

  handleChangeCustom = (elSelectedOption, type = undefined) => {
    this.setState(oPrevState => ({
      oRequisite: {
        ...oPrevState.oRequisite,
        [type]: elSelectedOption ? elSelectedOption.value : null
      }
    }));
  };

  getUsers = async () => {
    const oResponse = await Axios.get('/user');
    this.setState({
      aUsers: oResponse.data.aUsers.map(user => ({ label: user.user_cname, value: user.user_nid }))
    });
  };

  getRequisite = nRequisiteId => {
    if (!nRequisiteId) {
      return Promise.resolve();
    }

    return Axios.get(`requisite/${nRequisiteId}`)
      .then(oResponse => {
        const oRequisite = oResponse.data;

        if (oRequisite.norm && oRequisite.norm.norm_bdeadlineactive && oRequisite.req_ddeadline === null) {
          oRequisite.req_ddeadline = moment
            .parseZone(oRequisite.norm.norm_dactivationdeadline)
            .add(oRequisite.norm.norm_ndeadline, 'days')
            .format('YYYY-MM-DD');
        }
        this.setState({
          oRequisite,
          cEditorContent: oRequisite.req_cdescription
        });
      })
      .catch(() => { });
  };

  getRequisites = () => {
    const { aRequisites } = this.state;
    const { nId, nNormId } = this.props;

    return Axios.get(`requisite/pending/${nNormId}`)
      .then(oResponse => {
        Object.keys(oResponse.data).forEach(cKey => {
          if (oResponse.data[cKey].req_nid !== nId) {
            aRequisites.push({
              label: oResponse.data[cKey].req_cname,
              value: Number(oResponse.data[cKey].req_nid),
              bIsDisabled: oResponse.data[cKey].bIsDisabled
            });
          }
        });

        this.setState({
          aRequisites
        });
      })
      .catch(oError => {
        this.setState(
          {
            rcmpAlertMsg: (
              <Alert type="error" isOpen onCloseAlert={() => { }}>
                {oError.msgErrors}
              </Alert>
            )
          },
          () => this.alertWrapper?.current?.scrollIntoView()
        );
      });
  };

  optionRequisite = (oRequisite) => {
    const { aRequisites } = this.state;
    if (oRequisite && oRequisite.recursive_children_requisite !== undefined && oRequisite.recursive_children_requisite.length > 0) {
      const ids = this.listChildrenRequisite(oRequisite);
      return aRequisites.filter(oItem => !ids.includes(oItem.value));
    }

    return aRequisites;
  }

  listChildrenRequisite = (oRequisite, ids = []) => {
    ids.push(oRequisite.req_nid);
    if (oRequisite.recursive_children_requisite === undefined || !oRequisite.recursive_children_requisite.length) {
      return ids;
    }

    const requisetesIds = ids;
    oRequisite.recursive_children_requisite.forEach(oItem => {
      requisetesIds.concat(
        this.listChildrenRequisite(
          oItem,
          ids
        )
      );
    });

    return requisetesIds;
  }

  onChangeEditor = cContent => {

    this.setState({
      cEditorContent: cContent
    });

  };


  render() {
    const { bLoading, oRequisite, rcmpAlertMsg, aRequisites, aUsers, iLoading, oFilterOptionST, cEditorContent } = this.state;
    const cStringify = oOption => oOption.cLabel;
    const { closeModal, oNorm } = this.props;
    const oFilterOption = createFilter({ bIgnoreCase: false, cStringify });
    return (
      <Page loading={bLoading ? 1 : 0}>
        <form
          className="formQualidade"
          onSubmit={evt => (oRequisite.req_nid ? this.updateRequisite(evt) : this.saveRequisite(evt))}
        >
          <div ref={this.alertWrapper} style={{ paddingTop: 5 }}>
            {rcmpAlertMsg}
          </div>

          <label className="split1 colspan">
            {intl.get('Requisite.cnome')} <span className="required">*</span>
            <input
              onChange={this.handleChange}
              name="req_cname"
              maxLength="100"
              value={oRequisite.req_cname || ''}
              required
              type="text"
              className="form-control"
            />
          </label>

          {aRequisites.length !== 0 && (
            <label className="split2 colspan ">
              {intl.get('Requisite.requisito_pai')}

              <Select
                isClearable
                className="selectCustom"
                classNamePrefix="react-select"
                noOptionsMessage={() => intl.get('Requisitos.requisito_nao_encontrado')}
                placeholder={intl.get('select')}
                options={this.optionRequisite(oRequisite)}
                maxMenuHeight={230}
                filterOption={oFilterOption}
                isDisabled={oRequisite.req_etitletype === 'T'}
                defaultValue={
                  oRequisite.user_nid
                    ? aRequisites.filter(aRequisite => {
                      return aRequisite.value === oRequisite.req_nid_parent;
                    })
                    : ''
                }
                onChange={elSelectedOption => this.handleChangeCustom(elSelectedOption, 'req_nid_parent')}
              />
            </label>
          )}

          <label className="split2 colspan ">
            {intl.get('Requisite.possui_filhos')}
            <span className="required">*</span>
            <Select
              isDisabled={oRequisite.recursive_children_requisite?.length > 0}
              className="selectCustom"
              isSearchable={false}
              classNamePrefix="react-select"
              placeholder={intl.get('selecione')}
              options={
                oRequisite.recursive_children_requisite && oRequisite.recursive_children_requisite.length
                  ? [{ label: intl.get('sim'), value: true }]
                  : oFilterOptionST
              }
              noOptionsMessage={() => intl.get('sem_opcoes')}
              defaultValue={
                oRequisite.req_etitletype && oRequisite.req_nid
                  ? { label: intl.get('sim'), value: true }
                  : { label: oRequisite.req_nid ? intl.get('nao') : '', value: oRequisite.req_nid ? false : '' }
              }
              onChange={elSelectedOption => this.handleChangeCustom(elSelectedOption, 'req_etitletype')}
            />
          </label>

          <label className="split1 colspan">
            {intl.get('responsible')}
            {!this.isTeeType() && <span className="required">*</span>}

            <Select
              className="selectCustom select"
              classNamePrefix="react-select"
              noOptionsMessage={() => {
                intl.get('LinkDocument.nao_encontrado');
              }}
              placeholder={intl.get('Requisite.pesquisar_responsavel')}
              isClearable
              isSearchable
              options={aUsers}
              maxMenuHeight={130}
              defaultValue={
                oRequisite.user_nid
                  ? aUsers.filter(usr => {
                    return usr.value === oRequisite.user_nid;
                  })
                  : ''
              }
              onChange={elSelectedOption => this.handleChangeCustom(elSelectedOption, 'user_nid')}
            />
          </label>

          {((oNorm && oNorm.norm_bdeadlineactive) || (oRequisite.norm && Boolean(oRequisite.norm.norm_bdeadlineactive))) &&
            (oRequisite.stat_nid === undefined || oRequisite.stat_nid === REQUISITE_NOT_FINISHED_VALUE) &&
            oRequisite.req_etitletype !== 'T' &&
            ((oRequisite.req_etitletype !== true &&
              (oRequisite.req_nid_parent === null ||
                oRequisite.req_nid_parent === undefined ||
                oRequisite.req_nid_parent !== null ||
                oRequisite.req_nid_parent !== undefined)) ||
              (oRequisite.req_etitletype === true &&
                oRequisite.req_nid_parent !== null &&
                oRequisite.req_nid_parent !== undefined)) && (
              <label className="split1 colspan">
                {intl.get('Requisite.deadline')}
                <input
                  onChange={this.handleChange}
                  id="req_ddeadline"
                  name="req_ddeadline"
                  type="date"
                  required
                  maxLength="255"
                  defaultValue={oRequisite.req_ddeadline}
                  max="2100-12-31"
                />
              </label>
            )}
          <div id="section-desc">
            <p>{intl.get('Requisite.cdescricao')}</p>
            <div className="content-fr-view">
              <div className="content-fr-element">
                <FroalaEditorComponent
                  model={cEditorContent}
                  fnHandleChange={evtChange => this.onChangeEditor(evtChange)}
                  bSimpleEditor
                  config={{
                    videoUploadURL: `${url.cBase}api/requisite/editor-upload`,
                    videoUploadParam: 'fVideo',
                    videoUploadParams: {
                      nAreaId: oRequisite.req_nid,
                      cArea: 'quality'
                    },
                    imageUploadURL: `${url.cBase}api/requisite/editor-upload`,
                    imageUploadParam: 'fImage',
                    imageUploadParams: {
                      nAreaId: oRequisite.req_nid,
                      cArea: 'quality'
                    },
                    toolbarInline: false,
                    useClasses: false,
                    height: 200,
                    width: 100,
                    requestHeaders: {
                      Authorization: `Bearer ${localStorage.getItem('cAccessToken')}`
                    }
                  }}
                  fnRef={this.editorInstance}
                />
              </div>
            </div>
          </div>

          <div className="button-stack">
            <button type="button" onClick={() => closeModal()} className="btn alternate back btn btn-outline-secondary">
              {intl.get('cancelar')}
            </button>
            <button
              disabled={iLoading}
              type="submit"
              title={intl.get('salvar')}
              className={`btn btn btn-secondary ${iLoading ? 'disabled' : ''}`}
            >
              {intl.get('salvar')}
            </button>
          </div>
        </form>
      </Page >
    );
  }
}

export default Requisite;
