import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/pt-br';
import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { Button } from 'reactstrap';
import masterList, { masterListPdf, masterListXlsx } from '../../actions/ActionMasterList';
import Page from '../default-v2/Page';
import Pagination from 'react-js-pagination';
import Breadcrumb from '../../components/Breadcrumbs';
import CardHeader from '../default-v2/CardHeader';
import Filter from '../default-v2/Filter';
import './styles/report.scss';
import './styles/masterlist.scss';
import './styles/v2-reports.scss';
import ReportTableCustom from './ReportTableCustom';
import { checkModule } from '../../config/Permissions';
import reportsImg from '../../assets/img/reports.svg';
import EncodedLocalStorage from '../../config/EncodedLocalStorage';
import PaginationMenu from '../default-v2/PaginationMenu';
import { formatDateToUserTZ } from '../../utils/time';

const oLocalStorage = new EncodedLocalStorage();
class MasterList extends Component {
  constructor(oProps) {
    super(oProps);

    moment.locale(oLocalStorage.get('cLanguage'));

    this.state = {
      aMasterList: [],
      bLoading: true,
      cSearch: '',
      cOrderBy: 'cUserName',
      cOrderType: 'asc',
      aPages: [
        {
          cName: 'Nav.reports',
          cLink: undefined
        },
        {
          cName: 'MasterList.breadcrumb_label',
          cLink: undefined
        }
      ],
      nCurrentPage: 1,
      nTotalPages: 0,
      nItemsPerPage: 50,
      nTotal: 0,
      isXlsxRequestInProgress: false,
      isPdfRequestInProgress: false
    };
  }

  componentDidMount() {
    const { history } = this.props;
    checkModule(history, 'documents');

    this.fetchData();
  }

  getPending = (nPage, nPerPage, orderBy, order, cSearch) => {
    return masterList(nPage, nPerPage, orderBy, order, cSearch);
  };

  fetchData = () => {
    const { setAlert, clearAlert, match } = this.props;
    const { nCurrentPage, nItemsPerPage, cSearch, cOrderBy, cOrderType } = this.state;

    this.setState({ bLoading: true });

    this.getPending(nCurrentPage, nItemsPerPage, cOrderBy, cOrderType, cSearch)
      .then(aMasterList => {
        this.setState({
          bLoading: false,
          aMasterList: aMasterList.data,
          nTotalPages: aMasterList.last_page,
          nTotal: aMasterList.total
        });
      })
      .catch(oError => {
        this.setState({ bLoading: false, aMasterList: [] });
        setAlert('error', oError.aMsgErrors);
      });
  };

  handlePageChange = nPageNumber => {
    this.setState({ nCurrentPage: nPageNumber }, this.fetchData);
  };


  handleClickTable = (evt, cNewOrderBy, cNewOrderType) => {
    this.setState(
      {
        cOrderBy: cNewOrderBy,
        cOrderType: cNewOrderType
      },
      () => {
        this.handleFilter(evt);
      }
    );
  };

  renderMasterList = () => {
    const { aMasterList } = this.state;
    const { history } = this.props;

    if (aMasterList !== undefined && aMasterList.length === 0) {
      return (
        <>
          <p className="empty-result">
            <strong> {intl.get('MasterList.empty_result')}</strong>
          </p>
        </>
      );
    }

    const rcmpMasterList = Object.keys(aMasterList).map(cKey => {
      const oInformation = aMasterList[cKey];
      const fnCreateMarkup = htmlString => ({ __html: htmlString });

      return (
        <tr
          key={cKey}
          onKeyPress={evtKeyPress => {
            if (oInformation.bCanAccess) history.push(`/document/detail/${oInformation.nVersionId}`);
          }}
          onClick={evtClick => {
            if (oInformation.bCanAccess) history.push(`/document/detail/${oInformation.nVersionId}`);
          }}
          style={{
            cursor: oInformation.bCanAccess ? 'pointer' : 'default'
          }}
        >
          <td title={oInformation.cLocalization} className="col-localization">
            <span>
              <div dangerouslySetInnerHTML={fnCreateMarkup(oInformation.cLocalization)} />
            </span>
          </td>
          <td title={oInformation.cDescription} className="col-description">
            <span>
              <div dangerouslySetInnerHTML={fnCreateMarkup(oInformation.cDescription)} />
            </span>
          </td>
          <td className="col-version">
            <span>
              <div dangerouslySetInnerHTML={fnCreateMarkup(oInformation.cVersion)} />
            </span>
          </td>
          <td className="col-publication-date">
            <span>
              {oInformation.cPublicationDate && (
                <>
                  {
                    formatDateToUserTZ(
                      oInformation.cPublicationDate,
                      'L',
                      oLocalStorage.get('cTimezone')
                    )
                  }
                </>
              )}
            </span>
          </td>
          <td className="col-due-date">
            <span>
              {oInformation.cDueDate && (
                <>
                  {
                    formatDateToUserTZ(
                      oInformation.cDueDate,
                      'L',
                      oLocalStorage.get('cTimezone')
                    )
                  }
                </>
              )}
            </span>
          </td>
          <td className="col-redactor">
            <span>
              <div dangerouslySetInnerHTML={fnCreateMarkup(oInformation.cRedactor)} />
            </span>
          </td>
          <td className="col-reviewer">
            <span>
              <div dangerouslySetInnerHTML={fnCreateMarkup(oInformation.cReviewer)} />
            </span>
          </td>
          <td className="col-responsible">
            <span>
              <div dangerouslySetInnerHTML={fnCreateMarkup(oInformation.cResponsible)} />
            </span>
          </td>
          <td className="col-distribution badges">
            {oInformation.aSelectedDistributions &&
              oInformation.aSelectedDistributions.split(',').map((distribution, index) => (
                <span key={index} className="team-badge" title={distribution.trim()}>
                  {distribution.trim()}
                </span>
              ))}
          </td>
        </tr>
      );
    });

    return (
      <>
        <p className="distribution-msg">
          {intl.get('MasterList.distribution_msg')}
        </p>
        <ReportTableCustom
          aColumn={[
            {
              cName: intl.get('MasterList.localization'),
              cOrderBy: 'cLocalization',
              fnHandleClick: this.handleClickTable,
              bIsDisable: true
            },
            {
              cName: intl.get('MasterList.description'),
              cOrderBy: 'cDescription',
              fnHandleClick: this.handleClickTable
            },
            { cName: intl.get('MasterList.version'), cOrderBy: 'cVersion', fnHandleClick: this.handleClickTable },
            {
              cName: intl.get('MasterList.publication_date'),
              cOrderBy: 'cPublicationDate',
              fnHandleClick: this.handleClickTable
            },
            { cName: intl.get('MasterList.due_date'), cOrderBy: 'cDueDate', fnHandleClick: this.handleClickTable},
            { cName: intl.get('MasterList.redactor'), cOrderBy: 'cRedactor', fnHandleClick: this.handleClickTable},
            { cName: intl.get('MasterList.reviewer'), cOrderBy: 'cReviewer', fnHandleClick: this.handleClickTable},
            { cName: intl.get('MasterList.responsible'), cOrderBy: 'cResponsible',fnHandleClick: this.handleClickTable},
            { cName: intl.get('MasterList.distribution'),cOrderBy: 'cDistribution',fnHandleClick: this.handleClickTable}
          ]}
        >
          {rcmpMasterList}
        </ReportTableCustom>
      </>
    );
  };

  handleFilter = evt => {
    const { cSearch, cOrderBy, cOrderType } = this.state;
    const { setAlert } = this.props;

    masterList(cSearch, cOrderBy, cOrderType)
      .then(aMasterList => {
        this.setState({ aMasterList });
      })
      .catch(oError => {
        setAlert('error', oError.aMsgErrors);
      });
  };

  handleFilter = evt => {
    const { cSearch, cOrderBy, cOrderType, nCurrentPage, nItemsPerPage } = this.state;
    const { setAlert, clearAlert } = this.props;

    this.getPending(nCurrentPage, nItemsPerPage, cOrderBy, cOrderType, cSearch)
      .then(aMasterList => {
        this.setState({
          aMasterList: aMasterList.data,
          nTotalPages: aMasterList.last_page,
          nTotal: aMasterList.total
        });
        clearAlert();
      })
      .catch(oError => {
        this.setState({ bLoading: false, aMasterList: -1 });
        setAlert('error', oError.aMsgErrors);
      });
  };

  handleNewFilter = (evt, oFilter) => {
    this.setState(
      {
        cSearch: oFilter.cText,
        cOrderType: oFilter.cOrderType
      },
      () => {
        this.handleFilter(evt);
      }
    );
  };

  openPDF = evtOpen => {
    evtOpen.preventDefault();
    const { cSearch, cOrderBy, cOrderType } = this.state;
    const { setAlert } = this.props;

    if (this.state.isPdfRequestInProgress) return;

    this.setState({ isPdfRequestInProgress: true });

    masterListPdf(cSearch, cOrderBy, cOrderType)
      // .then(oResponse => oResponse.blob())
      .then(oBlob => {
        const cFileURL = window.URL.createObjectURL(oBlob);
        const elLink = document.createElement('a');
        elLink.href = cFileURL;
        elLink.download = `${intl.get('MasterList.filename')}.pdf`;
        elLink.click();
      })
      .catch(oError => {
        setAlert('error', oError.aMsgErrors);
      }).finally(() => {
        this.setState({ isPdfRequestInProgress: false });
      });
  };

  openXLSX = evtOpen => {
    evtOpen.preventDefault();
    const { cSearch, cOrderBy, cOrderType } = this.state;
    const { setAlert } = this.props;

    if (this.state.isXlsxRequestInProgress) return;

    this.setState({ isXlsxRequestInProgress: true });

    masterListXlsx(cSearch, cOrderBy, cOrderType)
      .then(oBlob => {
        const url = window.URL.createObjectURL(new Blob([oBlob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'lista-mestra.xlsx');
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      })
      .catch(oError => {
        setAlert('error', oError.aMsgErrors);
      }).finally(() => {
        this.setState({ isXlsxRequestInProgress: false });
      });
  };

  generateCardHeader = () => {
    const { history } = this.props;
    const rcmpBtnPDF = (
      <Button
        key="button-pdf"
        color="primary"
        className="btn-pdf"
        onClick={evtClick => {
          this.openPDF(evtClick);
        }}
        disabled={this.state.isPdfRequestInProgress}
        tooltiptext={intl.get('MasterList.pdf')}
      >
        <i className="icon-pdf-download" />
      </Button>
    );

    const rcmpBtnXlsx = (
      <Button
        key="button-xlsx"
        color="primary"
        className="btn-pdf"
        onClick={evtClick => {
          this.openXLSX(evtClick);
        }}
        disabled={this.state.isXlsxRequestInProgress}
        tooltiptext={intl.get('MasterList.xlsx')}
      >
        <i className="icon-document-download" />
      </Button>
    );
    const aButtons = [rcmpBtnPDF, rcmpBtnXlsx];

    const rcmpFilter = <Filter fnOnType={this.handleNewFilter} aButtons={aButtons} />;

    const rcmpCardHeader = <CardHeader history={history} rcmpFilter={rcmpFilter} />;
    return rcmpCardHeader;
  };

  handleItemsPerPageChange = eventPagination => {
    const nItemsPerPage = parseInt(eventPagination.target.value, 10);
    this.setState({ nItemsPerPage, nCurrentPage: 1 }, this.fetchData);
  };

  render() {
    const { aPages, bLoading, nCurrentPage, nItemsPerPage, nTotalPages, nTotal } = this.state;
    const { getAlert } = this.props;

    const rcmpBreadcrumb = <Breadcrumb aPages={aPages} />;

    const rcmpCardHeader = this.generateCardHeader();

    const cPageTitle = intl.get('MasterList.title');

    return (
      <Page
        loading={bLoading ? 1 : 0}
        rcmpBreadcrumb={rcmpBreadcrumb}
        cTitle={cPageTitle}
        cImage={reportsImg}
        rcmpCardHeader={rcmpCardHeader}
        className="v2-report"
        cCurrentSideMenuOption={intl.get('Nav.report')}
      >
        {getAlert()}
        {this.renderMasterList()}
        <PaginationMenu
          nCurrentPage={nCurrentPage}
          nItemsPerPage={nItemsPerPage}
          nTotalPages={nTotalPages}
          nTotal={nTotal}
          handlePageChange={this.handlePageChange}
          handleItemsPerPageChange={this.handleItemsPerPageChange} />
      </Page>
    );
  }
}

export default MasterList;
