import Axios from '../config/Axios';

export function updateUser(nId, oUser, aAllowedAccessTime) {
  return Axios.put(`/user/${nId}`, {
    oUser,
    aAllowedAccessTime
  });
}

export function uploadImage(fdataImage) {
  return Axios.post('/user/avatar', fdataImage, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}
