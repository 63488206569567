import React, { Component } from 'react';
import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/pt-br';
import { Table } from 'reactstrap';
import imgfSelectDown from '../default-v2/img/select-down.svg';
import imgfSelectUp from '../default-v2/img/select-up.svg';
import intl from 'react-intl-universal';
import './styles/reporttablecustom.scss';
import { checkModule } from '../../config/Permissions';
import EncodedLocalStorage from '../../config/EncodedLocalStorage';

const oLocalStorage = new EncodedLocalStorage();
class ReportTableCustom extends Component {
  constructor(oProps) {
    super(oProps);
    moment.locale(oLocalStorage.get('cLanguage'));

    this.state = {
      cOrderType: 'asc',
      cOrderBy: '',
      nSelectThIndex: -1
    };
  }

  componentDidMount() {
    const container = this.containerRef;
    console.log(container.scrollWidth, container.clientWidth); // Debugging widths
  
    if (container.scrollWidth > container.clientWidth) {
      console.log('Container is scrollable');
      container.scrollBy({ left: 200, behavior: 'smooth' });
    } else {
      console.log('Container is not scrollable');
    }
  }

  scrollLeft = () => {
    console.log("Scroll Left Triggered");
    const container = this.containerRef;
    if (container) {
      console.log("Scroll Left ENTROU");
      container.scrollBy({ left: -200, behavior: 'smooth' });
    }
  }
  
  scrollRight = () => {
    console.log("Scroll Right Triggered");
    const container = this.containerRef;
    if (container) {
      console.log("Scroll Right ENTROU");
      container.scrollBy({ left: 200, behavior: 'smooth' });
    }
  }
  
  handleClick = (evt, nIndex, cNewOrderBy, fnHandleClick) => {
    let cNewOrderType = 'asc';
    if (this.state.cOrderType === 'asc') {
      cNewOrderType = 'desc';
    }
    this.setState(
      {
        cOrderBy: cNewOrderBy,
        cOrderType: cNewOrderType,
        nSelectThIndex: nIndex
      },
      () => {
        fnHandleClick(evt, cNewOrderBy, cNewOrderType);
      }
    );
  };

  render() {
    const { cTableClassName = '', aColumn, children } = this.props;
    const cClassName = `report-table ${cTableClassName}`.trim();
  
    return (
      <div className="table-container-controler">
        <div className="overlay-left" onClick={this.scrollLeft}>&laquo;</div>
        <div className="table-container" ref={(el) => { this.containerRef = el; }}>
          <Table responsible className={cClassName}>
            <thead>
              <tr>
                {aColumn.map((oColumn, nIndex) => (
                  <th
                    key={nIndex}
                    tabIndex="0"
                    onClick={evtClick => {
                      if (oColumn.bIsDisable === undefined || !oColumn.bIsDisable)
                        this.handleClick(evtClick, nIndex, oColumn.cOrderBy, oColumn.fnHandleClick);
                    }}
                    onKeyPress={evtClick => {
                      if (oColumn.bIsDisable === undefined || !oColumn.bIsDisable)
                        this.handleClick(evtClick, nIndex, oColumn.cOrderBy, oColumn.fnHandleClick);
                    }}
                    className={
                      nIndex === this.state.nSelectThIndex && (oColumn.bIsDisable === undefined || !oColumn.bIsDisable)
                        ? 'v2-filter-orderDirection'
                        : ''
                    }
                  >
                    {oColumn.cName}
                    {(oColumn.bIsDisable === undefined || !oColumn.bIsDisable) && (
                      <img
                        style={{ display: nIndex === this.state.nSelectThIndex ? 'inline' : 'none' }}
                        src={this.state.cOrderType === 'asc' ? imgfSelectUp : imgfSelectDown}
                        alt={intl.get('direction')}
                      />
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>{children}</tbody>
          </Table>
          
        </div>
        <div className="overlay-right" onClick={this.scrollRight}>&raquo; </div>
      </div>
    );
  }
  
}

ReportTableCustom.defaultProps = {
  cTableClassName: '',
  aColumn: []
};

export default ReportTableCustom;
