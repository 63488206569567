import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { Button } from 'reactstrap';
import AltCheckbox from '../../components/AltCheckbox';
import Axios from '../../config/Axios';
import Page from '../default-v2/Page';
import CardHeader from '../default-v2/CardHeader';
import Breadcrumb from '../../components/Breadcrumbs';
import './styles/configheaderfooter.scss';
import { can, hasModule } from '../../config/Permissions';
import imgfConfig from '../default-v2/img/icon-config.svg';

export class ConfigHeaderFooter extends Component {
  bIsMounted = false;

  constructor(oProps) {
    super(oProps);

    this.state = {
      bLoading: true,
      aItems: [],
      bIsSending: false,
      aPages: [
        {
          cName: 'configs',
          cLink: undefined
        },
        {
          cName: 'ConfigHeaderFooter.breadcrumb_config_header_footer',
          cLink: undefined
        }
      ]
    };
  }

  componentDidMount = () => {
    this.bIsMounted = true;

    const { setAlert, history } = this.props;

    if (!can('admin', 'documents')) {
      history.push({
        pathname: '/',
        state: { aAlertMessages: { cType: 'error', cMsg: intl.get('sem_permissao') } }
      });
    }

    Axios.get('/config', this.state)
      .then(oResponse => {
        if (this.bIsMounted) {
          this.setState({
            aItems: oResponse.data.aItems,
            bLoading: false
          });
        }
      })
      .catch(oError => {
        setAlert('error', oError.aMsgErrors);
      });
  };

  componentWillUnmount() {
    this.bIsMounted = false;
  }

  handleSubmit = evt => {
    evt.preventDefault();

    const aHeaderFooterIds = [];
    const { aItems } = this.state;
    const { setAlert } = this.props;

    Object.keys(aItems).forEach(cCategory => {
      Object.keys(aItems[cCategory]).forEach(cKey => {
        if (aItems[cCategory][cKey].hfit_cuse) {
          aHeaderFooterIds.push(aItems[cCategory][cKey].hfit_nid);
        }
      });
    });
    this.setState({
      bIsSending: true
    });

    Axios.post('/config', { aIds: aHeaderFooterIds })
      .then(() => {
        this.setState({
          bIsSending: false
        });
        setAlert('success', intl.get('ConfigHeaderFooter.configuracao_salva'));
      })
      .catch(oError => {
        this.setState({
          bIsSending: false
        });
        setAlert('error', oError.aMsgErrors);
      });
  };

  handleChange = (evt, cCategory, cKey) => {
    const { aItems } = this.state;
    aItems[cCategory][cKey].hfit_cuse = evt.target.checked;

    this.setState({
      aItems
    });
  };

  generateCardHeader = () => {
    const { history } = this.props;

    const rcmpCardHeader = <CardHeader history={history} />;
    return rcmpCardHeader;
  };

  render() {
    const { aItems, bIsSending, bLoading, aPages } = this.state;
    const { history, getAlert } = this.props;

    const rcmpBreadcrumb = <Breadcrumb aPages={aPages} />;

    const rcmpCardHeader = this.generateCardHeader();

    const cPageTitle = intl.get('ConfigHeaderFooter.config_footer');

    return (
      <Page
        loading={bLoading ? 1 : 0}
        rcmpBreadcrumb={rcmpBreadcrumb}
        cTitle={cPageTitle}
        cImage={imgfConfig}
        rcmpCardHeader={rcmpCardHeader}
        className="v2-config-footer"
        cCurrentSideMenuOption={intl.get('configs')}
      >
        {getAlert()}
        <p className="config-footer-msg">{intl.get('ConfigHeaderFooter.msg_info')}</p>

        <form onSubmit={this.handleSubmit}>
          {Object.keys(aItems).map(cCategory => (
            <fieldset key={cCategory}>
              <div className="config-head">
                <legend>{intl.get(`ConfigHeaderFooter.${cCategory}`)}</legend>
              </div>

              <div className="config-body">
                {Object.keys(aItems[cCategory]).map(cKey => {
                  const oItem = aItems[cCategory][cKey];

                  return (
                    <AltCheckbox
                      onChange={evt => this.handleChange(evt, cCategory, cKey)}
                      checked={oItem.hfit_cuse}
                      label={intl.get(`ConfigHeaderFooter.${oItem.hfit_cslugtitle}`)}
                      name="hfit_nid[]"
                      value={oItem.hfit_nid}
                      key={oItem.hfit_nid}
                    />
                  );
                })}
              </div>
            </fieldset>
          ))}

          <div className="button-stack">
            <Button type="button" className="btn-cancel" onClick={history.goBack} disabled={bIsSending}>
              {intl.get('cancel')}
            </Button>
            <Button type="submit" className="btn" disabled={bIsSending}>
              {intl.get('salvar')}
            </Button>
          </div>
        </form>
      </Page>
    );
  }
}

export default ConfigHeaderFooter;
