import React, { Component } from 'react';
import intl from 'react-intl-universal';
import imgfNoPic from '../../assets/img/nopic.svg';
import Axios from '../../config/Axios';
import url from '../../config/Url';
import PageClean from '../default-v2/PageClean';
import { Button } from 'reactstrap';
import moment from 'moment';
import { getStatus } from '../default/Status';
import { checkModule } from '../../config/Permissions';
import EncodedLocalStorage from '../../config/EncodedLocalStorage';

const oLocalStorage = new EncodedLocalStorage();
export class Request extends Component {
  constructor(oProps) {
    super(oProps);
    const nRequestId =
      this.props.nRequestId && this.props.nRequestId !== undefined
        ? this.props.nRequestId
        : oProps.match.params.nRequestId;
    this.state = {
      bLoading: true,
      oRequest: undefined,
      nRequestId: nRequestId
    };
  }

  componentDidMount() {
    const { history } = this.props;
    checkModule(history, 'documents');

    this.fetchData();
  }

  fetchData = () => {
    const { nRequestId } = this.state;
    const { setAlert } = this.props;

    Axios.get(`/request/${nRequestId}`)
      .then(oResponse => {
        this.setState({
          bLoading: false,
          oRequest: oResponse.data.oRequest
        });
      })
      .catch(oError => {
        this.setState({ bLoading: false });
        setAlert('error', oError.aMsgErrors);
      });
  };

  getTitle = oRequest => {
    if ((oRequest || {}).eType === 'delete') {
      return <h1>{intl.get('Request.envio_arquivo')}</h1>;
    }
    if ((oRequest || {}).ver_nid) {
      return (
        <h1>
          {intl.get('Request.edicao_em')} {oRequest.document.doc_ctitle}
        </h1>
      );
    }
    return <h1>{intl.get('Request.novo_documento')}</h1>;
  };

  requestNotificationType = nAccept => {
    let cResult = '';
    if (nAccept === 30) {
      cResult = 'success';
    } else if (nAccept === 32) {
      cResult = 'error';
    } else if (nAccept === 31) {
      cResult = 'removed';
    }
    return cResult;
  };

  renderRequest = () => {
    const { oRequest } = this.state;
    const { closeModal } = this.props;

    if (!oRequest) return null;

    return (
      <>
        {this.getTitle(oRequest)}

        <div className="request-info">
          <div className="request">
            <strong>{intl.get('Request.titulo')}</strong>
            <p>{oRequest.rqst_ctitle}</p>
            <br />
            <strong>{intl.get('Request.justificativa')}</strong>
            <p style={{ whiteSpace: 'pre-wrap' }}>{oRequest.rqst_cjustification}</p>
            <br />
          </div>

          <div className="requester">
            <h2>{intl.get('Request.solicitante')}</h2>

            <div className="avatar">
              <img
                src={
                  oRequest.user_requester.user_cavatar
                    ? oRequest.user_requester.user_cavatar
                    : imgfNoPic
                }
                alt={oRequest.user_requester.user_cname}
              />
            </div>
            <p className="name" title={oRequest.user_requester.user_cname}>
              {oRequest.user_requester.user_cname}
            </p>
          </div>

          <div className="requester">
            <h2>{intl.get('Request.requested_dotted')}</h2>

            <div className="avatar">
              <img
                src={
                  oRequest.user_destiny.user_cavatar
                    ? oRequest.user_destiny.user_cavatar
                    : imgfNoPic
                }
                alt={oRequest.user_destiny.user_cname}
              />
            </div>
            <p className="name" title={oRequest.user_destiny.user_cname}>
              {oRequest.user_destiny.user_cname}
            </p>
          </div>
        </div>

        <div className={`notification-response ${this.requestNotificationType(oRequest.stat_nid)}`}>
          <p>
            {getStatus('accepted', 'Request', oRequest.status) && <strong>{intl.get('Request.aceita')}</strong>}
            {getStatus('removed', 'Request', oRequest.status) &&
              intl.getHTML('Request.removed_in', {
                date: moment
                  .parseZone(oRequest.rqst_ddeleted)
                  .tz(oLocalStorage.get('cTimezone'))
                  .format('DD/MM/YYYY')
              })}
            {getStatus('rejected', 'Request', oRequest.status) && (
              <>
                <strong>{intl.get('Request.justificativa_rejeicao')}</strong>
                {oRequest.rqst_crejectjustification}
              </>
            )}
          </p>
        </div>
        <div className="button-stack">
          {closeModal ? (
            <Button type="button" className="btn alternate back" onClick={() => closeModal()}>
              {intl.get('cancelar')}
            </Button>
          ) : (
            ''
          )}
        </div>
      </>
    );
  };

  render() {
    const { bLoading } = this.state;
    return <PageClean loading={bLoading ? 1 : 0}>{this.renderRequest()}</PageClean>;
  }
}

export default Request;
