import React, { Component } from 'react';
import OutsideHandlerClick from './OutsideHandlerClick';

export class SubMenu extends Component {
  constructor(oProps) {
    super(oProps);
    this.state = {
    };
  }

  componentDidMount() {

  }

  openSubmenu = evt => {
    if (evt.target.closest('div').getElementsByClassName('submenu')) {
      evt.target.closest('div').getElementsByClassName('submenu')[0].style = 'display: block';
    }
  };

  closeSubmenu = (evt, elWrapperRef) => {
    const elSubmenu = elWrapperRef.getElementsByClassName('submenu')[0];
    if (elSubmenu) {
      elSubmenu.style = 'display: none';
    }
  };

  render() {
    const { children } = this.props;

    return (
      <div className="right-menu">
        <span
          role="button"
          tabIndex="0"
          onKeyPress={evt => this.openSubmenu(evt)}
          onClick={evt => this.openSubmenu(evt)}
          title=""
          className="btn-menu"
        >
          <span />
        </span>

        <OutsideHandlerClick handleClickOutside={this.closeSubmenu}>
          <ul className="submenu">
            {children}
          </ul>
        </OutsideHandlerClick>

      </div>
    );
  }

};

export default SubMenu;
