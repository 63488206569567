import React, { Component } from 'react';
import visaLogo from '../../../assets/img/cc-visa.svg';
import mastercardLogo from '../../../assets/img/cc-mastercard.svg';
import maestroLogo from '../../../assets/img/cc-maestro.svg';
import dinnersClubLogo from '../../../assets/img/cc-diners-club.svg';
import discoverLogo from '../../../assets/img/cc-discover.svg';
import jcbLogo from '../../../assets/img/cc-jcb.svg';
import amexLogo from '../../../assets/img/cc-amex.svg';
import hiperCardLogo from '../../../assets/img/cc-hipercard.svg';
import unionPayLogo from '../../../assets/img/cc-unionpay.svg';
import eloLogo from '../../../assets/img/cc-elo.svg';
import creditCardIcon from '../../../assets/img/cc.svg';
import creditCardType from 'credit-card-type';
import '../styles/creditcarddisplay.scss';

export class CreditCardDisplay extends Component {
  constructor(oProps) {
    super(oProps);

    this.CREDITCARD_TYPE_LOGO = {
      visa: visaLogo,
      mastercard: mastercardLogo,
      'american-express': amexLogo,
      'diners-club': dinnersClubLogo,
      discover: discoverLogo,
      jcb: jcbLogo,
      unionpay: unionPayLogo,
      maestro: maestroLogo,
      mir: creditCardIcon,
      elo: eloLogo,
      hiper: creditCardIcon,
      hipercard: hiperCardLogo
    };

    this.state = {
      bEnabled: oProps.bStartEnabled
    };
  }

  formatCreditCardNumber = () => {
    if (this.props.cCreditCardNumber?.length >= 4) {
      return '**** ' + this.props.cCreditCardNumber.substr(this.props.cCreditCardNumber.length - 4);
    }
    return 'N/A';
  };

  formatCreditCardDueDate = () => {
    let cDueDate = '';
    cDueDate = this.props.cDueMonth?.length > 0 ? `${this.props.cDueMonth}` : 'N/A';
    cDueDate += this.props.cDueYear?.length > 0 ? ` / ${this.props.cDueYear}` : ' / N/A';

    return cDueDate;
  };

  getCreditCardBrand = () => {
    const creditCardPrefix = this.props.cCreditCardNumber.substr(0, 6);
    const cardType = creditCardType(creditCardPrefix);

    if (cardType.length === 1) {
      return (
        <img
          className="credit-card-logo"
          src={this.CREDITCARD_TYPE_LOGO[cardType[0].type]}
          alt={cardType[0].niceType + ' logo'}
        />
      );
    }

    return <i className="icon icon-credit-card" />;
  };

  render() {
    return (
      <>
        {this.getCreditCardBrand()}
        <span>{`${this.formatCreditCardNumber()} (${this.formatCreditCardDueDate()})`}</span>
      </>
    );
  }
}

export default CreditCardDisplay;
