import React, { Component } from 'react';
import intl from 'react-intl-universal';
import Select from 'react-select';
import Page from '../default/Page';
import { Form } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert/lib/dist/SweetAlert';
import Axios from '../../config/Axios';
//import { Alert } from '../../components/Alert';
import AltCheckbox from '../../components/AltCheckbox';
import { checkModule } from '../../config/Permissions';

class NormExport extends Component {
  constructor(props) {
    super(props);
    const { oNormExport } = this.props;

    this.state = {
      bIsConfirming: false,
      rcmpAlert: null,
      bIsSending: false,
      bRequestAuthorizationNorm: false,
      aSelectedResponsible: [],
      aUsers: [],
    };

    this.handleChange = this.handleChange.bind(this);
    // this.handleSelecionarResponsavel = this.handleSelecionarResponsavel.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  componentDidMount() {
    const { aNormModels, cName } = this.state;
    const { history } = this.props;
    checkModule(history, 'quality-panel');

    Axios.get('/user').then(oResponse => {
      this.setState({
        aUsers: oResponse.data.aUsers.map(user => ({ label: user.user_cname, value: user.user_nid }))
      });
    });

    if (cName === '') {
      Axios.get('norm/model').then(oResponse => {
        Object.keys(oResponse.data).forEach(cKey => {
          aNormModels.push({
            label: oResponse.data[cKey].norm_cinitials,
            custom: oResponse.data[cKey].norm_bfromclient,
            value: JSON.stringify({
              norm_nid: Number(oResponse.data[cKey].norm_nid),
              norm_bfromclient: oResponse.data[cKey].norm_bfromclient
            })
          });
        });

        this.setState({
          bLoading: false,
          aNormModels
        });
      });
    } else {
      this.setState({
        bLoading: false
      });
    }
  }

  handleChange = evt => {
    const {
      target: { name, value, type, checked },
    } = evt;
    let bShowResponsibleSelect = this.state.bShowResponsibleSelect;
    if (name === 'bRequestAuthorizationNorm') {
      bShowResponsibleSelect = checked;
      if (!checked) { // adiciona essa condição para zerar o estado aSelectedResponsible
        this.setState({ aSelectedResponsible: [] });
      }
    }
    this.setState({
      [name]: type === 'checkbox' ? checked : value,
      bShowResponsibleSelect,
    });
  };

  handleChangeCustom = (elSelectedOption, cType = undefined) => {
    if (cType === undefined) {
      try {
        const oSelectedOption = JSON.parse(elSelectedOption.value);
        this.setState({
          nNormModelId: elSelectedOption ? oSelectedOption.norm_nid : '',
          normBfromclient: elSelectedOption ? elSelectedOption.custom : ''
        });
      } catch (error) {
        this.setState({
          nNormModelId: '',
          normBfromclient: ''
        });
      }
    }

    if (cType !== undefined) {
      this.setState({
        [cType]: elSelectedOption.map(oOpt => oOpt.value)
      });
    }
  };

  handleClose = (props) => {
    // this.setState({ showExportModal: false });
    this.props.closeModal();
  };

  handleCancel() {
    this.props.onClose();
  }

  handleSave(event) {
    event.preventDefault();
    this.setState({
      rcmpAlert: (
        <SweetAlert
          warning
          showCancel
          confirmBtnText="Confirmar"
          cancelBtnText="Cancelar"
          confirmBtnBsStyle="primary"
          title=""
          onConfirm={this.handleSubmit}
          onCancel={() => this.setState({ rcmpAlert: null })}
          focusCancelBtn
        >
          Tem certeza que deseja exportar esta norma?
        </SweetAlert>
      ),
    });
  }

  handleSubmit = () => {
    if (this.state.bIsSending) {
      return; // Impede a execução adicional da função se já estiver enviando
    }

    const data = {
      nId_norm: this.props.oNormExport.norm_nid,
      bAuthorizeNorm: this.state.bRequestAuthorizationNorm ? '1' : '0',
      aResponsible: this.state.aSelectedResponsible.map(oOpt => (oOpt.value ? oOpt.value : oOpt)) || ''
    };
    this.setState({
      bIsConfirming: true,
      bIsSending: true
    });
    // Utiliza o Axios para enviar as informações para o endpoint
    Axios.post('norm/modelExport', data)
      .then(() => {
        this.setState({
          bIsSending: false,
          bShowAlert: true,
          rcmpAlert: (
            <SweetAlert
              success
              title=""
              onConfirm={() => this.handleAlertConfirm()}
            >
              As informações da norma foram enviadas com sucesso!
            </SweetAlert>
          ),
        });
      })
      .catch(() => {
        this.setState({
          bIsSending: false,
          bShowAlert: true,
          rcmpAlert: (
            <SweetAlert
              error
              title="Erro ao exportar norma"
              onConfirm={() => this.handleAlertConfirm()}
            >
              Houve um erro ao exportar a norma. Tente novamente mais tarde.
            </SweetAlert>
          ),
        });
      });
  };


  handleAlertConfirm = () => {
    this.setState({
      bIsConfirming: false,
      bShowAlert: false,
      rcmpAlert: null
    });
    this.handleClose(); // adicionado para fechar o componente NormExport
  };

  handleSelectedResponsible = selectedOptions => {
    this.setState({ aSelectedResponsible: selectedOptions });
  };


  render() {
    const {
      bIsSending,
      bRequestAuthorizationNorm,
      aSelectedResponsible,
      aUsers,
      bIsConfirming
    } = this.state;
    const { closeModal, oNormExport } = this.props;
    return (
      <Page>
        <form className="form" onSubmit={this.handleSave}>
          <div style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.5rem' }}>{intl.getHTML('QualityNormModal.norm_name')} {oNormExport.norm_cinitials}.</div>
          <div style={{ fontWeight: 'bold', textAlign: 'center' }}>{intl.getHTML('QualityNormModal.texto_exportar_norma')}</div>
          <label className="split1 colspan" style={{ marginBottom: 0 }}>
            <AltCheckbox
              title={intl.getHTML('QualityNormModal.exige_autorizacao')}
              label={intl.getHTML('QualityNormModal.exige_autorizacao')}
              name="bRequestAuthorizationNorm"
              onChange={evtChange => this.handleChange(evtChange)}
              className="split1"
              checked={bRequestAuthorizationNorm}
            />
          </label>

          {bRequestAuthorizationNorm && (
            <label className="split1 colspan">
              {intl.getHTML('QualityNormModal.responsaveis_autorizacao')}
              <Select
                className="selectCustom select"
                classNamePrefix="react-select"
                noOptionsMessage={() => intl.get('sem_opcoes')}
                placeholder={intl.get('QualityNormModal.atribuir_resposanveis')}
                required={bRequestAuthorizationNorm}
                isClearable
                isSearchable
                isMulti
                options={aUsers}
                maxMenuHeight={130}
                defaultValue=""
                onChange={this.handleSelectedResponsible}
              />
            </label>
          )}


          <div className="button-stack" style={{ display: 'flex', justifyContent: 'center' }}>
            <button type="button" onClick={() => closeModal()} className="btn alternate back btn btn-outline-secondary">
              {intl.get('cancelar')}
            </button>
            <button
              type="submit"
              title="Salvar"
              className={`btn btn-secondary ${bIsSending || bIsConfirming || (bRequestAuthorizationNorm && aSelectedResponsible.length === 0)
                ? 'disabled'
                : ''
                }`}
              defaultValue='0'
              required={(bRequestAuthorizationNorm && aSelectedResponsible.length > 0) ? true : false}
              disabled={bRequestAuthorizationNorm && aSelectedResponsible.length === 0}
            >
              {intl.get('salvar')}
            </button>
          </div>
        </form>
        {this.state.rcmpAlert}
      </Page>
    );
  }
}

export default NormExport;


