import React, { Component } from 'react';
import intl from 'react-intl-universal';
import './styles/attachment.scss';

class Attachments extends Component {
  constructor(oProps) {
    super(oProps);
    this.state = {};
  }


  render() {
    const { oAttachment, bIsToActivateCloseButton, fnHandleDownloadAttachments, fnHandleDeleteAttachments } = this.props;

    return (
      <div className="attachment">
        <a
          href="/"
          onClick={fnHandleDownloadAttachments}
          title={oAttachment.atch_cname}
        >
          {oAttachment.atch_cname}
        </a>

        {bIsToActivateCloseButton ? (
          <a
            href="/"
            onClick={fnHandleDeleteAttachments}
            title={intl.get('DocumentEditor.excluir_anexo')}
          >
            <span>x</span>
          </a>
        ) : ''}
      </div>
    );
  }
};

Attachments.defaultProps = {
  bIsToActivateCloseButton: false,
  oAttachment: null,
  fnHandleDownloadAttachments: () => { },
  fnHandleDeleteAttachments: () => { },
};

export default Attachments;
