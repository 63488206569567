import intl from 'react-intl-universal';
import { oLocalStorage } from './EncodedLocalStorage';

/**
 * Verifica se o cliente possui o módulo.
 * @param {string} cModuleSlug
 */
export function hasModule(cModuleSlug) {
  try {
    if (oLocalStorage.get('bTrial') == true) {
      return true;
    }
    const aModules = oLocalStorage.get('aModules') || [];
    return aModules.includes(cModuleSlug);
  } catch (oExcpHasModule) {
    return false;
  }
}

/**
 * Verifica se o usuário possui pelo menos uma das permissões passadas.
 * @param {string|string[]} varPermissions
 * @param {string} [cModuleSlug] caso seja fornecido, primeiro valida que o cliente do usuário possui o módulo
 */
export function canAtLeast(varPermissions, cModuleSlug) {
  try {
    if (cModuleSlug && !hasModule(cModuleSlug)) {
      return false;
    }

    if(oLocalStorage.get('bAdmin') == true) {
      return true;
    }

    const oMyPermissions = oLocalStorage.get('aPermissions') || [];
    if (typeof varPermissions === 'string') {
      return oMyPermissions.includes(varPermissions);
    }
    return varPermissions.some(elPermission => oMyPermissions.includes(elPermission));
  } catch (e) {
    return false;
  }
}

/**
 * Verifica se o usuário possui todas as permissões passadas.
 * @param {string|string[]} varPermissions
 * @param {string} [cModuleSlug] caso seja fornecido, primeiro valida que o cliente do usuário possui o módulo
 */
export function can(varPermissions, cModuleSlug) {
  try {
    if (cModuleSlug && !hasModule(cModuleSlug)) {
      return false;
    }

    if(oLocalStorage.get('bAdmin') == true) {
      return true;
    }

    const oMyPermissions = oLocalStorage.get('aPermissions') || [];
    if (typeof varPermissions === 'string') {
      return oMyPermissions.includes(varPermissions);
    }
    return varPermissions.every(elPermission => oMyPermissions.includes(elPermission));
  } catch (e) {
    return false;
  }
}

/**
 * Verifica se o cliente possui o módulo e, caso não possua, redireciona para a home com uma mensagem de erro.
 * @param {object} history `history` do componente que chama a função.
 * @param {string} cModuleSlug
 */
export function checkModule(history, cModuleSlug) {
  if (!hasModule(cModuleSlug)) {
    history.push({
      pathname: '/',
      state: { aAlertMessages: { cType: 'error', cMsg: intl.get('sem_permissao') } }
    });
  }
}
