import Axios from '../config/Axios';

const cDefaultLink = '/notification';

export default function getNotification(cNotificationLink) {
  const cLinkNotification = cNotificationLink || cDefaultLink;

  if (cLinkNotification) {
    return Axios.get(cLinkNotification)
      .then(oResponse => new Promise(resolve => {
        resolve({
          aNotifications: oResponse.data.data,
          cLinkNextPage: oResponse.data.next_page_url
        });
      }));
  }

  return { cLinkNextPage: null };
}


