import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { Button } from 'reactstrap';
import TreeView from '../../components/TreeView';
import PageClean from '../default-v2/PageClean';
import authorizedFolders from '../../actions/ActionAuthorizedFolders';
import { getMoveDocument, moveDocument } from '../../actions/ActionDocumentMove';
import { Alert } from '../../components/Alert';
import { checkModule } from '../../config/Permissions';

class DocumentMove extends Component {
  constructor(oProps) {
    super(oProps);

    this.state = {
      bLoading: true,
      nDocumentId: oProps.match ? Number(oProps.match.params.nDocumentId) : oProps.oDocument.doc_nid,
      bIsSend: false
    };
  }

  componentDidMount() {
    const { nDocumentId } = this.state;

    const { history } = this.props;
    checkModule(history, 'documents');

    Promise.all([
      getMoveDocument(nDocumentId).then(oDocument => this.setState({ oDocument, nFolderId: oDocument.fldr_nid })),
      authorizedFolders().then(aFolders => this.setState({ aTreeViewFolders: aFolders }))
    ])
      .then(() => this.setState({ bLoading: false }))
      .catch(oError => {
        this.setState({
          bLoading: false,
          rcmpAlertMsg: (
            <Alert type="error" isOpen onCloseAlert={() => {}}>
              {oError.aMsgErrors}
            </Alert>
          )
        });
      });
  }

  inputChangeHandler = evt => {
    this.setState({ nFolderId: evt.target.value });
  };

  handleSubmit = evt => {
    const { oDocument, nFolderId, nDocumentId } = this.state;
    const { onSuccess } = this.props;

    evt.preventDefault();
    this.setState({
      bIsSend: true
    });

    if (oDocument.fldr_nid === nFolderId) {
      this.setState({
        rcmpAlertMsg: (
          <Alert type="error" isOpen onCloseAlert={() => {}}>
            {intl.get('DocumentMove.encontra')}
          </Alert>
        )
      });

      this.setState({
        bIsSend: false
      });
      return;
    }

    moveDocument(nDocumentId, nFolderId)
      .then(() => {
        onSuccess(intl.get('DocumentMove.movido'));
      })
      .catch(oError => {
        this.setState({
          rcmpAlertMsg: (
            <Alert type="error" isOpen onCloseAlert={() => {}}>
              {oError.aMsgErrors}
            </Alert>
          )
        });
      });
  };

  render() {
    const { oDocument, bIsSend, aTreeViewFolders, bLoading, rcmpAlertMsg } = this.state;
    const { closeModal } = this.props;

    return (
      <PageClean loading={bLoading ? 1 : 0}>
        {oDocument && (
          <h1>
            {' '}
            {intl.get('DocumentMove.mover_documento')}: {oDocument.doc_ctitle}{' '}
          </h1>
        )}
        <form className="form" onSubmit={evt => this.handleSubmit(evt)}>
          {rcmpAlertMsg}
          <h2 className="inner-title">{intl.get('documento')}</h2>

          <header id="inactive-header">{oDocument ? oDocument.doc_ctitle : ''}</header>

          {oDocument && (
            <TreeView
              aFolders={aTreeViewFolders}
              nFolderId={oDocument.fldr_nid}
              handleChange={this.inputChangeHandler}
              cHeight="250px"
              id="tree-view"
            />
          )}
          <div className="button-stack">
            <Button type="button" onClick={closeModal} className="btn btn-cancel">
              {intl.get('cancel')}
            </Button>
            <Button disabled={!oDocument || bIsSend} type="submit">
              {intl.get('mover')}
            </Button>
          </div>
        </form>
      </PageClean>
    );
  }
}

export default DocumentMove;
