import Axios from '../config/Axios';

export function myPendingDocuments() {
  return Axios.get('/my-account/pending-document', { params: { "bResponsible": false } }).then(
    (oResponse) => oResponse.data
  );
}

export function documentsIAmResponsibleFor() {
  return Axios.get('/my-account/pending-document', { params: { "bResponsible": true } }).then(
    (oResponse) => oResponse.data
  );
}

export function documentsIAmResponsibleSearchFor() {
  return Axios.get('my-account/pending-document-search', { params: { "bResponsible": true } }).then(
    (oResponse) => oResponse.data
  );
}
