// depois pensar uma maneira de colocar divs dentro de li sem quebrar o layout. o elemento "li" por padrão não deve possuir atributo tabIndex
// (deixei comentado porque pretendo mexer nisso o mais breve possível)
import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import intl from 'react-intl-universal';
import moment from 'moment';
import Page from '../default/Page';
import Breadcrumb from '../../components/Breadcrumbs';
import Norm from './Norm';
import Axios from '../../config/Axios';
import { can, checkModule } from '../../config/Permissions';
import EncodedLocalStorage from '../../config/EncodedLocalStorage';

class NormIndex extends Component {
  constructor(oProps) {
    super(oProps);

    this.state = {
      bLoading: true,
      aNorms: [],
      aPages: [{ cName: 'Norm.titulo', cLink: undefined }]
    };
  }

  componentDidMount = () => {
    const { history } = this.props;
    checkModule(history, 'quality-panel');

    Axios.get('/norm')
      .then(oRes => {
        this.setState({
          aNorms: oRes.data,
          bLoading: false
        });
      })
      .catch(() => { });
  };

  hideAlert = () => {
    this.setState({
      rcmpAlert: null
    });
  };

  successMessage = cMsg => {
    const { setAlert } = this.props;
    this.hideAlert();
    this.componentDidMount();
    setAlert('success', cMsg);
  };

  openNorms = evt => {
    evt.preventDefault();
    this.setState({
      rcmpAlert: (
        <SweetAlert
          customClass="modal-edit md disabled-overflow"
          title=""
          onConfirm={this.hideAlert}
          showConfirm={false}
        >
          <div className="head-modal">
            {intl.get('Norm.nova_norma')}
            <span
              role="button"
              tabIndex="0"
              aria-labelledby="head-modal"
              onKeyPress={this.hideAlert}
              onClick={this.hideAlert}
              className="close"
              href=""
            />
          </div>
          <Norm
            onSuccess={cMsg => {
              this.successMessage(cMsg);
            }}
            closeModal={this.hideAlert}
          />
        </SweetAlert>
      )
    });
  };

  render() {
    const { bLoading, aPages, rcmpAlert, aNorms } = this.state;
    const { history } = this.props;
    const oLocalStorage = new EncodedLocalStorage();

    return (
      <Page loading={bLoading ? 1 : 0}>
        <h1>{intl.get('Norm.titulo')}</h1>
        {rcmpAlert}
        <Breadcrumb aPages={aPages} />
        {can('create-edit-norm-qualitypanel', 'quality-panel') && (
          <div className="buttonStack">
            <button type="button" onClick={evt => this.openNorms(evt)} className="btn primary no-left-margin">
              {intl.get('Norm.nova_norma')}
            </button>
          </div>
        )}
        <div id="table-emule">
          <div className="head-table">
            <ul>
              <li>{intl.get('Norm.titulo')}</li>
              <li>{intl.get('Norm.requisitos')}</li>
              <li>{intl.get('Norm.concluidos')}</li>
              <li className="mobile480-hide">{intl.get('Norm.inclusao_de_evidencia_mais_recente')}</li>
            </ul>
          </div>

          <div className="body-table">
            <ul>
              {aNorms.map(oNorm => (
                <li
                  title={oNorm.norm_cname}
                  onKeyPress={() => {
                    history.push(`/requisite/${oNorm.norm_nid}`);
                  }}
                  onClick={() => {
                    history.push(`/requisite/${oNorm.norm_nid}`);
                  }}
                  key={oNorm.norm_nid}
                >
                  <span>{oNorm.norm_cname}</span>
                  <span>{oNorm.requisite_count}</span>
                  <span>{oNorm.finishedRequisites}</span>
                  <span className="mobile480-hide">
                    {oNorm.evidenceLastChange
                      ? moment
                        .parseZone(oNorm.evidenceLastChange)
                        .tz(oLocalStorage.get('cTimezone'))
                        .format('DD/MM/YYYY HH:mm:ss')
                      : ' '}
                  </span>
                  {/* </div> */}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Page>
    );
  }
}

export default NormIndex;
