import React, { Component } from 'react';
import { Button, Input } from 'reactstrap';
import intl from 'react-intl-universal';
import Axios from '../../config/Axios';
import Alert from '../../components/Alert';
import PageClean from '../default-v2/PageClean';
import { checkModule } from '../../config/Permissions';

export class SuggestEdition extends Component {
  constructor(oProps) {
    super(oProps);

    this.state = {
      bLoading: true,
      nRequestId: oProps.match ? oProps.match.params.nRequestId : oProps.nRequestId,
      oRequest: {},
      oDocument: {}
    };
  }

  componentDidMount() {
    const { nRequestId } = this.state;
    const { setAlert, closeModal, history } = this.props;
    checkModule(history, 'documents');

    Axios.get(`/request/${nRequestId}`)
      .then(oResponse => {
        const { oRequest, oDocument } = oResponse.data;
        this.setState({
          oRequest,
          oDocument,
          bLoading: false
        });
      })
      .catch(oError => {
        this.setState({
          bLoading: false,
          bIsSending: false
        });
        if (closeModal) {
          this.setState({
            bLoading: false,
            bIsSending: false,
            rcmpAlertMsg: (
              <Alert type="error" isOpen onCloseAlert={() => {}}>
                {oError.aMsgErrors || intl.get('SuggestEdition.erro_criando')}
              </Alert>
            )
          });
        } else {
          this.setState({
            bLoading: false,
            bIsSending: false
          });
          setAlert('error', oError.aMsgErrors);
        }
      });
  }

  handleChange = evt => {
    const { oRequest } = this.state;
    oRequest[evt.target.name] = evt.target.value;
    this.setState({ oRequest });
  };

  handleSubmit = evt => {
    evt.preventDefault();

    this.setState({ bIsSending: true });

    const { oRequest, nRequestId } = this.state;
    const { onSuccess } = this.props;

    Axios.post(`/version/reject/${oRequest.ver_nid}`, {
      cText: oRequest.rqst_cjustification,
      nRequestId
    })
      .then(() => {
        this.setState({
          bLoading: false,
          bIsSending: false
        });
        onSuccess(intl.get('RequestCreate.sucesso_criando'));
      })
      .catch(oError => {
        this.setState({
          bLoading: false,
          bIsSending: false,
          rcmpAlertMsg: (
            <Alert type="error" isOpen onCloseAlert={() => {}}>
              {oError.aMsgErrors || intl.get('RequestCreate.erro_criando')}
            </Alert>
          )
        });
      });
  };

  render() {
    const { oRequest, oDocument, bIsSending, rcmpAlertMsg, bLoading } = this.state;
    const { history, closeModal } = this.props;

    return (
      <PageClean loading={bLoading ? 1 : 0}>
        {rcmpAlertMsg}

        <form className="form" onSubmit={this.handleSubmit}>
          <label>
            <h2> {intl.get('SuggestEdition.documento')}</h2>
            <p>{oDocument.doc_ctitle}</p>
          </label>

          <label>
            {intl.get('SuggestEdition.justificativa')} <span className="required">*</span>
            <Input
              type="textarea"
              required
              name="rqst_cjustification"
              cols="50"
              rows="10"
              onChange={this.handleChange}
              value={oRequest.rqst_cjustification}
            />
          </label>

          <div className="button-stack">
            {closeModal ? (
              <Button type="button" className="btn alternate back" onClick={() => closeModal()}>
                {intl.get('cancel')}
              </Button>
            ) : (
              <Button type="button" className="btn alternate alert" onClick={() => history.goBack()}>
                {intl.get('cancel')}
              </Button>
            )}
            <Button disabled={bIsSending} type="submit" className="btn">
              {intl.get('suggest')}
            </Button>
          </div>
        </form>
      </PageClean>
    );
  }
}

export default SuggestEdition;
