import Axios from './Axios';
import { signOut } from './Authentication';

export default function logout() {
  signOut();
  Axios.get('/logout')
    .then(() => {
      window.location.reload();
    })
    .catch(() => {
      window.location.reload();
    });
}
