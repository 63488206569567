/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint import/no-cycle: [2, { maxDepth: 1 }] */

import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Alert } from '../../components/Alert';
import intl from 'react-intl-universal';
import { Input } from 'reactstrap';
import Page from '../default-v2/Page';
import Breadcrumb from '../../components/Breadcrumbs';
import Axios from '../../config/Axios';
import OutsideHandlerClick from '../../components/OutsideHandlerClick';
import AltCheckbox from '../../components/AltCheckbox';
import ScrollBar from '../../components/ScrollBar';
import { can, checkModule } from '../../config/Permissions';
import url from '../../config/Url';
import imgfNoPic from '../../assets/img/nopic.svg';
import moment from 'moment';
import Audit from './Audit';
import { textSearchFormat } from '../norm/RequisiteIndex';
import Filter from '../default-v2/Filter';
import CardHeader from '../default-v2/CardHeader';
import Masonry from 'react-masonry-component';
import './styles/audit.scss';
import './styles/dashboardaudit.scss';
import managementImg from '../../assets/img/management.svg';
import Button from '../../components/Button';
import { oLocalStorage } from '../../config/EncodedLocalStorage';
import { fnDecodeHTMLEntities } from '../../config/Formatter';

class DashboardAudit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      audits: [],
      filterBy: 'audit',
      showExternal: true,
      showFinished: true,
      showInternal: true,
      filtredAudits: [],
      aPages: [{ cName: 'DashboardAudit.titulo', cLink: undefined }],
      oLoadNorm: ''
    };

    this.gridRef = React.createRef();

    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  msgSucesso = msg => {
    const { setAlert } = this.props;
    this.hideAlert();
    this.componentDidMount();

    setAlert('success', msg);
  };

  fetchAudits = () => {
    Axios.get('/audit')
      .then(res => {
        this.setState({
          audits: res.data.map(obj => ({
            ...obj,
            audi_bisactive: Boolean(obj.audi_bisactive)
          })),
          loading: false
        });
      })
      .catch(() => { });
  };

  componentDidMount = () => {
    const { history } = this.props;
    checkModule(history, 'audit-management');

    this.fetchAudits();
  };

  filterAudits = () => {
    const { showFinished, showExternal, showInternal, audits, filtredAudits } = this.state;
    return (filtredAudits.length ? filtredAudits : audits)
      .filter(audi =>
        showInternal === true && showExternal === true
          ? audi.audi_bisinternal === 0 || audi.audi_bisinternal === 1
          : showInternal === true && showExternal === false
            ? audi.audi_bisinternal === 1
            : showInternal === false && showExternal === true
              ? audi.audi_bisinternal === 0
              : null
      )
      .filter(auditF => (showFinished === true ? auditF.audi_nprogress >= 0 : auditF.audi_nprogress !== 100));
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  handleChangeState = e => {
    this.setState({
      [e.target.name]: e.target.checked
    });
  };

  filterByAuditor = (audit, filterSearch) => {
    return (
      audit.audit_responsibles.filter(responsible =>
        textSearchFormat(responsible.user.user_cname).includes(textSearchFormat(String(filterSearch)))
      ).length > 0
    );
  };

  filterByLocale = (audit, filterSearch) => {
    return (
      textSearchFormat(audit.local_audit ? audit.local_audit.locl_cfantasyname : '').indexOf(
        textSearchFormat(String(filterSearch))
      ) !== -1
    );
  };

  filterByName = (audit, filterSearch) => {
    const filter = textSearchFormat(String(filterSearch));

    return (
      (audit?.norm?.norm_cinitials && textSearchFormat(audit.norm.norm_cinitials).includes(filter)) ||
      (audit?.audi_cdescription && textSearchFormat(audit.audi_cdescription).includes(filter))
    );
  };

  filterByPeriod = (audit, filterSearch) => {
    return (
      moment
        .parseZone(audit.audi_dexecutionbegin)
        .format('DD/MM/YYYY')
        .toLowerCase()
        .indexOf(String(filterSearch).toLowerCase()) !== -1 ||
      moment
        .parseZone(audit.audi_dexecutionend)
        .format('DD/MM/YYYY')
        .toLowerCase()
        .indexOf(String(filterSearch).toLowerCase()) !== -1
    );
  };

  applyFilter = () => {
    const { filterSearch, audits } = this.state;

    const filtred = audits.filter(audit => {
      if (
        this.filterByAuditor(audit, filterSearch) ||
        this.filterByLocale(audit, filterSearch) ||
        this.filterByName(audit, filterSearch) ||
        this.filterByPeriod(audit, filterSearch)
      )
        return audit;
    });

    this.setState({
      filtredAudits: filtred
    });
  };

  handleChange = e => {
    const { audits } = this.state;
    this.setState(
      {
        filtredAudits: audits,
        [e.target.name]: e.target.value
      },
      () => {
        this.applyFilter();
      }
    );
  };

  handleChangeFilter = (e, oFilter) => {
    this.setState(
      {
        filterSearch: oFilter.cText
      },
      () => {
        this.applyFilter();
      }
    );
  };

  abrirSubmenu = e => {
    if (e.target.closest('div').getElementsByClassName('submenu')) {
      e.target.closest('div').getElementsByClassName('submenu')[0].style = 'display: block';
    }
  };

  fecharSubmenu = (e, wrapperRef) => {
    const element = wrapperRef;
    if (element.getElementsByClassName('submenu')[0]) {
      element.getElementsByClassName('submenu')[0].style = 'display: none';
    }
  };

  renderFilter = () => {
    const { filtredAudits, filterSearch, filterBy, showInternal, showExternal, showFinished } = this.state;

    return (
      <OutsideHandlerClick handleClickOutside={this.fecharSubmenu}>
        <form id="filter-box-norms" className="form submenu">
          <label className=" colspan">
            <Input
              onChange={this.handleChangeFilter}
              type="text"
              ref={cRef => {
                this.filterSearch = cRef;
              }}
              id="filterSearch"
              placeholder={intl.get('QualityPanelIndex.filtrar_por')}
              name="filterSearch"
              maxLength="100"
              value={filterSearch || ''}
              required
            />
          </label>
          {filtredAudits.length === 0 && filterSearch && (
            <p className="empty-search">{intl.get('QualityPanelIndex.sem_resultados')}</p>
          )}

          <label className="radioInput colspan">
            <Input
              onChange={this.handleChange}
              checked={filterBy === 'audit'}
              value="audit"
              name="filterBy"
              type="radio"
            />
            <div className="control-checkbox" />
            {intl.get('DashboardAudit.auditor')}
          </label>

          <label className="radioInput colspan">
            <Input
              onChange={this.handleChange}
              checked={filterBy === 'locale'}
              value="locale"
              name="filterBy"
              type="radio"
            />
            <div className="control-checkbox" />
            {intl.get('DashboardAudit.local')}
          </label>

          <label className="radioInput colspan">
            <Input
              onChange={this.handleChange}
              checked={filterBy === 'name'}
              value="name"
              name="filterBy"
              type="radio"
            />
            <div className="control-checkbox" />
            {intl.get('DashboardAudit.norma')}
          </label>

          <label className="radioInput colspan">
            <Input
              onChange={this.handleChange}
              checked={filterBy === 'period'}
              value="period"
              name="filterBy"
              type="radio"
            />
            <div className="control-checkbox" />
            {intl.get('DashboardAudit.periodo')}
          </label>

          <AltCheckbox
            title={intl.get('DashboardAudit.concluidas')}
            label={intl.get('DashboardAudit.concluidas')}
            className="split1 margin-bottom-2"
            checked={showFinished}
            name="showFinished"
            onChange={this.handleChangeState}
          />

          <AltCheckbox
            title={intl.get('DashboardAudit.auditorias_internas')}
            label={intl.get('DashboardAudit.auditorias_internas')}
            className="split1 margin-bottom-2"
            checked={showInternal}
            name="showInternal"
            onChange={this.handleChangeState}
          />

          <AltCheckbox
            title={intl.get('DashboardAudit.auditorias_externas')}
            label={intl.get('DashboardAudit.auditorias_externas')}
            className="split1 "
            checked={showExternal}
            name="showExternal"
            onChange={this.handleChangeState}
          />
        </form>
      </OutsideHandlerClick>
    );
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  openNewAudit = e => {
    e.preventDefault();
    this.setState({
      alert: (
        <SweetAlert
          customClass="modal-edit modal-audit-index md disabled-overflow ScrollBar"
          title=""
          onConfirm={this.hideAlert}
          showConfirm={false}
        >
          <div className="head-modal">
            {intl.get('DashboardAudit.nova_auditoria')}
            <span
              role="button"
              tabIndex="0"
              aria-labelledby="head-modal"
              onKeyPress={this.hideAlert}
              onClick={this.hideAlert}
              className="close"
              href=""
            />
          </div>
          <ScrollBar>
            <Audit
              onSuccess={msg => {
                this.msgSucesso(msg);
              }}
              closeModal={this.hideAlert}
            />
          </ScrollBar>
        </SweetAlert>
      )
    });
  };

  editAudit = (e, oAudit) => {
    e.preventDefault();
    this.setState({
      alert: (
        <SweetAlert
          customClass="modal-edit md disabled-overflow ScrollBar"
          title=""
          onConfirm={this.hideAlert}
          showConfirm={false}
        >
          <div className="head-modal">
            {intl.get('DashboardAudit.editar_auditoria')}
            <span
              role="button"
              tabIndex="0"
              aria-labelledby="head-modal"
              onKeyPress={this.hideAlert}
              onClick={this.hideAlert}
              className="close"
              href=""
            />
          </div>
          <ScrollBar>
            <Audit
              onSuccess={msg => {
                this.msgSucesso(msg);
              }}
              oAudit={oAudit}
              closeModal={this.hideAlert}
            />
          </ScrollBar>
        </SweetAlert>
      )
    });
  };

  showDeleteAudit = (e, { audi_nid, norm }) => {
    e.preventDefault();

    this.setState({
      alert: (
        <SweetAlert
          showCancel
          confirmBtnText={intl.get('confirmar')}
          cancelBtnText={intl.get('cancelar')}
          cancelBtnBsStyle="danger"
          title={intl.get('DashboardAudit.titulo_excluir_auditoria', { cNormInitials: norm.norm_cinitials })}
          onConfirm={() => this.deleteAudit(audi_nid, norm.norm_cinitials)}
          onCancel={this.hideAlert}
        >
          {intl.get('DashboardAudit.delete1')}
          <p> {intl.get('DashboardAudit.delete3')} </p>
        </SweetAlert>
      )
    });
  };

  setSeal = (evtChange, oAudit) => {
    if (evtChange.target.checked === true) {
      Axios.put(`audit/${oAudit.audi_nid}`, {
        audi_dvalidity: moment().format('YYYY/MM/DD HH:mm:ss')
      }).then(oRes => {

      });
      this.setState(oPrevState => ({
        audits: oPrevState.audits.map(oAuditPrev =>
          oAuditPrev.audi_nid === oAudit.audi_nid
            ? { ...oAuditPrev, audi_dvalidity: moment().format('YYYY/MM/DD HH:mm:ss') }
            : oAuditPrev
        )
      }));
    } else {
      Axios.put(`audit/${oAudit.audi_nid}`, {
        audi_dvalidity: null
      }).then(oRes => {

      });

      this.setState(oPrevState => ({
        audits: oPrevState.audits.map(oAuditPrev =>
          oAuditPrev.audi_nid === oAudit.audi_nid ? { ...oAuditPrev, audi_dvalidity: null } : oAuditPrev
        )
      }));
    }
  };

  updateDateSeal = (e, audit) => {
    Axios.put(`audit/${audit.audi_nid}`, {
      audi_dvalidity: moment.parseZone(e.target.value).format('YYYY-MM-DD HH:mm:ss')
    }).then(res => {
      //console.log(res);
    });
  };

  deleteAudit = (audi_nid, normName) => {
    Axios.delete(`audit/${audi_nid}`)
      .then(() => {
        this.setState(
          {
            alert: (
              <SweetAlert
                success
                title={intl.get('DashboardAudit.auditoria_removida', { cNorm: normName })}
                onConfirm={() => {
                  this.hideAlert();
                  this.componentDidMount();
                }}
              >
                {intl.get('DashboardAudit.auditoria_removida_sucesso', { cNorm: normName })}
              </SweetAlert>
            )
          },
          () => {
            this.hideAlert();
          }
        );
        this.fetchAudits();
      })
      .catch(error => {
        this.setState({
          alert: (
            <SweetAlert error title={intl.get('opa')} onConfirm={this.hideAlert}>
              {intl.get('falhou')}
              <p>{error.msgErrors}</p>
            </SweetAlert>
          )
        });
      });
  };

  printAudit = (e, audit) => {
    e.preventDefault();
    console.log(audit);
  };

  checkIfUserIsResponsible = oAudit => {
    const nUserId = Number(oLocalStorage.get('nUserId'));
    const aResponsibles = [...oAudit.audit_responsibles, ...oAudit.audit_auditors];

    return aResponsibles.some((oEntity) => oEntity.user.user_nid === nUserId);
  };

  checkCreateEditPermission = oAudit => {
    const cModule = 'audit-management';
    const nUserId = Number(oLocalStorage.get('nUserId'));
    return (
      can('admin', cModule) ||
      (can('create-edit-audit', cModule) &&
        oAudit.audit_responsibles?.some((oUsrRes) => oUsrRes.user.user_nid === nUserId))
    );
  };

  checkUserPermission = () => {
    const cModule = 'audit-management';
    return can('admin', cModule) || can('access-auditmanagement', cModule);
  };

  generateCardHeader = () => {
    const { showFinished, showInternal, showExternal } = this.state;
    const aButtons = [];
    const cModule = 'audit-management';

    const rcmpBtnNewAudit = (
      <Button
        onClick={event => {
          this.openNewAudit(event);
        }}
      >
        {intl.get('DashboardAudit.nova_auditoria')}
      </Button>
    );

    aButtons.push(can('admin', cModule) || can('create-edit-norm-auditmanagement', cModule) ? rcmpBtnNewAudit : []);

    const aCheckboxes = [];
    const rcmpFinishedSwitch = (
      <AltCheckbox
        key="checkbox-show-finished"
        onChange={this.handleChangeState}
        title={intl.get('DashboardAudit.concluidas')}
        label={intl.get('DashboardAudit.concluidas')}
        name="showFinished"
        localeLabel="end"
        checked={showFinished}
      />
    );
    aCheckboxes.push(rcmpFinishedSwitch);

    const rcmpInternalSwitch = (
      <AltCheckbox
        key="checkbox-show-internal"
        onChange={this.handleChangeState}
        title={intl.get('DashboardAudit.internas')}
        label={intl.get('DashboardAudit.internas')}
        name="showInternal"
        localeLabel="end"
        checked={showInternal}
      />
    );
    aCheckboxes.push(rcmpInternalSwitch);

    const rcmpExternalSwitch = (
      <AltCheckbox
        key="checkbox-show-external"
        onChange={this.handleChangeState}
        title={intl.get('DashboardAudit.externas')}
        label={intl.get('DashboardAudit.externas')}
        name="showExternal"
        localeLabel="end"
        checked={showExternal}
      />
    );
    aCheckboxes.push(rcmpExternalSwitch);

    const rcmpFilter = (
      <Filter aButtons={aButtons} fnOnType={this.handleChangeFilter} bIsOrderActive={false} aCheckboxes={aCheckboxes} />
    );

    const rcmpCardHeader = <CardHeader rcmpFilter={rcmpFilter} bIsItToInsertGoBackBtn={false} />;
    return rcmpCardHeader;
  };

  render() {
    const { loading, aPages, alert } = this.state;
    const { history, getAlert } = this.props;
    const cModule = 'audit-management';

    return (
      <Page
        loading={loading ? 1 : 0}
        rcmpBreadcrumb={<Breadcrumb aPages={aPages} />}
        cTitle={intl.get('DashboardAudit.titulo')}
        cImage={managementImg}
        rcmpCardHeader={this.checkUserPermission() ? this.generateCardHeader() : null}
        cCurrentSideMenuOption={intl.get('Nav.audit_management')}
        className="v2-dashboard-audit"
      >
        {alert}
        {getAlert()}

        {!this.checkUserPermission() && (
          <Alert type="error" isOpen onCloseAlert={() => { }}>
            {intl.get('sem_permissao')}
          </Alert>
        )}

        {this.checkUserPermission() && (
          <div className="container-cards">
            <Masonry className="grid" elementType="ul">
              {this.filterAudits().map(
                audit =>
                  (can('admin', cModule) || this.checkIfUserIsResponsible(audit)) && (
                    <li
                      key={audit.audi_nid}
                      className={`item norm-active-${audit.audi_bisactive}`}
                      role="presentation"
                      title={audit.norm.norm_cinitials}
                    >
                      <div className="content">
                        <div
                          onKeyPress={() => history.push(`/requisite-audit/${audit.audi_nid}`)}
                          onClick={() => history.push(`/requisite-audit/${audit.audi_nid}`)}
                          className="header"
                        >
                          <h2>{`${audit.norm.norm_cinitials}`}</h2>
                          {audit.audi_cdescription && (
                            <h3>
                              {audit.audi_cdescription.replace(/(&nbsp;|(<([^>]+)>))/gi, '').length < 80
                                ? fnDecodeHTMLEntities(audit.audi_cdescription.replace(/(&nbsp;|(<([^>]+)>))/gi, ''))
                                : fnDecodeHTMLEntities(audit.audi_cdescription.replace(/(&nbsp;|(<([^>]+)>))/gi, '').substring(0, 80)) +
                                '...'}
                            </h3>
                          )}

                          <div className="right-menu">
                            <span
                              onClick={e => {
                                e.stopPropagation();
                                this.abrirSubmenu(e);
                              }}
                              onKeyPress={e => {
                                e.stopPropagation();
                                this.abrirSubmenu(e);
                              }}
                              role="button"
                              tabIndex="0"
                              title=""
                              className="btn-menu"
                            >
                              <span className="insideP" />
                            </span>
                            <OutsideHandlerClick handleClickOutside={this.fecharSubmenu}>
                              <ul className="submenu">
                                {this.checkCreateEditPermission(audit) ? (
                                  <li>
                                    <a
                                      onClick={e => {
                                        this.editAudit(e, audit);
                                        e.stopPropagation();
                                      }}
                                      href="#change"
                                    >
                                      {intl.get('editar', { tipo: '' })}
                                    </a>
                                  </li>
                                ) : (
                                  ''
                                )}
                                <li>
                                  <a
                                    onClick={e => {
                                      e.stopPropagation();
                                      e.preventDefault();
                                      history.push(`report/audit/${audit.audi_nid}`);
                                    }}
                                    href="#report-audit"
                                    target="_blank"
                                  >
                                    {intl.get('DashboardAudit.imprimir')}
                                  </a>
                                </li>

                                <li>
                                  <a
                                    onClick={e => {
                                      e.stopPropagation();
                                      e.preventDefault();
                                      history.push(`audit/interaction/${audit.audi_nid}`);
                                    }}
                                    target="_blank"
                                    href="#report-interactions"
                                  >
                                    {intl.get('DashboardAudit.interacoes')}
                                  </a>
                                </li>

                                {this.checkCreateEditPermission(audit) ? (
                                  <li>
                                    <a
                                      href="#remove"
                                      onClick={e => {
                                        this.showDeleteAudit(e, audit);
                                        e.stopPropagation();
                                      }}
                                      className="alt"
                                    >
                                      {intl.get('excluir', { tipo: '' })}
                                    </a>
                                  </li>
                                ) : (
                                  ''
                                )}
                              </ul>
                            </OutsideHandlerClick>
                          </div>
                        </div>
                        <div className="contentRole">
                          <div
                            onKeyPress={() => history.push(`/requisite-audit/${audit.audi_nid}`)}
                            onClick={() => history.push(`/requisite-audit/${audit.audi_nid}`)}
                          >
                            <p className="period">
                              <i className="icon icon-clock2" />
                              <span>
                                {` ${moment.parseZone(audit.audi_dexecutionbegin).format('DD/MM/YYYY')}
                            a
                            ${moment.parseZone(audit.audi_dexecutionend).format('DD/MM/YYYY')}`}
                              </span>
                            </p>
                            {audit.local_audit && (
                              <div className="contentRole">
                                <p className="local">
                                  <i className="icon icon-map-pin" />
                                  <b>{` ${audit.local_audit.locl_cfantasyname}`}</b>
                                </p>
                              </div>
                            )}

                            <div className="containerBar">
                              <div className="progressBar">
                                <span
                                  style={{
                                    width: `${audit.audi_nprogress}%`
                                  }}
                                  className="fill"
                                />
                              </div>

                              <span className="percent">{`${audit.audi_nprogress.toFixed(0)}%`}</span>
                            </div>

                            <div className="users">
                              <ul>
                                {audit.audit_responsibles.map(usr => (
                                  <li key={`responsible-${usr.ars_nid + audit.audit_nid}`}>
                                    <img
                                      src={
                                        usr.user && usr.user.user_cavatar
                                          ? usr.user.user_cavatar
                                          : imgfNoPic
                                      }
                                      alt={intl.get('usuario')}
                                    />
                                    <span>
                                      <p>{usr?.user?.user_cname ?? ''}</p>
                                      <p>{intl.get('DashboardAudit.responsavel')}</p>
                                    </span>
                                  </li>
                                ))}
                              </ul>

                              <ul>
                                {audit.audit_auditors.map(usr => (
                                  <li key={`auditor-${usr.ars_nid + audit.audit_nid}`}>
                                    <img
                                      src={
                                        usr.user && usr.user.user_cavatar
                                          ? usr.user.user_cavatar
                                          : imgfNoPic
                                      }
                                      alt={intl.get('usuario')}
                                    />
                                    <span>
                                      <p>{usr?.user?.user_cname ?? ''}</p>
                                      <p>{intl.get('DashboardAudit.auditor')}</p>
                                    </span>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                          {audit.audit_auditors.filter(function (oUsrRes) {
                            return oUsrRes.user.user_nid == oLocalStorage.get('nUserId');
                          }).length > 0 && (
                              <>
                                {!audit.audi_bisinternal && (
                                  <div className="valid-audit-period">
                                    <label className={`alt-checkbox custom-checkbox`}>
                                      <span className="alt-checkbox-label">{intl.get('DashboardAudit.selo_obtido')}</span>
                                      <input
                                        onChange={evtChange => this.setSeal(evtChange, audit)}
                                        checked={audit.audi_dvalidity ? true : false}
                                        type="checkbox"
                                      />
                                      <span
                                        title={intl.get('DashboardAudit.selo_obtido')}
                                        className={`togglecheck ${audit.audi_dvalidity ? "selected" : ""}`}
                                      ></span>
                                    </label>

                                    {audit.audi_dvalidity && (
                                      <div className="validade-selo">
                                        {intl.get('DashboardAudit.valido_ate')}
                                        <input
                                          onChange={e => this.updateDateSeal(e, audit)}
                                          type="date"
                                          defaultValue={moment.parseZone(audit.audi_dvalidity).format('YYYY-MM-DD') || ''}
                                        />
                                      </div>
                                    )}
                                  </div>
                                )}
                              </>
                            )}
                        </div>
                      </div>
                    </li>
                  )
              )}
            </Masonry>
          </div>
        )}
      </Page>
    );
  }
}

export default DashboardAudit;
