import React from 'react';
import PlusIcon from '../assets/icons/PlusIcon';

const Button = ({ children, onClick, className = '', style }) => {
  return (
    <button
      className={`btn btn-nav btn-flex${className ? ' ' + className : ''}`}
      type="submit"
      onClick={onClick}
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', ...style }}
    >
      <PlusIcon style={{ marginRight: '10px' }} />
      <span style={{ textTransform: 'uppercase' }}>{children}</span>
    </button>
  );
};

export default Button;
