import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import intl from 'react-intl-universal';

class Breadcrumb extends Component {
  constructor(oProps) {
    super(oProps);
    this.state = {
      aPages: oProps.aPages || undefined,
      bLastLink: oProps.bLastLink || '',
      aBreadcrumb: oProps.aFolders
    };
  }

  getLink(oFolder, i, aList) {
    const { bLastLink } = this.state;
    const { cType, oDocument, bIsAbbreviate } = this.props;

    let nId = '';
    let cName = '';

    nId = oFolder.fldr_nid;
    if (oFolder.fldr_cname === 'Documentos') {
      cName = intl.get('Nav.document');
    } else {
      cName = oFolder.fldr_cname;
    }

    if (aList.length !== i + 1 || bLastLink) {
      if (cType === 'document') {
        let cTitle = intl.get('new_document');
        if (typeof oDocument.doc_ctitle === 'string' && oDocument.doc_ctitle.length > 0) {
          cTitle = oDocument.doc_ctitle;
        }
        return (
          <>
            <NavLink key={nId} to={`/folder/${nId}`} title={cName}>
              {bIsAbbreviate ? cName.trunc(35) : cName}
            </NavLink>
            <span>></span>
            {aList.length === i + 1 && (
              <em key={oDocument.doc_nid} title={!bIsAbbreviate && cTitle.length < 35 ? '' : cTitle}>
                {bIsAbbreviate ? cTitle.trunc(35) : cTitle}
              </em>
            )}
          </>
        );
      }

      return (
        <>
          <NavLink key={nId} to={`/folder/${nId}`} title={cName}>
            {bIsAbbreviate ? cName.trunc(35) : cName}
          </NavLink>
          <span> > </span>
        </>
      );
    }

    return (
      <em key={nId} title={!bIsAbbreviate && cName.length < 35 ? '' : cName}>
        {bIsAbbreviate ? cName.trunc(35) : cName}
      </em>
    );
  }

  breadcrumbPages() {
    const { aPages } = this.state;

    const rcmpList = aPages.map((oItem, nIndex) => (
      <React.Fragment key={nIndex}>
        <span> > </span>
        {oItem.cLink === undefined ? (
          <em key={oItem}>{intl.get(oItem.cName) ? intl.get(oItem.cName) : oItem.cName}</em>
        ) : (
          <>
            <NavLink key={oItem} to={oItem.cLink} title={intl.get(oItem.cName) ?? oItem.cName}>
              {intl.get(oItem.cName) ? intl.get(oItem.cName) : oItem.cName}
            </NavLink>
          </>
        )}
      </React.Fragment>
    ));

    return (
      <p className="breadcrumbs " style={{ overflow: 'visible' }}>
        <em>
          <NavLink key={1} to="/" title={intl.get('home')}>
            {intl.get('home')}
          </NavLink>
          {rcmpList}
        </em>
      </p>
    );
  }

  render() {
    const { aPages, aBreadcrumb } = this.state;
    if (aPages !== undefined) {
      return this.breadcrumbPages();
    }

    return (
      <p className="breadcrumbs">
        <NavLink key={0} to="/" title={intl.get('home')}>
          {intl.get('home')}
        </NavLink>
        <span>></span>
        {aBreadcrumb &&
          aBreadcrumb.map((oFolder, i, aList) => (
            <em key={oFolder.fldr_nid} style={{ display: oFolder.fldr_bisvisible === false ? 'none' : '' }}>
              {this.getLink(oFolder, i, aList)}
            </em>
          ))}
      </p>
    );
  }
}

Breadcrumb.defaultProps = {
  aPages: undefined,
  bLastLink: '',
  aFolders: [],
  bIsAbbreviate: true
};

export default Breadcrumb;
