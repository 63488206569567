import Axios from '../config/Axios';
import loadBreadcrumb from './ActionBreadcrumb';

export function getDocumentReview(nVersionId) {
  return Axios.get('version/review', { params: { nId: nVersionId } }).then(
    oResponse =>
      new Promise((resolve, reject) => {
        loadBreadcrumb(oResponse.data.oDocument.fldr_nid)
          .then(aBreadcrumbFolders => {
            resolve({
              ...oResponse.data,
              oDocument: oResponse.data.oDocument,
              oVersion: oResponse.data.oVersion,
              aBreadcrumbFolders
            });
          })
          .catch(oError => reject(oError));
      })
  );
}

export function approveReview(nVersionId) {
  return Axios.post(`version/review-save/${nVersionId}`, { action: 'approve' }).then(
    oResponse => oResponse.data.nVersionId
  );
}
