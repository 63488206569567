import React, { useMemo } from 'react';
import intl from 'react-intl-universal';

const IconStatus = (oProps) => {
  const { cStatus, onClick, cTitle } = oProps;
  return (
    <span className="action" onClick={onClick}>
      {cStatus === 'in-review' && <i className="icon-comment" title={cTitle ?? intl.get(`Status.em-revisao`)} />}
      {cStatus === 'awaiting-publish' && <i className="icon-published" title={cTitle ?? intl.get(`Status.aguardando-publicacao`)} />}
      {cStatus === 'in-writing' && <i className="icon-editing" title={cTitle ?? intl.get(`Status.em-redacao`)} />}
      {cStatus === 'published' && <i className="icon-checkmark" title={cTitle ?? intl.get(`Status.publicado`)} />}
    </span>
  );
};

export default IconStatus;
