import React, { Component } from 'react';
import './styles/folderCard.scss';
import FolderIcon from './FolderIcon';
import { formatDateToUserTZ } from '../../utils/time';

const oIndividualDocRedirect = {
  'Publicação de documento': 'document/publish',
  'Revisão de documento': 'document/review',
  'Redação de documento': 'document/editor',
  undefined: 'document/detail'
};
class FolderCard extends Component {
  constructor(oProps) {
    super(oProps);
    this.state = {};
  }

  fnHandleShowDate = date => {
    return date ? formatDateToUserTZ(date, 'DD/MM/YYYY') : '-';
  };

  fnRenderDocumentItem = (data, isPending) => {
    if (!data || data.length < 1) {
      return (
        <li style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div>
            <div>
              <h2 style={{ overflow: 'visible', textAlign: 'center', whiteSpace: 'normal' }}>
                Nenhum documento nesse status foi encontrado.
              </h2>
            </div>
          </div>
        </li>
      );
    }
    return (
      <>
        {data.map(item => {
          return (
            <li key={item.nDocId}>
              <div>
                <FolderIcon />
                <div>
                  {!isPending ? (
                    <>
                      <h2 title={item.cDescription || ''}>{item.cDescription || ''}</h2>
                      <p title={item.cUserName || ''}>Responsável: {item.cUserName || ''}</p>
                      <p title={item.cDeadline || ''}>Prazo: {this.fnHandleShowDate(item.cDeadline)}</p>
                    </>
                  ) : (
                    <>
                      <h2 title={item.cDescription || ''}>{item.cDescription || ''}</h2>
                      <p>
                        Lidos: {item.nQuantityReaders} / A ler: {item.nQuantityNotRead}
                      </p>
                    </>
                  )}
                </div>
              </div>
            </li>
          );
        })}
      </>
    );
  };

  fnHandleRedirect = path => {
    window.location.href = path;
  };

  render() {
    const { cTitle, nCount, data, isPending, cRedirectPath } = this.props;
    return (
      <div className="folder-card">
        <div className="folder-card__header">
          <h2>{cTitle}</h2>
          <span>{nCount}</span>
        </div>
        <div className="folder-card__body">
          <ul>
            <li>Últimos documentos</li>
            {this.fnRenderDocumentItem(data, isPending)}
          </ul>
        </div>
        <div className="folder-card__footer">
          <button onClick={() => this.fnHandleRedirect(cRedirectPath)} type="button">
            Ver Todos
          </button>
        </div>
      </div>
    );
  }
}

export default FolderCard;
