import React, { useEffect } from 'react';
import Page from '../default-v2/Page';
import './styles/loading.scss';
import './styles/modal.scss';

const Loading = () => {
  useEffect(() => {
    const { searchParams } = new URL(window.location.href);
    const code = searchParams.get('code');
    const envelopeId = searchParams.get('envelopeId');
    const logout = searchParams.get('error');

    if (code) {
      const url = JSON.parse(window.localStorage.getItem('url')) + '?code=' + code;
      window.localStorage.removeItem('url');
      window.location.href = url;
    }

    if (envelopeId) {
      const url = JSON.parse(window.localStorage.getItem('url')) + '?envelopeId=' + envelopeId;
      window.localStorage.removeItem('url');
      window.location.href = url;
    }

    if (logout) {
      const url = JSON.parse(window.localStorage.getItem('url'));
      window.localStorage.removeItem('url');
      window.location.href = url;
    }
  }, []);

  return (
    <div>
      <Page>
        <div className="spinner"></div>
      </Page>
    </div>
  );
};

export default Loading;
