/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import Scrollbars from 'react-custom-scrollbars';

export class ScrollBar extends Component {
  elScrollBar = null;

  constructor(oProps) {
    super(oProps);

    this.state = {
      bIsShowScroll: false
    };

  }

  componentDidMount() {
    document.body.style.overflow = 'hidden';
    if (this.elScrollBar !== null) {
      const elContent = this.elScrollBar.view.children[0];
      elContent.addEventListener('mouseenter', this.showScroll);
    }
  }

  componentWillUnmount() {
    document.body.style.overflow = 'unset';
  }

  showScroll = evt => {
    const elView = this.elScrollBar.view;
    const elContent = this.elScrollBar.view.children[0];
    const nDif = elView.clientHeight - elContent.clientHeight;
    if (elView && elContent && nDif < -2) {
      this.setState({
        bIsShowScroll: true
      });
      elContent.removeEventListener('mouseenter', this.showScroll);
    }
  }

  render() {
    const { children } = this.props;
    const { bIsShowScroll } = this.state;

    return (
      <Scrollbars
        autoHeight
        autoHeightMin={"20vh"}
        autoHeightMax={"70vh"}
        renderTrackVertical={oProps => <div {...oProps} className="track-vertical" />}
        renderThumbVertical={oProps => {
          const { bIsShowScroll } = this.state;
          return (<div {...oProps} className={`thumb-vertical ${bIsShowScroll ? "init-height" : ""}`} />)
        }}
        renderView={({ style, ...oProps }) => <div {...oProps} style={{ ...style }} className="view" />}
        ref={(elScrollBar) => { this.elScrollBar = elScrollBar }}
      >
        {children}
      </Scrollbars>
    );
  }
}

export default ScrollBar;
