import React, { Component } from 'react';
import { Input } from 'reactstrap';
import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/pt-br';
import intl from 'react-intl-universal';
import Pagination from 'react-js-pagination';
import Breadcrumb from '../../components/Breadcrumbs';
import Page from '../default-v2/Page';
import CardHeader from '../default-v2/CardHeader';
import Filter from '../default-v2/Filter';
import './styles/report.scss';
import './styles/v2-reports.scss';
import ReportTable from './ReportTable';
import { canAtLeast } from '../../config/Permissions';
import reportsImg from '../../assets/img/reports.svg';
import EncodedLocalStorage from '../../config/EncodedLocalStorage';
import { formatDateToUserTZ } from '../../utils/time';
import documentReading from '../../actions/ActionDocumentReading';
import PaginationMenu from '../default-v2/PaginationMenu';

const oLocalStorage = new EncodedLocalStorage();
export class DocumentReading extends Component {
  constructor(oProps) {
    super(oProps);

    moment.locale(oLocalStorage.get('cLanguage'));

    this.state = {
      bLoading: true,
      aInfos: {},
      aFolders: {},
      cSearch: '',
      cOrderBy: 'dpublished',
      cOrderType: 'asc',
      aPages: [
        {
          cName: 'Nav.reports',
          cLink: undefined
        },
        {
          cName: 'DocumentReading.title',
          cLink: undefined
        }
      ],
      nCurrentPage: 1,
      nTotalPages: 0,
      nItemsPerPage: 50,
      nTotal: 0,
    };
  }

  componentDidMount = () => {
    const { history } = this.props;

    if (!canAtLeast(['document-reading', 'admin'], 'documents')) {
      history.push({
        pathname: '/user',
        state: { aAlertMessages: { cType: 'error', cMsg: intl.get('sem_permissao') } }
      });
    }

    this.fetchData();
  };

  getPending = (nPage, nPerPage, orderBy, order, cSearch) => {
    return documentReading(nPage, nPerPage, orderBy, order, cSearch);
  };

  fetchData = () => {
    const { setAlert } = this.props;
    const { nCurrentPage, nItemsPerPage } = this.state;

    this.setState({ bLoading: true });

    this.getPending(nCurrentPage, nItemsPerPage)
      .then(aDocuments => {
        this.setState({
          bLoading: false,
          aInfos: aDocuments.data,
          nTotalPages: aDocuments.last_page,
          nTotal: aDocuments.total
        });
      })
      .catch(oError => {
        this.setState({ bLoading: false, aInfos: [] });
        setAlert('error', oError.aMsgErrors);
      });
  };

  handlePageChange = nPageNumber => {
    this.setState({ nCurrentPage: nPageNumber }, this.fetchData);
  };

  handleChange = evt => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  changeOrder = evt => {
    evt.preventDefault();
    this.setState(oPrevState => ({
      cOrderType: oPrevState.cOrderType === 'asc' ? 'desc' : 'asc'
    }));
  };

  handleFilter = evt => {
    const { nCurrentPage, nItemsPerPage, cOrderType, cSearch, cOrderBy } = this.state;
    const { setAlert } = this.props;

    this.getPending(nCurrentPage, nItemsPerPage, cOrderBy, cOrderType, cSearch)
      .then(aDocuments => {
        this.setState({
          bLoading: false,
          aInfos: aDocuments.data,
          nTotalPages: aDocuments.last_page,
          nTotal: aDocuments.total
        });
      })
      .catch(oError => {
        this.setState({ bLoading: false, aInfos: [] });
        setAlert('error', oError.aMsgErrors);
      });
  };

  renderListOption() {
    const { aFolders, nFolderId } = this.state;
    const aMappedFolders = Object.keys(aFolders).map(cKey => (
      <option key={aFolders[cKey]} value={cKey}>
        {aFolders[cKey]}
      </option>
    ));

    return (
      <div className="filter-wrapper basic filter-wrapper-block">
        <span>{intl.get('DocumentReading.filtrar_por_pasta')}</span>
        <label>
          <Input type="select" name="nFolderId" id="nFolderId" onChange={this.handleChange} value={nFolderId || ''}>
            <option value="">{intl.get('DocumentReading.selecione_pasta')}</option>
            {aMappedFolders}
          </Input>
        </label>
      </div>
    );
  }

  handleClickTable = (evt, cNewOrderBy, cNewOrderType) => {
    this.setState(
      {
        cOrderBy: cNewOrderBy,
        cOrderType: cNewOrderType
      },
      () => {
        this.handleFilter(evt);
      }
    );
  };

  renderReadList() {
    const { aInfos } = this.state;
    const { history } = this.props;

    if (aInfos !== undefined && aInfos.length === 0) {
      return (
        <>
          <p className="empty-result">
            <strong> {intl.get('DocumentReading.empty_result')}</strong>
          </p>
        </>
      );
    }

    const rcmpReadList = Object.keys(aInfos).map(cKey => {
      const oInformation = aInfos[cKey];

      return (
        <tr
          key={oInformation.nId}
          onClick={evt => {
            history.push(`/document/reading/${oInformation.nId}`);
          }}
        >
          <td className="col-id">
            <span>
              <span>{oInformation.nId}</span>
            </span>
          </td>
          <td title={oInformation.cDescription} className="col-doc-name">
            <span>{oInformation.cDescription}</span>
          </td>
          <td>
            {oInformation.dpublished &&
              formatDateToUserTZ(
                oInformation.dpublished,
                `DD/MM/YYYY [${intl.get('DocumentReading.at')}] HH:mm`
              )}
          </td>
          <td>
            <strong>{oInformation.nQuantityReaders > 0 ? oInformation.nQuantityReaders : '-'}</strong>
          </td>
          <td>
            <strong>{oInformation.nQuantityNotRead > 0 ? oInformation.nQuantityNotRead : '-'}</strong>
          </td>
        </tr>
      );
    });

    return (
      <ReportTable
        aColumn={[
          {
            cName: 'ID',
            cOrderBy: 'nId',
            fnHandleClick: this.handleClickTable
          },
          {
            cName: intl.get('DocumentReading.document'),
            cOrderBy: 'cDescription',
            fnHandleClick: this.handleClickTable
          },
          {
            cName: intl.get('DocumentReading.published_at'),
            cOrderBy: 'dpublished',
            fnHandleClick: this.handleClickTable
          },
          {
            cName: intl.get('DocumentReading.confirmed_reads'),
            cOrderBy: 'nQuantityReaders',
            fnHandleClick: this.handleClickTable
          },
          {
            cName: intl.get('DocumentReading.pending_reads'),
            cOrderBy: 'nQuantityNotRead',
            fnHandleClick: this.handleClickTable
          }
        ]}
        cTableClassName="document-readings-table"
      >
        {rcmpReadList}
      </ReportTable>
    );
  }

  handleNewFilter = (evt, oFilter) => {
    this.setState(
      {
        cSearch: oFilter.cText,
        cOrderType: oFilter.cOrderType
      },
      () => {
        this.handleFilter(evt);
      }
    );
  };

  generateCardHeader = () => {
    const { history } = this.props;

    const rcmpFilter = <Filter fnOnType={this.handleNewFilter} />;

    const rcmpCardHeader = <CardHeader history={history} rcmpFilter={rcmpFilter} />;
    return rcmpCardHeader;
  };

  handleItemsPerPageChange = eventPagination => {
    const nItemsPerPage = parseInt(eventPagination.target.value, 10);
    this.setState({ nItemsPerPage, nCurrentPage: 1 }, this.fetchData);
  };

  render() {
    const { aPages, bLoading, nCurrentPage, nItemsPerPage, nTotalPages, nTotal } = this.state;
    const { getAlert } = this.props;

    const rcmpBreadcrumb = <Breadcrumb aPages={aPages} />;

    const cPageTitle = intl.get('DocumentReading.title');
    const rcmpCardHeader = this.generateCardHeader();

    return (
      <Page
        loading={bLoading ? 1 : 0}
        rcmpBreadcrumb={rcmpBreadcrumb}
        cTitle={cPageTitle}
        cImage={reportsImg}
        rcmpCardHeader={rcmpCardHeader}
        className="v2-report"
        cCurrentSideMenuOption={intl.get('Nav.report')}
      >
        {getAlert()}
        {this.renderReadList()}
        <PaginationMenu
          nCurrentPage={nCurrentPage}
          nItemsPerPage={nItemsPerPage}
          nTotalPages={nTotalPages}
          nTotal={nTotal}
          handlePageChange={this.handlePageChange}
          handleItemsPerPageChange={this.handleItemsPerPageChange} />
      </Page>
    );
  }
}

export default DocumentReading;
