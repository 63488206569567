import Axios from '../config/Axios';

function myPendingReadings(page = 1, perPage = 50, orderBy = 'doc_ctitle', order = 'ASC', cSearch) {
  return Axios.get('/my-account/pending-read', {
    params: {
      page,
      perPage,
      orderBy,
      order,
      cSearch
    }
  }).then((oResponse) => oResponse.data);
}

export { myPendingReadings };
