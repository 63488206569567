import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import withAlert from '../../components/withAlert';
import { Dashboard } from '../dashboard/Dashboard';
import { Downloads } from '../dashboard/Downloads';
import { Prints } from '../dashboard/Prints';
import { NoLink } from '../dashboard/NoLink';
import { Filed } from '../document/Filed';
import DocumentDetailsRead from '../document/DocumentDetailsRead';
import VersionDiff from '../document/VersionDiff';
import Document from '../document/Document';
import DocumentCopy from '../document/DocumentCopy';
import DocumentDetails from '../document/DocumentDetails';
import DocumentEdit from '../document/DocumentEdit';
import Loading from '../document/Loading';
import { DocumentEditor } from '../document/DocumentEditor';
import DocumentPrint from '../document/DocumentPrint';
import { DocumentInteraction } from '../document/DocumentInteraction';
import DocumentMove from '../document/DocumentMove';
import DocumentPublish from '../document/DocumentPublish';
import DocumentReview from '../document/DocumentReview';
import NewVersion from '../document/NewVersion';
import { VersionHistory } from '../document/VersionHistory';
import { TrashBin } from '../document/TrashBin';
import { ExtractIndex } from '../financial/ExtractIndex';
import { PermissionGroup } from '../permissiongroup/PermissionGroup';
import { PermissionGroupIndex } from '../permissiongroup/PermissionGroupIndex';
import Notification from '../notification/Notification';
import FolderMove from '../folder/FolderMove';
import FolderIndex from '../folder/FolderIndex';

import { DocumentReading } from '../report/DocumentReading';
import User from '../user/User';
import InviteUsers from '../user/InviteUsers';
import UserIndex from '../user/UserIndex';
import { Backup } from './Backup';
import { Extract } from '../financial/Extract';
import NotFound from '../default-v2/NotFound';
import Configurations from './Configurations';
import Index from './Index';

import NormIndex from '../norm/NormIndex';
import NormInteractions from '../norm/NormInteractions';
import RequisiteIndex from '../norm/RequisiteIndex';
import RequisiteShow from '../norm/RequisiteShow';
import ChangeEmail from '../login/ChangeEmail';

import PendingNormsList from '../norm/PendingNormsList';

import RegisterActivation from '../login/RegisterActivation';
import QualityPanelIndex from '../quality-panel/QualityPanelIndex';
import QualityPanelConsultant from '../quality-panel/QualityPanelConsultant';
import AuditIndex from '../audit/AuditIndex';
import AuditInteractions from '../audit/AuditInteractions';
import DashboardAudit from '../audit/DashboardAudit';
import ConfigHeaderFooter from '../document/ConfigHeaderFooter';
import Customer from '../customer/Customer';
import AdhesionContract from '../myaccount/AdhesionContract';
import Pdf from '../report/Pdf';
import Module from '../financial/Module';
import General from '../myaccount/General';
import ReadingDocumentDelegates from '../report/ReadingDocumentDelegates';
import ReadingDocumentResponsible from '../report/ReadingDocumentResponsible';
import EditsDocumentDelegates from '../report/EditsDocumentDelegates';
import EditsDocumentResponsible from '../report/EditsDocumentResponsible';
import Request from '../report/Request';
import PendingPerUser from '../report/PendingPerUser';
import ExtractHistory from '../financial/ExtractHistory';
import MasterList from '../report/MasterList';
import ActivitiesPending from '../report/ActivitiesPending';
import MigrateResponsibilityDocument from '../document/MigrateResponsibilityDocument';
import MigrateResponsibilityQuality from '../quality-panel/MigrateResponsibilityQuality';
import MigrateResponsibilityOperational from '../operational-panel/MigrateResponsibilityOperational';
import MigrateResponsibilityAudits from '../audit/MigrateResponsibilityAudit';

import DocumentAccess from '../document/DocumentAccess';

const RouterMain = () => (
  <Switch>
    <Route exact path="/" component={withAlert(Dashboard)} />
    <Route exact path="/home/:nSynchronization?" component={withAlert(Dashboard)} />
    <Route exact path="/login" render={() => <Redirect to="/" />} />
    <Route exact path="/reset-email/:cToken" component={ChangeEmail} />

    <Route exact path="/user" component={withAlert(UserIndex)} />
    <Route exact path="/user/update/:nId" component={withAlert(User)} />
    <Route exact path="/invite-users" component={withAlert(InviteUsers)} />

    <Route exact path="/notification" component={withAlert(Notification)} />
    <Route exact path="/pending-norms" component={withAlert(PendingNormsList)} />

    <Route exact path="/report/request/update/:nRequestId" component={withAlert(Request)} />
    <Route exact path="/report/request/accept/:nRequestId" component={withAlert(Request)} />
    <Route exact path="/report/request/:nRequestId?" component={withAlert(Request)} />
    <Route exact path="/report/activities-pending" component={withAlert(ActivitiesPending)} />

    <Route exact path="/folder/" component={withAlert(FolderIndex)} />
    <Route exact path="/folder/:nFolderId" component={withAlert(FolderIndex)} />

    <Route exact path="/document/create/:nFolderId?" component={withAlert(Document)} />
    <Route exact path="/document/loading" component={withAlert(Loading)} />
    <Route exact path="/document/review/:nVersionId" component={withAlert(DocumentReview)} />
    <Route exact path="/document/publish/:nVersionId" component={withAlert(DocumentPublish)} />
    <Route exact path="/document/editor/:nVersionId" component={withAlert(DocumentEditor)} />
    <Route exact path="/document/edit/:nId" component={withAlert(DocumentEdit)} />
    <Route exact path="/document/move/:nId" component={withAlert(DocumentMove)} />
    <Route exact path="/document/detail/:nVersionId" component={withAlert(DocumentDetails)} />
    <Route exact path="/document/no-links" component={withAlert(NoLink)} />
    <Route exact path="/document/reading/:nVersionId" component={withAlert(DocumentDetailsRead)} />
    <Route exact path="/document/print/:nDocumentId" component={withAlert(DocumentPrint)} />
    <Route exact path="/document/download" component={withAlert(Downloads)} />
    <Route exact path="/document/print" component={withAlert(Prints)} />
    <Route exact path="/document/copy/:nVersionId" component={withAlert(DocumentCopy)} />
    <Route exact path="/document/history/:nId" component={withAlert(VersionHistory)} />
    <Route exact path="/document/interaction/:nVersionId" component={withAlert(DocumentInteraction)} />
    <Route exact path="/document/compare/:nId/:cV1/:cV2" component={withAlert(VersionDiff)} />
    <Route exact path="/document/new-version/:nId" component={withAlert(NewVersion)} />
    <Route exact path="/document/migrate-responsibility/:nId" component={withAlert(MigrateResponsibilityDocument)} />

    <Route exact path="/quality-panel" component={withAlert(QualityPanelIndex)} />
    <Route exact path="/quality-panel-consultant" component={withAlert(QualityPanelConsultant)} />
    <Route exact path="/requisite-audit/:nAuditId?/:nEvidenceId?" component={withAlert(AuditIndex)} />
    <Route exact path="/report/audit/:nAuditId?" component={withAlert(Pdf)} />
    <Route
      exact
      path="/quality-panel/migrate-responsibility/:nId"
      component={withAlert(MigrateResponsibilityQuality)}
    />
    <Route exact path="/audits/migrate-responsibility/:nId" component={withAlert(MigrateResponsibilityAudits)} />
    <Route exact path="/audit-management" component={withAlert(DashboardAudit)} />
    <Route exact path="/audit/interaction/:nAuditId?" component={withAlert(AuditInteractions)} />

    <Route exact path="/norm/:nId?" component={withAlert(NormIndex)} />
    <Route exact path="/norm/interaction/:nId?" component={withAlert(NormInteractions)} />
    <Route exact path="/requisite/:nNormId?/:bDeadline?" component={withAlert(RequisiteIndex)} />
    <Route exact path="/requisite/detail/:nId?" component={withAlert(RequisiteShow)} />

    <Route exact path="/team" component={withAlert(PermissionGroupIndex)} />
    <Route exact path="/team/create" component={withAlert(PermissionGroup)} />
    <Route exact path="/team/update/:nId" component={withAlert(PermissionGroup)} />

    <Route exact path="/my-account/my-profile" component={withAlert(General)} />

    <Route exact path="/trash/" component={withAlert(TrashBin)} />
    <Route exact path="/document/filed/" component={withAlert(Filed)} />

    <Route exact path="/report/reading-document/" component={withAlert(DocumentReading)} />
    <Route exact path="/report/reading-document-delegate" component={withAlert(ReadingDocumentDelegates)} />
    <Route exact path="/report/reading-document-responsible" component={withAlert(ReadingDocumentResponsible)} />
    <Route exact path="/report/edits-document-delegate" component={withAlert(EditsDocumentDelegates)} />
    <Route exact path="/report/edits-document-responsible" component={withAlert(EditsDocumentResponsible)} />
    <Route exact path="/report/pending-per-user" component={withAlert(PendingPerUser)} />
    <Route exact path="/report/pending-per-user/:cPendingType" component={withAlert(PendingPerUser)} />
    <Route exact path="/report/master-list" component={withAlert(MasterList)} />

    <Route exact path="/backup/" component={withAlert(Backup)} />

    <Route exact path="/customer/update" component={withAlert(Customer)} />
    <Route exact path="/document/configure" component={withAlert(ConfigHeaderFooter)} />
    <Route exact path="/terms" component={withAlert(AdhesionContract)} />

    <Route exact path="/extract/" component={withAlert(ExtractIndex)} />
    <Route exact path="/extract/:nInvoiceId" component={withAlert(Extract)} />
    <Route exact path="/extract-history" component={withAlert(ExtractHistory)} />

    <Route
      exact
      path="/operational-panel/migrate-responsibility/:nId"
      component={withAlert(MigrateResponsibilityOperational)}
    />

    <Route exact path="/index/" component={withAlert(Index)} />

    <Route exact path="/register/activate/:cToken" component={RegisterActivation} />

    <Route exact path="/modules" component={withAlert(Module)} />

    <Route exact path="/document-share/:hash" component={DocumentAccess} />

    <Route path="*" component={NotFound} status={404} />
  </Switch>
);

export default RouterMain;
