import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { Input } from 'reactstrap';
import Select from 'react-select';
import NavItem from './NavItem';
import './styles/filter.scss';
import imgfSearch from '../../assets/img/search.svg';
import imgfSearchFocus from './img/icon-search.svg';
import imgfSelectDown from './img/select-down.svg';
import imgfSelectUp from './img/select-up.svg';
import imgCloseBlueIcon from '../../assets/img/closeBlueIcon.svg';

class Filter extends Component {
  constructor(oProps) {
    super(oProps);

    this.state = {
      bSearching: false,
      cText: '',
      cOrderBy: '',
      cOrderType: 'asc',
      isFocused: false,
      flex: this.props.flex
    };

    this.deboumceTimer = null;
  }

  customDebounce = (callback, delay) => {
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }
    this.debounceTimer = setTimeout(callback, delay);
  };

  componentDidMount() {
    const { cDefaultOrder } = this.props;

    if (cDefaultOrder != '') {
      this.setState({
        cOrderBy: cDefaultOrder
      });
    }
  }

  renderOrder = () => {
    const { aOrderItems, fnOnType, bIsOrderActive } = this.props;
    const { cOrderType, cOrderBy, cText } = this.state;
    let { cDefaultOrder } = this.props;

    if (!bIsOrderActive) {
      return '';
    }

    if (aOrderItems.length > 0) {
      if (!cDefaultOrder) {
        cDefaultOrder = aOrderItems[0].value;
      }

      return (
        <>
          <Select
            className="v2-select"
            classNamePrefix="react-select"
            options={aOrderItems}
            noOptionsMessage={() => intl.get('sem_opcoes')}
            components={{ DropdownIndicator: () => null }}
            value={aOrderItems.filter(oObj => oObj.value === cOrderBy)}
            defaultValue={cDefaultOrder}
            placeholder={intl.get('select')}
            onChange={evt => {
              const cNewOrderBy = evt.value;
              this.handleOrderChange(evt);
              fnOnType(evt, {
                cText,
                cOrderBy: cNewOrderBy,
                cOrderType
              });
            }}
          />
          <NavItem
            className="v2-filter-orderDirection"
            cIcon={cOrderType === 'asc' ? imgfSelectUp : imgfSelectDown}
            cText={intl.get('direction')}
            onClick={evt => {
              let cNewOrderType = 'asc';
              if (cOrderType === 'asc') {
                cNewOrderType = 'desc';
              }
              this.setState({
                cOrderType: cNewOrderType
              });
              fnOnType(evt, {
                cText,
                cOrderBy,
                cOrderType: cNewOrderType
              });
            }}
          />
        </>
      );
    }
    return '';
  };

  getFilterParams = () => {
    const { cText, cOrderBy, cOrderType } = this.state;

    return {
      cText,
      cOrderBy,
      cOrderType
    };
  };

  handleFilterChange = evt => {
    this.setState({
      cText: evt.target.value
    });
  };

  handleOrderChange = evt => {
    this.setState({
      cOrderBy: evt.value
    });
  };

  handleFocus = () => {
    this.setState({ isFocused: true });
  };

  handleBlur = () => {
    this.setState({ isFocused: false });
  };

  getSearching = () => {
    const { fnOnType, cFilterName, cPlaceholder } = this.props;
    const { cOrderBy, cOrderType, cText, isFocused } = this.state;
    return (
      <div className="v2-filter-searching">
        <span className="v2-filter-searching-icon">
          <img
            src={isFocused ? imgfSearchFocus : imgfSearch}
            alt={intl.get('search')}
            className="v2-filter-searching-image"
            style={{ width: '21px', height: '21px' }}
          />
        </span>
        <span
          className="v2-filter-searching-nav close"
          role="button"
          tabIndex={0}
          onClick={() => {
            this.setState({ bSearching: false });
          }}
          onKeyPress={() => {
            this.setState({ bSearching: false });
          }}
        >
          <img
            src={imgCloseBlueIcon}
            alt={intl.get('close')}
            onClick={() => {
              this.setState({ bSearching: false });
              this.setState({ cText: '' });
              fnOnType('', {
                cText: '',
                cOrderBy,
                cOrderType
              });
            }}
            style={{ height: '17px' }}
          />
        </span>
        <Input
          className="v2-filter-searching-input"
          type="text"
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          name={cFilterName}
          id={cFilterName}
          placeholder={cPlaceholder || 'Digite...'}
          value={cText}
          onChange={evt => {
            evt.persist();
            const cNewText = evt.target.value;
            this.handleFilterChange(evt);
            this.customDebounce(() => {
              fnOnType(evt, {
                cText: cNewText,
                cOrderBy,
                cOrderType
              });
            }, 500);
          }}
        />
      </div>
    );
  };

  getNotSearching = () => {
    return (
      <div
        className={this.state.flex ? `v2-filter-notsearching v2-filter-notsearching-flex` : 'v2-filter-notsearching'}
      >
        {this.renderButtons()}
        {this.renderCheckboxes()}
        {this.renderOrder()}
        <ul>
          {this.renderItems()}
          <NavItem
            className="v2-filter-iconSearch"
            cIcon={imgfSearch}
            cIconFocus={imgfSearch}
            cText={intl.get('search')}
            onClick={() => {
              this.setState({ bSearching: true });
            }}
          />
          {this.renderRightMenu()}
        </ul>
      </div>
    );
  };

  renderRightMenu = () => {
    const { rcmpRightMenu } = this.props;
    return rcmpRightMenu ?? '';
  };

  renderButtons = () => {
    const { aButtons } = this.props;
    const rcmpButtons = Object.keys(aButtons).map(cKey => aButtons[cKey]);
    return rcmpButtons;
  };

  renderCheckboxes = () => {
    const { aCheckboxes } = this.props;
    if (typeof aCheckboxes === 'undefined') {
      return '';
    }

    const rcmpCheckboxes = Object.keys(aCheckboxes).map(cKey => aCheckboxes[cKey]);
    return rcmpCheckboxes;
  };

  renderItems = () => {
    const { aItems } = this.props;
    if (typeof aItems === 'undefined') {
      return '';
    }

    const rcmpNavItems = Object.keys(aItems).map(cKey => aItems[cKey]);
    return rcmpNavItems;
  };

  render = () => {
    const { bSearching } = this.state;
    const { className } = this.props;
    let result = '';
    let cClassName = 'v2-cardheader-filter ';

    if (bSearching) {
      result = this.getSearching();
    } else {
      result = this.getNotSearching();
    }

    if (className.length > 0) {
      cClassName += className;
    }
    return <div className={cClassName}>{result}</div>;
  };
}
Filter.defaultProps = {
  fnOnType: null,
  cFilterName: '',
  cPlaceholder: '',
  aOrderItems: [],
  cDefaultOrder: '',
  aButtons: [],
  aCheckboxes: [],
  aItems: [],
  className: '',
  bIsOrderActive: true,
  rcmpRightMenu: ''
};
export default Filter;
