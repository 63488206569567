import React, { Component } from 'react';
import intl from 'react-intl-universal';
import './App.scss';
import { loadReCaptcha } from 'react-recaptcha-google';
import { Balloons } from './pages/default/Balloons';
import Footer from './pages/default/Footer';
import RouterExternal from './pages/default/RouterExternal';
import RouterMain from './pages/default/RouterMain';
import { DocusignProvider } from './components/DocusignContext';
import { oLocalStorage } from './config/EncodedLocalStorage';

const oLocales = {
  en: require('./locales/en.json'),
  'pt-br': require('./locales/pt-br.json')
};

class App extends Component {
  state = { initDone: false, drawerOpen: true, anchorEl: null };

  componentDidMount() {
    this.loadLocales();
    loadReCaptcha();
  }

  static getLanguage() {
    const cLanguage = oLocalStorage.get('cLanguage');
    if (cLanguage) return cLanguage;

    switch (intl.determineLocale()) {
      case 'en_US':
      case 'en-US':
      case 'us':
        return 'en';
      case 'pt-BR':
      case 'pt_BR':
      case 'pt':
      default:
        return 'pt-br';
    }
  }

  loadLocales() {
    intl
      .init({
        currentLocale: App.getLanguage(),
        locales: oLocales
      })
      .then(() => {
        this.setState({ initDone: true });
      });
  }

  render() {
    const { initDone } = this.state;
    const bIsLoggedIn = localStorage.getItem('cStorageToken');
    const cPathname = window.location.pathname;

    if (bIsLoggedIn && bIsLoggedIn !== 'undefined') {
      return (
        <div>
          <DocusignProvider>
            <main className={cPathname !== '/' ? 'card' : ''} id="container">
              <div className="MessageHolder centered" />
              {initDone && <RouterMain />}
            </main>
            {cPathname !== '/quality-panel-consultant' && <Balloons />}
            <Footer />
          </DocusignProvider>
        </div>
      );
    }
    return <RouterExternal />;
  }
}

export default App;
