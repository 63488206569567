import moment from 'moment';
import EncodedLocalStorage from '../config/EncodedLocalStorage';

/** Timezone utilizada no banco de dados */
const CTIMEZONE_DB = 'America/Bahia';

/**
 * Cria um objeto moment no fuso-horário do usuário.
 * @param {string} cDateStr Data como string
 * @param {string} cSrcTZ Timezone em que a data está. Por padrão, é a timezone do banco de dados.
 */
export function momentWithUserTZ(cDateStr, cSrcTZ = CTIMEZONE_DB) {
  const oLocalStorage = new EncodedLocalStorage();
  return moment.tz(cDateStr, cSrcTZ).tz(oLocalStorage.get('cTimezone'));
}

/**
 * Formata uma data no fuso-horário do usuário de acordo com o formato especificado.
 * @param {string} cDateStr Data como string
 * @param {string} cFormat Formato da data. Utilize o formato aceito pela biblioteca moment. Por padrão, é 'DD/MM/YYYY HH:mm:ss'.
 * @param {string} cSrcTZ Timezone em que a data está. Por padrão, é a timezone do banco de dados.
 */
export function formatDateToUserTZ(cDateStr, cFormat = 'DD/MM/YYYY HH:mm:ss', cSrcTZ = CTIMEZONE_DB) {
  return momentWithUserTZ(cDateStr, cSrcTZ).format(cFormat);
}

export default {
  momentWithUserTZ,
  formatDateToUserTZ
};
