import React from 'react';
import intl from 'react-intl-universal';
import imgfLoading from '../../assets/img/preloader-black.gif';

export default function Page(oProps) {
  const { loading, children, notcentered, className } = oProps;
  return (
    <div {...oProps} className={`${(notcentered === "true") ? '' : 'centered'} ${className}`}>
      {loading ? (
        <div className="centered-text">
          <img alt={intl.get('carregando')} src={imgfLoading} />
        </div>
      ) : (
        children
      )}
    </div>
  );
}
