import Axios from '../config/Axios';

export default function activitiesPending(page = 1, perPage = 50, orderBy = 'activity_ddeadline', order = 'ASC', search) {
  return Axios.get('/operationa-panel-activity/report', {
    params: {
      page,
      perPage,
      orderBy,
      order,
      search
    }
  }).then(oResponse => oResponse.data.data);
}
