import React, { Component } from 'react';
import intl from 'react-intl-universal';
import getInteractions from '../../actions/ActionDocumentInteraction';
import imgfNoPic from '../../assets/img/nopic.svg';
import Breadcrumb from '../../components/Breadcrumbs';
import url from '../../config/Url';
import Page from '../default-v2/Page';
import CardHeader from '../default-v2/CardHeader';
import './styles/documentinteraction.scss';
import { checkModule } from '../../config/Permissions';
import documentImg from '../../assets/img/documents.svg';
import { formatDateToUserTZ } from '../../utils/time';

export class DocumentInteraction extends Component {
  constructor(oProps) {
    super(oProps);

    this.state = {
      bLoading: true,
      aNewDocumentRequests: [],
      oVersion: { interactions: [] }
    };
  }

  componentDidMount() {
    const { history } = this.props;
    checkModule(history, 'documents');

    this.fetchInteractions();
  }

  fetchInteractions = () => {
    const { match, setAlert } = this.props;

    getInteractions(match.params.nVersionId)
      .then(oData => this.setState({ bLoading: false, ...oData }))
      .catch(oError => {
        this.setState({ bLoading: false });
        setAlert('error', oError.aMsgErrors);
      });
  };

  render() {
    const { oVersion, aNewDocumentRequests, aBreadcrumbFolders, bLoading } = this.state;
    const { history, getAlert } = this.props;
    const cTitle =
      oVersion && oVersion.document
        ? intl.get('DocumentInteraction.interacoes') + ' - ' + oVersion.document.doc_ctitle + ' v. ' + oVersion.ver_nid
        : '';

    return (
      <Page
        loading={bLoading ? 1 : 0}
        rcmpBreadcrumb={
          <Breadcrumb
            bLastLink
            key={oVersion && oVersion.document ? oVersion.document.doc_nid : ''}
            aFolders={aBreadcrumbFolders}
            oDocument={oVersion && oVersion.document ? oVersion.document : null}
            cType="document"
          />
        }
        cTitle={cTitle}
        cImage={documentImg}
        rcmpCardHeader={<CardHeader history={history} />}
        cCurrentSideMenuOption={intl.get('Nav.document')}
      >
        {getAlert()}
        <ul className="interactions">
          {oVersion.interactions.map(oInteraction => (
            <li key={`i-${oInteraction.intr_nid}`}>
              <div className="title-wrapper">
                <span className="avatar">
                  <img
                    src={oInteraction.user.user_cavatar ? oInteraction.user.user_cavatar : imgfNoPic}
                    alt={oInteraction.user.user_cname}
                  />
                </span>
                <span title={oInteraction.user.user_cname}>
                  <p className="name">{oInteraction.user.user_cname}</p>
                </span>
              </div>

              <div className="message">
                <pre>
                  <p>{oInteraction.intr_ctext}</p>
                </pre>
              </div>

              <p className="date">
                {formatDateToUserTZ(
                  oInteraction[oInteraction.status?.stat_cslugname === 'saved' ? 'intr_dupdated' : 'intr_dcreated']
                )}
              </p>
            </li>
          ))}

          {aNewDocumentRequests.map(oRequest => (
            <li key={`s-${oRequest.rqst_nid}`}>
              <div className="title-wrapper">
                <span className="avatar">
                  <img
                    src={oRequest.user_requester.user_cavatar ? oRequest.user_requester.user_cavatar : imgfNoPic}
                    alt={oRequest.user_requester.user_cname}
                  />
                </span>
                <p className="name">{oRequest.user_requester.user_cname}</p>
              </div>

              <div className="message">
                <p style={{ whiteSpace: 'pre-wrap' }}>{oRequest.rqst_cjustification}</p>
              </div>

              <p className="date">
                <i className="icon-calendar" /> {formatDateToUserTZ(oRequest.rqst_dcreated)}
              </p>
            </li>
          ))}
        </ul>
      </Page>
    );
  }
}

export default DocumentInteraction;
