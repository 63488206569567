import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import intl from 'react-intl-universal';
import DocusignContext from './DocusignContext';

const DocusignButton = ({
  children,
  htmlToDocusign,
  aSelectedList,
  setAlert,
  titleToDocusign,
  envelopeId,
  bIsDisabled
}) => {
  const {
    showDeleteModal,
    setShowDeleteModal,
    showDifferentUserModal,
    setShowDifferentUserModal,
    docusignAccountId,
    setDocusignAccountId,
    setDocusignError
  } = useContext(DocusignContext);

  useEffect(() => {
    const code = new URL(window.location.href).searchParams.get('code');

    async function fetchToken() {
      try {
        const response = await axios.post('docusign', { code });
        const [accessToken, userData, isUserAuthorized] = response?.data ?? [];

        if (!userData || !userData.accounts || !userData.accounts.length) {
          throw new Error('Unable to retrieve account ID');
        }

        const accountId = userData.accounts[0].account_id;

        setDocusignAccountId(accountId);

        if (JSON.parse(window.localStorage.getItem('delete')) == true) {
          window.localStorage.removeItem('delete');

          await axios.delete(
            `docusign/destroy-envelope/${envelopeId}?accessToken=${accessToken}&accountId=${accountId}`
          );

          window.localStorage.removeItem('delete');
          window.location.href = window.location.href.split('?')[0];
          return;
        }

        if (isUserAuthorized === true) {
          await axios.post('docusign/configure-webhook', {
            accessToken: accessToken.access_token,
            userInfo: userData
          });

          const senderView = await axios.post('docusign/send-envelope', {
            userInfo: userData,
            accessToken: accessToken.access_token,
            html: htmlToDocusign,
            docTitle: titleToDocusign
          });

          window.localStorage.setItem('url', JSON.stringify(window.location.href.split('?')[0]));
          window.location.href = senderView.data;
        } else {
          setShowDifferentUserModal(true);
        }
      } catch (error) {
        setDocusignError(true);
      }
    }

    if (code) {
      fetchToken();
    }
  }, []);
  const redirectToLogin = e => {
    e.preventDefault();
    if (aSelectedList.length == 0) {
      setAlert('error', intl.get('DocumentPublish.messagem_select_user_team'));
      return;
    }
    const redirectUrl = `${process.env.REACT_APP_DOCUSIGN_BASE_URL}/auth?response_type=code&client_id=${process.env.REACT_APP_DOCUSIGN_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_DOCUSIGN_REDIRECT_URL}&scope=signature`;
    window.localStorage.removeItem('url');
    window.localStorage.setItem('url', JSON.stringify(window.location.href.split('?')[0]));
    window.localStorage.removeItem('aSelectedList');
    window.localStorage.setItem('aSelectedList', JSON.stringify(aSelectedList));

    window.location.href = redirectUrl;
  };
  const destroyEnvelope = e => {
    e.preventDefault();
    setShowDeleteModal(true);
  };

  return (
    <>
      {envelopeId ? (
        <>
          <button className="btn docusign-cancel-btn docusign-btn" onClick={destroyEnvelope} disabled={bIsDisabled}>
            {children}
          </button>
        </>
      ) : (
        <>
          <button className="btn docusign-btn" id="docusign-btn" onClick={redirectToLogin} disabled={bIsDisabled}>
            {children}
          </button>
        </>
      )}
    </>
  );
};

export default DocusignButton;
