import Axios from '../config/Axios';

export default function masterList(page = 1, perPage = 50, orderBy = 'cUserName', order = 'ASC', cSearch) {
  return Axios.get('/report/master-list', {
    params: {
      page,
      perPage,
      orderBy,
      order,
      cSearch
    }
  }).then(oResponse => oResponse.data);
}

export function masterListPdf(cSearch, orderBy = 'cUserName', order = 'ASC') {
  return Axios.get('/report/master-list-pdf', {
    params: {
      cSearch,
      orderBy,
      order
    },
    responseType: 'blob'
  }).then(oResponse => oResponse.data);
}

export function masterListXlsx(cSearch, orderBy = 'cUserName', order = 'ASC') {
  return Axios.get('/report/master-list-xlsx', {
    params: {
      cSearch,
      orderBy,
      order
    },
    responseType: 'blob'
  }).then(oResponse => oResponse.data);
}
