import React, { Component } from 'react';
import User from '../user/User';
import Axios from '../../config/Axios';
import Page from '../default-v2/Page';
import CardHeader from '../default-v2/CardHeader';
import Breadcrumb from '../../components/Breadcrumbs';
import intl from 'react-intl-universal';
import userImg from '../../assets/img/users.svg';

class General extends Component {
  constructor(oProps) {
    super(oProps);
    this.state = {
      oUserConfig: '',
      bLoading: true,
      aPages: [
        {
          cName: 'MyProfile.title',
          cLink: undefined
        }
      ]
    };
  }

  componentDidMount = () => {
    const { setAlert } = this.props;

    Axios.get('my-account')
      .then(oResponse => {
        const { aUser } = oResponse.data;
        this.setState({ oUserConfig: aUser, bLoading: false });
      })
      .catch(error => {
        this.setState({ bLoading: false });
        setAlert('error', error.aMsgErrors);
      });
  };

  generateCardHeader = () => {
    const { history } = this.props;

    const rcmpCardHeader = <CardHeader history={history} />;
    return rcmpCardHeader;
  };

  render() {
    const { oUserConfig, bLoading, aPages } = this.state;
    const { addAlert, setAlert, clearAlert, getAlert, history } = this.props;
    const bIsCleanPage = true;

    const rcmpBreadcrumb = <Breadcrumb aPages={aPages} />;

    const rcmpCardHeader = this.generateCardHeader();

    const cPageTitle = intl.get('MyProfile.title');

    return (
      <Page
        loading={bLoading ? 1 : 0}
        rcmpBreadcrumb={rcmpBreadcrumb}
        cTitle={cPageTitle}
        cImage={userImg}
        rcmpCardHeader={rcmpCardHeader}
        className="v2-my-profile"
        bIsUserConfigMenu={true}
      >
        {getAlert()}
        {oUserConfig.user_nid && (
          <User
            bIsCleanPage={bIsCleanPage}
            history={history}
            nId={oUserConfig.user_nid}
            addAlert={addAlert}
            setAlert={setAlert}
            clearAlert={clearAlert}
          />
        )}
      </Page>
    );
  }
}

export default General;
