import React from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';

const AltCheckbox = ({
  className,
  onChange,
  title,
  checked,
  value,
  name,
  id,
  label,
  message,
  localeLabel,
  disabled = false
}) => {
  return label ? (
    <label
      className={`alt-checkbox ${className}`}
      style={{ width: 'auto', minWidth: 'unset', margin: '7.5px 20px 0 20px' }}
      onChange={e => onChange(e)}
    >
      {localeLabel && <span className="alt-checkbox-label">{label}</span>}

      <span title={title} className={`togglecheck ${checked ? 'selected' : ''}`} />
      <input
        checked={checked}
        disabled={disabled}
        value={value}
        name={name}
        type="checkbox"
        id={id}
        onChange={() => {}}
      />
    </label>
  ) : (
    <label
      className={`alt-checkbox ${className}`}
      style={{ width: 'auto', minWidth: 'unset', margin: '0' }}
      onChange={onChange}
    >
      <span title={title} className={`togglecheck ${checked ? 'selected' : ''}`} />
      <input
        checked={checked}
        disabled={disabled}
        value={value}
        name={name}
        type="checkbox"
        id={id}
        onChange={() => {}}
      />
    </label>
  );
};

AltCheckbox.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  message: PropTypes.string,
  localeLabel: PropTypes.string
};

AltCheckbox.defaultProps = {
  title: intl.get('AltCheckbox.status'),
  className: 'split2',
  localeLabel: 'start',
  message: null
};

export default AltCheckbox;
