import React, { Component } from 'react';
import { Input, Button } from 'reactstrap';
import Select from 'react-select';
import intl from 'react-intl-universal';
import Axios, { extractErrorMessages } from '../../config/Axios';
import AltCheckbox from '../../components/AltCheckbox';
import Breadcrumb from '../../components/Breadcrumbs';
import { can, canAtLeast } from '../../config/Permissions';
import Page from '../default-v2/Page';
import LimitWidget from '../../components/LimitWidget';
import { Alert } from '../../components/Alert';
import CardHeader from '../default-v2/CardHeader';
import './styles/inviteusers.scss';
import userImg from '../../assets/img/users.svg';

class InviteUsers extends Component {
  constructor(oProps) {
    super(oProps);

    this.state = {
      bLoading: true,
      aInvitations: [{ nKey: 0, cName: '', cEmail: '', bIsAdmin: false, aTeams: [] }],
      bWasSent: false,
      oLimits: {
        oUser: {
          nLeft: '',
          nAllowed: ''
        }
      },
      // usado pelo sistema global para breadcrumb
      aPages: [
        {
          cName: 'InviteUsers.title',
          cLink: '/user'
        },
        {
          cName: 'InviteUsers.new_user',
          cLink: undefined
        }
      ]
    };
  }

  componentDidMount = () => {
    const { setAlert, history } = this.props;

    if (!canAtLeast(['create-edit-user', 'admin'])) {
      history.push({
        pathname: '/user',
        state: { aAlertMessages: { cType: 'error', cMsg: intl.get('sem_permissao') } }
      });
    }

    Promise.all([
      Axios.get('team').then(oResponse => {
        const aTeams = oResponse.data.map(oTeam => ({
          label: oTeam.team_cname,
          value: oTeam.team_nid
        }));
        this.setState({ aTeams });
      }),
      Axios.get('customer/users-limit').then(oResponse => {
        const oLimits = {
          oUser: oResponse.data.aUser
        };
        this.setState({
          oLimits
        });
      })
    ])
      .then(() => this.setState({ bLoading: false }))
      .catch(oError => {
        this.setState({ bLoading: false });
        setAlert('error', oError.aMsgErrors);
      });
  };

  handleChange = (evt, nKey) => {
    const { aInvitations } = this.state;
    aInvitations.forEach(oInvitation => {
      if (oInvitation.nKey === nKey) {
        // eslint-disable-next-line no-param-reassign
        oInvitation[evt.target.name] = evt.target.type === 'checkbox' ? evt.target.checked : evt.target.value;
      }
    });
    this.setState({ aInvitations });
  };

  handleChangeTeams = (aSelectedOption, nKey) => {
    const { aInvitations } = this.state;
    aInvitations.forEach(oInvitation => {
      if (oInvitation.nKey === nKey) {
        // eslint-disable-next-line no-param-reassign
        oInvitation.aTeams = aSelectedOption;
      }
    });
    this.setState({ aInvitations });
  };

  hasDuplicatedEmails = () => {
    const { aInvitations } = this.state;
    const { setAlert } = this.props;

    // eslint-disable-next-line no-restricted-syntax
    for (const oInvite of aInvitations) {
      if (aInvitations.filter(oFilteredInvite => oFilteredInvite.cEmail === oInvite.cEmail).length > 1) {
        setAlert('error', intl.get('InviteUsers.email_duplicado', { email: oInvite.cEmail }));
        return true;
      }
    }
    return false;
  };

  handleSubmit = evt => {
    evt.preventDefault();

    const self = this;
    // usado pelo sistema global para histórico e alerta
    const { history, setAlert, addAlert, clearAlert } = this.props;
    const { aInvitations } = this.state;

    if (self.hasDuplicatedEmails()) {
      return;
    }

    const bAreTeamsEmpty = aInvitations.some(oInvite => oInvite.aTeams.length === 0);

    if (bAreTeamsEmpty) {
      setAlert('error', intl.get('InviteUsers.equipe_obrigatorio'));
      return;
    }

    self.setState({
      bWasSent: true
    });

    Axios.post('user-invite', aInvitations)
      .then(oResponse => {
        if (oResponse.status === 207) {
          self.setState({
            bWasSent: false
          });
          clearAlert();
          window.scrollTo(0, 0);

          const aSuccessMails = [];
          let cSuccessMessage = `E-mail(s) enviado(s) para `;
          oResponse.data.aSuccesses.forEach(oSuccess => {
            aSuccessMails.push(oSuccess.cEmail);
          });
          cSuccessMessage += aSuccessMails.join(', ');
          cSuccessMessage += ` com sucesso.`;
          addAlert('success', cSuccessMessage);

          oResponse.data.aFailures.forEach(oFailures => {
            addAlert('error', oFailures.cMessage);
          });

          this.setState(oCurrentState => ({
            aInvitations: oCurrentState.aInvitations.filter(oItem => aSuccessMails.indexOf(oItem.cEmail) === -1)
          }));
        } else {
          this.setState({
            bWasSent: false
          });

          history.push({
            pathname: '/user',
            state: { aAlertMessages: { cType: 'success', cMsg: intl.get('InviteUsers.convites_sucesso') } }
          });
        }
      })
      .catch(oError => {
        this.setState({
          bWasSent: false
        });
        clearAlert();
        window.scrollTo(0, 0);

        const aErrorMessages = extractErrorMessages(oError);
        this.setState({
          rcmpAlert: aErrorMessages.map(cMsg => (
            <Alert type={cMsg.includes('ativação') ? 'success' : 'error'} key={cMsg} isOpen onCloseAlert={() => {}}>
              <div dangerouslySetInnerHTML={{ __html: cMsg }} />
            </Alert>
          ))
        });
      });
  };

  getNextKey = () => {
    const { aInvitations } = this.state;
    let nKey = 0;

    aInvitations.forEach(oInvitation => {
      if (oInvitation.nKey > nKey) {
        nKey = oInvitation.nKey;
      }
    });
    nKey += 1;

    return nKey;
  };

  addUserField = evt => {
    evt.preventDefault();
    const oItem = { nKey: this.getNextKey(), cName: '', cEmail: '', bIsAdmin: false, aTeams: [] };
    this.setState(oCurrentState => ({
      aInvitations: oCurrentState.aInvitations.concat(oItem)
    }));
  };

  removeField = nKey => {
    this.setState(oCurrentState => ({
      aInvitations: oCurrentState.aInvitations.filter(oItem => oItem.nKey !== nKey)
    }));
  };

  generateCardHeader = () => {
    const { history } = this.props;

    const rcmpCardHeader = <CardHeader history={history} />;
    return rcmpCardHeader;
  };

  render() {
    const { rcmpAlert, bWasSent, aTeams, aInvitations, aPages, bLoading, oLimits } = this.state;
    const { history, getAlert } = this.props;

    const rcmpBreadcrumb = <Breadcrumb aPages={aPages} />;

    const cPageTitle = intl.get('InviteUsers.title');
    const rcmpCardHeader = this.generateCardHeader();

    return (
      <Page
        loading={bLoading ? 1 : 0}
        rcmpBreadcrumb={rcmpBreadcrumb}
        cTitle={cPageTitle}
        cImage={userImg}
        rcmpCardHeader={rcmpCardHeader}
        className="v2-invite-users"
        cCurrentSideMenuOption={intl.get('Nav.users')}
      >
        {rcmpAlert}
        {getAlert()}

        {can('admin') && <LimitWidget oLimits={oLimits} />}

        <form onSubmit={this.handleSubmit}>
          {aInvitations.map((oInvitation, nIndex) => (
            // eslint-disable-next-line react/no-array-index-key
            <fieldset style={{ position: 'relative' }} key={oInvitation.nKey}>
              <div className="form-head">
                <span
                  className="btn-close"
                  title={intl.get('excluir', { tipo: '' })}
                  role="button"
                  tabIndex={0}
                  onKeyPress={() => this.removeField(oInvitation.nKey)}
                  onClick={() => this.removeField(oInvitation.nKey)}
                />

                <legend>{`${intl.get('InviteUsers.invitation')} ${nIndex + 1}`}</legend>
              </div>
              <div className="form-body">
                <label className="split2">
                  <span>
                    {intl.get('User.cnome')} <span className="required">*</span>
                  </span>
                  <Input
                    type="text"
                    id="cName"
                    name="cName"
                    required
                    placeholder={intl.get('InviteUsers.add_name_user')}
                    onChange={evt => this.handleChange(evt, oInvitation.nKey)}
                  />
                </label>

                <label className="split2">
                  <span>
                    {intl.get('cemail')} <span className="required">*</span>
                  </span>
                  <Input
                    autoComplete="email"
                    type="email"
                    id="cEmail"
                    name="cEmail"
                    maxLength="250"
                    required
                    placeholder={intl.get('InviteUsers.add_email_user')}
                    onChange={evt => this.handleChange(evt, oInvitation.nKey)}
                  />
                </label>

                <label>
                  <span>
                    {intl.get('equipes')} <span className="required">*</span>
                  </span>
                  <Select
                    onChange={elSelectedOption => this.handleChangeTeams(elSelectedOption, oInvitation.nKey)}
                    className="selectCustom"
                    classNamePrefix="react-select"
                    noOptionsMessage={() => intl.get('InviteUsers.equipe_nao_encontrada')}
                    isMulti
                    placeholder={intl.get('InviteUsers.add_team_user')}
                    options={aTeams}
                  />
                </label>
                <AltCheckbox
                  name="bIsAdmin"
                  label={intl.get('User.badministrador')}
                  onChange={e => this.handleChange(e, oInvitation.nKey)}
                  checked={oInvitation.bIsAdmin}
                />
              </div>
            </fieldset>
          ))}
          <div className="button-stack">
            <Button
              disabled={bWasSent || aInvitations.length <= 0}
              className="btn-cancel"
              onClick={evt => {
                history.goBack();
              }}
            >
              {intl.get('cancelar')}
            </Button>

            {(aInvitations.length < oLimits.oUser.nLeft || oLimits.oUser.nAllowed === -1) && (
              <Button disabled={bWasSent} className="btn-add-invite" onClick={evt => this.addUserField(evt)}>
                {intl.get('InviteUsers.add_invitation')}
              </Button>
            )}

            <Button disabled={bWasSent || aInvitations.length <= 0} type="submit" className="btn">
              {intl.get('InviteUsers.invite')}
            </Button>
          </div>
        </form>
      </Page>
    );
  }
}
export default InviteUsers;
