import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import $ from 'jquery';
import intl from 'react-intl-universal';
import { can, canAtLeast } from '../../config/Permissions';
import { doLogout } from './Header';

class Nav extends Component {
  componentDidMount = () => {
    $(() => {
      $('body, .close-menu').on('click', evt => {
        if (evt.target.id === 'close-menu') {
          evt.preventDefault();
        }

        if (evt.target.id !== 'menu-button' && evt.target.id !== 'main-menu') {
          $('#menu-button').removeClass('active');
          $('#main-menu').removeClass('opened');
        }
      });

      function slideAction(elThis) {
        $(elThis)
          .parent()
          .find('ul')
          .stop(true, true)
          .slideToggle();
      }

      $('#main-menu li.has-childs > a').click(function(evt) {
        evt.stopPropagation();
        let elThis = this;

        $('#main-menu li.has-childs > a').each(function(elItem) {
          if ($(this).hasClass('active') && this !== elThis) {
            $(this).removeClass('active');
            slideAction(this);
          }
        });

        if (!$(this).hasClass('active')) {
          $(this).toggleClass('active');
          slideAction(this);
        } else {
          $(this).removeClass('active');
          slideAction(this);
        }

        return false;
      });
    });
  };

  render() {
    return (
      <nav id="main-menu">
        <a className="close-menu" id="close-menu" href="/#fechar" tabIndex={0}>
          &nbsp;
        </a>
        <ul>
          <ul>
            <li className="top-level-item">
              <NavLink to="/my-account/general" title={intl.get('minha_conta')}>
                {intl.get('minha_conta')}
              </NavLink>
            </li>
          </ul>

          <ul>
            <li className="top-level-item">
              {canAtLeast(['edit-customer', 'access-plan', 'admin']) && (
                <NavLink to="/settings/client/update/" title="Configurações" className="config-tip" id="config-tip">
                  {intl.get('MyAccount.titulo_tab4')}
                </NavLink>
              )}
            </li>
          </ul>

          <li className="top-level-item has-childs">
            <NavLink className="toggle-menu" exact to="/#folder" title={intl.get('Nav.document')}>
              {intl.get('Nav.document')}
            </NavLink>
            <ul style={{ display: 'none' }}>
              <li>
                <NavLink to="/folder" title={intl.get('Nav.document')}>
                  {intl.get('Nav.document')}
                </NavLink>
              </li>
              <li>
                <NavLink to="/document/filed/" title={intl.get('Nav.filed_documents')}>
                  {intl.get('Nav.filed_documents')}
                </NavLink>
              </li>
              <li>
                <NavLink to="/trash" title={intl.get('Nav.lixeira')}>
                  {intl.get('Nav.lixeira')}
                </NavLink>
              </li>
            </ul>
          </li>
        </ul>
        <ul>
          <li className="top-level-item">
            <NavLink to="/norm" title="Normas">
              {intl.get('Nav.normas')}
            </NavLink>
          </li>
        </ul>
        {canAtLeast(['user', 'team']) && (
          <ul>
            <li className="top-level-item has-childs">
              <NavLink className="toggle-menu" exact to="/#user-permission" title={intl.get('Nav.usuarios')}>
                {intl.get('Nav.usuarios')}
              </NavLink>
              <ul>
                {can('team') && (
                  <li>
                    <NavLink to="/team" title={intl.get('Nav.equipes')}>
                      {intl.get('Nav.equipes')}
                    </NavLink>
                  </li>
                )}
                {can('team') && (
                  <li>
                    <NavLink to="/user" title={intl.get('Nav.usuarios')}>
                      {intl.get('Nav.usuarios')}
                    </NavLink>
                  </li>
                )}
              </ul>
            </li>
          </ul>
        )}
        {canAtLeast('report') && (
          <ul>
            <li className="top-level-item has-childs">
              <NavLink className="toggle-menu" exact to="/#report" title={intl.get('Nav.relatorio')}>
                {intl.get('Nav.relatorio')}
              </NavLink>
              <ul>
                <li>
                  <NavLink to="/document/download" title="Download">
                    {intl.get('Nav.download')}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/document/print" title="Impressão">
                    {intl.get('Nav.impressao')}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/report/reading-document/" title={intl.get('Nav.leitura_documento')}>
                    {intl.get('Nav.leitura_documento')}
                  </NavLink>
                </li>
              </ul>
            </li>
          </ul>
        )}
        <ul>
          <li className="top-level-item">
            <a href="/#" title={intl.get('sair')} onClick={() => doLogout()}>
              {intl.get('sair')}
            </a>
          </li>
        </ul>
      </nav>
    );
  }
}

export default Nav;
