import React, { useEffect, useState, createContext } from 'react';

const DocusignContext = createContext({
  showDeleteModal: false,
  setShowDeleteModal: () => {}
});

export const DocusignProvider = ({ children }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showStatusDetailsModal, setShowStatusDetailsModal] = useState(false);
  const [signers, setSigners] = useState([]);
  const [showDifferentUserModal, setShowDifferentUserModal] = useState(false);
  const [docusignAccountID, setDocusignAccountId] = useState('');
  const [docusignError, setDocusignError] = useState(false);

  return (
    <DocusignContext.Provider
      value={{
        showDeleteModal,
        setShowDeleteModal,
        showStatusDetailsModal,
        setShowStatusDetailsModal,
        signers,
        setSigners,
        showDifferentUserModal,
        setShowDifferentUserModal,
        docusignAccountID,
        setDocusignAccountId,
        docusignError,
        setDocusignError
      }}
    >
      {children}
    </DocusignContext.Provider>
  );
};

export default DocusignContext;
