import React, { useState, useEffect } from 'react';
import Axios from '../../config/Axios';
import intl from 'react-intl-universal';
import Page from '../default-v2/Page';
import Breadcrumb from '../../components/Breadcrumbs';
import CardHeader from '../default-v2/CardHeader';
import './styles/pendingnorms.scss';
import cPendingImg from '../../assets/img/264-warning-blue.svg';
import { Alert } from '../../components/Alert';

const PendingNormsList = ({ history }) => {
  const [aPendingNorms, setPendingNorms] = useState([]);
  const [bLoading, setLoading] = useState(true);
  const [cSuccessMessage, setCSuccessMessage] = useState('');
  const aPages = [{ cName: 'PendingNorms.title', cLink: undefined }];
  const [cErrorMessage, setCErrormessage] = useState('');
  const [rcmpAlertMsg, setRcmpAlertMsg] = useState(null);

  useEffect(() => {
  }, [aPendingNorms]);

  useEffect(() => {
    const fetchPendingNorms = async () => {
      try {
        const oResResponse = await Axios.get('norm/pendingNorms');
        setPendingNorms(oResResponse.data.messages);
        setLoading(false);
      } catch (oExcpError) {
        setCErrormessage(oExcpError.message);
      }
    };
    fetchPendingNorms();
  }, []);

  const handleAuthorization = async (nIdNorm, nCustNid, nUserNid, bNrmAuth) => {
    try {
      const oResResponse = await Axios.post('norm/authorizeNorm', { id_norm: nIdNorm, cust_nid: nCustNid, user_nid: nUserNid, na_bisauthorized: bNrmAuth });
      const cSuccessMessage = oResResponse.data.message; // Obter a mensagem de sucesso do response
      setPendingNorms(aPendingNorms.filter((oNormData) => oNormData.id_norm !== nIdNorm));
      setCSuccessMessage(cSuccessMessage); // Atualizar a variável com a mensagem de sucesso
      setRcmpAlertMsg(
        <Alert type="success" isOpen onCloseAlert={() => setRcmpAlertMsg(null)}>
          {cSuccessMessage}
        </Alert>
      );
    } catch (oExcpError) {
      const cErrorMessage = oExcpError.response.data.error; // Obter a mensagem de erro do response
      setCErrormessage(cErrorMessage); // Atualizar a variável com a mensagem de erro
      setRcmpAlertMsg(
        <Alert type="error" isOpen onCloseAlert={() => setRcmpAlertMsg(null)}>
          {cErrorMessage}
        </Alert>
      );
    }
  };



  return (
    <div>
      <Page
        loading={bLoading}
        rcmpBreadcrumb={<Breadcrumb aPages={aPages} />}
        cImage={cPendingImg}
        cTitle={intl.get('PendingNorms.title')}
        rcmpCardHeader={<CardHeader history={history} />}
        cCurrentSideMenuOption={intl.get('PendingNorms.title')}
      >
        <div className="pending-norms-container">
          {rcmpAlertMsg}
          {bLoading ? (
            <div>{intl.get('PendingNorms.loading')}</div>
          ) : (
            <ul className="pending-norms-list">
              {aPendingNorms.length > 0 ? (
                aPendingNorms.map((oNrmData) => (
                  <li key={oNrmData.id_norm} className="pending-norms-item">
                    <div className="norm-initials">{oNrmData.cMsg}</div>
                    {Object.keys(oNrmData).length > 2 && (
                      <>
                        <div className="norm-cmsg">{oNrmData.norm_name}</div>
                        <br />
                        <div className="details-container">
                          <div className="details-item">
                            <span className="details-label">Nome de Empresa: </span>
                            <strong>{oNrmData.cust_cfantasyname}</strong>
                          </div>
                          <div className="details-item">
                            <span className="details-label">Cidade/Uf: </span>
                            <strong>{oNrmData.user_ccity.length === 1 ? '' : oNrmData.user_ccity}</strong>
                          </div>
                          <div className="details-item">
                            <span className="details-label">Telefone: </span>
                            {oNrmData.user_ccellphone && (
                              <strong>
                                <a className="green-link" href={`https://wa.me/55${oNrmData.user_ccellphone.replace(/\D/g, '')}`} target="_blank" rel="noopener noreferrer">
                                  {oNrmData.user_ccellphone}
                                </a>
                              </strong>
                            )}
                          </div>
                          <div className="details-item">
                            <span className="details-label">Usuário solicitante: </span>
                            <strong>{oNrmData.user_cname}</strong>
                          </div>
                          <div className="details-item">
                            <span className="details-label">E-mail do solicitante: </span>
                            {oNrmData.user_cemail && (
                              <strong>
                                <a className="blue-link" href={`mailto:${oNrmData.user_cemail}`}>
                                  {oNrmData.user_cemail}
                                </a>
                              </strong>
                            )}
                          </div>
                        </div>
                        <br />
                        <div className="buttons-container">
                          <button
                            className="btn btn-pending-norm"
                            onClick={() =>
                              handleAuthorization(
                                oNrmData.id_norm,
                                oNrmData.cust_nid,
                                oNrmData.user_nid,
                                false
                              )
                            }
                          >
                            Rejeitar
                          </button>
                          <button
                            className="btn btn-pending-norm"
                            onClick={() =>
                              handleAuthorization(
                                oNrmData.id_norm,
                                oNrmData.cust_nid,
                                oNrmData.user_nid,
                                true
                              )
                            }
                          >
                            Autorizar
                          </button>
                        </div>
                      </>
                    )}
                  </li>
                ))
              ) : (
                <li>{intl.get('PendingNorms.no_pending_norms')}</li>
              )}
            </ul>
          )}
        </div>
      </Page>
    </div>
  );

};

export default PendingNormsList;
