import React, { Component } from 'react';
import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/pt-br';
import { Table } from 'reactstrap';
import imgfSelectDown from '../assets/img/select-down.svg';
import imgfSelectUp from '../assets/img/select-up.svg';
import intl from 'react-intl-universal';
import EncodedLocalStorage from '../config/EncodedLocalStorage';

class OrderingTable extends Component {
  constructor(oProps) {
    super(oProps);
    const oLocalStorage = new EncodedLocalStorage();
    moment.locale(oLocalStorage.get('cLanguage'));

    this.state = {
      cOrderType: 'asc',
      cOrderBy: '',
      nSelectThIndex: -1
    }
  }

  handleClick = (evt, nIndex, cNewOrderBy, fnHandleClick) => {
    let cNewOrderType = 'asc';
    if (this.state.cOrderType === 'asc') {
      cNewOrderType = 'desc';
    }
    this.setState({
      cOrderBy: cNewOrderBy,
      cOrderType: cNewOrderType,
      nSelectThIndex: nIndex
    }, () => {
      fnHandleClick(evt, cNewOrderBy, cNewOrderType);
    });
  }

  render() {
    const { cTableClassName, aColumn, children } = this.props;
    let cClassName = cTableClassName ? `ordering-table ${cTableClassName}` : 'ordering-table';

    return (
      <Table responsive className={cClassName}>
        <thead>
          <tr>
            {aColumn.map((oColumn, nIndex) => (
              <th
                key={nIndex}
                tabIndex="0"
                onClick={(evt) => this.handleClick(evt, nIndex, oColumn.cOrderBy, oColumn.fnHandleClick)}
                onKeyPress={(evt) => this.handleClick(evt, nIndex, oColumn.cOrderBy, oColumn.fnHandleClick)}
                className={nIndex === this.state.nSelectThIndex ? 'v2-filter-orderDirection' : ''}
              >
                {oColumn.cName}
                <img
                  style={{ display: nIndex === this.state.nSelectThIndex ? 'inline' : 'none' }}
                  src={this.state.cOrderType === 'asc' ? imgfSelectUp : imgfSelectDown}
                  alt={intl.get('direction')}
                />
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {children}
        </tbody>
      </Table>
    );
  }
};

OrderingTable.defaultProps = {
  cTableClassName: '',
  aColumn: []
};

export default OrderingTable;
