import React, { Component } from 'react';
import { Button, Form, Input } from 'reactstrap';
import intl from 'react-intl-universal';
import Select from 'react-select';
import Page from '../default-v2/Page';
import { createCopy, getCopy } from '../../actions/ActionDocumentCopy';
import authorizedFolders from '../../actions/ActionAuthorizedFolders';
import Breadcrumb from '../../components/Breadcrumbs';
import ButtonBack from '../../components/ButtonBack';
import TreeView from '../../components/TreeView';
import CardHeader from '../default-v2/CardHeader';
import DateLimitation from '../../components/DateLimitation';
import checkTime from '../../components/CheckDate';
import { checkModule } from '../../config/Permissions';
import documentImg from '../../assets/img/documents.svg';

class DocumentCopy extends Component {
  constructor(oProps) {
    super(oProps);

    this.state = {
      bLoading: true,
      nVersionId: oProps.match.params.nVersionId,
      oDocument: [],
      oVersion: [],
      oDocCopy: {},
      aSelectedUsers: [],
      aTreeViewFolders: [],
      aTeams: [],
      aSelectedDistribution: []
    };
  }

  componentDidMount = () => {
    const { setAlert, history } = this.props;
    const { nVersionId } = this.state;
    checkModule(history, 'documents');

    Promise.all([
      authorizedFolders().then(aFolders => this.setState({ aTreeViewFolders: aFolders })),
      getCopy(nVersionId).then(oData => {
        const oDocCopy = {};
        const aSelectedUsers = [];
        let aNewSelectedDistribution = [];

        Object.assign(oDocCopy, oData.oDocument);

        Object.keys(oData.aUsers).forEach(cKey => {
          aSelectedUsers.push({
            label: oData.aUsers[cKey].user_cname,
            value: Number(oData.aUsers[cKey].user_nid)
          });
        });

        Object.keys(oData.aSelectedDistribution).forEach(cKey => {
          aNewSelectedDistribution.push({
            label: oData.aSelectedDistribution[cKey].team_cname,
            value: Number(oData.aSelectedDistribution[cKey].team_nid)
          });
        });

        const aNewTeams = oData.aTeams.map(oTeam => ({
          label: oTeam.team_cname,
          value: oTeam.team_nid
        }));

        this.setState({
          ...oData,
          oDocCopy,
          aSelectedUsers,
          aTeams: aNewTeams,
          aSelectedDistribution: aNewSelectedDistribution
        });
      })
    ])
      .then(() => this.setState({ bLoading: false }))
      .catch(oError => {
        this.setState({ bLoading: false });
        setAlert('error', oError.aMsgErrors);
      });
  };

  inputChangeHandler = evtChange => {
    const { oDocCopy } = this.state;

    if (evtChange.target.type === 'checkbox') {
      oDocCopy[evtChange.target.name] = evtChange.target.checked;
      this.setState({
        oDocCopy
      });
    } else if (evtChange.target.value !== null) {
      oDocCopy[evtChange.target.name] = evtChange.target.value;
      this.setState({
        oDocCopy
      });
    }
  };

  inputChangeSelectHandler = () => {
    const { oDocCopy } = this.state;
    this.setState({
      oDocCopy
    });
  };

  handleChange = evtChange => {
    this.setState({ [evtChange.target.name]: evtChange.target.value });
  };

  handleChangeDoc = evtChange => {
    const { oDocCopy } = this.state;
    oDocCopy[evtChange.target.name] = evtChange.target.value;
    this.setState({
      oDocCopy
    });
  };

  handleChangeFolders = elSelectedOption => {
    const { oDocCopy } = this.state;

    if (elSelectedOption !== null) oDocCopy['fldr_nid'] = elSelectedOption.value;
    else oDocCopy['fldr_nid'] = null;

    this.setState({
      oDocCopy
    });
  };

  handleChangeUser = (elSelectedOption, cType) => {
    this.setState({ [cType]: elSelectedOption.value });
  };

  handleVersionChange = evtChange => {
    const { oVersion } = this.state;
    oVersion[evtChange.target.name] = evtChange.target.value;
    this.setState({
      oVersion
    });
  };

  handleChangeCustomMult = (elSelectedOption, cType) => {
    this.setState({
      [cType]: elSelectedOption.map(oOpt => oOpt.value)
    });
  };

  renderUsers = aUsers => {
    const rcmpUsers = aUsers.map(oUser => (
      <option key={oUser.user_nid} value={oUser.user_nid}>
        {oUser.user_cname}
      </option>
    ));
    return <>{rcmpUsers}</>;
  };

  handleSubmit = evtSubmit => {
    evtSubmit.preventDefault();

    const { oDocCopy, oVersion, nWriterId, nReviewerId, aSelectedDistribution } = this.state;
    const { history, setAlert } = this.props;

    if (!checkTime(oDocCopy.doc_etermunitessay, oDocCopy.doc_ntermessay, '2100-12-31')) {
      setAlert('error', intl.getHTML('warning_date_number', { cField: intl.get('Document.term_essay') }));
      return;
    }
    if (!checkTime(oDocCopy.doc_etermunitreview, oDocCopy.doc_ntermreview, '2100-12-31')) {
      setAlert('error', intl.getHTML('warning_date_number', { cField: intl.get('Document.prazo_revisao') }));
      return;
    }
    if (!checkTime(oDocCopy.doc_etermunitliberation, oDocCopy.doc_ntermliberation, '2100-12-31')) {
      setAlert('error', intl.getHTML('warning_date_number', { cField: intl.get('Document.prazo_liberacao') }));
      return;
    }

    const aNewSelectedDistribution = aSelectedDistribution.map(oDistribution =>
      oDistribution.value ? oDistribution.value : oDistribution
    );

    createCopy(oVersion, oDocCopy, nWriterId, nReviewerId, aNewSelectedDistribution)
      .then(oResponse => {
        history.push({
          pathname: '/',
          state: {
            aAlertMessages: {
              cType: 'success',
              cMsg: oResponse.data.success
            }
          }
        });
      })
      .catch(oError => {
        let cMessage = '';

        if (oError.response.data.cMessage != null) cMessage = oError.response.data.cMessage;
        else cMessage = oError.aMsgErrors;

        setAlert('error', cMessage);
      });
  };

  generateCardHeader = () => {
    const { history } = this.props;

    const rcmpCardHeader = <CardHeader history={history} />;
    return rcmpCardHeader;
  };

  render() {
    const {
      oDocument,
      oVersion,
      nWriterId,
      nReviewerId,
      aBreadcrumbFolders,
      bLoading,
      oDocCopy,
      aTreeViewFolders,
      aSelectedUsers,
      aTeams,
      aSelectedDistribution
    } = this.state;
    const { getAlert } = this.props;
    const bDocTitleLoaded = oDocument.doc_ctitle !== undefined && oDocument.doc_ctitle.length > 35;

    const rcmpBreadcrumb = (
      <div className="breadcrumbs-wrapper" style={{ fontSize: '14px' }}>
        <div className="inner-bread">
          <Breadcrumb bLastLink key={oDocument.doc_nid} aFolders={aBreadcrumbFolders} />
        </div>

        <em title={bDocTitleLoaded ? oDocument.doc_ctitle : ''}>
          {bDocTitleLoaded ? oDocument.doc_ctitle.trunc(35) : oDocument.doc_ctitle}
        </em>
      </div>
    );

    const cPageTitle = intl.get('DocumentCopy.title');

    const rcmpCardHeader = this.generateCardHeader();

    return (
      <Page
        loading={bLoading ? 1 : 0}
        rcmpBreadcrumb={rcmpBreadcrumb}
        cTitle={cPageTitle}
        cImage={documentImg}
        rcmpCardHeader={rcmpCardHeader}
        cCurrentSideMenuOption={intl.get('Nav.document')}
      >
        {getAlert()}
        <Form onSubmit={this.handleSubmit} className="form">
          <label className="split1 colspan">
            {intl.get('Document.campo_titulo')} <span className="required">*</span>
            <Input
              required
              name="doc_ctitle"
              maxLength={255}
              onChange={this.handleChangeDoc}
              value={oDocCopy.doc_ctitle || ''}
            />
          </label>

          <label className="split1 colspan">
            {intl.get('Document.campo_justificativa')} <span className="required">*</span>
            <Input
              type="textarea"
              name="ver_cjustification"
              maxLength={255}
              onChange={this.handleVersionChange}
              style={{ height: '80px' }}
              required
              value={oVersion.ver_cjustification || ''}
            />
          </label>

          <label className="tree-view-label" htmlFor="tree-view">
            {intl.get('Document.pasta')}
            <TreeView
              aFolders={aTreeViewFolders}
              nFolderId={oDocument.fldr_nid}
              handleChange={this.handleChangeDoc}
              cHeight="250px"
              id="tree-view"
            />
          </label>

          <label className="split2">
            {intl.get('Document.writer')} <span className="required">*</span>
            <Select
              className="selectCustom"
              classNamePrefix="react-select"
              options={aSelectedUsers}
              placeholder={intl.get('Document.writer_select')}
              value={
                nWriterId
                  ? aSelectedUsers.filter(oUserItem => {
                      return nWriterId === oUserItem.value;
                    })
                  : ''
              }
              onChange={elSelectedOption => this.handleChangeUser(elSelectedOption, 'nWriterId')}
            />
          </label>

          <label className="split2">
            {intl.get('Document.revisor')} <span className="required">*</span>
            <Select
              className="selectCustom"
              classNamePrefix="react-select"
              options={aSelectedUsers}
              placeholder={intl.get('Document.selecione_revisor')}
              value={
                nReviewerId
                  ? aSelectedUsers.filter(oUserItem => {
                      return nReviewerId === oUserItem.value;
                    })
                  : ''
              }
              onChange={elSelectedOption => this.handleChangeUser(elSelectedOption, 'nReviewerId')}
            />
          </label>

          <div className="row split3 half-box">
            <label>
              {intl.get('Document.term_essay')} <span className="required">*</span>
            </label>
            <DateLimitation
              cId="input-term-essay"
              cName="doc_ntermessay"
              bIsRequired
              nMin={0}
              fnHandleChange={this.inputChangeHandler}
              cClassName="half2"
              cValue={oDocCopy.doc_ntermessay}
              cDiffTime={oDocCopy.doc_etermunitessay}
            />

            <Input
              onChange={this.handleChangeDoc}
              type="select"
              value={oDocCopy.doc_etermunitessay || ''}
              required
              id="select-essay"
              name="doc_etermunitessay"
              className="half2"
            >
              <option value="D"> {intl.get('Document.dias')}</option>
              <option value="M"> {intl.get('Document.meses')}</option>
            </Input>
          </div>

          <div className="row split3 half-box">
            <label>
              {intl.get('Document.prazo_revisao')} <span className="required">*</span>
            </label>
            <DateLimitation
              cId="input-term-review"
              cName="doc_ntermreview"
              bIsRequired
              nMin={0}
              fnHandleChange={this.inputChangeHandler}
              cClassName="half2"
              cValue={oDocCopy.doc_ntermreview}
              cDiffTime={oDocCopy.doc_etermunitreview}
            />

            <Input
              onChange={this.handleChangeDoc}
              type="select"
              value={oDocCopy.doc_etermunitreview || ''}
              required
              id="select-review"
              name="doc_etermunitreview"
              className="half2"
            >
              <option value="D"> {intl.get('Document.dias')}</option>
              <option value="M"> {intl.get('Document.meses')}</option>
            </Input>
          </div>

          <div className="row split3 half-box">
            <label>
              {intl.get('Document.prazo_liberacao')} <span className="required">*</span>
            </label>
            <DateLimitation
              cId="input-term-liberation"
              cName="doc_ntermliberation"
              bIsRequired
              nMin={0}
              fnHandleChange={this.inputChangeHandler}
              cClassName="half2"
              cValue={oDocCopy.doc_ntermliberation}
              cDiffTime={oDocCopy.doc_etermunitliberation}
            />

            <Input
              onChange={this.handleChangeDoc}
              type="select"
              value={oDocCopy.doc_etermunitliberation || ''}
              required
              id="select-liberation"
              name="doc_etermunitliberation"
              className="half2"
            >
              <option value="D"> {intl.get('Document.dias')}</option>
              <option value="M"> {intl.get('Document.meses')}</option>
            </Input>
          </div>

          <br style={{ clear: 'both' }} />

          <label className="split3">
            {intl.get('Document.versionamento')}
            <Input
              type="select"
              id="doc_eversiontype"
              name="doc_eversiontype"
              onChange={evt => this.inputChangeHandler.call(this, evt)}
              value={oDocCopy.doc_eversiontype || ''}
            >
              <option value="A">{intl.get('Document.automatico')}</option>
              <option value="M">{intl.get('Document.manual')}</option>
            </Input>
          </label>

          {oDocCopy.doc_eversiontype === 'M' && (
            <label className="split6" id="version-manual">
              {intl.get('Document.versao')}
              <Input
                type="text"
                id="ver_cnumber"
                name="ver_cnumber"
                maxLength="10"
                onChange={this.handleVersionChange}
                value={oVersion.ver_cnumber || ''}
              />
            </label>
          )}

          <label className="split1">
            <span>{intl.get('DocumentCopy.distribution')}</span>
            <Select
              className="selectCustom"
              classNamePrefix="react-select"
              noOptionsMessage={() => intl.get('DocumentCopy.team_not_found')}
              isMulti
              defaultValue={aSelectedDistribution}
              options={aTeams}
              onChange={elSelectedOption => this.handleChangeCustomMult(elSelectedOption, 'aSelectedDistribution')}
              placeholder={intl.get('DocumentCopy.select_teams')}
              maxMenuHeight={100}
            />
          </label>

          <div className="button-stack">
            <ButtonBack />
            <Button type="submit">{intl.get('criar')}</Button>
          </div>
        </Form>
      </Page>
    );
  }
}

export default DocumentCopy;
