import Axios from '../config/Axios';

export function deleteRequest(nRequestId) {
  return Axios.delete(`/request/${nRequestId}`);
}

export function getRequest(nRequestId) {
  return Axios.get(`/request/${nRequestId}`);
}

export function validateRequest(nRequestId, $nStatId) {
  return Axios.put(`/request/update-status/${nRequestId}/${$nStatId}`);
}
