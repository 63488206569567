import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import moment from 'moment';
import { Button } from 'reactstrap';
import Select from 'react-select';
import Page from '../default-v2/Page';
import Breadcrumb from '../../components/Breadcrumbs';
import userImg from '../../assets/img/users.svg';
import CardHeader from '../default-v2/CardHeader';
import Axios from '../../config/Axios';
import imgfSelectDown from '../default-v2/img/select-down.svg';
import imgfSelectUp from '../default-v2/img/select-up.svg';
import userConfigImg from '../../assets/img/user-config-blue.svg';
import successImg from '../../assets/img/success.svg';
import 'moment/locale/pt';
import { can, canAtLeast } from '../../config/Permissions';
import EncodedLocalStorage from '../../config/EncodedLocalStorage';

const MigrateResponsibilityOperational = () => {
  const [bIsLoading, setBIsLoading] = useState(true);
  const [oNormsRequisites, setONormsRequisites] = useState([]);
  const [oFilterOrder, setOFilterOrder] = useState({ cOrderBy: 'cName', cType: 'asc' });
  const [bIsShowingModal, setBIsShowingModal] = useState(false);
  const [aSelectedItems, setASelectedItems] = useState([]);
  const [aFilteredOptions, setAFilteredOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');
  const [cPassword, setCPassword] = useState('');
  const [cUserError, setCUserError] = useState('');
  const [cPasswordError, setCPasswordError] = useState('');
  const [bIsShowingSuccessModal, setBIsShowingSuccessModal] = useState(false);

  const aPages = [
    { cName: 'user', cLink: '/user' },
    { cName: 'Nav.quality_panel', cLink: '/quality-panel' },
    { cName: 'User.migrate_responsibilities', cLink: undefined }
  ];
  const rcmpBreadcrumb = <Breadcrumb aPages={aPages} />;

  const rcmpCardHeader = <CardHeader bIsItToInsertGoBackBtn windowHistory />;

  const cCurrentUrl = window.location.href;
  const nUserId = cCurrentUrl.substring(cCurrentUrl.lastIndexOf('/') + 1);

  useEffect(() => {
    if (!can('admin')) {
      window.location.href = '/';
    }
    Axios.get(`/norm/get-responsibilities/${nUserId}`).then(oResponse => {
      setONormsRequisites(oResponse.data);
      setBIsLoading(false);
    });
  }, []);

  useEffect(() => {
    Axios.get(`/user/all`).then(oResponse => {
      const aUserOptions = oResponse.data.aUsers
        .filter(item => item.user_nid !== Number(nUserId))
        .map(oUser => ({ value: oUser.user_nid, label: oUser.user_cname }));

      setAFilteredOptions(aUserOptions);
    });
  }, []);

  const cOrderType = oFilterOrder.cType === 'asc' ? 'desc' : 'asc';

  let cIcon = imgfSelectDown;
  if (cOrderType === 'asc') {
    cIcon = imgfSelectUp;
  }

  function setFilter(cOrder, cType) {
    if (cOrder === 'cName') {
      const sortedItems = oNormsRequisites.sort((a, b) => {
        if (!a.req_cname || !b.req_cname) {
          return 0;
        }
        if (cType === 'asc') {
          return a.req_cname.localeCompare(b.req_cname);
        }
        if (cType === 'desc') {
          return b.req_cname.localeCompare(a.req_cname);
        }
        return 0;
      });

      setONormsRequisites(sortedItems);
      setOFilterOrder({ cOrderBy: 'cName', cType });
    } else if (cOrder === 'cDueDate') {
      const sortedItems = oNormsRequisites.sort((a, b) => {
        const aDueDate = a?.conclusionDate ? moment.parseZone(a.conclusionDate).valueOf() : Number.MIN_SAFE_INTEGER;
        const bDueDate = b?.conclusionDate ? moment.parseZone(b.conclusionDate).valueOf() : Number.MIN_SAFE_INTEGER;

        if (cType === 'asc') {
          return aDueDate - bDueDate;
        }
        if (cType === 'desc') {
          return bDueDate - aDueDate;
        }
        return 0;
      });
      setONormsRequisites(sortedItems);
      setOFilterOrder({ cOrderBy: 'cDueDate', cType });
    }

    localStorage.setItem('cConfig', JSON.stringify(oFilterOrder));
  }

  function showModal() {
    setBIsShowingModal(true);
  }

  function handleCloseModal() {
    setBIsShowingModal(false);
    setBIsShowingSuccessModal(false);
  }

  function handleCloseSuccessModal() {
    setBIsShowingSuccessModal(false);
    window.location.reload();
  }

  function handleMigration() {
    setCUserError('');
    setCPasswordError('');
    if (!selectedOption) {
      setCUserError(intl.get('FolderIndex.no_user_selected'));
      return;
    }
    if (!cPassword) {
      setCPasswordError(intl.get('FolderIndex.incorrect_password'));
      return;
    }
    if (aSelectedItems.length < 1) {
      setCUserError(intl.get('FolderIndex.no_document_selected'));
      return;
    }

    const aItems = aSelectedItems.map(index => oNormsRequisites[index]);

    Axios.post(`/norm/migrate-responsibilities/${nUserId}`, {
      aItems,
      nNewUser: selectedOption.value,
      cPassword
    })
      .then(oResponse => {
        setBIsShowingModal(false);
        setBIsShowingSuccessModal(true);
      })
      .catch(error => {
        setCPasswordError(error.response.data.cMessage);
      });
  }

  const handleCheckboxClick = event => {
    const nId = event.target.value;
    if (event.target.checked) {
      setASelectedItems([...aSelectedItems, nId]);
    } else {
      setASelectedItems(aSelectedItems.filter(id => id !== nId));
    }
  };

  const handleCheckAll = event => {
    const checkboxes = document.getElementsByName('document-checkbox');
    const newCheckedIds = [];

    checkboxes.forEach(checkbox => {
      checkbox.checked = event.target.checked;
      if (checkbox.checked) {
        newCheckedIds.push(checkbox.value);
      }
    });

    setASelectedItems(newCheckedIds);
  };

  const handleChange = value => {
    setSelectedOption(value);
  };

  const handlePassword = event => {
    setCPassword(event.target.value);
  };
  const oLocalStorage = new EncodedLocalStorage();

  const handleShowLastUpdated = setONormsRequisite => {
    if (setONormsRequisite.req_dedited) {
      return moment
        .parseZone(new Date(setONormsRequisite?.req_dedited))
        .tz(oLocalStorage.get('cTimezone'))
        .format('LL');
    }
    if (setONormsRequisite.norm_dupdated) {
      return moment
        .parseZone(new Date(setONormsRequisite?.norm_dupdated))
        .tz(oLocalStorage.get('cTimezone'))
        .format('LL');
    }
    return intl.get('FolderIndex.no_due');
  };

  return (
    <div>
      <Page
        loading={bIsLoading ? 1 : 0}
        rcmpBreadcrumb={rcmpBreadcrumb}
        cTitle={intl.get('User.migrate_responsibilities')}
        cImage={userImg}
        rcmpCardHeader={rcmpCardHeader}
        className="v2-document"
        cCurrentSideMenuOption={intl.get('Nav.document')}
      >
        <div className="filterList noselect">
          <span role="button" tabIndex="0" className="alt-checkbox-v2">
            <input type="checkbox" onClick={handleCheckAll} />
          </span>{' '}
          <span
            role="button"
            tabIndex="0"
            onClick={() => setFilter('cName', cOrderType)}
            onKeyPress={() => setFilter('cName', cOrderType)}
            className={`filterByName-v2 ${oFilterOrder.cOrderBy === 'cName' ? 'active' : ''}`}
          >
            {intl.get('Dashboard.requirements')}
            {oFilterOrder.cOrderBy === 'cName' && (
              <img className="folderindex-list-icon" src={cIcon} alt={cOrderType} />
            )}
          </span>
          <span
            role="button"
            tabIndex="0"
            onClick={() => setFilter('cType', cOrderType)}
            onKeyPress={() => setFilter('cType', cOrderType)}
            className={`filterByPublish ${oFilterOrder.cOrderBy === 'cType' ? 'active' : ''}`}
          >
            {intl.get('status')}
            {oFilterOrder.cOrderBy === 'cType' && (
              <img className="folderindex-list-icon" src={cIcon} alt={cOrderType} />
            )}
          </span>
          <span
            role="button"
            tabIndex="0"
            onClick={() => setFilter('cType', cOrderType)}
            onKeyPress={() => setFilter('cType', cOrderType)}
            className={`filterByPublish ${oFilterOrder.cOrderBy === 'cType' ? 'active' : ''}`}
          >
            {intl.get('LinkDocument.tipo')}
            {oFilterOrder.cOrderBy === 'cType' && (
              <img className="folderindex-list-icon" src={cIcon} alt={cOrderType} />
            )}
          </span>
          <span
            role="button"
            tabIndex="0"
            onClick={() => setFilter('cType', cOrderType)}
            onKeyPress={() => setFilter('cType', cOrderType)}
            className={`filterByPublish ${oFilterOrder.cOrderBy === 'cType' ? 'active' : ''}`}
            style={{ width: '20%' }}
          >
            {intl.get('DocumentPrint.location')}
            {oFilterOrder.cOrderBy === 'cType' && (
              <img className="folderindex-list-icon" src={cIcon} alt={cOrderType} />
            )}
          </span>
          <span
            role="button"
            tabIndex="0"
            onClick={() => setFilter('cLocal', cOrderType)}
            onKeyPress={() => setFilter('cLocal', cOrderType)}
            className={`filterByDue ${oFilterOrder.cOrderBy === 'cLocal' ? 'active' : ''}`}
          >
            {intl.get('Norm.last_update')}
            {oFilterOrder.cOrderBy === 'cLocal' && (
              <img className="folderindex-list-icon" src={cIcon} alt={cOrderType} />
            )}{' '}
          </span>
        </div>
        <div>
          <ul style={{ display: 'block' }} className="document-list list-shadow list-view">
            {!bIsLoading &&
              oNormsRequisites.map((setONormsRequisite, i) => (
                <li role="presentation">
                  <>
                    <h2 className="alt-checkbox-v2 ">
                      <input type="checkbox" name="document-checkbox" value={i} onClick={handleCheckboxClick} />
                    </h2>
                    <h2
                      className="filterByName-v2"
                      title={setONormsRequisite.req_cname || setONormsRequisite.norm_cinitials}
                    >
                      {setONormsRequisite.req_cname || setONormsRequisite.norm_cinitials}
                    </h2>

                    <p className="filterByPublish">
                      {setONormsRequisite?.status_requisite?.stat_cname ||
                        (setONormsRequisite.norm_bisactive === 1
                          ? intl.get('Status.ativo')
                          : intl.get('Status.inativo'))}
                    </p>

                    <p className="filterByPublish">
                      {setONormsRequisite.type === 'norm'
                        ? intl.get('RequisiteIndex.norma')
                        : intl.get('Dashboard.requisite')}
                    </p>

                    <p className="filterByPublish" style={{ width: '20%' }}>
                      {setONormsRequisite.norm?.norm_cinitials || '-'}
                    </p>

                    <p className="filterByPublish">{handleShowLastUpdated(setONormsRequisite)}</p>
                  </>
                </li>
              ))}
          </ul>
          {bIsShowingModal && (
            <div className="docusign-modal-overlay">
              <div className="docusign-modal">
                <div className="docusign-header-modal">
                  <h3>{intl.get('User.migrate_responsibilities')}</h3>
                  <span className="docusign-close" onClick={handleCloseModal}>
                    &times;
                  </span>
                </div>
                <div className="docusign-modal-body">
                  <div className="icon-container">
                    <img className="block-title-icon" src={userConfigImg} />
                  </div>

                  <div className="docusign-modal-text">
                    <div className="margin-bottom-30">
                      <form autoComplete="off">
                        <label style={{ fontWeight: '700' }}>{intl.get('FolderIndex.new_responsible')}</label>
                        {cUserError && <p style={{ color: '#FF3541', fontSize: '12px' }}>{cUserError}</p>}
                        <label className="split1 colspan ">
                          <Select
                            className="selectCustom select"
                            classNamePrefix="react-select"
                            noOptionsMessage={() => intl.get('sem_opcoes')}
                            placeholder={`${intl.get('selecione')}...`}
                            isClearable
                            isSearchable
                            options={aFilteredOptions}
                            maxMenuHeight={130}
                            defaultValue={selectedOption}
                            onChange={handleChange}
                          />
                        </label>
                      </form>
                    </div>
                    <div>
                      <label style={{ fontWeight: '700' }}>{intl.get('FolderIndex.digit_password')}</label>
                      <div className="input-select ">
                        <input
                          type="password"
                          className={cPasswordError ? 'red-border' : ''}
                          value={cPassword}
                          onChange={handlePassword}
                        />
                      </div>
                      {cPasswordError && <p style={{ color: '#FF3541', fontSize: '12px' }}>{cPasswordError}</p>}
                    </div>
                  </div>
                </div>
                <div className="button-container">
                  <button className="btn alternate" onClick={handleCloseModal}>
                    {intl.get('cancel')}
                  </button>
                  <button className="btn" onClick={handleMigration}>
                    {intl.get('FolderIndex.migrate')}{' '}
                  </button>
                </div>
              </div>
            </div>
          )}
          {bIsShowingSuccessModal && (
            <div className="docusign-modal-overlay">
              <div className="docusign-modal">
                <div className="docusign-header-modal">
                  <h3>{intl.get('User.migrate_responsibilities')}</h3>
                  <span className="docusign-close" onClick={handleCloseSuccessModal}>
                    &times;
                  </span>
                </div>
                <div className="docusign-modal-body">
                  <div className="icon-container">
                    <img className="block-title-v2" src={successImg} />
                  </div>
                  <div className="docusign-modal-text align-center" style={{ fontWeight: '700' }}>
                    {intl.get('FolderIndex.success_migration')}
                  </div>
                </div>
                <div className="button-container align-center">
                  <button className="btn" onClick={handleCloseSuccessModal}>
                    {intl.get('close')}
                  </button>
                </div>
              </div>
            </div>
          )}
          <div className="button-stack">
            <Button type="button" className="btn-cancel" onClick={() => window.history.back()}>
              {intl.get('cancelar')}
            </Button>

            <Button
              type="submit"
              className="btn break-line-btn"
              disabled={aSelectedItems.length < 1}
              onClick={() => showModal()}
            >
              {intl.get('FolderIndex.migrate')} <br /> {intl.get('FolderIndex.selecteds')}
            </Button>
          </div>
        </div>
      </Page>
    </div>
  );
};

export default MigrateResponsibilityOperational;
