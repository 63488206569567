import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert/lib/dist/SweetAlert';
import { Button } from 'reactstrap';
import intl from 'react-intl-universal';
import imgfNoPic from '../../assets/img/nopic.svg';
import Axios from '../../config/Axios';
import url from '../../config/Url';
import PageClean from '../default-v2/PageClean';
import LimitWidget from '../../components/LimitWidget';
import RequestCreate from '../request/RequestCreate';
import { deleteRequest } from '../../actions/ActionRequest';
import ScrollBar from '../../components/ScrollBar';
import { checkModule } from '../../config/Permissions';

export function getRequestTitle(cType) {
  if (cType === 'delete') {
    return intl.getHTML('RequestEdit.delete');
  }
  if (cType === 'update') {
    return intl.getHTML('RequestEdit.update');
  }
  if (cType === 'new') {
    return intl.getHTML('RequestEdit.novo');
  }
  return '';
}

export class RequestReject extends Component {
  constructor(oProps) {
    super(oProps);
    this.form = React.createRef();

    this.state = {
      bLoading: true,
      bIsSending: false,
      oLimits: {
        oDocument: {
          cRemaining: '',
          cAllowed: ''
        }
      },
      nRequestId: oProps.match ? oProps.match.params.nRequestId : oProps.nRequestId
    };
  }

  componentDidMount() {
    const { setAlert, history } = this.props;
    const { nRequestId } = this.state;
    checkModule(history, 'documents');

    Promise.all([
      Axios.get(`/request/form/edit/${nRequestId}`).then(oResponse => {
        if (oResponse.data === 'request.show') {
          history.push(`/request/${nRequestId}`);
        } else {
          const { oRequest, aMsg } = oResponse.data;
          this.setState({
            oRequest,
            aMsg
          });
        }
      })
    ])
      .then(() => this.setState({ bLoading: false }))
      .catch(oError => {
        this.setState({ bLoading: false });
        setAlert('error', oError.aMsgErrors);
      });
  }

  handleChange = evt => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  onNewRequest = () => {
    const { nRequestId, onSuccess, closeModal } = this.props;
    const { oRequest } = this.state;

    this.setState({
      rcmpAlert: (
        <SweetAlert customClass="modal-edit lg scrollBar" title="" showConfirm={false}>
          <div className="head-modal">
            {intl.get('new_request')}
            <span
              aria-labelledby="head-modal"
              onKeyPress={() => onSuccess('')}
              onClick={() => onSuccess('')}
              className="close"
              role="button"
              tabIndex={0}
            />
          </div>
          <ScrollBar>
            <RequestCreate
              closeModal={closeModal}
              onSuccess={onSuccess}
              cType={oRequest.rqst_etype}
              nRequestId={nRequestId}
              nVersionId={oRequest.ver_nid}
              cTitle={oRequest.rqst_ctitle}
              cJustification={oRequest.rqst_cjustification}
            />
          </ScrollBar>
        </SweetAlert>
      )
    });
  };

  onRemoveRequest = evt => {
    this.setState({ bIsSending: true });
    const { nRequestId } = this.state;
    const { setAlert, onSuccess } = this.props;

    deleteRequest(nRequestId)
      .then(oResponse => {
        onSuccess(oResponse.data.success);
      })
      .catch(oError => {
        setAlert('error', oError.aMsgErrors);
        this.setState({
          bIsSending: false
        });
      });
  };

  render() {
    const { oRequest, rcmpAlert, bIsSending, bLoading, oLimits } = this.state;
    const { history, closeModal, cType } = this.props;
    const bIsBtnSet = cType && cType != undefined && cType == 'update' ? true : false;

    return (
      <PageClean loading={bLoading ? 1 : 0}>
        {rcmpAlert}

        {oRequest && <h1>{getRequestTitle(oRequest.rqst_etype)}</h1>}

        {oRequest && (
          <form className="form" ref={this.form}>
            {oRequest.document && (
              <label className="document">
                <h2> {intl.get('documento')}</h2>
                <p>{oRequest.document.doc_ctitle}</p>
              </label>
            )}

            <div className="request-info">
              <div className="request">
                <strong>{intl.get('Request.titulo')}</strong>
                <p>{oRequest.rqst_ctitle}</p>
                <br />
                <strong>{intl.get('Request.justificativa')}</strong>
                <p style={{ whiteSpace: 'pre-wrap' }}>{oRequest.rqst_cjustification}</p>
                <br />
                <strong>{intl.get('Request.justificativa_rejeicao')}</strong>
                <p style={{ whiteSpace: 'pre-wrap' }}>{oRequest.rqst_crejectjustification}</p>
              </div>
              <div className="requester">
                <strong className="header">{intl.get('Request.solicitante')}</strong>

                <div className="avatar">
                  <img
                    src={
                      oRequest.user_requester.user_cavatar
                        ? oRequest.user_requester.user_cavatar
                        : imgfNoPic
                    }
                    alt={oRequest.user_requester.user_cname}
                  />
                </div>
                <p className="name" title={oRequest.user_requester.user_cname}>
                  {oRequest.user_requester.user_cname}
                </p>
              </div>
              <div className="requester">
                <strong className="header">{intl.get('Request.requested_dotted')}</strong>

                <div className="avatar">
                  <img
                    src={
                      oRequest.user_destiny.user_cavatar
                        ? oRequest.user_destiny.user_cavatar
                        : imgfNoPic
                    }
                    alt={oRequest.user_destiny.user_cname}
                  />
                </div>
                <p className="name" title={oRequest.user_destiny.user_cname}>
                  {oRequest.user_destiny.user_cname}
                </p>
              </div>
            </div>

            {oRequest.rqst_etype === 'new' && oLimits.oDocument.nLeft === 0 && <LimitWidget oLimits={oLimits} />}

            <div className="button-stack">
              <Button outline className="btn btn-requestReject alternate back" onClick={closeModal || history.goBack}>
                {intl.get('cancelar')}
              </Button>
              {bIsBtnSet ? (
                ''
              ) : (
                <>
                  <Button
                    name="action"
                    value="remove"
                    type="submit"
                    className="btn btn-danger btn-requestReject"
                    onClick={this.onRemoveRequest}
                    disabled={bIsSending}
                  >
                    {intl.get('remover')}
                  </Button>

                  <Button
                    name="action"
                    value="new_request"
                    type="button"
                    className="btn btn-primary btn-requestReject"
                    onClick={evt => this.onNewRequest()}
                    disabled={bIsSending}
                  >
                    {intl.get('new_request')}
                  </Button>
                </>
              )}
            </div>
          </form>
        )}
      </PageClean>
    );
  }
}

export default RequestReject;
