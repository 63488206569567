import React from 'react';
import intl from 'react-intl-universal';

const Footer = (oProps) => {
  const cVersion = process.env.REACT_APP_VERSION;
  const cStatus = process.env.REACT_APP_STATUS;

  return (
    <footer id="footer">
      <p className="copy">
        Acredite.se © {new Date().getFullYear()} - {intl.get('Footer.direitos')} - {cStatus == 'show' ? <span id="version">{cVersion}</span> : ''}
      </p>
      <div className="uniware" />
    </footer>
  );
};

export default Footer;
