import React, { Component } from 'react';
import intl from 'react-intl-universal';
import AltCheckbox from '../../components/AltCheckbox';

class Permissions extends Component {
  constructor(oProps) {
    super(oProps);
  }


  handleChange = (evt, oCheckBoxItem, nIndex) => {
    const { fnOnChange } = this.props;

    if (fnOnChange) fnOnChange(evt, oCheckBoxItem);
  }

  renderRowCheckBox = (nIndex, nItemByRow) => {
    const { aCheckBoxItems, aSelectedCheckBox } = this.props;
    let rcmpCheckBox = [];

    for (let nIndexAux = nIndex; nIndexAux < aCheckBoxItems.length; nIndexAux++) {
      if (nIndexAux < nIndex + nItemByRow) {
        rcmpCheckBox.push(
          (
            <AltCheckbox
              onChange={evt => this.handleChange(evt, aCheckBoxItems[nIndexAux], nIndex)}
              checked={!!aSelectedCheckBox.find(
                oSelectedPermission => oSelectedPermission.perm_nid === aCheckBoxItems[nIndexAux].perm_nid
              )}
              label={intl.get(`PermissionGroup.${aCheckBoxItems[nIndexAux].perm_cslug}`)}
              name="nIdList[]"
              value={aCheckBoxItems[nIndexAux].perm_nid}
              key={nIndexAux}
            />
          )
        )
      } else {
        break;
      }
    }
    return rcmpCheckBox;
  }

  renderPermissions = () => {
    const { aCheckBoxItems } = this.props;

    let aRcmpCheckBox = [];
    let nControl = 0;

    while (nControl < aCheckBoxItems.length) {
      let rcmpCheckBoxAux = (
        <div
          key={`permission-section-${nControl}`}
          className="permission-section-body-row"
        >
          {
            this.renderRowCheckBox(nControl, 4).map(oRowCheckBox => {
              return oRowCheckBox;
            })
          }
        </div>
      );
      nControl = nControl + 4;
      aRcmpCheckBox.push(rcmpCheckBoxAux);
    }

    return aRcmpCheckBox;
  };

  render() {
    const { cTitle } = this.props;

    return (
      <section className="permission-section">
        <div className="permission-section-head">
          <h3>{cTitle}</h3>
        </div>

        <div className="permission-section-body">
          {this.renderPermissions().map(oPermissions => {
            return oPermissions;
          })}
        </div>
      </section>
    );
  }
}

Permissions.defaultProps = {
  cTitle: '',
  aCheckBoxItems: [],
  aSelectedCheckBox: [],
  fnOnChange: null
};

export default Permissions;
