import React, { Component } from 'react';
import intl from 'react-intl-universal';
import Select from 'react-select';
import Page from '../default/Page';
import Axios from '../../config/Axios';
import { Alert } from '../../components/Alert';
import AltCheckbox from '../../components/AltCheckbox';
import { Input } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert/lib/dist/SweetAlert';
import { checkModule } from '../../config/Permissions';
import EncodedLocalStorage from '../../config/EncodedLocalStorage';

export function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

class Norm extends Component {
  constructor(oProps) {
    super(oProps);
    const { oNorm } = this.props;
    this.alertWrapper = React.createRef();

    this.state = {
      bLoading: true,
      cName: oNorm ? oNorm.norm_cname : '',
      cInitials: oNorm ? oNorm.norm_cinitials : '',
      norm_flabelimage: oNorm ? oNorm.norm_flabelimage : '',
      cImagePreviewUrl: oNorm && oNorm.norm_flabelimage ? `data:image/png;base64,${oNorm.norm_flabelimage}` : '',
      aSelectedConsultants: oNorm
        ? oNorm.norm_consultants.map(usr => ({
          label: usr.consultant.user.user_cname,
          value: usr.consultant.user.user_nid
        }))
        : '',
      aSelectedResponsible: oNorm
        ? oNorm.norm_responsibles.map(usr => ({ label: usr.user.user_cname, value: usr.user.user_nid }))
        : '',
      aUsers: [],
      aNormModels: [],
      bIsSending: false,
      nNormModelId: '',
      bIsActive: oNorm ? oNorm.norm_bisactive : true,
      nFileInputKey: Date.now(),
      bIsDeadlineActive: oNorm ? oNorm.norm_bdeadlineactive : false,
      nDeadline: oNorm ? oNorm.norm_ndeadline : 1,
      nOldDeadline: oNorm ? oNorm.norm_ndeadline : 1,
      bShowAuthorizationAlert: false,
      oSelectedNorm: null,
      oSelectedDefaultNorm: null
    };
  }

  handleChange = evt => {
    this.setState({ [evt.target.name]: evt.target.type === 'checkbox' ? evt.target.checked : evt.target.value });
  };

  handleChangeCustom = (elSelectedOption, cType = undefined) => {
    if (cType === undefined) {
      try {
        const oSelectedOption = JSON.parse(elSelectedOption.value);
        const oLocalStorage = new EncodedLocalStorage();
        if (oSelectedOption && oSelectedOption.norm_brequiresauthorization && !oSelectedOption.na_bisauthorized
          && oSelectedOption.user_nid !== Number(oLocalStorage.get('nUserId'))
        ) {
          this.setState({
            bShowAuthorizationAlert: true,
            oSelectedNorm: oSelectedOption,
            oSelectedDefaultNorm: elSelectedOption
          });
        } else {
          this.setState({
            nNormModelId: elSelectedOption ? oSelectedOption.norm_nid : '',
            nNormBFromClient: elSelectedOption ? elSelectedOption.custom : '',
            oSelectedDefaultNorm: elSelectedOption

          });
        }
      } catch (oError) {
        this.setState({
          nNormModelId: '',
          nNormBFromClient: '',
          oSelectedDefaultNorm: null
        });
      }
    }

    if (cType !== undefined) {
      this.setState({
        [cType]: elSelectedOption.map(oOpt => oOpt.value)
      });
    }
  };

  hideSweetAlert = () => {
    this.setState({
      rcmpAlertMsg: null
    });
  };

  handleAuthorizationConfirm = async () => {
    const { oSelectedNorm } = this.state;

    try {
      this.setState({
        bShowAuthorizationAlert: false,
        nNormModelId: oSelectedNorm.norm_nid,
        nNormBFromClient: oSelectedNorm.custom
      });
      await Axios.post('/norm/getAuthorization', {
        nId_norm: oSelectedNorm.norm_nid
      }).then(() => {
        this.setState({
          oSelectedDefaultNorm: null,
          bShowAuthorizationAlert: false,
          rcmpAlertMsg: (
            <SweetAlert
              success
              title=""
              onConfirm={() => this.hideSweetAlert()}
            >
              Sua solicitação foi enviada com sucesso, assim que o processo for concluído te avisaremos por e-mail. Esta norma poderá ser utilizada somente após a autorização!
            </SweetAlert>
          ),
          nNormModelId: '',//oSelectedNorm.norm_nid,
          nNormBFromClient: oSelectedNorm.custom
        });
      }).catch((error) => {

        this.setState({
          oSelectedDefaultNorm: null,
          bShowAuthorizationAlert: false,
          nNormModelId: '',
          nNormBFromClient: oSelectedNorm.custom,
          rcmpAlertMsg: (
            <SweetAlert
              error
              title="Erro ao pedir autorizacao!"
              onConfirm={() => this.hideSweetAlert()}
            >
              {error.response && error.response.data && error.response.data.error ? error.response.data.error : 'Houve um erro na tentativa de enviar autorização. Tente novamente mais tarde.'}
            </SweetAlert>
          ),
        });
      });


    } catch (oError) {

      this.setState({
        bShowAuthorizationAlert: false,
        nNormModelId: oSelectedNorm.norm_nid,
        nNormBFromClient: oSelectedNorm.custom
      });
    }
  };


  handleAuthorizationCancel = () => {
    this.setState({
      bShowAuthorizationAlert: false,
      nNormModelId: '',
      nNormBFromClient: '',
      oSelectedDefaultNorm: null
    });
  };

  componentDidMount = () => {
    const { aNormModels, cName } = this.state;
    const { history } = this.props;
    checkModule(history, 'quality-panel');

    Axios.get('/user').then(oResponse => {
      this.setState({
        aUsers: oResponse.data.aUsers.map(user => ({ label: user.user_cname, value: user.user_nid }))
      });
    });

    if (cName === '') {
      Axios.get('norm/model').then(oResponse => {
        Object.keys(oResponse.data).forEach(cKey => {
          aNormModels.push({
            label: oResponse.data[cKey].norm_cinitials,
            custom: oResponse.data[cKey].norm_bfromclient,
            value: JSON.stringify({
              norm_nid: Number(oResponse.data[cKey].norm_nid),
              norm_bfromclient: oResponse.data[cKey].norm_bfromclient,
              norm_brequiresauthorization: oResponse.data[cKey].norm_brequiresauthorization,
              na_bisauthorized: oResponse.data[cKey].na_bisauthorized,
              user_nid: oResponse.data[cKey].user_nid,
              label: oResponse.data[cKey].norm_cinitials
            })
          });
        });

        this.setState({
          bLoading: false,
          aNormModels
        });
      });
    } else {
      this.setState({
        bLoading: false
      });
    }
  };

  validateUpdateDeadline = evtSubmit => {
    evtSubmit.preventDefault();
    const { nDeadline, nOldDeadline } = this.state;
    if (nOldDeadline > 0 && nDeadline !== nOldDeadline) {
      this.setState({
        rcmpAlertMsg: (
          <>
            <SweetAlert
              warning
              showCancel
              confirmBtnText={intl.get('confirmar')}
              cancelBtnText={intl.get('cancelar')}
              cancelBtnBsStyle="danger"
              title={intl.get('Norm.update_deadline')}
              onConfirm={() => this.updateNorm(null)}
              onCancel={() => {
                this.setState({
                  rcmpAlertMsg: ''
                });
              }}
            >
              {intl.get('Norm.update_deadline_msg')}
            </SweetAlert>
          </>
        )
      });
    } else {
      this.updateNorm(evtSubmit);
    }
  };

  updateNorm = evtSubmit => {
    if (evtSubmit !== null && evtSubmit !== undefined) evtSubmit.preventDefault();

    const {
      cImagePreviewUrl,
      cName,
      cInitials,
      aSelectedConsultants,
      aSelectedResponsible,
      bIsActive,
      bIsDeadlineActive,
      nDeadline
    } = this.state;
    const { onSuccess, oNorm } = this.props;
    let fSelectedFile = cImagePreviewUrl
      ? dataURLtoFile(
        cImagePreviewUrl,
        `${Math.random()
          .toString(36)
          .substring(7)}.png`
      )
      : undefined;

    this.setState({
      fSelectedFile,
      bIsSending: true
    });

    const fData = new FormData();
    fData.append('norm_cname', cName);

    fData.append('norm_cinitials', cInitials);
    fData.append('list_consultors', aSelectedConsultants.map(opt => (opt.value ? opt.value : opt)) || '');
    fData.append('list_responsibles', aSelectedResponsible.map(opt => (opt.value ? opt.value : opt)) || '');
    fData.append('norm_bisactive', bIsActive);
    fData.append('norm_bdeadlineactive', bIsDeadlineActive ? '1' : '0');
    fData.append('norm_ndeadline', nDeadline);

    if (!aSelectedResponsible) {
      this.setState(
        {
          bIsSending: false,
          rcmpAlertMsg: (
            <Alert type="error" isOpen onCloseAlert={() => { }}>
              {intl.get('Norm.alert_responsible')}
            </Alert>
          )
        },
        () => this.alertWrapper?.current?.scrollIntoView()
      );
      return;
    }

    if (fSelectedFile) {
      fData.append('norm_flabelimage', fSelectedFile, fSelectedFile.name);
    }

    Axios.post(`/norm-update/${oNorm.norm_nid}`, fData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(() => {
        this.setState(
          {
            bIsSending: false
          },
          () => {
            onSuccess(intl.get('Norm.norma_alterada_com_sucesso'));
          }
        );
      })
      .catch(oError => {
        if (typeof oError.response.data === 'string') {
          this.setState(
            {
              bIsSending: false,
              rcmpAlertMsg: (
                <Alert type="error" isOpen onCloseAlert={() => { }}>
                  {oError.response.data}
                </Alert>
              )
            },
            () => this.alertWrapper?.current?.scrollIntoView()
          );
        } else {
          this.setState(
            {
              bIsSending: false,
              rcmpAlertMsg: (
                <Alert type="error" isOpen onCloseAlert={() => { }}>
                  {Object.keys(oError.response.data.errors).map((cKey, i) => (
                    <p> - {oError.response.data.errors[cKey]}</p>
                  ))}
                </Alert>
              )
            },
            () => this.alertWrapper?.current?.scrollIntoView()
          );
        }
      });
  };

  saveNorm = evt => {
    evt.preventDefault();
    const {
      cName,
      cInitials,
      nNormModelId,
      aSelectedConsultants,
      aSelectedResponsible,
      fSelectedFile,
      bIsActive,
      nNormBFromClient,
      bIsDeadlineActive,
      nDeadline
    } = this.state;
    const { onSuccess } = this.props;

    this.setState({
      bIsSending: true
    });

    const fData = new FormData();
    fData.append('norm_cname', cName);
    fData.append('norm_cinitials', cInitials);
    fData.append('norm_nid_normmodel', nNormModelId);
    fData.append('norm_bfromclient', Boolean(Number(nNormBFromClient)));
    fData.append('list_consultors', aSelectedConsultants || '');
    fData.append('list_responsibles', aSelectedResponsible || '');
    fData.append('norm_bisactive', bIsActive);
    fData.append('norm_bdeadlineactive', bIsDeadlineActive ? '1' : '0');
    fData.append('norm_ndeadline', nDeadline);

    if (!aSelectedResponsible) {
      this.setState(
        {
          bIsSending: false,
          rcmpAlertMsg: (
            <Alert type="error" isOpen onCloseAlert={() => { }}>
              {intl.get('Norm.alert_responsible')}
            </Alert>
          )
        },
        () => this.alertWrapper?.current?.scrollIntoView()
      );
      return;
    }

    if (fSelectedFile) {
      fData.append('norm_flabelimage', fSelectedFile, fSelectedFile.name);
    }

    Axios.post('/norm', fData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(() => {
        this.setState(
          {
            bIsSending: false
          },
          () => {
            onSuccess(intl.get('Norm.norma_criada_com_sucesso'));
          }
        );
      })
      .catch(oError => {
        if (typeof oError.response.data === 'string') {
          this.setState(
            {
              bIsSending: false,
              rcmpAlertMsg: (
                <Alert type="error" isOpen onCloseAlert={() => { }}>
                  {oError.response.data}
                </Alert>
              )
            },
            () => this.alertWrapper?.current?.scrollIntoView()
          );
        } else {
          this.setState(
            {
              bIsSending: false,
              rcmpAlertMsg: (
                <Alert type="error" isOpen onCloseAlert={() => { }}>
                  {Object.keys(oError.response.data.errors).map((cKey, i) => (
                    <p> - {oError.response.data.errors[cKey]}</p>
                  ))}
                </Alert>
              )
            },
            () => this.alertWrapper?.current?.scrollIntoView()
          );
        }
      });
  };

  triggerInputFile = () => {
    const elLink = document.getElementById('norm_seal');
    elLink.click();
  };

  fileChangedHandler = evt => {
    evt.preventDefault();

    const frChanged = new FileReader();
    const fFile = evt.target.files[0];
    if (fFile && (fFile.size / 1024).toFixed() > 200) {
      this.setState(
        {
          rcmpAlertMsg: (
            <Alert type="error" isOpen onCloseAlert={() => { }}>
              {intl.get('Norm.alert_size_image')}
            </Alert>
          )
        },
        () => this.alertWrapper?.current?.scrollIntoView()
      );
      return;
    }

    frChanged.onloadend = () => {
      this.setState({
        fSelectedFile: fFile,
        cImagePreviewUrl: frChanged.result
      });
    };
    if (fFile) frChanged.readAsDataURL(fFile);
  };

  clearImage = e => {
    e.preventDefault();
    this.setState({
      fSelectedFile: '',
      cImagePreviewUrl: '',
      nFileInputKey: Date.now()
    });
  };

  render() {
    const {
      bLoading,
      bIsSending,
      aNormModels,
      cName,
      cInitials,
      rcmpAlertMsg,
      aUsers,
      cImagePreviewUrl,
      bIsActive,
      bIsDeadlineActive,
      nDeadline,
      aSelectedConsultants,
      aSelectedResponsible,
      nFileInputKey,
      oSelectedDefaultNorm
    } = this.state;
    const { closeModal, oNorm } = this.props;
    return (
      <Page loading={bLoading ? 1 : 0}>
        <form
          className="form"
          onSubmit={oNorm ? evtSubmit => this.validateUpdateDeadline(evtSubmit) : evtSubmit => this.saveNorm(evtSubmit)}
        >
          <div ref={this.alertWrapper} style={{ paddingTop: 5 }} className="norm-alert">
            {rcmpAlertMsg}
          </div>
          {this.state.bShowAuthorizationAlert && this.state.oSelectedNorm && (
            <SweetAlert
              warning
              showCancel
              confirmBtnText={intl.get('confirmar')}
              cancelBtnText={intl.get('cancelar')}
              confirmBtnBsStyle="primary"
              title=""
              onConfirm={this.handleAuthorizationConfirm}
              onCancel={this.handleAuthorizationCancel}
              focusCancelBtn

            >
              {intl.get('Norm.request_authorization', { cName: this.state.oSelectedNorm.label })}
            </SweetAlert>

          )}
          <label className="split1">
            {intl.get('Norm.nome_da_norma_sigla')} <span className="required">*</span>
            <input
              onChange={evt => this.handleChange.call(this, evt)}
              id="cInitials"
              name="cInitials"
              maxLength="25"
              value={cInitials || ''}
              required
              type="text"
              className="form-control"
            />
          </label>

          <label className="split1">
            {intl.get('Norm.descricao')}
            <span className="required">*</span>
            <input
              onChange={evt => this.handleChange.call(this, evt)}
              id="cName"
              name="cName"
              maxLength="100"
              value={cName || ''}
              required
              type="text"
              className="form-control"
            />
          </label>
          {oNorm === undefined && (
            <label className="split1 colspan">
              {intl.get('Norm.criar_norma_baseada_em')}
              <Select
                className="selectCustom select"
                classNamePrefix="react-select"
                noOptionsMessage={() => intl.get('sem_opcoes')}
                placeholder={intl.get('Norm.norma_base')}
                isClearable
                isSearchable
                options={aNormModels}
                value={oSelectedDefaultNorm}
                onChange={elSelectedOption => this.handleChangeCustom(elSelectedOption)}
              />
            </label>
          )}

          <label className="split1 colspan">
            {intl.get('Norm.consultores')}
            <Select
              className="selectCustom select"
              classNamePrefix="react-select"
              noOptionsMessage={() => intl.get('sem_opcoes')}
              placeholder={intl.get('Norm.pesquisar_consultores')}
              isClearable
              isSearchable
              isMulti
              options={aUsers}
              maxMenuHeight={130}
              defaultValue={aSelectedConsultants}
              onChange={elSelectedOption => this.handleChangeCustom(elSelectedOption, 'aSelectedConsultants')}
            />
          </label>

          <label className="split1 colspan">
            {intl.get('Norm.responsaveis')} <span className="required">*</span>
            <Select
              className="selectCustom select"
              classNamePrefix="react-select"
              noOptionsMessage={() => intl.get('sem_opcoes')}
              placeholder={intl.get('Norm.atribuir_resposanveis')}
              required
              isClearable
              isSearchable
              isMulti
              options={aUsers}
              maxMenuHeight={130}
              defaultValue={aSelectedResponsible}
              onChange={elSelectedOption => this.handleChangeCustom(elSelectedOption, 'aSelectedResponsible')}
            />
          </label>

          <label className="split1 colspan">
            <p> {intl.get('Norm.imagem_selo')} </p>
          </label>
          <Input
            onChange={this.fileChangedHandler}
            type="file"
            name="norm_seal"
            id="norm_seal"
            className="hidden norm_seal"
            data-preview="norm_seal img"
            accept=".jpg, .jpeg, .png"
            onClick={evt => {
              evt.target.value = '';
            }}
            key={nFileInputKey || ''}
          />

          {cImagePreviewUrl && (
            <img id="avatar-preview" src={cImagePreviewUrl} alt="avatar" onClick={() => this.triggerInputFile()} />
          )}
          {!cImagePreviewUrl && (
            <button
              type="button"
              onClick={() => this.triggerInputFile()}
              className="btn alternate back small-bt small btn-outline-secondary"
            >
              {intl.get('Norm.carregar_imagem')}
            </button>
          )}

          {cImagePreviewUrl && (
            <>
              <br />
              <button
                type="button"
                onClick={e => this.clearImage(e)}
                className="btn alternate back small-bt small btn-outline-secondary"
              >
                {intl.get('excluir', { tipo: intl.get('Customers.legenda_imagem') })}
              </button>
            </>
          )}

          {!cImagePreviewUrl && (
            <label className="split1 colspan">
              <p>
                <small className="restriction">{intl.get('Norm.imagem_restricao')}</small>
              </p>
            </label>
          )}

          <label className="split1 colspan" style={{ marginBottom: 0 }}>
            {intl.get('Norm.deadline_for_requisite')}
            <div className='alt-label'>
              <AltCheckbox
                title={intl.get("Status.ativo")}
                label={intl.get("Status.ativo")}
                name="bIsDeadlineActive"
                onChange={evtChange => this.handleChange(evtChange)}
                className="split1"
                checked={bIsDeadlineActive}
              />
            </div>
          </label>

          {bIsDeadlineActive && (
            <label className="split1 colspan">
              {intl.get('Norm.deadline_in_days')}
              <input
                onChange={evtChange => this.handleChange(evtChange)}
                id="nDeadline"
                name="nDeadline"
                type="number"
                required
                min={1}
                max="365"
                defaultValue={nDeadline}
              />
            </label>
          )}

          <label className="split1 colspan">
            {intl.get('Norm.activate_standard')}
            <div className='alt-label'>
              <AltCheckbox
                title={intl.get("Status.ativo")}
                label={intl.get("Status.ativo")}
                name="bIsActive"
                onChange={evtChange => this.handleChange(evtChange)}
                className="split1"
                checked={bIsActive}
              />
            </div>
          </label>

          <div className="button-stack">
            <button type="button" onClick={() => closeModal()} className="btn alternate back btn btn-outline-secondary">
              {intl.get('cancelar')}
            </button>
            <button type="submit" title="Salvar" className={`btn btn-secondary ${bIsSending ? 'disabled' : ''}`}>
              {intl.get('salvar')}
            </button>
          </div>
        </form>
      </Page >
    );
  }
}

export default Norm;
