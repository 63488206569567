import React, { Component } from 'react';
import intl from 'react-intl-universal';
import Axios from '../../config/Axios';
import Page from '../default-v2/Page';
import CardHeader from '../default-v2/CardHeader';
import Breadcrumb from '../../components/Breadcrumbs';
import cLoadingImage from '../../assets/img/preloader-black.gif';
import './styles/extractindex.scss';
import { can } from '../../config/Permissions';
import CreditCardDisplay from './components/CreditCardDisplay';
import imgfConfig from '../default-v2/img/icon-config.svg';
import EncodedLocalStorage from '../../config/EncodedLocalStorage';

const oLocalStorage = new EncodedLocalStorage();
export class ExtractIndex extends Component {
  constructor(oProps) {
    super(oProps);

    this.LOCALE = oLocalStorage.get('cLanguage') ?? 'pt-br';

    this.state = {
      bLoadingUser: true,
      bLoadingExtractInfo: true,
      oUser: null,
      nTotalSpace: 0,
      nTotalUsers: 0,
      nPaidValue: 0,
      nDueDay: 0,
      oInvoiceLast: {},
      oBillNext: {},
      oPaymentInfo: {},
      oCustomer: {},
      oCustomerSubscription: {},
      cSuperlogicaAreaLink: '',
      cSuperlogicaHistoryLink: '',
      aPages: [
        { cName: 'configs', cLink: undefined },
        { cName: 'ExtractIndex.extrato_titulo', cLink: undefined }
      ]
    };
  }

  componentDidMount() {
    const { setAlert, history } = this.props;

    if (!can('admin')) {
      history.push({
        pathname: '/',
        state: { aAlertMessages: { cType: 'error', cMsg: intl.get('sem_permissao') } }
      });
    }

    Axios.get('/extract')
      .then(oResponse => {
        this.setState({
          nTotalSpace: oResponse.data.nTotalSpace,
          nTotalUsers: oResponse.data.nTotalUsers,
          nPaidValue: oResponse.data.nPaidValue,
          nDueDay: oResponse.data.nDueDay,
          oInvoiceLast: oResponse.data.oInvoiceLast,
          oBillNext: oResponse.data.oBillNext,
          oPaymentInfo: oResponse.data.oPaymentInfo,
          cSuperlogicaAreaLink: oResponse.data.cSuperlogicaAreaLink,
          cSuperlogicaHistoryLink: oResponse.data.cSuperlogicaHistoryLink
        });
      })
      .catch(oError => {
        setAlert('error', oError.aMsgErrors);
      })
      .finally(() => {
        this.setState({ bLoadingExtractInfo: false });
      });

    Axios.get(`/user/${oLocalStorage.get('nUserId')}`)
      .then(oResponse => {
        this.setState({
          oUser: oResponse.data
        });
      })
      .catch(oError => {
        //TODO: set alert indicating error
      })
      .finally(() => {
        this.setState({ bLoadingUser: false });
      });
  }

  openSuperlogicaHistoryLink = () => {
    const { cSuperlogicaHistoryLink } = this.state;
    window.open(cSuperlogicaHistoryLink);
  };

  openSuperlogicaAreaLink = () => {
    const { cSuperlogicaAreaLink } = this.state;
    window.open(cSuperlogicaAreaLink);
  };

  convertSuperlogicaDate = cDate => {
    const [month, day, year] = cDate.split('/');
    return `${day}/${month}/${year}`;
  };

  getDueDate = () => {
    const { nDueDay } = this.state;
    if (nDueDay > 0) {
      const oDate = new Date();
      oDate.setDate(nDueDay);
      return oDate.toLocaleDateString(this.LOCALE);
    }
    return 'N/A';
  };

  getPaymentMethod = oBill => {
    if (oBill) {
      if (oBill.payment_method_name === 'Boleto') {
        return 'Boleto';
      } else {
        return (
          <CreditCardDisplay
            cCreditCardNumber={oBill.credit_card_number}
            cDueMonth={oBill.credit_card_due_month}
            cDueYear={oBill.credit_card_due_year}
          />
        );
      }
    }
    return 'N/A';
  };

  render() {
    const {
      bLoadingExtractInfo,
      bLoadingUser,
      aPages,
      nTotalUsers,
      nTotalSpace,
      nPaidValue,
      oInvoiceLast,
      oBillNext,
      oUser,
      cSuperlogicaAreaLink
    } = this.state;
    const { history } = this.props;

    return (
      <Page
        loading={bLoadingExtractInfo || bLoadingUser}
        rcmpBreadcrumb={<Breadcrumb aPages={aPages} />}
        cTitle={intl.get('ExtractIndex.extrato_titulo')}
        cImage={imgfConfig}
        rcmpCardHeader={<CardHeader history={history} />}
        className="v2-extractindex"
      >
        {bLoadingExtractInfo ? (
          <div className="loading-container">
            <img alt={intl.get('loading')} src={cLoadingImage} />
          </div>
        ) : (
          <>
            <div className="extractindex-title">
              <h3>{intl.get('ExtractIndex.general_view')}</h3>
            </div>

            <div className="extractindex-container-info">
              <div className="extractindex-users">
                <h4>{intl.get('Module.module_extras_users')}</h4>
                <p>{nTotalUsers}</p>
                <button type="button" className="btn btn-extract" onClick={() => history.push('/modules')}>
                  {intl.get('ExtractIndex.change_total_users')}
                </button>
              </div>
              <div className="extractindex-space">
                <h4>{intl.get('Module.module_extras_space')}</h4>
                <p>{nTotalSpace} Gb</p>
                <button type="button" className="btn btn-extract" onClick={() => history.push('/modules')}>
                  {intl.get('ExtractIndex.change_storage')}
                </button>
              </div>
              <div className="extractindex-payment">
                <h4>{intl.get('ExtractIndex.monthly_payment')}</h4>
                <p>R$ {nPaidValue.toFixed(2)}</p>
                <button type="button" className="btn btn-extract" onClick={() => history.push('/modules')}>
                  {intl.get('ExtractIndex.manage_plan')}
                </button>
              </div>
            </div>

            <div className="extractindex-container-extract">
              <div className="extractindex-last">
                <p>
                  <label>{intl.get('ExtractIndex.last_paid_bill')}: </label>
                  {oInvoiceLast?.id ? `#${oInvoiceLast.id}` : 'N/A'}
                </p>
                <p>
                  <label>{intl.get('ExtractIndex.value')}: </label>
                  {oInvoiceLast?.total_value ? `R$ ${oInvoiceLast.total_value}` : 'N/A'}
                </p>
                <p>
                  <label>{intl.get('ExtractIndex.payment_date')}: </label>
                  {oInvoiceLast?.payment_date ? this.convertSuperlogicaDate(oInvoiceLast.payment_date) : 'N/A'}
                </p>
                <button
                  type="button"
                  disabled={!cSuperlogicaAreaLink}
                  className="btn btn-bill"
                  onClick={() => this.openSuperlogicaHistoryLink()}
                >
                  {intl.get('ExtractIndex.history_view')}
                </button>
              </div>

              <div className="extractindex-billing">
                <div className="extractindex-billing-header">
                  <h4>{intl.get('ExtractIndex.bill_details')}</h4>
                  <button
                    type="button"
                    disabled={!cSuperlogicaAreaLink}
                    className="btn btn-changeData"
                    onClick={() => this.openSuperlogicaAreaLink()}
                  >
                    {intl.get('ExtractIndex.change_data')}
                  </button>
                </div>
                <div className="extractindex-billing-body">
                  <p>
                    <label>{intl.get('company')}: </label>
                    {oUser?.customer?.cust_cname ? oUser.customer.cust_cname : 'N/A'}
                  </p>
                  <p>
                    <label>{intl.get('ExtractIndex.payment_method')}: </label>
                    {oBillNext?.payment_method_name
                      ? this.getPaymentMethod(oBillNext)
                      : oInvoiceLast?.payment_method_name
                        ? this.getPaymentMethod(oInvoiceLast)
                        : 'N/A'}
                  </p>
                  <p>
                    <label>{intl.get('ExtractIndex.next_payment_date')}: </label>
                    {oBillNext?.due_date ? this.convertSuperlogicaDate(oBillNext.due_date) : this.getDueDate()}
                  </p>
                  <p>
                    <label>{intl.get('ExtractIndex.bill_country')}: </label>
                    {oUser?.customer?.cust_ccountry ? oUser.customer.cust_ccountry : 'N/A'}
                  </p>
                </div>
              </div>
            </div>
          </>
        )}
      </Page>
    );
  }
}

export default ExtractIndex;