import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button } from 'reactstrap';
import intl from 'react-intl-universal';

const ButtonBack = ({ history }) => (
  <Button outline className="btn alternate back" onClick={history.goBack}>
    {intl.get('voltar')}
  </Button>
);

export default withRouter(ButtonBack);
