import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert/lib/dist/SweetAlert';
import { Button, Input } from 'reactstrap';
import intl from 'react-intl-universal';
import imgfNoPic from '../../assets/img/nopic.svg';
import AltCheckbox from '../../components/AltCheckbox';
import Axios from '../../config/Axios';
import url from '../../config/Url';
import PageClean from '../default-v2/PageClean';
import Alert from '../../components/Alert';
import LimitWidget from '../../components/LimitWidget';
import Document from '../document/Document';
import { validateRequest } from '../../actions/ActionRequest';
import NewVersion from '../document/NewVersion';
import ScrollBar from '../../components/ScrollBar';
import { getStatus } from '../default/Status';
import { checkModule } from '../../config/Permissions';
import { createAndRedirect } from '../../actions/ActionDocumentEditor';

export function getRequestTitle(cType) {
  if (cType === 'delete') {
    return intl.getHTML('RequestEdit.delete');
  }
  if (cType === 'update') {
    return intl.getHTML('RequestEdit.update');
  }
  if (cType === 'new') {
    return intl.getHTML('RequestEdit.novo');
  }
  return '';
}

export function getRequestStatus(oStatItem) {
  if (getStatus('pending', 'Request', oStatItem)) {
    return intl.getHTML('pending');
  }
  if (getStatus('accepted', 'Request', oStatItem)) {
    return intl.getHTML('accepted');
  }
  if (getStatus('removed', 'Request', oStatItem)) {
    return intl.getHTML('removed');
  }
  if (getStatus('rejected', 'Request', oStatItem)) {
    return intl.getHTML('rejected');
  }
  return '';
}

export class RequestEdit extends Component {
  constructor(oProps) {
    super(oProps);
    this.form = React.createRef();

    this.state = {
      bLoading: true,
      bIsSending: false,
      oLimits: {
        oDocument: {
          cRemaining: '',
          cAllowed: ''
        }
      },
      nRequestId: oProps.match ? oProps.match.params.nRequestId : oProps.nRequestId,
      bCanBeAccepted: '',
      cJustification: '',
      bReject: false,
      cAction: '',
      bCanRejectRequest: false
    };
  }

  componentDidMount() {
    const { setAlert, history, closeModal, onError } = this.props;
    const { nRequestId } = this.state;
    checkModule(history, 'documents');

    Promise.all([
      Axios.get(`/request/form/edit/${nRequestId}`).then(oResponse => {
        if (oResponse.data === 'request.show') {
          history.push(`/request/${nRequestId}`);
        } else {
          const { bCanBeAccepted, oRequest, aMsg } = oResponse.data;
          const { cType } = this.props;

          let bIsBtnUpdate = cType && cType == 'update' ? true : false;
          let bCanRejectRequest = bIsBtnUpdate && !bCanBeAccepted ? true : false;

          this.setState({
            bCanBeAccepted,
            oRequest,
            aMsg,
            bReject: bCanRejectRequest,
            bCanRejectRequest
          });
        }
      })
    ])
      .then(() => this.setState({ bLoading: false }))
      .catch(oError => {
        if (oError.response.status === 406) {
          closeModal();
          if (onError && onError !== undefined) onError(nRequestId);
          setAlert('error', oError.aMsgErrors);
        } else {
          setAlert('error', oError.aMsgErrors);
          this.setState({ bLoading: false });
        }
      });
  }

  handleChange = evt => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  openModalNewVersion = (nRequestId, nDocId) => {
    const { onSuccess, closeModal, setAlert, history } = this.props;

    validateRequest(nRequestId, 29)
      .then(oResponse => {
        Axios.get(`version/validate/new-version/${nDocId}`)
          .then(oResponse2 => {
            this.setState({
              rcmpAlert: (
                <SweetAlert
                  customClass="modal-edit md disabled-overflow scrollBar"
                  title=""
                  onConfirm={closeModal}
                  showConfirm={false}
                >
                  <div className="head-modal">
                    {intl.get('NewVersion.title')}
                    <span
                      role="button"
                      tabIndex="0"
                      aria-labelledby="head-modal"
                      onKeyDown={this.handleKeyDown}
                      onClick={closeModal}
                      className="close"
                      href=""
                    />
                  </div>
                  <ScrollBar>
                    <NewVersion
                      onSuccess={onSuccess}
                      closeModal={closeModal}
                      nRequestId={nRequestId}
                      history={history}
                    />
                  </ScrollBar>
                </SweetAlert>
              )
            });
          })
          .catch(oError => {
            setAlert('error', oError.aMsgErrors);
          });
      })
      .catch(oError => {
        this.setState({
          bLoading: false,
          bIsSending: true,
          rcmpAlert: (
            <Alert type="error" isOpen onCloseAlert={() => {}}>
              {oError.aMsgErrors}
            </Alert>
          )
        });
      });
  };

  handleSubmit = () => {
    const { nRequestId, cJustification, cAction, oRequest } = this.state;
    const { history, setAlert, closeModal, onError, cType } = this.props;

    if (cType && cType == 'update' && cAction === 'accept') {
      this.openModalNewVersion(nRequestId, oRequest.doc_nid);
      return;
    }

    this.setState({ bIsSending: true });
    if (cAction === 'accept' && cType !== 'delete') {
      this.openCreateDocumentModal(nRequestId);
      return;
    }

    Axios.put(`/request`, {
      rqst_nid: nRequestId,
      cRejectJustification: cJustification,
      dUpdated: oRequest.rqst_dupdated,
      cAction
    })
      .then(oResponse => {
        if (oResponse.data === 'request.show') {
          history.push(`/request/${nRequestId}`);
        } else if (oResponse.data === 'version.acceptNewVersion') {
          history.push(`/request/accept/${nRequestId}`);
        } else {
          history.push({
            pathname: history.pathname,
            state: { aAlertMessages: { cType: 'success', cMsg: oResponse.data.success } }
          });
        }
      })
      .catch(oError => {
        if (oError.response && oError.response !== undefined && oError.response.status === 406) {
          closeModal();
          if (onError && onError !== undefined) onError(nRequestId);
          setAlert('error', oError.aMsgErrors);
        } else {
          this.setState({
            bLoading: false,
            rcmpAlert: (
              <Alert type="error" isOpen onCloseAlert={() => {}}>
                {oError.aMsgErrors}
              </Alert>
            )
          });
        }
      });
  };

  openCreateDocumentModal = nRequestId => {
    const { onSuccess } = this.props;

    validateRequest(nRequestId, 29)
      .then(oResponse => {
        createAndRedirect('request', nRequestId)
          .catch((oError) => {
            this.setState({
              rcmpAlert: (
                <SweetAlert
                  warning
                  showCancel
                  customClass="modal-edit modalMedium scrollBar"
                  cancelBtnText={intl.get('close')}
                  cancelBtnBsStyle="danger"
                  onCancel={this.hideAlert}
                  title={intl.get('create_document_error')}
                  showConfirm={false}
                >{oError.response.data}</SweetAlert>
              )
            });
          });
      })
      .catch(oError => {
        this.setState({
          bLoading: false,
          rcmpAlert: (
            <Alert type="error" isOpen onCloseAlert={() => {}}>
              {oError.aMsgErrors}
            </Alert>
          )
        });
      });
  };

  onSubmit = (evt, cAction) => {
    evt.preventDefault();
    const { oRequest } = this.state;

    this.setState({ cAction }, () => {
      if (oRequest.rqst_etype === 'delete') {
        this.showSendToFile(oRequest.document, cAction);
      } else {
        this.handleSubmit();
      }
    });
  };

  showSendToFile = (oDocument, cAction) => {
    this.setState({
      rcmpAlert: (
        <SweetAlert
          showCancel
          confirmBtnText={intl.get('confirmar')}
          cancelBtnText={intl.get('cancelar')}
          cancelBtnBsStyle="danger"
          title={cAction === 'accept' ? intl.get('DocumentDetails.arquivar') : intl.get('Request.rejeitar')}
          onConfirm={() => {
            this.handleSubmit();
            this.hideAlert();
          }}
          onCancel={this.hideAlert}
        >
          {cAction === 'accept'
            ? `${intl.get('DocumentDetails.arquivar_pergunta')} "${oDocument.doc_ctitle}"?`
            : `${intl.get('Request.rejeitar_arquivar_pergunta')} "${oDocument.doc_ctitle}"?`}
        </SweetAlert>
      )
    });
  };

  hideAlert = () => {
    this.setState({
      rcmpAlert: null
    });
  };

  render() {
    const {
      oRequest,
      rcmpAlert,
      bCanBeAccepted,
      bIsSending,
      bReject,
      cJustification,
      bLoading,
      aMsg,
      oLimits,
      bCanRejectRequest
    } = this.state;
    const { history, closeModal, cType } = this.props;
    let bIsBtnUpdate = cType && cType == 'update' ? true : false;
    let cBtnReject = bReject
      ? intl.get('Request.rejeitar')
      : bIsBtnUpdate
        ? intl.get('DocumentDetails.nova_versao')
        : intl.get('Request.criar_documento');

    return (
      <PageClean loading={bLoading ? 1 : 0}>
        {rcmpAlert}
        {aMsg && aMsg.length > 0 && (
          <Alert type="info" isOpen onCloseAlert={() => {}}>
            {aMsg}
          </Alert>
        )}

        {oRequest && <h1>{getRequestTitle(oRequest.rqst_etype)}</h1>}

        {oRequest && (
          <form className="form" ref={this.form}>
            {oRequest.document && !bIsBtnUpdate && (
              <label className="document">
                <h2> {intl.get('documento')}</h2>
                <p>{oRequest.document.doc_ctitle}</p>
              </label>
            )}

            <div className="request-info">
              <div className="request">
                <strong>{bIsBtnUpdate ? intl.get('Request.doc_title') : intl.get('Request.titulo')}</strong>
                <p>{oRequest.rqst_ctitle}</p>
                <br />
                <strong>{bIsBtnUpdate ? intl.get('Request.suggestion') : intl.get('Request.justificativa')}</strong>
                <p>{oRequest.rqst_cjustification}</p>
                <br />
              </div>
              <div className="requester">
                <strong className="header">{intl.get('Request.solicitante')}</strong>

                <div className="avatar">
                  <img
                    src={
                      oRequest.user_requester.user_cavatar
                        ? oRequest.user_requester.user_cavatar
                        : imgfNoPic
                    }
                    alt={oRequest.user_requester.user_cname}
                  />
                </div>
                <p className="name" title={oRequest.user_requester.user_cname}>
                  {oRequest.user_requester.user_cname}
                </p>
              </div>
              <div className="requester">
                <strong className="header">{intl.get('Request.requested_dotted')}</strong>

                <div className="avatar">
                  <img
                    src={
                      oRequest.user_destiny.user_cavatar
                        ? oRequest.user_destiny.user_cavatar
                        : imgfNoPic
                    }
                    alt={oRequest.user_destiny.user_cname}
                  />
                </div>
                <p className="name" title={oRequest.user_destiny.user_cname}>
                  {oRequest.user_destiny.user_cname}
                </p>
              </div>
            </div>

            {oRequest.rqst_etype === 'new' && oLimits.oDocument.nLeft === 0 ? (
              ''
            ) : bCanBeAccepted ? (
              <AltCheckbox
                onChange={() => this.setState(oState => ({ bReject: !oState.bReject }))}
                checked={!bReject}
                label={`${bReject ? intl.get('Request.rejeitar') : intl.get('Request.aceitar')} ${intl.get('request')}`}
                value={!bReject}
                className="custom"
              />
            ) : (
              ''
            )}

            {bCanRejectRequest ? (
              <AltCheckbox
                onChange={() => this.setState(oState => ({ bReject: true }))}
                checked={false}
                label={intl.get('Request.rejeitar')}
                value={false}
                disabled={true}
                className="custom"
              />
            ) : (
              ''
            )}

            {(bReject || bCanRejectRequest) && (
              <div className="message-wrapper" style={{ display: 'block' }}>
                <label className="">
                  {intl.get('Request.justificativa_rejeicao')} <span className="required">*</span>
                  <Input
                    type="textarea"
                    required
                    maxLength="250"
                    name="cJustification"
                    cols="50"
                    rows="10"
                    onChange={this.handleChange}
                    value={cJustification}
                  />
                </label>
              </div>
            )}

            {oRequest.rqst_etype === 'new' && oLimits.oDocument.nLeft === 0 && <LimitWidget oLimits={oLimits} />}

            <div className="button-stack">
              <Button outline className="btn alternate back" onClick={closeModal || history.goBack}>
                {intl.get('cancelar')}
              </Button>

              {oRequest.rqst_etype === 'new' && oLimits.oDocument.nLeft === 0 ? (
                ''
              ) : (
                <>
                  {(bCanBeAccepted || bCanRejectRequest) && (
                    <Button
                      name="action"
                      value="accept"
                      type="submit"
                      className="btn"
                      onClick={evt => this.onSubmit(evt, bReject ? 'reject' : 'accept')}
                      disabled={bIsSending}
                    >
                      {oRequest.rqst_etype === 'delete' && !bReject ? intl.get('DocumentDetails.arquivar') : cBtnReject}
                    </Button>
                  )}
                </>
              )}
            </div>
          </form>
        )}
      </PageClean>
    );
  }
}

export default RequestEdit;
