import Axios from '../config/Axios';
import loadBreadcrumb from './ActionBreadcrumb';

export function getDocumentPublishing(nVersionId) {
  return Axios.get(`version/publish/${nVersionId}`).then(
    oResponse =>
      new Promise((resolve, reject) => {
        loadBreadcrumb(oResponse.data.oDocument.fldr_nid)
          .then(aBreadcrumbFolders => {
            resolve({
              ...oResponse.data,
              oDocument: oResponse.data.oDocument,
              aBreadcrumbFolders
            });
          })
          .catch(oError => reject(oError));
      })
  );
}

export function publishDocument(
  nVersionId,
  ver_creviewperiod,
  ver_nvaluereviewperiod,
  ver_cnotification,
  ver_nvaluenotification,
  selectedList
) {
  return Axios.post(`version/publish/${nVersionId}`, {
    ver_creviewperiod,
    ver_nvaluereviewperiod,
    ver_cnotification,
    ver_nvaluenotification,
    aSelected: selectedList
  });
}

export function docusignAlterStatus(
  nVersionId,
  ver_creviewperiod,
  ver_nvaluereviewperiod,
  ver_cnotification,
  ver_nvaluenotification,
  selectedList,
  cHasEnvelopeId
) {
  return Axios.post(`version/publish/docusign/status/${nVersionId}/${cHasEnvelopeId}`, {
    ver_creviewperiod,
    ver_nvaluereviewperiod,
    ver_cnotification,
    ver_nvaluenotification,
    aSelected: selectedList,
  });
}

export function viewPDF(nVersionId) {
  return new Promise((resolve, reject) =>
    Axios.get(`version/pdf/${nVersionId}`, { responseType: 'blob' })
      .then(oResponse => {
        const fFile = new Blob([oResponse.data], { type: oResponse.data.type });
        const cFileURL = URL.createObjectURL(fFile);
        const elLink = document.createElement('a');
        elLink.href = cFileURL;
        elLink.target = '_blank';
        document.body.appendChild(elLink);
        elLink.click();
        resolve();
      })
      .catch(oError => new Response(oError.response.data).text().then(cText => reject(cText)))
  );
}
