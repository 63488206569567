// atenção: na próxima vez que precisarmos usar HTML perigoso, lembremos de sanitizá-lo para aplicar a sanitização nesses dangerouslySetInnerHTML também
import _ from 'lodash';
import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import intl from 'react-intl-universal';
import { NavLink } from 'react-router-dom';
import { Button, Input } from 'reactstrap';
import { downloadAttachment, fnDownloadLocalAttachment, fnGetImageAttachment } from '../../actions/ActionDocumentDetails';
import {
  getDocumentPublishing,
  publishDocument,
  docusignAlterStatus,
  viewPDF
} from '../../actions/ActionDocumentPublish';
import imgfGroup from '../../assets/img/group.svg';
import imgfNoPic from '../../assets/img/nopic.svg';
import Breadcrumb from '../../components/Breadcrumbs';
import SendDocumentToTrashBin from '../../components/SendDocumentToTrashBin';
import { OptionMenu } from '../../components/OptionMenu';
import OutsideHandlerClick from '../../components/OutsideHandlerClick';
import url from '../../config/Url';
import Page from '../default-v2/Page';
import { SuggestEdition } from './SuggestEdition';
import Axios from '../../config/Axios';
import ScrollBar from '../../components/ScrollBar';
import './styles/documentpublish.scss';
import CardHeader from '../default-v2/CardHeader';
import Attachment from '../document/Attachments';
import { getStatus } from '../default/Status';
import 'froala-editor/css/froala_style.min.css';
import '../../assets/scss/froala-custom.scss';
import { checkModule, can, hasModule } from '../../config/Permissions';
import DocumentHeader from './DocumentHeader';
import Document from './Document';
import DocusignButton from '../../components/DocusignButton';
import Modal from '../../components/Modal';
import documentImg from '../../assets/img/documents.svg';
import cImageDownload from './img/icon-download.svg';
//import { TailSpin } from 'react-loader-spinner';

import DocusignContext from '../../components/DocusignContext';

class DocumentPublish extends Component {
  static contextType = DocusignContext;

  constructor(oProps) {
    super(oProps);

    this.state = {
      bLoading: true,
      oReview: [],
      nVersionId: oProps.match.params.nVersionId,
      aImgFiles: ['.png', '.jpg', '.jpeg', '.bmp', '.ai', '.eps', '.psd'],
      aAttachments: [],
      ver_creviewperiod: 'S',
      nValReviewPeriod: 0,
      ver_cnotification: 'D',
      nValueRevNotification: 0,
      aTeams: [],
      aList: [],
      bIsSend: false,
      bAmReviewer: false,
      bAmResponsible: false,
      bAmWriter: false,
      aSelectedList: [],
      aAvailableList: [],
      oRequest: {},
      aFilteredUsers: [],
      focused: false,
      bShowWarning: false,
      cSearch: '',
      cHasEnvelopeId: '',
      showDetails: false,
      bVerValNotification: true,
      bVerValPeriod: true,
      bShowSpinner: false
    };
  }

  getDocumentHeader = () => {
    return <DocumentHeader bStepEdit bStepReview bStepPublish />;
  };

  componentDidMount = () => {
    const { nVersionId } = this.state;
    const { history, setAlert } = this.props;
    const envevelopeId = new URL(window.location.href).searchParams.get('envelopeId');

    if (envevelopeId) {
      this.state.cHasEnvelopeId = envevelopeId;
      const aSelectedList = JSON.parse(window.localStorage.getItem('aSelectedList'));
      window.localStorage.removeItem('aSelectedList');
      this.state.aSelectedList = aSelectedList;
      this.publish();
    }

    checkModule(history, 'documents');

    getDocumentPublishing(nVersionId)
      .then(oData => {
        const { aSelected, aAvailable, oVersion, bAmResponsible, bAmWriter, bAmReviewer } = oData;
        let aNewAvailableList = [...aAvailable];

        if (getStatus('published', 'Document', oData.oVersion.status)) {
          history.push(`/document/detail/${oVersion.ver_nid}`);
        } else if (getStatus('in-writing', 'Document', oData.oVersion.status) && bAmWriter === true) {
          history.push(`/document/editor/${oVersion.ver_nid}`);
        } else if (getStatus('in-review', 'Document', oData.oVersion.status) && bAmReviewer === true) {
          history.push(`/document/review/${oVersion.ver_nid}`);
        } else if (!getStatus('awaiting-publish', 'Document', oData.oVersion.status) && !bAmResponsible === true) {
          history.push({
            pathname: '/',
            state: { aAlertMessages: { cType: 'error', cMsg: intl.get('DocumentEditor.documento_indisponivel') } }
          });
        }

        let j = 0;
        let n = 0;
        for (let i = 0; i < aSelected.length; i += 1) {
          j = 0;
          n = aNewAvailableList.length;
          while (j < n) {
            if (
              aSelected[i] &&
              aSelected[i].nTeamId !== null &&
              aNewAvailableList[j].nUserId !== null &&
              aNewAvailableList[j].aTeamsIds.includes(aSelected[i].nTeamId)
            ) {
              aNewAvailableList.splice(j, 1);
              n = aNewAvailableList.length;
            } else {
              j += 1;
            }
          }
        }

        this.setState({
          bLoading: false,
          ...oData,
          aSelectedList: [...oData.aSelected],
          aAvailable: [...oData.aAvailable.concat(oData.aSelected)],
          aAvailableList: [...aNewAvailableList],
          aFilteredUsers: [...aNewAvailableList]
        });
      })
      .catch(oError => {
        if ([406, 403].includes(oError.response?.status)) {
          this.setState({
            bLoading: false,
            rcmpAlert: (
            <SweetAlert
                confirmBtnText={intl.get('ok')}
                onConfirm={() => history.push({pathname: '/'})}
              >
                {oError.aMsgErrors.join('\n')}
              </SweetAlert>
          )});
        } else {
          this.setState({ bLoading: false });
          setAlert('error', oError.aMsgErrors || intl.get('DocumentPublish.problemas_temporarios'));
        }
      });
  };

  checkSelectedList = evt => {
    evt.preventDefault();
    const { setAlert, history } = this.props;

    if (this.state.aSelectedList.length == 0) {
      setAlert('error', intl.get('DocumentPublish.messagem_select_user_team'));
      return;
    }
  };
  publish = evt => {
    if (evt) evt.preventDefault();
    const {
      ver_creviewperiod,
      nValReviewPeriod,
      ver_cnotification,
      nValueRevNotification,
      nVersionId,
      aSelectedList,
      cHasEnvelopeId
    } = this.state;
    const { setAlert, history } = this.props;
    if (aSelectedList.length == 0) {
      setAlert('error', intl.get('DocumentPublish.messagem_select_user_team'));
      return;
    }

    this.setState({ bIsSend: true });
    if (cHasEnvelopeId) {
      docusignAlterStatus(
        nVersionId,
        ver_creviewperiod,
        nValReviewPeriod,
        ver_cnotification,
        nValueRevNotification,
        aSelectedList,
        cHasEnvelopeId
      )
        .then(() =>
          history.push({
            pathname: '/',
            state: { aAlertMessages: { cType: 'success', cMsg: 'Documento Liberado Para Assinatura' } }
          })
        )
        .catch(oError => {
          this.setState({ bIsSend: false });
          setAlert('error', oError.aMsgErrors);
        });
      return;
    }
    publishDocument(
      nVersionId,
      ver_creviewperiod,
      nValReviewPeriod,
      ver_cnotification,
      nValueRevNotification,
      aSelectedList
    )
      .then(() =>
        history.push({
          pathname: '/',
          state: { aAlertMessages: { cType: 'success', cMsg: intl.get('DocumentPublish.documento_publicado') } }
        })
      )
      .catch(oError => {
        this.setState({ bIsSend: false });
        setAlert('error', oError.aMsgErrors);
      });
  };

  handleChangeAccess = (evt, cPropName) => {
    const cValue = evt.target.value;
    if (evt.target.checked) {
      this.setState(oPrevState => ({
        [cPropName]: [...oPrevState[cPropName], cValue]
      }));
    } else {
      this.setState(oPrevState => {
        const aNew = oPrevState[cPropName];
        const nPosition = aNew.indexOf(cValue);
        aNew.splice(nPosition, 1);
        this.setState({ [cPropName]: aNew });
      });
    }
    evt.target.closest('label').classList.toggle('selected');
  };

  checkIfAttachmentIsImage = (cFilename) => {
    const { aImgFiles } = this.state;
    for (let i = 0; i < aImgFiles.length; i++) {
      if (cFilename.endsWith(aImgFiles[i])) {
        return true;
      }
    }
    return false;
  }

  openAttachmentImageModal = (oAttachment, fileType) => {
    const bIsLocal = oAttachment.atch_clocal.startsWith("customer\\");
    fnGetImageAttachment(oAttachment.atch_nid, oAttachment.atch_cname, bIsLocal).then(oData => {
      let cImageSource = oData.cContent;

      if (oData.cType == "base64") {
        cImageSource = "data:image/png;base64, " + oData.cContent;
      }

      if (oData.cType === 'pdf') {
        const base64Content = oData.cContent;
        cImageSource = `data:application/pdf;base64, ${base64Content}`;
      }

      this.setState({
        rcmpAlert: (
          <SweetAlert
            customClass="modal-edit modalMedium scrollBar"
            title=""
            onConfirm={this.hideAlert}
            showConfirm={false}
          >
            <div className="head-modal">
              {intl.get("DocumentEditor.view_attachment")}
              <span
                role="button"
                tabIndex="0"
                aria-labelledby="head-modal"
                onClick={() => (bIsLocal) ?
                  fnDownloadLocalAttachment(oAttachment.atch_nid, oAttachment.atch_cname) : downloadAttachment(oAttachment.atch_nid, oAttachment.atch_cname)}
                className="attachment-download"
                href=""
              ><img src={cImageDownload} /></span>
              <span
                role="button"
                tabIndex="0"
                aria-labelledby="head-modal"
                onKeyDown={this.handleKeyDown}
                onClick={this.hideAlert}
                className="close"
                href=""
              />
            </div>
            {['png', 'jpg', 'jpeg', 'bmp'].includes(fileType) ? (
              <img className="attachment-image" src={cImageSource} />
            ) : (
              <iframe src={cImageSource} style={{ width: '100%', height: '500px' }}></iframe>
            )}
          </SweetAlert>
        )
      });

      this.setState({ bShowSpinner: false });
    }).catch(oError => {
      // ...lidar com erro
      this.setState({ bShowSpinner: false });
    });
  }

  handleDownloadAttachment = (evt, oAttachment) => {
    const { setAlert } = this.props;
    evt.preventDefault();
    const attachmentType = oAttachment.atch_cname.split('.').pop().toLowerCase();

    if (['png', 'jpg', 'jpeg', 'bmp', 'pdf', 'docx', 'doc', 'xls', 'xlsx', 'html', 'txt', 'ods'].includes(attachmentType)) {
      this.setState({ bShowSpinner: true });
      this.openAttachmentImageModal(oAttachment, attachmentType);
    } else {
      const bIsLocal = oAttachment.atch_clocal.startsWith("customer\\");
      if (bIsLocal) {
        fnDownloadLocalAttachment(oAttachment.atch_nid, oAttachment.atch_cname).catch(oError => setAlert('error', oError));
      } else {
        downloadAttachment(oAttachment.atch_nid, oAttachment.atch_cname).catch(oError => setAlert('error', oError));
      }
    }
  };

  handleChange = evt => {
    const { name, value } = evt.target;
    let oUpdateVer = { [name]: value };

    // Lógica para o campo ver_creviewperiod
    if (name === 'ver_creviewperiod') {
      if (value === 'T') {
        oUpdateVer = {
          ...oUpdateVer,
          ver_creviewperiod: 'T',
          bVerValPeriod: false
        };
      } else {
        oUpdateVer.bVerValPeriod = true;
      }
    }

    // Lógica para o campo ver_cnotification
    if (name === 'ver_cnotification') {
      if (value === 'T') {
        oUpdateVer.bVerValNotification = false;
      } else {
        oUpdateVer.bVerValNotification = true;
      }
    }

    this.setState(oUpdateVer);
  };

  viewDocumentPDF = evt => {
    evt.preventDefault();
    const { setAlert } = this.props;
    const { nVersionId } = this.state;

    this.setState({ bIsSend: true });

    viewPDF(nVersionId)
      .then(this.setState({ bIsSend: false }))
      .catch(oError => {
        this.setState({ bIsSend: false });
        setAlert('error', oError);
      });
  };

  onFocusSearch = () => {
    this.setState({ focused: true });
  };

  filterList = evtChange => {
    evtChange.preventDefault();

    this.setState({ focused: true });

    this.processFilterList(evtChange.target.value);
  };

  processFilterList = cSearchText => {
    const { aAvailableList } = this.state;

    let aUpdated = aAvailableList;

    aUpdated = aUpdated.filter(oItem => {
      return oItem.cName.toLowerCase().includes(cSearchText.toLowerCase());
    });

    this.setState({
      aFilteredUsers: aUpdated,
      cSearch: cSearchText
    });
  };

  addUser = oUser => {
    const { aAvailableList, aSelectedList } = this.state;
    let aNewaAvailableList = [...aAvailableList];

    if (oUser && oUser.nTeamId !== null) {
      let aNewSelectedList = [...aSelectedList];
      let nIndex = -1;
      let aUser = [];
      let bShowWarning = false;

      aUser = this.getUsersRelatedToTeam(oUser.nTeamId);

      for (let i = 0; i < aUser.length; i += 1) {
        nIndex = aNewaAvailableList.findIndex(oFilterItem => aUser[i].nUserId === oFilterItem.nUserId);
        if (nIndex !== -1) {
          aNewaAvailableList.splice(nIndex, 1);
        }
        nIndex = aNewSelectedList.findIndex(oSelectedItem => aUser[i].nUserId === oSelectedItem.nUserId);
        if (nIndex !== -1) {
          aNewSelectedList.splice(nIndex, 1);
          bShowWarning = true;
        }
      }

      nIndex = aNewaAvailableList.findIndex(oFilterItem => oUser.nTeamId === oFilterItem.nTeamId);
      aNewaAvailableList.splice(nIndex, 1);

      this.setState(
        {
          aAvailableList: [...aNewaAvailableList],
          aSelectedList: [...aNewSelectedList, oUser],
          bShowWarning
        },
        () => {
          this.processFilterList(this.state.cSearch);
        }
      );
    } else {
      const nIndex = aNewaAvailableList.findIndex(oFilterItem => oUser.nUserId === oFilterItem.nUserId);
      aNewaAvailableList.splice(nIndex, 1);
      this.setState(
        {
          aAvailableList: [...aNewaAvailableList],
          aSelectedList: [...aSelectedList, oUser]
        },
        () => {
          this.processFilterList(this.state.cSearch);
        }
      );
    }
  };

  removeUser = oUser => {
    const { aSelectedList, aAvailableList } = this.state;

    if (oUser && oUser.nTeamId !== null) {
      let aNewAvailableList = [...aAvailableList];
      let aUser = [];

      aUser = this.getUsersRelatedToTeam(oUser.nTeamId);

      aNewAvailableList = aNewAvailableList.concat(aUser);

      this.setState(
        {
          aSelectedList: aSelectedList.filter(oActualUser => oActualUser.nTeamId !== oUser.nTeamId),
          aAvailableList: [...aNewAvailableList, oUser],
          bShowWarning: false
        },
        () => {
          this.processFilterList(this.state.cSearch);
        }
      );
    } else {
      this.setState(
        {
          aSelectedList: aSelectedList.filter(oActualUser => oActualUser.nUserId !== oUser.nUserId),
          aAvailableList: [...aAvailableList, oUser]
        },
        () => {
          this.processFilterList(this.state.cSearch);
        }
      );
    }
  };

  getUsersRelatedToTeam = nTeamId => {
    const { aAvailable } = this.state;
    return aAvailable.filter(oAvailableItem => {
      if (oAvailableItem && oAvailableItem.nUserId !== null && oAvailableItem.aTeamsIds.includes(nTeamId)) {
        return true;
      }
      return false;
    });
  };

  changeRequest = evt => {
    evt.preventDefault();
    const { bAmResponsible, bAmWriter } = this.state;
    this.setState({ bIsSend: true });

    if (!bAmResponsible) {
      return;
    }

    if (!bAmWriter) {
      this.openModalRequest(evt);
    } else {
      this.sendToEditor();
    }
  };

  sendToEditor = () => {
    const { oRequest } = this.state;
    const { setAlert, history } = this.props;

    Axios.post(`/version/reject/${oRequest.ver_nid}`, {
      cText: oRequest.rqst_cjustification,
      nRequestId: oRequest.rqst_nid
    })
      .then(() => {
        history.push(`/document/editor/${oRequest.ver_nid}`);
      })
      .catch(oError => {
        this.setState({
          bLoading: false,
          bIsSend: false
        });
        setAlert('error', oError.aMsgErrors);
      });
  };

  openModalRequest = evt => {
    const { oRequest } = this.state;
    this.setState({
      rcmpAlert: (
        <SweetAlert
          customClass="modal-edit modal-edit-v2 lg scrollBar"
          title=""
          onConfirm={this.hideAlert}
          showConfirm={false}
        >
          <div className="head-modal">
            {intl.get('suggest_editing')}
            <span
              role="button"
              tabIndex="0"
              aria-labelledby="head-modal"
              onKeyDown={this.handleKeyDown}
              onClick={this.hideAlert}
              className="close"
              href=""
            />
          </div>
          <ScrollBar>
            <SuggestEdition
              onSuccess={cMsg => {
                this.successMessage(cMsg);
              }}
              closeModal={this.hideAlert}
              eType="published"
              nRequestId={oRequest.rqst_nid}
            />
          </ScrollBar>
        </SweetAlert>
      ),
      bIsSend: false
    });
  };

  hideAlert = () => {
    this.setState({
      rcmpAlert: null
    });
  };

  successMessage = cMsg => {
    const { history } = this.props;

    this.hideAlert();

    history.push({
      pathname: '/',
      state: { aAlertMessages: { cType: 'success', cMsg } }
    });
  };

  getImageSource = oItem => {
    let imgfSource = imgfGroup;
    if (oItem.cAvatar) {
      imgfSource = `${oItem.cAvatar}`;
    } else if (oItem.nUserId !== null) {
      imgfSource = imgfNoPic;
    }
    return imgfSource;
  };

  renderList = aItems => {
    const { focused } = this.state;
    return (
      <OutsideHandlerClick handleClickOutside={this.closeSubmenu}>
        <div
          style={{ display: focused === true ? 'block' : 'none' }}
          className={`searchBox ${aItems.length === 0 ? 'notfound' : ''}`}
          id="searchResult"
        >
          <ul className="list-group">
            {aItems.map(oItem => (
              <li
                className={`list-group-item ${!oItem.bIsRegister ? 'pending' : ''}`}
                key={oItem.nUserId ? `user-${oItem.nUserId}` : `team-${oItem.nTeamId}`}
              >
                <img src={this.getImageSource(oItem)} alt={oItem.nUserId !== null ? 'Usuário' : 'Equipe'} />
                <p className="infos">
                  <b>{oItem.cName}</b> <br /> {oItem.cRole}
                </p>
                <span
                  role="button"
                  tabIndex="0"
                  onKeyPress={() => this.addUser(oItem)}
                  onClick={() => this.addUser(oItem)}
                  className="btn btn-primary"
                >
                  {intl.get('adicionar')}
                </span>
              </li>
            ))}
          </ul>
          {aItems.length === 0 && focused === true && (
            <div className="boxInvite">
              <p>{intl.get('DocumentEdit.sem_usuarios_equipes')}</p>
            </div>
          )}
        </div>
      </OutsideHandlerClick>
    );
  };

  closeSubmenu = (evt, elWrapperRef) => {
    const elWrapper = elWrapperRef;
    if (elWrapper.getElementsByClassName('searchBox')[0]) {
      elWrapper.getElementsByClassName('searchBox')[0].style = 'display: none';
      this.setState({ focused: false });
    }
  };

  openStatusDetailsModal = evt => {
    evt.preventDefault();
    const { oDocument, oVersion, bAmResponsible } = this.state;
    const { showStatusDetailsModal, setShowStatusDetailsModal, signers, setSigners } = this.context;
    setSigners(oVersion.signers);
    setShowStatusDetailsModal(true);
  };

  getOptionMenu = () => {
    const { oDocument, oVersion, bAmResponsible } = this.state;
    const { history } = this.props;
    const cModule = 'documents';

    return (
      oDocument &&
      oVersion && (
        <OptionMenu>
          <li>
            <NavLink title={intl.get('DocumentDetails.interacoes')} to={`/document/interaction/${oVersion.ver_nid}`}>
              <i className="icon-clock" />
              {intl.get('DocumentDetails.interacoes')}
            </NavLink>
          </li>
          {hasModule(cModule) && (bAmResponsible || can('admin', cModule)) && (
            <>
              <li>
                <span
                  role="button"
                  tabIndex="0"
                  onKeyPress={evtKeyPress =>
                    this.handleDocumentModal(evtKeyPress, this.handleModalSuccess, this.handleModalError)
                  }
                  onClick={evtClick =>
                    this.handleDocumentModal(evtClick, this.handleModalSuccess, this.handleModalError)
                  }
                  title={intl.get('DocumentDetails.editar_documento')}
                >
                  <i className="icon-edit" />
                  {intl.get('DocumentDetails.editar_documento')}
                </span>
              </li>
            </>
          )}
          {bAmResponsible && (
            <li>
              <SendDocumentToTrashBin oVersion={oVersion} oDocument={oDocument} history={history} />
            </li>
          )}
        </OptionMenu>
      )
    );
  };

  handleModalSuccess = cMessage => {
    this.setState({
      rcmpAlert: (
        <SweetAlert success onConfirm={() => this.hideAlert()}>
          {' '}
          {cMessage}{' '}
        </SweetAlert>
      )
    });
  };

  handleModalError = cMessage => {
    this.setState({
      rcmpAlert: (
        <SweetAlert error onConfirm={() => this.hideAlert()}>
          {' '}
          {cMessage}{' '}
        </SweetAlert>
      )
    });
  };

  handleDocumentModal = (evtEditing, fnOnSuccess, fnOnError) => {
    const { nVersionId } = this.state;
    this.setState({
      rcmpAlert: (
        <SweetAlert
          customClass="modal-edit modal-edit-v2 lg scrollBar"
          title=""
          onConfirm={this.hideAlert}
          showConfirm={false}
        >
          <div className="head-modal">
            {intl.get('DocumentDetails.editar_documento')}
            <span
              role="button"
              tabIndex="0"
              aria-labelledby="head-modal"
              onKeyDown={this.handleKeyDown}
              onClick={this.hideAlert}
              className="close"
              href=""
            />
          </div>
          <ScrollBar>
            <Document
              bNewDocument={false}
              nVersionId={nVersionId}
              onSuccess={fnOnSuccess}
              onError={fnOnError}
              closeModal={this.hideAlert}
            />
          </ScrollBar>
        </SweetAlert>
      )
    });
  };

  render() {
    const {
      oDocument,
      oVersion,
      aAttachments,
      bIsSend,
      nValueRevNotification,
      nValReviewPeriod,
      aFilteredUsers,
      aSelectedList,
      bLoading,
      bAmWriter,
      bAmResponsible,
      aBreadcrumbFolders,
      rcmpAlert,
      bShowWarning,
      showDetails,
      ver_cnotification,
      ver_creviewperiod,
      bVerValNotification,
      bVerValPeriod,
      bShowSpinner
      // oUser
    } = this.state;

    const { history, getAlert } = this.props;

    const rcmpBreadcrumb = oDocument && (
      <Breadcrumb bLastLink aFolders={aBreadcrumbFolders} cType="document" oDocument={oDocument} />
    );

    return (
      <Page
        loading={bLoading ? 1 : 0}
        rcmpBreadcrumb={rcmpBreadcrumb}
        cTitle={oDocument ? oDocument.doc_ctitle : ''}
        cImage={documentImg}
        rcmpCardHeader={
          <CardHeader rcmpOptionMenu={this.getOptionMenu()} history={history} cHtml={this.getDocumentHeader()} />
        }
        cCurrentSideMenuOption={intl.get('Nav.document')}
      >
        {rcmpAlert}
        {bShowSpinner && (
          <div style={{
            position: 'fixed', // Fixa em relação ao viewport
            top: 0,            // Começa no topo do viewport
            left: 0,           // Alinhado à esquerda do viewport
            right: 0,          // Estende até a direita do viewport
            bottom: 0,         // Estende até o fundo do viewport
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Cor de fundo semi-transparente
            display: 'flex',
            justifyContent: 'center', // Centraliza horizontalmente o conteúdo
            alignItems: 'center',     // Centraliza verticalmente o conteúdo
            zIndex: 1000              // Garante que a sobreposição esteja acima de outros elementos
          }}>
            {/* <TailSpin
              type="Puff"
              color="#00BFFF"
              height={100}
              width={100}
            /> */}
          </div>
        )}
        {getAlert()}

        <div className="clearfix" />
        <Modal></Modal>
        <div className="containerJustification">
          <h2>{intl.get('justificativa')}</h2>
          <p style={{ whiteSpace: 'pre-wrap' }}>{oVersion && oVersion.ver_cjustification}</p>
        </div>
        <form autoComplete="off" className="form" onSubmit={this.publish}>
          <div id="fr-wrapper">
            <article
              className="fr-element fr-view"
              dangerouslySetInnerHTML={{ __html: oVersion ? oVersion.ver_ctext : '' }}
            />
          </div>

          <div className="attachments-container">
            <div className="attachments-wrapper">
              {aAttachments.length > 0 && (
                <>
                  <span>{intl.get('anexos')}:</span>
                  <ul className="attachments">
                    {aAttachments.map((oAttachment, i) => (
                      <li key={oAttachment.atch_nid}>
                        <a
                          className="download-file-attachment"
                          onClick={evt => this.handleDownloadAttachment(evt, oAttachment)}
                          download
                        >
                          <img
                            className="align clip-icon"
                            src={require('../../assets/img/clip.svg')}
                            alt="attachment icon"
                          />
                          <span>{oAttachment.atch_cname}</span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </div>
          </div>

          <div className="containerTeam">
            <label>
              <b>{intl.get('DocumentPublish.busca')}</b>
              {bShowWarning && <span className="user-message">{intl.get('DocumentEdit.remove_users_message')}</span>}
              <Input
                type="text"
                id="search"
                name="search"
                maxLength="250"
                placeholder={intl.get('DocumentPublish.membros_equipe_placeholder')}
                onChange={this.filterList}
                onFocus={this.onFocusSearch}
                onClick={this.onFocusSearch}
              />
              {this.renderList(_.orderBy(aFilteredUsers, [oUser => oUser.cName.toLowerCase()], ['asc']))}
            </label>

            <div id="selectedUsers">
              {aSelectedList.map(oUser => (
                <span
                  key={oUser.nUserId ? oUser.nUserId : `eq${oUser.nTeamId}`}
                  className={`userBox ${!oUser.bIsRegister ? 'pending' : ''}`}
                >
                  <span
                    role="button"
                    tabIndex="0"
                    onKeyPress={() => this.removeUser(oUser)}
                    onClick={() => this.removeUser(oUser)}
                    className="removeUser"
                  >
                    {intl.get('remover')}
                  </span>
                  <img src={this.getImageSource(oUser)} alt={oUser.nUserId !== null ? 'Usuário' : 'Equipe'} />

                  <p className="name" title={oUser.cName}>
                    {oUser.cName}
                  </p>
                </span>
              ))}
            </div>
            <div className="status-details">
              {oVersion?.ver_cenvelope_id ? (
                <>
                  <p>Status</p>
                  {oVersion.signers.length > 0
                    ? oVersion.signers.every(item => item.ds_cstatus === 'completed')
                      ? intl.get('DocumentPublish.assinado')
                      : intl.get('DocumentPublish.esperando_assinatura') +
                      ' ' +
                      oVersion.signers.filter(item => item.ds_cstatus === 'completed').length +
                      '/' +
                      oVersion.signers.length
                    : intl.get('DocumentPublish.esperando_docusign')}
                  <a className="detail-anchor" onClick={evt => this.openStatusDetailsModal(evt)}>
                    {intl.get('DocumentPublish.ver_detalhes')}
                  </a>
                </>
              ) : (
                ''
              )}
            </div>
          </div>

          <label className="split2 no-spacing" htmlFor="nValReviewPeriod">
            {intl.get('DocumentPublish.revisar_a_cada')}
          </label>
          <label className="split2 no-spacing" htmlFor="nValueRevNotification">
            {intl.get('DocumentPublish.notificar_antes_de')}
          </label>
          <div className="row split4">
            <Input type="select" onChange={this.handleChange} required name="ver_creviewperiod" id="ver_creviewperiod" value={ver_creviewperiod}>
              <option value="S">{intl.get('DocumentPublish.semanas')}</option>
              <option value="M">{intl.get('Document.meses')}</option>
              <option value="A">{intl.get('DocumentPublish.anos')}</option>
              <option value="T">{intl.get('DocumentPublish.date_doc')}</option>
            </Input>
          </div>
          {bVerValPeriod ? (
            <div className="row split4">
              <Input
                onChange={this.handleChange}
                min="0"
                max="999"
                id="nValReviewPeriod"
                name="nValReviewPeriod"
                type="number"
                value={nValReviewPeriod}
                maxLength="255"
              />
            </div>
          ) : (
            <div className="row split4">
              <Input
                onChange={this.handleChange}
                id="nValReviewPeriod"
                name="nValReviewPeriod"
                type="date"
                min="2023-01-01"
                max="2100-12-31"
              />
            </div>
          )}
          <div className="row split4">
            <Input type="select" onChange={this.handleChange} required name="ver_cnotification" id="ver_cnotification" value={ver_cnotification}>
              <option value="D">{intl.get('Document.dias')}</option>
              <option value="S">{intl.get('DocumentPublish.semanas')}</option>
              <option value="T" disabled={ver_creviewperiod !== 'T'}>{intl.get('DocumentPublish.date_doc')}</option>
            </Input>
          </div>
          {bVerValNotification ? (
            <div className="row split4">
              <Input
                onChange={this.handleChange}
                min="0"
                max="999"
                id="nValueRevNotification"
                name="nValueRevNotification"
                type="number"
                value={nValueRevNotification}
                maxLength="255"
              />
            </div>
          ) : (
            <div className="row split4">
              <Input
                onChange={this.handleChange}
                id="nValueRevNotification"
                name="nValueRevNotification"
                type="date"
                min="2023-01-01"
                max="2100-12-31"
              />
            </div>
          )}
          <div className="button-stack">
            <Button className="btn alternate back" onClick={history.goBack} disabled={bIsSend}>
              {intl.get('cancel')}
            </Button>
            {bAmResponsible && (
              <>
                <Button
                  className="btn alternate"
                  type="button"
                  disabled={bIsSend || oVersion?.ver_cenvelope_id}
                  onClick={evt => this.changeRequest(evt)}
                >
                  {!bAmWriter && intl.get('suggest_editing')}
                  {bAmWriter && intl.get('edit', { cType: '' })}
                </Button>
                <Button
                  disabled={bIsSend || oVersion?.ver_cenvelope_id}
                  value="aprovar"
                  id="publish"
                  type="submit"
                  className="btn"
                >
                  {intl.get('DocumentPublish.publicar')}
                </Button>
                {oVersion.ver_cenvelope_id ? (
                  <DocusignButton
                    htmlToDocusign={oVersion.ver_ctext}
                    aSelectedList={aSelectedList}
                    setAlert={this.props.setAlert}
                    titleToDocusign={oDocument.doc_ctitle}
                    envelopeId={oVersion.ver_cenvelope_id}
                    onClick={evt => this.checkSelectedList(evt)}
                    bIsDisabled={bIsSend}
                  >
                    Cancelar <br /> Assinaturas
                  </DocusignButton>
                ) : (
                  <DocusignButton
                    htmlToDocusign={oVersion.ver_ctext}
                    aSelectedList={aSelectedList}
                    setAlert={this.props.setAlert}
                    titleToDocusign={oDocument.doc_ctitle}
                    onClick={evt => this.checkSelectedList(evt)}
                    bIsDisabled={bIsSend}
                  >
                    {intl.get('DocumentPublish.publicar')} <br /> {intl.get('DocumentPublish.com_assinatura')}
                  </DocusignButton>
                )}
              </>
            )}
          </div>
        </form>
      </Page>
    );
  }
}

export default DocumentPublish;
