import React, { Component } from 'react';
import { Button } from 'reactstrap';
import axios from 'axios';
import intl from 'react-intl-universal';
import imgfLogo from '../../assets/img/logo.png';
import { InputPassword } from '../../components/InputPassword';
import { doLogout } from '../default/Header';

class ChangeEmail extends Component {
  constructor(oProps) {
    super(oProps);

    document.body.classList.add('w-bkg');

    this.state = {
      "cToken": oProps.match.params.cToken,
      "cPassword": '',
      "bHasAlert": false,
      "aMsgErrors": [''],
      "cAlertType": 'error',
      "bIsSending": false
    };
  }

  componentDidMount() {
    const cIsLoggedIn = localStorage.getItem('cAccessToken');
    if (cIsLoggedIn) {
      doLogout();
    }
  }

  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  handleSubmit = (evt) => {
    evt.preventDefault();
    const { cToken, cPassword } = this.state;
    this.setState({ "bIsSending": true });

    axios
      .post(`/change-email/${cToken}`, {
        cPassword
      })
      .then(() => {
        this.setState({
          "bHasAlert": true,
          "aMsgErrors": [intl.get('ChangeEmail.email_alterado')],
          "cAlertType": 'success'
        });
      })
      .catch((oError) => {
        const aErrorMessages = [];
        if (oError.response.status === 422) {
          oError.response.data.map((evtResponse) => aErrorMessages.push(evtResponse));
        } else {
          aErrorMessages.push(intl.get('erro_500'));
        }
        this.setState({
          "bHasAlert": true,
          "aMsgErrors": aErrorMessages,
          "cAlertType": 'error',
          "bIsSending": false
        });
      });
  };

  render() {
    const { cPassword, bIsSending, bHasAlert, cAlertType, aMsgErrors } = this.state;
    return (
      <section id="register">
        <div className="wrapper-login">
          <header>
            <div className="logo">
              <img src={imgfLogo} alt="Acredite.se" />
            </div>
            <h1>{intl.get('ChangeEmail.resetar_email')}</h1>
          </header>
          <main className="form">
            <form className="form-horizontal" onSubmit={this.handleSubmit}>
              <InputPassword value={cPassword} onChange={this.handleChange} />

              <Button disabled={bIsSending} type="submit">
                {intl.get('enviar')}
              </Button>

              <a href="/login" className="btn alternate" style={{ float: 'left', textAlign: 'center' }}>
                {intl.get('voltar')}
              </a>

              {bHasAlert && (
                <div className={`alert ${cAlertType}`}>
                  {aMsgErrors.map((cMsg) => (
                    <p key={Math.random()}>{cMsg}</p>
                  ))}
                </div>
              )}
            </form>
          </main>
        </div>
      </section>
    );
  }
}

export default ChangeEmail;
