import React, { Component } from 'react';
import './styles/documentheader.scss';
import intl from 'react-intl-universal';

export class DocumentHeader extends Component {
  constructor(oProps) {
    super(oProps);
  }

  render() {
    const { bStepEdit, bStepReview, bStepPublish } = this.props;

    let cEditClass = 'icon-editing-nonchecked';
    let cReviewClass = 'icon-comment-nonchecked';
    let cPublishClass = 'icon-published-nonchecked';
    
    let cEditLine = '';
    let cReviewLine = '';
    cEditLine = cReviewLine = 'hd-line-none';
    
    if (bStepEdit) {
      cEditClass = 'icon-editing';
      cEditLine = 'hd-line-half';
    }

    if (bStepReview) {
      cReviewClass = 'icon-comment';
      cEditLine = 'hd-line-full';
      cReviewLine = 'hd-line-half';
    }

    if (bStepPublish) {
      cPublishClass = 'icon-published';
      cEditLine = cReviewLine = 'hd-line-full';
    }
  
    return <div className='header-document'>
      <div className='header-document-edit'>
        <i className={cEditClass} title={intl.get(`Status.em-redacao`)} />
        <div className={cEditLine}>&nbsp;</div>
      </div>
      <div className='header-document-review'>
        <i className={cReviewClass} title={intl.get(`Status.em-revisao`)} />
        <div className={cReviewLine}>&nbsp;</div>
      </div>
      <div className='header-document-publish'>
        <i className={cPublishClass} title={intl.get(`Status.aguardando-publicacao`)} />
      </div>
    </div>;
  }
}

DocumentHeader.defaultProps = {
  bStepEdit: false,
  bStepReview: false,
  bStepPublish: false
};

export default DocumentHeader;
