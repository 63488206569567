import _ from 'lodash';
import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import intl from 'react-intl-universal';
import { NavLink } from 'react-router-dom';
import { Button, Input } from 'reactstrap';
import { downloadAttachment, fnDownloadLocalAttachment, fnGetImageAttachment } from '../../actions/ActionDocumentDetails';
import {
  sendToTrashBin,
  sendToReview,
  deleteAttachment,
  getDocumentEssay,
  saveEssay,
  uploadImage,
  uploadAttachment,
  getAttachments,
  removeImage
} from '../../actions/ActionDocumentEditor';
import Breadcrumb from '../../components/Breadcrumbs';
import ButtonBack from '../../components/ButtonBack';
import { can, hasModule, checkModule } from '../../config/Permissions';
import Page from '../default-v2/Page';
import SendDocumentToTrashBin from '../../components/SendDocumentToTrashBin';
import { OptionMenu } from '../default-v2/OptionMenu';
import { Alert } from '../../components/Alert';
import '../../assets/scss/froala-custom-popup.scss';
import FroalaEditorComponent from '../../config/FroalaEditorComponent';
import { getStatus } from '../default/Status';
import './styles/documenteditor.scss';
import './styles/document.scss';
import CardHeader from '../default-v2/CardHeader';
import Attachment from '../document/Attachments';
import url from '../../config/Url';
import Axios from 'axios';
import DocumentHeader from './DocumentHeader';
import Document from './Document';
import documentImg from '../../assets/img/documents.svg';
import cImageDownload from './img/icon-download.svg';

import ScrollBar from '../../components/ScrollBar';
import PrintConfig from './PrintConfig';
import EncodedLocalStorage from '../../config/EncodedLocalStorage';
//import { TailSpin } from 'react-loader-spinner';


export class DocumentEditor extends Component {
  bIsMounted = true;
  tmInterval = null;

  constructor(oProps) {
    super(oProps);

    this.state = {
      bLoading: true,
      nVersionId: oProps.match.params.nVersionId,
      oDocument: '',
      aAttachments: [],
      bIsSending: false,
      aImgFiles: ['png', 'jpg', 'jpeg', 'bmp', 'ai', 'eps', 'psd'],
      oVersion: '',
      oRequest: {},
      cEditorContent: '',
      aUrls: [],
      oEditor: null,
      bShowSpinner: false,
      isSubmitting: false
    };
  }

  componentWillUnmount() {
    this.bIsMounted = false;
  }

  componentDidMount = () => {
    this.bIsMounted = true;
    const { nVersionId } = this.state;
    const { history, setAlert } = this.props;
    checkModule(history, 'documents');

    getDocumentEssay(nVersionId)
      .then(oData => {
        const { oVersion } = oData;
        if (this.bIsMounted) {
          if (getStatus('awaiting-publish', 'Document', oData.oVersion.status) && oData.bAmResponsible === true) {
            history.push(`/document/publish/${oVersion.ver_nid}`);
          } else if (getStatus('published', 'Document', oData.oVersion.status)) {
            history.push(`/document/detail/${oVersion.ver_nid}`);
          } else if (getStatus('in-review', 'Document', oData.oVersion.status) && oData.bAmReviewer === true) {
            history.push(`/document/review/${oVersion.ver_nid}`);
          } else if (!getStatus('in-writing', 'Document', oData.oVersion.status) && !oData.bAmWriter === true) {
            history.push({
              pathname: '/',
              state: { aAlertMessages: { cType: 'error', cMsg: intl.get('DocumentEditor.documento_indisponivel') } }
            });
          }

          let cEditorContent = '';
          if (oVersion.ver_ctext !== null) {
            cEditorContent = oVersion.ver_ctext;
          }

          return this.setState({
            bLoading: false,
            ...oData,
            cEditorContent
          });
        }
      })
      .catch(oError => {
        if ([406, 403].includes(oError.response?.status)) {
          this.setState({
            bLoading: false,
            rcmpAlert: (
              <SweetAlert
                confirmBtnText={intl.get('ok')}
                onConfirm={() => history.push({ pathname: '/' })}
              >
                {oError.aMsgErrors.join('\n')}
              </SweetAlert>
            )
          });
        } else {
          this.setState({ bLoading: false });
          setAlert('error', oError.aMsgErrors);
        }
      });
  };

  onChangeEditor = cContent => {
    const { bAmWriter } = this.state;

    this.setState({
      cEditorContent: cContent
    });

    if (bAmWriter) {
      this.save(false);
    }
  };

  save = async (bScroll, bOpenModalAfterSaving, fnOnModalSuccess) => {
    const { oVersion, cEditorContent, bIsSending, oDocument } = this.state;
    const { setAlert, history } = this.props;

    if (bIsSending) return;

    if (typeof oDocument.doc_ctitle !== 'string' || oDocument.doc_ctitle.length == 0) {
      this.setState({
        rcmpAlert: (
          <SweetAlert error onConfirm={() => this.hideAlert()}>
            {intl.get('DocumentEditor.no_title')}
          </SweetAlert>
        )
      });
      return;
    } else if (typeof oVersion.ver_cjustification !== 'string' || oVersion.ver_cjustification.length == 0) {
      this.setState({
        rcmpAlert: (
          <SweetAlert error onConfirm={() => this.hideAlert()}>
            {intl.get('DocumentEditor.no_justification')}
          </SweetAlert>
        )
      });
      return;
    }

    if (this.bIsMounted) this.setState({ bIsSending: true });

    try {
      await saveEssay(oVersion.ver_nid, cEditorContent, oDocument.doc_ctitle, oVersion.ver_cjustification);
      if (!this.bIsMounted) return;
      if (bOpenModalAfterSaving) {
        this.handleDocumentModal(fnOnModalSuccess, this.handleModalError);
      } else if (bScroll) {
        setAlert('success', intl.get('DocumentEditor.salvar_sucesso'));
      }
    } catch (oError) {
      if (oError.response === undefined) {
        setAlert('error', oError.message);
      } else if (oError.response.status === 406) {
        history.push({
          pathname: '/',
          state: { aAlertMessages: { cType: 'error', cMsg: oError.aMsgErrors } }
        });
      } else if (oError.response.status === 413) {
        setAlert('error', intl.get('DocumentEditor.tamanho_maximo'));
      } else {
        setAlert('error', oError.aMsgErrors);
      }
    } finally {
      this.setState({ bIsSending: false });
    }
  }

  forwardReview = () => {
    //evt.preventDefault();
    this.setState({
      bIsSending: true
    });
    const { oVersion, cEditorContent } = this.state;
    const { history, setAlert } = this.props;

    sendToReview(oVersion.ver_nid, cEditorContent)
      .then(() => {
        if (this.bIsMounted) {
          history.push({
            pathname: '/',
            state: {
              aAlertMessages: {
                cType: 'success',
                cMsg: intl.get('DocumentEditor.encaminhado')
              }
            }
          });
        }
      })
      .catch(oError => {
        if (oError.response !== undefined && oError.response.status === 406) {
          history.push({
            pathname: '/',
            state: { aAlertMessages: { cType: 'error', cMsg: oError.aMsgErrors } }
          });
        }
        this.setState({ bIsSending: false });
        setAlert('error', oError.aMsgErrors);
      });
  };

  uploadAttachments = async (aUAttachments) => {
    const { oVersion, bIsSending } = this.state;
    const { setAlert, history } = this.props;

    if (bIsSending) return;

    try {
      this.setState({ bIsSending: true });
      await uploadAttachment(oVersion.ver_nid, aUAttachments);
      const { aAttachments } = await getAttachments(oVersion.ver_nid);
      if (!aAttachments) return;
      this.setState({ aAttachments, bIsSending: false });
    } catch (oError) {
      if (oError.response === undefined) {
        setAlert('error', oError.message);
      } else if (oError.response.status === 403) {
        history.push({
          pathname: '/',
          state: { aAlertMessages: { cType: 'error', cMsg: oError.aMsgErrors } }
        });
      } else if (oError.response.status === 413) {
        this.handleModalError(intl.get('DocumentEditor.tamanho_maximo'));
      } else {
        setAlert('error', oError.aMsgErrors);
      }
      this.setState({ bIsSending: false });
    }
  };

  triggerUpload = evt => {
    evt.preventDefault();
    this.fileUpload.click();
  };

  fileChangedHandler = evt => {
    evt.preventDefault();
    const aHandlerAttachments = Array.from(evt.target.files ?? []);
    const nMaxSize = 200 * 1024 * 1024; // 200MB
    if (aHandlerAttachments.some(f => f.size > nMaxSize)) {
      this.handleModalError(intl.get('DocumentEditor.tamanho_maximo'));
      return;
    }
    this.uploadAttachments(aHandlerAttachments);
  };

  handleDeleteAttachment = oAttachment => {
    deleteAttachment(oAttachment.atch_nid)
      .then(response => {
        if (response.status === 200 && response.data.success) {
          if (this.bIsMounted) {
            this.setState({
              rcmpAlert: (
                <SweetAlert success title={intl.get('removido')} onConfirm={() => this.hideAlert()}>
                  {' '}
                  {intl.get('DocumentEditor.anexo_removido')}{' '}
                </SweetAlert>
              )
            });

            this.setState(oState => ({
              aAttachments: oState.aAttachments.filter(oIAttachment => oIAttachment.atch_nid !== oAttachment.atch_nid)
            }));
          }
        } else {
          throw new Error('Erro ao remover anexo');
        }
      })
      .catch(error => {
        this.setState({
          rcmpAlert: (
            <SweetAlert error title={intl.get('opa')} onConfirm={this.hideAlert}>
              {intl.get('falhou')}
            </SweetAlert>
          )
        });
      });
  };

  showDelete = (evt, oAttachment) => {
    evt.preventDefault();
    this.setState({
      rcmpAlert: (
        <SweetAlert
          warning
          showCancel
          confirmBtnText={intl.get('confirmar')}
          cancelBtnText={intl.get('cancel')}
          cancelBtnBsStyle="danger"
          title={intl.get('remover')}
          onConfirm={() => this.handleDeleteAttachment(oAttachment)}
          onCancel={this.hideAlert}
        >
          {intl.get('mensagem_excluir', { tipo: intl.get('DocumentEditor.anexo'), nome: oAttachment.atch_cname })}
        </SweetAlert>
      )
    });
  };

  hideAlert = () => {
    this.setState({
      rcmpAlert: null
    });
  };

  checkIfAttachmentIsImage = (cFilename) => {
    const { aImgFiles } = this.state;
    for (let i = 0; i < aImgFiles.length; i++) {
      if (cFilename.endsWith(aImgFiles[i])) {
        return true;
      }
    }
    return false;
  }

  openAttachmentImageModal = (oAttachment, fileType) => {
    const bIsLocal = oAttachment.atch_clocal.startsWith("customer\\");
    fnGetImageAttachment(oAttachment.atch_nid, oAttachment.atch_cname, bIsLocal).then(oData => {
      let cImageSource = oData.cContent;

      if (oData.cType == "base64") {
        cImageSource = "data:image/png;base64, " + oData.cContent;
      }

      if (oData.cType === 'pdf') {
        const base64Content = oData.cContent;
        cImageSource = `data:application/pdf;base64, ${base64Content}`;
      }

      this.setState({
        rcmpAlert: (
          <SweetAlert
            customClass="modal-edit modalMedium scrollBar"
            title=""
            onConfirm={this.hideAlert}
            showConfirm={false}
          >
            <div className="head-modal">
              {intl.get("DocumentEditor.view_attachment")}
              <span
                role="button"
                tabIndex="0"
                aria-labelledby="head-modal"
                onClick={() => (bIsLocal) ?
                  fnDownloadLocalAttachment(oAttachment.atch_nid, oAttachment.atch_cname) : downloadAttachment(oAttachment.atch_nid, oAttachment.atch_cname)}
                className="attachment-download"
                href=""
              ><img src={cImageDownload} /></span>
              <span
                role="button"
                tabIndex="0"
                aria-labelledby="head-modal"
                onKeyDown={this.handleKeyDown}
                onClick={this.hideAlert}
                className="close"
                href=""
              />
            </div>
            {['png', 'jpg', 'jpeg', 'bmp'].includes(fileType) ? (
              <img className="attachment-image" src={cImageSource} />
            ) : (
              <iframe src={cImageSource} style={{ width: '100%', height: '500px' }}></iframe>
            )}
          </SweetAlert>
        )
      });

      this.setState({ bShowSpinner: false });
    }).catch(oError => {
      // ...lidar com erro
      this.setState({ bShowSpinner: false });
    });
  }

  handleDownloadAttachment = (evt, oAttachment) => {
    const { setAlert } = this.props;
    evt.preventDefault();
    const attachmentType = oAttachment.atch_cname.split('.').pop().toLowerCase();

    if (['png', 'jpg', 'jpeg', 'bmp', 'pdf', 'docx', 'doc', 'xls', 'xlsx', 'html', 'txt', 'ods'].includes(attachmentType)) {
      this.setState({ bShowSpinner: true });
      this.openAttachmentImageModal(oAttachment, attachmentType);
    } else {
      const bIsLocal = oAttachment.atch_clocal.startsWith("customer\\");
      if (bIsLocal) {
        fnDownloadLocalAttachment(oAttachment.atch_nid, oAttachment.atch_cname).catch(oError => setAlert('error', oError));
      } else {
        downloadAttachment(oAttachment.atch_nid, oAttachment.atch_cname).catch(oError => setAlert('error', oError));
      }
    }
  };

  editorLanguage = () => {
    const oLocalStorage = new EncodedLocalStorage();
    const cLanguage = oLocalStorage.get('cLanguage') ? oLocalStorage.get('cLanguage') : 'pt';
    return cLanguage.slice(0, 2);
  };

  handleDownloadPdf = (evtClick, nVersionId, cDocName) => {
    evtClick.preventDefault();
    const { setAlert } = this.props;

    this.setState({
      rcmpAlert: (
        <SweetAlert
          customClass="modal-edit modalMedium scrollBar"
          title=""
          onConfirm={this.hideAlert}
          showConfirm={false}
        >
          <div className="head-modal">
            {intl.get('DocumentDetails.print_config')}
            <span
              role="button"
              tabIndex="0"
              aria-labelledby="head-modal"
              onKeyPress={this.hideAlert}
              onClick={this.hideAlert}
              className="close"
              href=""
            />
          </div>
          <ScrollBar>
            <PrintConfig closeModal={this.hideAlert} nVersionId={nVersionId} cDocName={cDocName} cPreview="Y" />
          </ScrollBar>
        </SweetAlert>
      )
    });
  };

  showSendToTrashBin = (evt, oVersion, oDocument) => {
    evt.preventDefault();
    this.setState({
      rcmpAlert: (
        <SweetAlert
          showCancel
          confirmBtnText={intl.get('confirmar')}
          cancelBtnText={intl.get('cancel')}
          cancelBtnBsStyle="danger"
          title={intl.get('lixeira')}
          onConfirm={() => this.handleSendToTrashBin(oVersion.ver_nid)}
          onCancel={this.hideAlert}
        >
          {intl.get('mensagem_excluir', { tipo: intl.get('o_documento'), nome: oDocument.doc_ctitle })}
        </SweetAlert>
      )
    });
  };

  handleSendToTrashBin = nVersionId => {
    const { history } = this.props;
    if (this.state.isSubmitting) return; // Bloquear dedo nervoso
    this.setState({ isSubmitting: true }, () => {
      sendToTrashBin(nVersionId)
        .then(() => {
          this.setState({
            rcmpAlert: (
              <SweetAlert
                success
                title=""
                onConfirm={() => {
                  this.hideAlert();
                  history.push(`/my-account/`);
                }}
              >
                {intl.get('DocumentEditor.mensagem_removido')}
              </SweetAlert>
            ),
            isSubmitting: false
          });
        })
        .catch(() => {
          this.setState({
            rcmpAlert: (
              <SweetAlert error title={intl.get('opa')} onConfirm={this.hideAlert}>
                {intl.get('falhou')}
              </SweetAlert>
            ),
            isSubmitting: false
          });
        });
    });
  };

  handleRemovedImage = oImage => {
    const { oVersion } = this.state;
    let aSplits = oImage[0].currentSrc.split('/');
    let cImageName = '';
    let cVersionIdImage = '';
    let bRemovePermanently = true;
    let nRepetitions = 0;

    if (aSplits.length > 2) {
      cImageName = aSplits[aSplits.length - 1];
      cVersionIdImage = aSplits[aSplits.length - 2];
    }

    let aImages = document.querySelectorAll(`img[src='${oImage[0].currentSrc}']`);
    aImages.forEach(oImage => {
      if (oImage.hasAttribute('data-fr-image-pasted')) {
        bRemovePermanently = false;
      } else {
        nRepetitions += 1;
      }
    });

    if (
      bRemovePermanently ||
      aImages.length === 1 ||
      (!oImage[0].hasAttribute('data-fr-image-pasted') && nRepetitions > 1)
    ) {
      removeImage(cImageName, cVersionIdImage, oVersion.ver_nid)
        .then(oResponse => {
          this.removeImageEditor(oImage);
        })
        .catch(oError => {
          this.removeImageEditor(oImage);
        });
    } else {
      this.removeImageEditor(oImage);
    }

    return false;
  };

  removeImageEditor = oImage => {
    oImage.remove();
    const elImageMenu = document.getElementsByClassName('fr-popup fr-desktop fr-active')[0];
    const elImageResizer = document.getElementsByClassName('fr-image-resizer')[0];

    if (elImageMenu !== null && elImageMenu !== undefined) {
      elImageMenu.classList.remove('fr-active');
    }

    if (elImageResizer !== null && elImageResizer !== undefined) {
      elImageResizer.classList.remove('fr-active');
    }
  };

  editorInstance = oEditor => {
    this.setState({
      oEditor
    });
  };

  cancelChanges = () => { };

  handleChange = evtInput => {
    const { oDocument, oVersion } = this.state;
    if (evtInput.target.name === 'doc_ctitle') {
      oDocument.doc_ctitle = evtInput.target.value;
    } else if (evtInput.target.name === 'ver_cjustification') {
      oVersion.ver_cjustification = evtInput.target.value;
    }
    this.setState({
      oDocument,
      oVersion
    });
  };

  handleModalSuccess = cMessage => {
    this.setState({
      rcmpAlert: (
        <SweetAlert success onConfirm={() => this.hideAlert()}>
          {' '}
          {cMessage}{' '}
        </SweetAlert>
      )
    });
  };

  handleModalError = cMessage => {
    this.setState({
      rcmpAlert: (
        <SweetAlert error onConfirm={() => this.hideAlert()}>
          {' '}
          {cMessage}{' '}
        </SweetAlert>
      )
    });
  };

  handleDocumentModal = (fnOnSuccess, fnOnError) => {
    const { nVersionId } = this.state;

    this.setState({
      rcmpAlert: (
        <SweetAlert
          customClass="modal-edit modal-edit-v2 lg scrollBar"
          title=""
          onConfirm={this.hideAlert}
          showConfirm={false}
        >
          <div className="head-modal">
            {intl.get('DocumentDetails.editar_documento')}
            <span
              role="button"
              tabIndex="0"
              aria-labelledby="head-modal"
              onKeyDown={this.handleKeyDown}
              onClick={this.hideAlert}
              className="close"
              href=""
            />
          </div>
          <ScrollBar>
            <Document
              bNewDocument={false}
              nVersionId={nVersionId}
              onSuccess={fnOnSuccess}
              onError={fnOnError}
              closeModal={this.hideAlert}
            />
          </ScrollBar>
        </SweetAlert>
      )
    });
  };

  getOptionMenu = () => {
    const { bAmResponsible, oVersion, oDocument } = this.state;
    const { history } = this.props;
    const cModule = 'documents';

    const bPermissionPrint = hasModule(cModule) && (oDocument.bCanEdit || can('print', cModule));

    return (
      <OptionMenu>
        {hasModule(cModule) && (oDocument.bCanEdit || can('print', cModule)) && (
          <li>
            <span
              role="button"
              tabIndex="0"
              onKeyPress={evt => this.handleDownloadPdf(evt, oVersion.ver_nid, oDocument.doc_ctitle)}
              onClick={evt => this.handleDownloadPdf(evt, oVersion.ver_nid, oDocument.doc_ctitle)}
              title={intl.get('DocumentDetails.title_imprimir')}
            >
              <i className="icon-print" />
              {intl.get('DocumentDetails.imprimir')}
            </span>
          </li>
        )}
        <li>
          <NavLink title={intl.get('DocumentDetails.interacoes')} to={`/document/interaction/${oVersion.ver_nid}`}>
            <i className="icon-clock" />
            {intl.get('DocumentDetails.interacoes')}
          </NavLink>
        </li>
        {hasModule(cModule) && (bAmResponsible || can('admin', cModule)) && (
          <>
            <li>
              <span
                role="button"
                tabIndex="0"
                onKeyPress={() => this.save(true, true, this.handleModalSuccess)}
                onClick={() => this.save(true, true, this.handleModalSuccess)}
                title={intl.get('DocumentDetails.editar_documento')}
              >
                <i className="icon-edit" />
                {intl.get('DocumentDetails.editar_documento')}
              </span>
            </li>
            <li>
              <SendDocumentToTrashBin oVersion={oVersion} oDocument={oDocument} history={history} />
            </li>
          </>
        )}
      </OptionMenu>
    );
  };

  getDocumentHeader = () => {
    return <DocumentHeader bStepEdit />;
  };

  render() {
    const {
      rcmpAlert,
      oDocument,
      aAttachments,
      bAmWriter,
      bAmResponsible,
      oVersion,
      bIsSending,
      bLoading,
      oRequest,
      aBreadcrumbFolders,
      cEditorContent,
      bShowSpinner
    } = this.state;

    const { history, getAlert } = this.props;
    const cModule = 'documents';

    const bReadonly = !bAmWriter;
    const rcmpBreadcrumb = oDocument && (
      <Breadcrumb bLastLink aFolders={aBreadcrumbFolders} cType="document" oDocument={oDocument} />
    );

    return (
      <Page
        loading={bLoading ? 1 : 0}
        rcmpBreadcrumb={rcmpBreadcrumb}
        cTitle={oDocument && oDocument.doc_ctitle ? oDocument.doc_ctitle : intl.get('Document.titulo')}
        cImage={documentImg}
        rcmpCardHeader={
          <CardHeader rcmpOptionMenu={this.getOptionMenu()} history={history} cHtml={this.getDocumentHeader()} />
        }
        cCurrentSideMenuOption={intl.get('Nav.document')}
      >
        {getAlert()}
        {hasModule(cModule) &&
          ((bAmWriter && getStatus('in-writing', 'Document', oVersion.status)) || bAmResponsible || can('admin')) ? (
          <>
            {rcmpAlert}

            {bShowSpinner && (
              <div style={{
                position: 'fixed', // Fixa em relação ao viewport
                top: 0,            // Começa no topo do viewport
                left: 0,           // Alinhado à esquerda do viewport
                right: 0,          // Estende até a direita do viewport
                bottom: 0,         // Estende até o fundo do viewport
                backgroundColor: 'rgba(0, 0, 0, 0.5)', // Cor de fundo semi-transparente
                display: 'flex',
                justifyContent: 'center', // Centraliza horizontalmente o conteúdo
                alignItems: 'center',     // Centraliza verticalmente o conteúdo
                zIndex: 1000              // Garante que a sobreposição esteja acima de outros elementos
              }}>

                {/* <TailSpin
                  type="Puff"
                  color="#00BFFF"
                  height={100}
                  width={100}
                /> */}
              </div>
            )}

            {oVersion && (
              <Input
                className="editor-input"
                onChange={this.handleChange}
                type="text"
                value={typeof oDocument.doc_ctitle === 'string' ? oDocument.doc_ctitle : ''}
                name="doc_ctitle"
                placeholder={intl.get('DocumentEditor.placeholder_title')}
                maxLength="100"
              />
            )}

            {oVersion && (
              <Input
                className="editor-input"
                onChange={this.handleChange}
                type="textarea"
                style={{ height: '40px' }}
                value={typeof oVersion.ver_cjustification === 'string' ? oVersion.ver_cjustification : ''}
                name="ver_cjustification"
                placeholder={intl.get('DocumentEditor.placeholder_justification')}
                maxLength="256"
              />
            )}

            {!_.isEmpty(oRequest.rqst_crejectjustification) && (
              <div className="containerJustification suggestion">
                <h2>{`${intl.get('DocumentEditor.sugestao_edicao')} | ${intl.get('user')} ${oRequest.user_requester.user_cname
                  }`}</h2>
                <p style={{ whiteSpace: 'pre-wrap' }}>{oRequest.rqst_crejectjustification}</p>
              </div>
            )}
            <form className="formFroala">
              <>
                {oVersion && (
                  <FroalaEditorComponent
                    model={cEditorContent}
                    fnHandleChange={evt => this.onChangeEditor(evt)}
                    config={{
                      lineHeights: {
                        Default: '',
                        Single: '1',
                        '1.15': '1.15',
                        '1.5': '1.5',
                        Double: '2'
                      },
                      toolbarButtons: [
                        'chatGPT',
                        'undo',
                        'redo',
                        'fullscreen',
                        'bold',
                        'italic',
                        'underline',
                        'textColor',
                        'backgroundColor',
                        'clearFormatting',
                        'alignLeft',
                        'alignCenter',
                        'alignRight',
                        'alignJustify',
                        'formatOL',
                        'formatUL',
                        'indent',
                        'outdent',
                        'lineHeight',
                        'paragraphStyle',
                        'paragraphFormat',
                        'fontFamily',
                        'fontSize',
                        'insertLink',
                        'insertImage',
                        'insertVideo',
                        'insertTable',
                        'selectAll',
                        'quote',
                        'specialCharacters',
                        'embedly',
                        'insertHR',
                        'help',
                        'html'
                      ],
                      //useClasses: true,
                      videoAllowedAttributes: [
                        'src',
                        'width',
                        'height',
                        'controls',
                        'poster',
                        'source',
                        'type',
                        'style',
                        'class',
                        'name',
                        'id'
                      ],
                      videoAllowedTypes: ['mp4'],
                      videoUploadParam: 'fVideo',
                      videoUploadMethod: 'POST',
                      videoMaxSize: 80 * 1024 * 1024,
                      videoUploadURL: `${url.cBase}api/version/upload-video`,
                      videoUploadParams: {
                        nVersionId: oVersion.ver_nid,
                        cImgArea: 'version'
                      },
                      imageUploadURL: `${url.cBase}api/version/upload-image`,
                      imageUploadParam: 'fImage',
                      imageUploadParams: {
                        nVersionId: oVersion.ver_nid,
                        cImgArea: 'version'
                      },
                      requestHeaders: {
                        Authorization: `Bearer ${localStorage.getItem('cAccessToken')}`
                      },
                      fontSizeUnit: 'pt',
                      language: 'pt_br',
                      listAdvancedTypes: true,
                      documentReady: true
                    }}
                    fnRef={this.editorInstance}
                  />
                )}

                <div className="attachments-container">
                  <div className="attachments-wrapper">
                    {aAttachments.length > 0 && (
                      <>
                        <span>{intl.get('anexos')}:</span>
                        <ul className="attachments">
                          {aAttachments.map((oAttachment, i) => (
                            <li key={oAttachment.atch_nid}>
                              <a
                                className="download-file-attachment"
                                onClick={evt => this.handleDownloadAttachment(evt, oAttachment)}
                                download
                              >
                                <img
                                  className="align clip-icon"
                                  src={require('../../assets/img/clip.svg')}
                                  alt="attachment icon"
                                />
                                <span>{oAttachment.atch_cname}</span>
                              </a>
                              <img
                                style={{ cursor: 'pointer', marginLeft: '5px' }}
                                className="align delete-icon"
                                src={require('../../assets/img/red-trash.svg')}
                                alt="delete-icon"
                                onClick={evt => this.showDelete(evt, oAttachment)}
                              />
                            </li>
                          ))}
                        </ul>
                      </>
                    )}
                  </div>

                  {bAmWriter && (
                    <div className="file-field-wrapper">
                      <a
                        style={{ cursor: 'pointer' }}
                        onClick={evt => this.triggerUpload(evt)}
                        className="attach-button"
                      >
                        <i className="icon-attachment" />
                        {intl.get('DocumentEditor.adicionar_anexos')}
                      </a>
                      <label htmlFor="file-upload">
                        <input
                          onChange={this.fileChangedHandler}
                          ref={cRef => {
                            this.fileUpload = cRef;
                          }}
                          className="hidden"
                          id="file-upload"
                          multiple="multiple"
                          name="aAttachments[]"
                          type="file"
                        />
                      </label>
                    </div>
                  )}
                </div>

                <div className="button-stack">
                  {bAmWriter && (
                    <>
                      <Button
                        className={bIsSending ? 'loading disabled' : 'btn alternate'}
                        disabled={bIsSending}
                        type="button"
                        onClick={() => this.save(true, false)}
                      >
                        {bIsSending ? intl.get('salvando') : intl.get('salvar')}
                      </Button>
                      <Button
                        disabled={bIsSending}
                        type="button"
                        onClick={() => this.save(true, true, this.forwardReview)}
                      >
                        {intl.get('DocumentEditor.enviar_revisao')}
                      </Button>
                    </>
                  )}
                </div>
              </>
            </form>
          </>
        ) : (
          <>
            <Alert type="error" isOpen onCloseAlert={() => { }}>
              {intl.get('DocumentEditor.sem_permissao')}
            </Alert>
            <ButtonBack />
          </>
        )}
      </Page>
    );
  }
}

export default DocumentEditor;
