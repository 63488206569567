const url = {
  "cBase": process.env.REACT_APP_API_BASE_URL,
  "oRoutes": {
    "cImageUser": 'user/',
    "cImageCustomer": 'customer/',
    "cAttachment": 'attachment/download/'
  },

  "oPlans": {
    "cList": 'settings/plan',
    "cContinue": 'plan/continue',
    "cSuccess": 'plan/buy'
  },

  "oCustomers": {
    "cEdit": 'settings/client/update/'
  },
  "oIndex": {
    "cIndex": 'index'
  },
  "oDashboard": {
    "cList": ''
  }
};

export default url;
