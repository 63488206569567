import React, { Component } from 'react';

export class OptionMenu extends Component {
  constructor(oProps) {
    super(oProps);

    this.state = {
      bShowMenu: false
    };
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  handleClickOutside = evt => {
    if (evt.target !== this.span && evt.target !== this.spanLink) {
      this.setState({
        bShowMenu: false
      });
    }
  };

  menuOpen = () => {
    this.setState(oCurrentState => ({
      bShowMenu: !oCurrentState.bShowMenu
    }));
  };

  hideMenu = () => {
    this.setState({
      bShowMenu: false
    });
  };

  render() {
    const { bShowMenu } = this.state;
    const { children } = this.props;
    return (
      <div onMouseLeave={evt => this.hideMenu(evt)} className="right-menu">
        <span
          onKeyPress={() => this.menuOpen()}
          onClick={() => this.menuOpen()}
          className="btn-menu"
          role="button"
          tabIndex={0}
          ref={cSpanLink => {
            this.spanLink = cSpanLink;
          }}
        >
          <span
            className="insidespan"
            ref={elSpan => {
              this.span = elSpan;
            }}
          />
        </span>

        <ul style={{ display: bShowMenu ? 'block' : 'none' }} className="submenu">
          {children}
        </ul>
      </div>
    );
  }
}

export default OptionMenu;
