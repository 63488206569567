import React, { Component } from 'react';

class FolderIcon extends Component {
  constructor(oProps) {
    super(oProps);
    this.state = {};
  }

  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="17" viewBox="0 0 14 17">
        <g id="Grupo_9682" data-name="Grupo 9682" transform="translate(-230.122 -578.178)">
          <g id="Grupo_9681" data-name="Grupo 9681">
            <rect
              id="Retângulo_2737"
              data-name="Retângulo 2737"
              width="13"
              height="16"
              rx="1"
              transform="translate(230.622 578.678)"
              fill="none"
              stroke="#415364"
              strokeMiterlimit="10"
              strokeWidth="1"
            />
            <g id="Grupo_9680" data-name="Grupo 9680">
              <path
                id="Caminho_11757"
                data-name="Caminho 11757"
                d="M241.686,582.472h-9.127a.278.278,0,0,0,0,.556h9.127a.278.278,0,0,0,0-.556Z"
                fill="#415364"
              />
              <path
                id="Caminho_11758"
                data-name="Caminho 11758"
                d="M241.686,584.044h-9.127a.277.277,0,1,0,0,.555h9.127a.277.277,0,0,0,0-.555Z"
                fill="#415364"
              />
              <path
                id="Caminho_11759"
                data-name="Caminho 11759"
                d="M241.686,585.615h-9.127a.277.277,0,1,0,0,.555h9.127a.277.277,0,0,0,0-.555Z"
                fill="#415364"
              />
              <path
                id="Caminho_11760"
                data-name="Caminho 11760"
                d="M241.686,587.186h-9.127a.278.278,0,0,0,0,.556h9.127a.278.278,0,0,0,0-.556Z"
                fill="#415364"
              />
              <path
                id="Caminho_11761"
                data-name="Caminho 11761"
                d="M241.686,588.758h-9.127a.277.277,0,1,0,0,.555h9.127a.277.277,0,0,0,0-.555Z"
                fill="#415364"
              />
              <path
                id="Caminho_11762"
                data-name="Caminho 11762"
                d="M239.942,590.329H234.3c-.1,0-.172.124-.172.278s.077.278.172.278h5.639c.1,0,.172-.125.172-.278S240.037,590.329,239.942,590.329Z"
                fill="#415364"
              />
            </g>
          </g>
        </g>
      </svg>
    );
  }
}

export default FolderIcon;
