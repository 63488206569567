import React, { Component } from 'react';
import '../styles/block.scss';

export class Block extends Component {
  constructor(oProps) {
    super(oProps);
  }

  render() {
    const { imgfIcon, cName, cDescription, fnOnClick, cExtraClass } = this.props;

    return (
      <div
        className={`block ${cExtraClass}`}
        role="button"
        tabIndex={0}
        onClick={() => {
          fnOnClick();
        }}
        onKeyPress={() => {
          fnOnClick();
        }}
      >
        {this.props.children}
        <div className="block-title">
          <img className="block-title-icon" src={imgfIcon} alt={cName} />
          <div>
            <p className="block-title-line1">{cName.substring(0, cName.lastIndexOf(' '))}</p>
            <p className="block-title-line2">{cName.split(' ').splice(-1)}</p>
          </div>
        </div>
        <div className="block-text">
          <div className="block-text-container">
            <p>{cDescription}</p>
          </div>
        </div>
      </div>
    );
  }
}

export default Block;
