import Axios from '../config/Axios';
import loadBreadcrumb from './ActionBreadcrumb';

export function getCopy(nVersionId) {
  return Axios.get(`version/copy/${nVersionId}`).then(
    (oResponse) =>
      new Promise((resolve, reject) => {
        loadBreadcrumb(oResponse.data.oDocument.fldr_nid)
          .then((aBreadcrumbFolders) => {
            const { oDocument, oVersion, aUsers, nWriterId, nReviewerId, aFolders, aTeams, aSelectedDistribution } = oResponse.data;
            resolve({
              "oDocument": oDocument,
              "nWriterId": nWriterId,
              "nReviewerId": nReviewerId,
              "oVersion": oVersion,
              "aUsers": aUsers,
              "aFolders": aFolders,
              aBreadcrumbFolders,
              aTeams,
              aSelectedDistribution
            });
          })
          .catch(error => reject(error));
      })
  );
}

export function createCopy(oVersion, oDocument, nWriterId, nReviewerId, aSelectedDistribution) {
  return Axios.post(`/version/copying/${oVersion.ver_nid}`, {
    "cTitle": oDocument.doc_ctitle,
    "cJustification": oVersion.ver_cjustification,
    "nFolderId": oDocument.fldr_nid,
    "nMaxPrints": oDocument.doc_nmaxprints,
    "nMaxDownloadsPdf": oDocument.doc_nmaxdownloadspdf,
    "eVersionType": oDocument.doc_eversiontype,
    "nTermEssay": oDocument.doc_ntermessay,
    "eTermUnitEssay": oDocument.doc_etermunitessay,
    "nTermLiberation": oDocument.doc_ntermliberation,
    "eTermUnitLiberation": oDocument.doc_etermunitliberation,
    "nTermReview": oDocument.doc_ntermreview,
    "eTermUnitReview": oDocument.doc_etermunitreview,
    "cNorm": oVersion.ver_cnorm,
    "cVersion": oVersion.ver_cnumber,
    "nWriterId": Number(nWriterId),
    "nReviewerId": Number(nReviewerId),
    "cMnemonic": oDocument.doc_cmnemonic,
    aSelectedDistribution
  });
}
