import React, { Component } from 'react';

import intl from 'react-intl-universal';
import Page from '../../default/Page';
import Axios from '../../../config/Axios';
import VideoRecorder from 'react-video-recorder';
import Webcam from 'react-webcam';
import { Input } from 'reactstrap';
import { dataURLtoFile } from '../../norm/Norm';
import '../../evidence/styles/evidences.scss';
import EncodedLocalStorage from '../../../config/EncodedLocalStorage';
import fImgSwitchCam from "../../../assets/img/loop_24dp.svg";

const oLocalStorage = new EncodedLocalStorage();
class CaptureMedia extends Component {
  constructor(oProps) {
    super(oProps);

    this.state = {
      bLoading: true,
      getMediaType: undefined,
      video: '',
      screenshot: '',
      cNameEvidence: '',
      errors: {},
      iLocalSend: false,
      cFacingMode: 'user' // 'user' para câmera frontal, 'environment' para câmera traseira
    };
  }

  componentDidMount = () => {
    const { oRequisite, aEvidences } = this.props;
    let disabledPic = 0;
    let disabledVid = 0;
    this.setState({
      disabledPic,
      disabledVid,
      aEvidences,
      oRequisite,
      bLoading: false
    });
  };

  setMediaType = type => {
    const { cNameEvidence, iLocalSend } = this.state;
    if (iLocalSend) return;
    if (cNameEvidence === '') {
      this.setState((oPrevState) => ({
        errors: {
          ...oPrevState.errors,
          cNameEvidence: intl.get("CaptureMedia.titulo_vazio"),
        }
      }));
      return;
    }

    this.setState((oPrevState) => ({
      mediaType: type,
      errors: {
        ...oPrevState.errors,
        cNameEvidence: '',
      }
    }));
  };

  setVideo = videoBlob => {
    let blobUrl = URL.createObjectURL(videoBlob);
    this.setState({ video: blobUrl, videoFile: videoBlob });
  };

  handleClick = () => {
    const screenshot = this.webcam.getScreenshot();
    this.setState({ screenshot });
  };

  handleChange = evt => {
    const { aEvidences } = this.state;
    if (aEvidences.length) {
      let testNameEvid = aEvidences.filter(
        aEvidence => (aEvidence.arev_ctitle && aEvidence.arev_ctitle.toLowerCase()) === evt.target.value.trim().toLowerCase()
      );
      if (testNameEvid.length) {
        this.setState((oPrevState) => ({
          iLocalSend: true,
          errors: {
            ...oPrevState.errors,
            cNameEvidence: intl.get("CaptureMedia.titulo_em_uso")
          }
        }));
      } else {
        this.setState((oPrevState) => ({
          iLocalSend: false,
          errors: {
            ...oPrevState.errors,
            cNameEvidence: ''
          }
        }));
      }
    }
    this.setState({ [evt.target.name]: evt.target.value });
  };

  clearScreenShoot = () => {
    this.setState({ screenshot: '' });
  };

  clearVideo = () => {
    this.setState({ video: '', videoFile: '' });
  };

  clearMediaType = () => {
    this.setState({
      mediaType: '',
      screenshot: '',
      iLocalSend: false,
      video: ''
    });
  };

  blobToFile = (theBlob, fileName) => {
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
  };

  saveEvidence = evt => {
    this.setState({ iLocalSend: true });
    const { mediaType, screenshot, oRequisite, cNameEvidence, videoFile } = this.state;
    const { onSuccess, oAudit } = this.props;
    const oAuditEvidence = oRequisite.audit ?? oAudit;

    const formData = new FormData();
    if (mediaType === 'picture') {
      formData.set('arev_cattachment', dataURLtoFile(screenshot, `${cNameEvidence}.jpg`));
      formData.append('arev_ctitle', `${cNameEvidence.trim()}.jpg`);
    } else {
      formData.set('arev_cattachment', videoFile, `${cNameEvidence}.webm`);
      formData.append('arev_ctitle', cNameEvidence.trim());
    }
    formData.append('user_nid', oLocalStorage.get('nUserId'));
    formData.append('arq_nid', oAuditEvidence.arq_nid);
    formData.append('norm_nid', oRequisite.norm_nid);
    formData.append('req_nid', oRequisite.req_nid);
    formData.append('arev_etype', 'photo');
    formData.append('arev_ntype', 1);
    formData.append('arev_bisactive', 1);

    Axios.post(`audit-evidence/${oAuditEvidence.audi_nid}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(() => {
      onSuccess(intl.get('LinkDocument.requisito_incluido_com_sucesso'));
    });
  };

  switchCamera = () => {
    const { cFacingMode } = this.state;

    const cNewFacingMode = cFacingMode === 'user' ? 'environment' : 'user';
    this.setState({ cFacingMode: cNewFacingMode });
  }

  render() {
    const { bLoading, cFacingMode, rcmpAlertMsg, mediaType, video, screenshot, iLocalSend, disabledPic, disabledVid } = this.state;
    const { closeModal } = this.props;
    return (
      <Page loading={bLoading ? 1 : 0}>
        <div className="captureMediaBox form">
          {rcmpAlertMsg}

          {mediaType && (
            <a onClick={this.clearMediaType} className="backMediaSelect">
              <i className="icon icon-prev" />
            </a>
          )}

          {!mediaType && (
            <>
              <p>{intl.getHTML('CaptureMedia.descricao')}</p>

              <label className="split3 labelEvidence">
                <p>{intl.get('CaptureMedia.label')}<span className="required">*</span></p>
                <Input onChange={this.handleChange} name="cNameEvidence" type="text" maxLength="100" required value={this.state.cNameEvidence} />
                <span className="validation-error">{this.state.errors["cNameEvidence"]}</span>
              </label>

              <button
                disabled={disabledPic >= 20}
                className={`buttonSetMedia ${disabledPic >= 20 ? 'disabled' : ''} ${iLocalSend ? 'disabled' : ''}`}
                onClick={() => this.setMediaType('picture')}
              >
                <i className="icon icon-camera" />
              </button>
              <button
                disabled={disabledVid >= 10}
                className={`buttonSetMedia ${disabledVid >= 10 ? 'disabled' : ''} ${iLocalSend ? 'disabled' : ''}`}
                onClick={() => this.setMediaType('video')}
              >
                <i className="icon icon-film" />
              </button>
            </>
          )}

          {mediaType === 'picture' && (
            <div className="getPicture">
              {!screenshot ? (
                <>
                  <Webcam
                    width="766"
                    height="400"
                    className="getPicture"
                    style={{ background: '#000' }}
                    mirrored={cFacingMode === 'user'}
                    audio={false}
                    ref={node => (this.webcam = node)}
                    screenshotFormat="image/jpeg"
                    videoConstraints={{ facingMode: cFacingMode }}
                  />
                  <div className="controls">
                    <button className="button-switch-camera" onClick={this.switchCamera}>
                      <img className="icon-switch-cam" src={fImgSwitchCam} />
                    </button>
                    <button onClick={this.handleClick}>capture</button>
                  </div>
                </>
              ) : (
                <>
                  <img className="previewPic" src={screenshot} alt="screenshot" />
                  <div className="controls">
                    <button
                      className={`repeatCapture ${iLocalSend ? 'disabled' : ''}`}
                      onClick={this.clearScreenShoot}
                    >
                      {intl.get('CaptureMedia.outra_foto')}
                    </button>
                  </div>
                </>
              )}
            </div>
          )}
          {mediaType === 'video' && (
            <div id="videoRecorder" style={{ height: '400px' }}>
              <VideoRecorder
                timeLimit={120000}
                isRunningCountdown
                isOnInitially={true}
                showReplayControls={true}
                onTurnOnCamera={() => this.clearVideo()}
                onRecordingComplete={videoBlob => {
                  this.setVideo(videoBlob);
                }}
              />
            </div>
          )}

          {(video || screenshot) && (
            <div className="button-stack">
              <button
                type="button"
                onClick={() => closeModal()}
                className="btn alternate back btn btn-outline-secondary"
              >
                {intl.get('cancelar')}
              </button>
              <button
                disabled={iLocalSend}
                onClick={() => this.saveEvidence()}
                type="submit"
                className={`btn ${iLocalSend ? 'loading-white' : ''}`}
              >
                {iLocalSend ? intl.get('salvando') : intl.get('salvar')}
              </button>
            </div>
          )}
        </div>
      </Page>
    );
  }
}

export default CaptureMedia;
