import { oLocalStorage } from "./EncodedLocalStorage";

export function signIn(oData, bSaveCustIdInSession = false, bReload = true) {
  if (oData.cAccessToken) {
    localStorage.setItem('cAccessToken', oData.cAccessToken);
  }
  localStorage.setItem('cStorageToken', oData.cStorageToken ?? oData);
  oLocalStorage.decodeToken();
  if (oLocalStorage.get("cAccessToken")) {
    localStorage.setItem('cAccessToken', oLocalStorage.get("cAccessToken"));
  }

  if (bSaveCustIdInSession) {
    sessionStorage.setItem('nCustId', oLocalStorage.get("nCustId"));
    sessionStorage.setItem('cOldCompanyName', oLocalStorage.get("cCompanyName"));
  }
}

export function signOut() {
  localStorage.removeItem('cAccessToken');
  
  oLocalStorage.destroy();
  sessionStorage.removeItem('nCustId');
  sessionStorage.removeItem('cOldCompanyName');
}
