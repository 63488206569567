import React, { Component } from 'react';
import { Form, Button, Input } from 'reactstrap';
import _ from 'lodash';
import intl from 'react-intl-universal';
import Axios from '../../config/Axios';
import url from '../../config/Url';
import imgfGroup from '../../assets/img/group.svg';
import PageClean from '../default-v2/PageClean';
import imgfNoPic from '../../assets/img/nopic.svg';
import OutsideHandlerClick from '../../components/OutsideHandlerClick';
import { Alert } from '../../components/Alert';
import './styles/documentedit.scss';
import Select from 'react-select';
import DateLimitation from '../../components/DateLimitation';
import checkTime from '../../components/CheckDate';
import { can, checkModule } from '../../config/Permissions';

class DocumentEdit extends Component {
  constructor(oProps) {
    super(oProps);
    this.alertWrapper = React.createRef();

    this.state = {
      bLoading: true,
      nDocumentId: oProps.match ? oProps.match.params.nDocumentId : oProps.oDocument.doc_nid,
      cNameSearch1: undefined,
      cNameSearch2: undefined,
      aCreateTeams: [],
      aCreateUsers: [],
      oDocument: [],
      aTeams: [],
      aSelectedDistribution: [],
      aSelectedUsers: [],
      aSelected: [],
      aAvailableList: [],
      aUsers: [],
      oVersion: [],
      bIsSend: false,
      cResponsibleName: '',
      aAvailable: [],
      bShowWarning: false,
      cSearch: ''
    };
  }

  componentDidMount = () => {
    const { nDocumentId } = this.state;
    const { setAlert, match, history } = this.props;
    checkModule(history, 'documents');

    Axios.get(`/document/edit/${nDocumentId}`)
      .then(oResponse => {
        const {
          oDocument,
          aTeams,
          aSelectedDistribution,
          oVersion,
          aSelected,
          aAvailableList,
          aSelectedUsers,
          nResponsibleId,
          nWriterId,
          nReviewerId,
          bAmResponsible
        } = oResponse.data;
        let aUsers = [];
        let cResponsibleName = this.getResponsible(aSelectedUsers, nResponsibleId);
        if (cResponsibleName !== undefined) {
          cResponsibleName = cResponsibleName.user_cname;
        }
        let aNewAvailableList = [...aAvailableList];
        let aNewSelectedDistribution = [];

        Object.keys(aSelectedUsers).forEach(cKey => {
          aUsers.push({
            label: aSelectedUsers[cKey].user_cname,
            value: Number(aSelectedUsers[cKey].user_nid)
          });
        });

        Object.keys(aSelectedDistribution).forEach(cKey => {
          aNewSelectedDistribution.push({
            label: aSelectedDistribution[cKey].team_cname,
            value: Number(aSelectedDistribution[cKey].team_nid)
          });
        });

        const aNewTeams = aTeams.map(oTeam => ({
          label: oTeam.team_cname,
          value: oTeam.team_nid
        }));

        let j = 0;
        let n = 0;
        for (let i = 0; i < aSelected.length; i += 1) {
          j = 0;
          n = aNewAvailableList.length;
          while (j < n) {
            if (
              aSelected[i] &&
              aSelected[i].team_nid !== null &&
              aNewAvailableList[j].user_nid !== null &&
              aNewAvailableList[j].aTeamsIds.includes(aSelected[i].team_nid)
            ) {
              aNewAvailableList.splice(j, 1);
              n = aNewAvailableList.length;
            } else {
              j += 1;
            }
          }
        }

        this.setState({
          bLoading: false,
          oDocument,
          aTeams: aNewTeams,
          aSelectedDistribution: aNewSelectedDistribution,
          oVersion,
          aSelectedUsers,
          nResponsibleId,
          nWriterId,
          nReviewerId,
          aSelected,
          aFilteredUsers: [...aNewAvailableList],
          aAvailableList: [...aNewAvailableList],
          cResponsibleName,
          bAmResponsible,
          aUsers,
          aAvailable: [...aAvailableList.concat(aSelected)]
        });
      })
      .catch(oError => {
        this.setState({ bLoading: false });
        if (match) {
          setAlert('error', oError.aMsgErrors);
        } else {
          this.setState({
            rcmpAlertMsg: (
              <Alert type="error" isOpen onCloseAlert={() => { }}>
                {oError.aMsgErrors}
              </Alert>
            )
          });
        }
      });
  };

  handleChange = evt => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  handleChangeDoc = evt => {
    const { oDocument } = this.state;
    oDocument[evt.target.name] = evt.target.value;
    this.setState({
      oDocument
    });
  };

  handleChangeVersion = evt => {
    const { oVersion } = this.state;

    oVersion[evt.target.name] = evt.target.value;
    this.setState({
      oVersion
    });
  };

  handleChangeAccess = (evt, cPropName) => {
    const nValue = Number(evt.target.value);

    if (evt.target.checked) {
      this.setState(oPrevState => ({
        [cPropName]: [...oPrevState[cPropName], nValue]
      }));
    } else {
      this.setState(oPrevState => {
        const aNew = oPrevState[cPropName];
        const nPosition = aNew.indexOf(nValue);
        aNew.splice(nPosition, 1);
        this.setState({ [cPropName]: aNew });
      });
    }

    evt.target.closest('label').classList.toggle('selected');
  };

  handleSubmit = evt => {
    evt.preventDefault();

    const {
      oDocument,
      oVersion,
      nResponsibleId,
      nWriterId,
      nReviewerId,
      aSelected,
      aSelectedDistribution
    } = this.state;
    const { onSuccess, match, setAlert } = this.props;

    if (!checkTime(oDocument.doc_etermunitessay, oDocument.doc_ntermessay, '2100-12-31')) {
      this.setState(
        {
          rcmpAlertMsg: (
            <Alert type="error" isOpen onCloseAlert={() => { }}>
              {intl.getHTML('warning_date_number', { cField: intl.get('Document.term_essay') })}
            </Alert>
          )
        },
        () => this.alertWrapper?.current?.scrollIntoView()
      );
      return;
    }
    if (!checkTime(oDocument.doc_etermunitreview, oDocument.doc_ntermreview, '2100-12-31')) {
      this.setState(
        {
          rcmpAlertMsg: (
            <Alert type="error" isOpen onCloseAlert={() => { }}>
              {intl.getHTML('warning_date_number', { cField: intl.get('Document.prazo_revisao') })}
            </Alert>
          )
        },
        () => this.alertWrapper?.current?.scrollIntoView()
      );
      return;
    }
    if (!checkTime(oDocument.doc_etermunitliberation, oDocument.doc_ntermliberation, '2100-12-31')) {
      this.setState(
        {
          rcmpAlertMsg: (
            <Alert type="error" isOpen onCloseAlert={() => { }}>
              {intl.getHTML('warning_date_number', { cField: intl.get('Document.prazo_liberacao') })}
            </Alert>
          )
        },
        () => this.alertWrapper?.current?.scrollIntoView()
      );
      return;
    }

    this.setState({
      bIsSend: true
    });

    Axios.put(`document/edit/${oDocument.doc_nid}`, {
      nTermEssay: oDocument.doc_ntermessay,
      cTermUnitEssay: oDocument.doc_etermunitessay,
      nTermLiberation: oDocument.doc_ntermliberation,
      cTermUnitLiberation: oDocument.doc_etermunitliberation,
      nTermReview: oDocument.doc_ntermreview,
      cTermUnitReview: oDocument.doc_etermunitreview,
      ...(oDocument.doc_eversiontype === 'M' ? { cVersion: oVersion.ver_cnumber } : {}),
      nResponsibleId: Number(nResponsibleId),
      nWriterId: Number(nWriterId),
      nReviewerId: Number(nReviewerId),
      cVersionType: oDocument.doc_eversiontype,
      aSelected,
      aSelectedDistribution: aSelectedDistribution.map(oDistribution =>
        oDistribution.value ? oDistribution.value : oDistribution
      )
    })
      .then(() => {
        if (match) {
          setAlert('success', intl.get('DocumentEdit.alteracoes_sucesso'));
        } else {
          onSuccess(intl.get('DocumentEdit.alteracoes_sucesso'));
        }
        this.setState({
          bIsSend: false
        });
      })
      .catch(oError => {
        let cMessage = '';

        if (oError.response.data.cMessage != null) cMessage = oError.response.data.cMessage;
        else cMessage = oError.aMsgErrors;

        this.setState(
          {
            rcmpAlertMsg: (
              <Alert type="error" isOpen onCloseAlert={() => { }}>
                {cMessage}
              </Alert>
            )
          },
          () => this.alertWrapper?.current?.scrollIntoView()
        );
        this.setState({
          bIsSend: false
        });
      });
  };

  handleChangeCustom = (elSelectedOption, cType) => {
    this.setState({ [cType]: elSelectedOption.value });
  };

  handleChangeCustomMult = (elSelectedOption, cType) => {
    this.setState({
      [cType]: elSelectedOption.map(oOpt => oOpt.value)
    });
  };

  renderUsers = aUsers => {
    const rcmpUsers = aUsers.map(oUser => (
      <option key={oUser.user_nid} value={oUser.user_nid}>
        {oUser.user_cname}
      </option>
    ));
    return <>{rcmpUsers}</>;
  };

  onFocusSearch = () => {
    this.setState({ focused: true });
  };

  filterList = evtChange => {
    evtChange.preventDefault();

    this.setState({ focused: true });

    this.processFilterList(evtChange.target.value);
  };

  processFilterList = cSearchText => {
    const { aAvailableList } = this.state;

    let aUpdated = aAvailableList;

    aUpdated = aUpdated.filter(oItem => {
      return oItem.user_cname.toLowerCase().includes(cSearchText.toLowerCase());
    });

    this.setState({
      aFilteredUsers: aUpdated,
      cSearch: cSearchText
    });
  };

  addUser = oUser => {
    const { aAvailableList, aSelected } = this.state;
    let aNewaAvailableList = [...aAvailableList];

    if (oUser && oUser.team_nid !== null) {
      let aNewSelectedList = [...aSelected];
      let nIndex = -1;
      let aUser = [];
      let bShowWarning = false;

      aUser = this.getUsersRelatedToTeam(oUser.team_nid);

      for (let i = 0; i < aUser.length; i += 1) {
        nIndex = aNewaAvailableList.findIndex(oFilterItem => aUser[i].user_nid === oFilterItem.user_nid);
        if (nIndex !== -1) {
          aNewaAvailableList.splice(nIndex, 1);
        }
        nIndex = aNewSelectedList.findIndex(oSelectedItem => aUser[i].user_nid === oSelectedItem.user_nid);
        if (nIndex !== -1) {
          aNewSelectedList.splice(nIndex, 1);
          bShowWarning = true;
        }
      }

      nIndex = aNewaAvailableList.findIndex(oFilterItem => oUser.team_nid === oFilterItem.team_nid);
      aNewaAvailableList.splice(nIndex, 1);

      this.setState(
        {
          aAvailableList: [...aNewaAvailableList],
          aSelected: [...aNewSelectedList, oUser],
          bShowWarning
        },
        () => {
          this.processFilterList(this.state.cSearch);
        }
      );
    } else {
      const nIndex = aNewaAvailableList.findIndex(oFilterItem => oUser.user_nid === oFilterItem.user_nid);
      aNewaAvailableList.splice(nIndex, 1);
      this.setState(
        {
          aAvailableList: [...aNewaAvailableList],
          aSelected: [...aSelected, oUser]
        },
        () => {
          this.processFilterList(this.state.cSearch);
        }
      );
    }
  };

  removeUser = oUser => {
    const { aSelected, aAvailableList } = this.state;

    if (oUser && oUser.team_nid !== null) {
      let aNewAvailableList = [...aAvailableList];
      let aUser = [];

      aUser = this.getUsersRelatedToTeam(oUser.team_nid);

      aNewAvailableList = aNewAvailableList.concat(aUser);

      this.setState(
        {
          aSelected: aSelected.filter(oActualUser => oActualUser.team_nid !== oUser.team_nid),
          aAvailableList: [...aNewAvailableList, oUser],
          bShowWarning: false
        },
        () => {
          this.processFilterList(this.state.cSearch);
        }
      );
    } else {
      this.setState(
        {
          aSelected: aSelected.filter(oActualUser => oActualUser.user_nid !== oUser.user_nid),
          aAvailableList: [...aAvailableList, oUser]
        },
        () => {
          this.processFilterList(this.state.cSearch);
        }
      );
    }
  };

  getUsersRelatedToTeam = nTeamId => {
    const { aAvailable } = this.state;
    return aAvailable.filter(oAvailableItem => {
      if (oAvailableItem && oAvailableItem.nUserId !== null && oAvailableItem.aTeamsIds.includes(nTeamId)) {
        return true;
      }
      return false;
    });
  };

  getListImageSource = oItem => {
    let cStr = imgfGroup;
    if (oItem.user_cavatar) {
      cStr = oItem.user_cavatar
    } else if (oItem.user_nid !== null) {
      cStr = imgfNoPic;
    }
    return cStr;
  };

  renderList = aItems => {
    const { focused } = this.state;
    return (
      <OutsideHandlerClick handleClickOutside={this.closeSubmenu}>
        <div
          style={{ display: focused === true ? 'block' : 'none' }}
          className={`buscaBox ${aItems.length === 0 ? 'notfound' : ''}`}
          id="searchResult"
        >
          <ul className="list-group">
            {aItems.map(oItem => (
              <li
                key={oItem.user_nid ? `user-${oItem.user_nid}` : `team-${oItem.team_nid}`}
                className={`list-group-item ${!oItem.user_bregistered ? 'pending' : ''}`}
              >
                <img src={this.getListImageSource(oItem)} alt={oItem.user_nid !== null ? 'Usuário' : 'Equipe'} />
                <p className="infos">
                  <b>{oItem.user_cname}</b> <br /> {oItem.user_crole}
                </p>
                <span
                  role="button"
                  tabIndex="0"
                  onKeyPress={() => this.addUser(oItem)}
                  onClick={() => this.addUser(oItem)}
                  className="btn btn-primary"
                >
                  {intl.get('adicionar')}
                </span>
              </li>
            ))}
          </ul>
          {aItems.length === 0 && focused === true && (
            <div className="boxInvite">
              <p>{intl.get('DocumentEdit.sem_usuarios_equipes')}</p>
            </div>
          )}
        </div>
      </OutsideHandlerClick>
    );
  };

  closeSubmenu = (evt, elWrapperRef) => {
    const element = elWrapperRef;
    if (element.getElementsByClassName('buscaBox')[0]) {
      element.getElementsByClassName('buscaBox')[0].style = 'display: none';
      this.setState({ focused: false });
    }
  };

  getResponsible = (aSelectedUsers, nResponsibleId) => {
    let oUserResponsible = aSelectedUsers.find(oUserItem => oUserItem.user_nid == nResponsibleId);
    return oUserResponsible;
  };

  handleChangeDocumentResponsibles = (elSelectedOption, cResponsibleType) => {
    if (!cResponsibleType) return;

    this.setState({[cResponsibleType]: elSelectedOption.value})
  };

  render() {
    const {
      oDocument,
      oVersion,
      nResponsibleId,
      nWriterId,
      nReviewerId,
      aSelectedUsers,
      aFilteredUsers,
      aSelected,
      bIsSend,
      bLoading,
      cResponsibleName,
      rcmpAlertMsg,
      bAmResponsible,
      aUsers,
      bShowWarning,
      aTeams,
      aSelectedDistribution
    } = this.state;

    const { match, closeModal } = this.props;
    const cModule = 'documents';

    return (
      <PageClean loading={bLoading ? 1 : 0}>
        <div ref={this.alertWrapper} style={{ paddingTop: 5 }}>
          {rcmpAlertMsg}
        </div>
        <h2>{intl.get('DocumentEdit.documento')}</h2>
        <header id="inactive-header">
          <div className="info">
            <p>{oDocument.doc_ctitle}</p>
          </div>
        </header>

        <Form autoComplete="off" onSubmit={this.handleSubmit} className="form">
          <div className="row-responsible-title">
            <label className="label-responsible">
              {intl.get('Document.responsibles')}<span className="required">*</span>
            </label>
            <label className="label-term">
              {intl.get('Document.term')} ({intl.get('Document.days')})<span className="required">*</span>
            </label>
          </div>

          {can('admin', cModule) && (
            <div className="row-responsible">
              <div className="column-user-image">
                <i className="icon-editing" title={intl.get("Status.em-redacao")} />
                <div className="icon-vertical-line">&nbsp;</div>
              </div>
              <div className="column-user-responsible">
                <label>
                  {intl.get('Status.redaction')} <span className="required">*</span>
                  <Select
                    className="selectCustom"
                    classNamePrefix="react-select"
                    options={aUsers}
                    placeholder={intl.get('Document.writer_select')}
                    value={
                      nWriterId?
                        aUsers.filter(oUserItem => {
                          return nWriterId === oUserItem.value
                        })
                        : ''
                    }
                    maxMenuHeight={150}
                    onChange={elSelectedOption => this.handleChangeDocumentResponsibles(elSelectedOption, 'nWriterId')}
                    required
                  />
                </label>
              </div>
              <div className="column-user-term">
                <DateLimitation
                  cId="doc_ntermessay"
                  cName="doc_ntermessay"
                  bIsRequired
                  nMin={0}
                  fnHandleChange={this.inputChangeHandler}
                  cValue={String(oDocument.doc_ntermessay) || ''}
                  cDiffTime={'D'}
                />
              </div>
            </div>
          )}

          <div className="row-responsible">
            <div className="column-user-image">
              <i className="icon-comment" title={intl.get("Status.em-revisao")} />
              <div className="icon-vertical-line">&nbsp;</div>
            </div>
            <div className="column-user-responsible">
              <label>
                {intl.get('Status.review_capitalized')} <span className="required">*</span>
                <Select
                  className="selectCustom"
                  classNamePrefix="react-select"
                  options={aUsers}
                  placeholder={intl.get('Document.selecione_revisor')}
                  value={
                    nReviewerId?
                      aUsers.filter(oUserItem => {
                        return nReviewerId === oUserItem.value
                      })
                      : ''
                  }
                  maxMenuHeight={150}
                  onChange={elSelectedOption => this.handleChangeDocumentResponsibles(elSelectedOption, 'nReviewerId')}
                  required
                />
              </label>
            </div>
            <div className="column-user-term">
              <DateLimitation
                cId="doc_ntermreview"
                cName="doc_ntermreviewer"
                bIsRequired
                nMin={0}
                fnHandleChange={this.inputChangeHandler}
                cValue={String(oDocument.doc_ntermreview) || ''}
                cDiffTime={'D'}
              />
            </div>
          </div>

          {can('admin', cModule) && (
            <div className="row-responsible">
              <div className="column-user-image">
                <i className="icon-published" title={intl.get("Status.aguardando-publicacao")} />
              </div>
              <div className="column-user-responsible">
                <label>
                  {intl.get('Status.publishing_capitalized')} <span className="required">*</span>
                  {can('admin', cModule)? (<Select
                    className="selectCustom"
                    classNamePrefix="react-select"
                    options={aUsers}
                    placeholder={intl.get('Document.responsible_select')}
                    value={
                      nResponsibleId?
                        aUsers.filter(oUserItem => {
                          return nResponsibleId === oUserItem.value
                        })
                        : ''
                    }
                    maxMenuHeight={150}
                    onChange={elSelectedOption => this.handleChangeDocumentResponsibles(elSelectedOption, 'nResponsibleId')}
                    required
                  />)
                    : (
                      <Input
                        onChange={this.handleChange}
                        type="text"
                        value={cResponsibleName}
                        required
                        readOnly={true}
                        name="nResponsibleId"
                      />
                    )}
                </label>
              </div>
              <div className="column-user-term">
                <DateLimitation
                  cId="doc_ntermliberation"
                  cName="doc_ntermliberation"
                  bIsRequired
                  nMin={0}
                  fnHandleChange={this.inputChangeHandler}
                  cValue={String(oDocument.doc_ntermliberation) || ''}
                  cDiffTime={'D'}
                />
              </div>
            </div>
          )}

          <label className="split1">
            {intl.get('DocumentEdit.versionamento')} &nbsp;
            <small>{intl.get('DocumentEdit.versionamento_atencao')}</small>
            <Input
              onChange={this.handleChangeDoc}
              defaultValue={oDocument.doc_eversiontype}
              type="select"
              id="select-versioning"
              name="doc_eversiontype"
              className="half2"
            >
              <option value="A">{intl.get('DocumentEdit.versionamento_automatico')}</option>
              <option value="M">{intl.get('DocumentEdit.versionamento_manual')}</option>
            </Input>
          </label>
          <label
            className="split1"
            style={{ display: oDocument.doc_eversiontype === 'M' ? 'block' : 'none' }}
            id="version-manual"
          >
            {intl.get('DocumentEdit.versao')} <span className="required">*</span>
            <Input
              onChange={this.handleChangeVersion}
              value={oVersion.ver_cnumber || ''}
              maxLength={10}
              name="ver_cnumber"
              required
              type="text"
            />
          </label>

          <label className="split1">
            <span>{intl.get('DocumentEdit.distribution')}</span>
            <Select
              className="selectCustom"
              classNamePrefix="react-select"
              noOptionsMessage={() => intl.get('DocumentEdit.team_not_found')}
              isMulti
              defaultValue={aSelectedDistribution}
              options={aTeams}
              onChange={elSelectedOption => this.handleChangeCustomMult(elSelectedOption, 'aSelectedDistribution')}
              placeholder={intl.get('DocumentEdit.select_teams')}
              maxMenuHeight={100}
            />
          </label>

          <div className="containerTeam">
            <label>
              <b>{intl.get('DocumentPublish.busca')}</b>
              {bShowWarning && <span className="user-message">{intl.get('DocumentEdit.remove_users_message')}</span>}
              <Input
                type="text"
                id="busca"
                name="busca"
                maxLength="250"
                placeholder={intl.get('DocumentPublish.membros_equipe_placeholder')}
                onChange={this.filterList}
                onFocus={this.onFocusSearch}
                onClick={this.onFocusSearch}
              />
              {this.renderList(_.orderBy(aFilteredUsers, [oUser => oUser.user_cname.toLowerCase()], ['asc']))}
            </label>

            <div id="selectedUsers">
              {aSelected.map(oUser => (
                <span
                  key={oUser.user_nid ? `u-${oUser.user_nid}` : `e-${oUser.team_nid}`}
                  className={`userBox ${!oUser.user_bregistered ? 'pending' : ''}`}
                >
                  <span
                    role="button"
                    tabIndex="0"
                    onKeyPress={() => this.removeUser(oUser)}
                    onClick={() => this.removeUser(oUser)}
                    className="removeUser"
                  >
                    {intl.get('remover')}
                  </span>
                  <img src={this.getListImageSource(oUser)} alt={oUser.user_nid !== null ? 'Usuário' : 'Equipe'} />

                  <p className="user_cname" title={oUser.user_cname}>
                    {oUser.user_cname}
                  </p>
                </span>
              ))}
            </div>
          </div>

          <div className="button-stack">
            <Button
              type="button"
              onClick={match !== undefined ? () => window.history.back() : closeModal}
              className="btn btn-cancel"
            >
              {intl.get('cancel')}
            </Button>
            <Button disabled={bIsSend} name="action" type="submit" className="btn">
              {intl.get('salvar')}
            </Button>
          </div>
        </Form>
      </PageClean>
    );
  }
}

export default DocumentEdit;
