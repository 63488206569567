export function validarCNPJ(cCNPJ) {
  cCNPJ = cCNPJ.replace(/[^\d]+/g, '');

  if (cCNPJ === '') return false;

  if (cCNPJ.length !== 14) return false;

  // Elimina CNPJs invalidos conhecidos
  if (
    cCNPJ === '00000000000000' ||
    cCNPJ === '11111111111111' ||
    cCNPJ === '22222222222222' ||
    cCNPJ === '33333333333333' ||
    cCNPJ === '44444444444444' ||
    cCNPJ === '55555555555555' ||
    cCNPJ === '66666666666666' ||
    cCNPJ === '77777777777777' ||
    cCNPJ === '88888888888888' ||
    cCNPJ === '99999999999999'
  )
    return false;

  // Valida DVs
  let nSize = cCNPJ.length - 2;
  let cNumbers = cCNPJ.substring(0, nSize);
  const cDigits = cCNPJ.substring(nSize);
  let nSum = 0;
  let nPos = nSize - 7;
  for (let i = nSize; i >= 1; i--) {
    nSum += cNumbers.charAt(nSize - i) * nPos--;
    if (nPos < 2) nPos = 9;
  }
  let nResult = nSum % 11 < 2 ? 0 : 11 - (nSum % 11);

  if (String(nResult) !== cDigits.charAt(0)) return false;

  nSize += 1;
  cNumbers = cCNPJ.substring(0, nSize);
  nSum = 0;
  nPos = nSize - 7;
  for (let i = nSize; i >= 1; i--) {
    nSum += cNumbers.charAt(nSize - i) * nPos--;
    if (nPos < 2) nPos = 9;
  }
  nResult = nSum % 11 < 2 ? 0 : 11 - (nSum % 11);
  if (String(nResult) !== cDigits.charAt(1)) return false;

  return true;
}

export function validarCPF(cCPF) {
  cCPF = cCPF.replace(/[^\d]+/g, '');
  if (cCPF === '') return false;
  // Elimina CPFs invalidos conhecidos

  if (
    cCPF.length !== 11 ||
    cCPF === '00000000000' ||
    cCPF === '11111111111' ||
    cCPF === '22222222222' ||
    cCPF === '33333333333' ||
    cCPF === '44444444444' ||
    cCPF === '55555555555' ||
    cCPF === '66666666666' ||
    cCPF === '77777777777' ||
    cCPF === '88888888888' ||
    cCPF === '99999999999'
  )
    return false;

  // Valida 1o digito
  let nAdd = 0;
  for (let i = 0; i < 9; i++) nAdd += parseInt(cCPF.charAt(i)) * (10 - i);
  let nRev = 11 - (nAdd % 11);

  if (nRev === 10 || nRev === 11) nRev = 0;
  if (nRev !== parseInt(cCPF.charAt(9))) return false;

  // Valida 2o digito
  nAdd = 0;
  for (let i = 0; i < 10; i++) nAdd += parseInt(cCPF.charAt(i)) * (11 - i);
  nRev = 11 - (nAdd % 11);
  if (nRev === 10 || nRev === 11) nRev = 0;
  if (nRev !== parseInt(cCPF.charAt(10))) return false;
  return true;
}
