import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { Button } from 'reactstrap';
import Select from 'react-select';
import Page from '../default-v2/Page';
import Breadcrumb from '../../components/Breadcrumbs';
import userImg from '../../assets/img/users.svg';
import CardHeader from '../default-v2/CardHeader';
import Axios from '../../config/Axios';
import imgfSelectDown from '../default-v2/img/select-down.svg';
import imgfSelectUp from '../default-v2/img/select-up.svg';
import userConfigImg from '../../assets/img/user-config-blue.svg';
import successImg from '../../assets/img/success.svg';
import 'moment/locale/pt';
import { can, canAtLeast } from '../../config/Permissions';
import { formatDateToUserTZ } from '../../utils/time';

const MigrateResponsibilityOperational = () => {
  const [bIsLoading, setBIsLoading] = useState(true);
  const [oAuditsRequisites, setOAuditsRequisites] = useState([]);
  const [oFilterOrder, setOFilterOrder] = useState({ cOrderBy: 'cName', cType: 'asc' });
  const [bIsShowingModal, setBIsShowingModal] = useState(false);
  const [aSelectedItems, setASelectedItems] = useState([]);
  const [aFilteredOptions, setAFilteredOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');
  const [cPassword, setCPassword] = useState('');
  const [cUserError, setCUserError] = useState('');
  const [cPasswordError, setCPasswordError] = useState('');
  const [bIsShowingSuccessModal, setBIsShowingSuccessModal] = useState(false);

  const aPages = [
    { cName: 'user', cLink: '/user' },
    { cName: 'User.audits', cLink: '/audit-management' },
    { cName: 'User.migrate_responsibilities', cLink: undefined }
  ];
  const rcmpBreadcrumb = <Breadcrumb aPages={aPages} />;

  const rcmpCardHeader = <CardHeader bIsItToInsertGoBackBtn windowHistory />;

  const cCurrentUrl = window.location.href;
  const nUserId = cCurrentUrl.substring(cCurrentUrl.lastIndexOf('/') + 1);

  useEffect(() => {
    if (!can('admin')) {
      window.location.href = '/';
    }
    Axios.get(`/audit/get-responsibilities/${nUserId}`).then(oResponse => {
      setOAuditsRequisites(oResponse.data);
      setBIsLoading(false);
    });
  }, []);

  useEffect(() => {
    Axios.get(`/user/all`).then(oResponse => {
      const aUserOptions = oResponse.data.aUsers
        .filter(item => item.user_nid !== Number(nUserId))
        .map(oUser => ({ value: oUser.user_nid, label: oUser.user_cname }));

      setAFilteredOptions(aUserOptions);
    });
  }, []);

  const cOrderType = oFilterOrder.cType === 'asc' ? 'desc' : 'asc';

  let cIcon = imgfSelectDown;
  if (cOrderType === 'asc') {
    cIcon = imgfSelectUp;
  }

  function setFilter(cOrder, cType) {
    if (cOrder === 'cName') {
      const sortedItems = oAuditsRequisites.sort((a, b) => {
        if (!a.req_cname || !b.req_cname) {
          return 0;
        }
        if (cType === 'asc') {
          return a.req_cname.localeCompare(b.req_cname);
        }
        if (cType === 'desc') {
          return b.req_cname.localeCompare(a.req_cname);
        }
        return 0;
      });

      setOAuditsRequisites(sortedItems);
      setOFilterOrder({ cOrderBy: 'cName', cType });
    } else if (cOrder === 'cLocal') {
      const sortedItems = oAuditsRequisites.sort((a, b) => {
        if (!a.local_audit?.locl_cfantasyname || !b.local_audit?.locl_cfantasyname) {
          return 0;
        }
        if (cType === 'asc') {
          return a.local_audit?.locl_cfantasyname.localeCompare(b.local_auditw.locl_cfantasyname);
        }
        if (cType === 'desc') {
          return b.local_audit?.locl_cfantasyname.localeCompare(a.local_audit?.locl_cfantasyname);
        }
        return 0;
      });

      setOAuditsRequisites(sortedItems);
      setOFilterOrder({ cOrderBy: 'cLocal', cType });
    } else if (cOrder === 'cResponsibility') {
      const sortedItems = oAuditsRequisites.sort((a, b) => {
        const aHasAuditors = a?.audit_auditors.filter(item => item.user_nid !== Number(nUserId)).length > 0;
        const aHasResponsibles = a?.audit_responsibles.filter(item => item.user_nid !== Number(nUserId)).length > 0;
        const bHasAuditors = b?.audit_auditors.filter(item => item.user_nid !== Number(nUserId)).length > 0;
        const bHasResponsibles = b?.audit_responsibles.filter(item => item.user_nid !== Number(nUserId)).length > 0;

        if (cType === 'asc') {
          return aHasAuditors || aHasResponsibles ? 1 : -1;
        }
        if (cType === 'desc') {
          return aHasAuditors || aHasResponsibles ? -1 : 1;
        }

        return 0;
      });
      setOAuditsRequisites(sortedItems);
      setOFilterOrder({ cOrderBy: 'cResponsibility', cType });
    }
    localStorage.setItem('cConfig', JSON.stringify(oFilterOrder));
  }

  function showModal() {
    setBIsShowingModal(true);
  }

  function handleCloseModal() {
    setBIsShowingModal(false);
    setBIsShowingSuccessModal(false);
  }

  function handleCloseSuccessModal() {
    setBIsShowingSuccessModal(false);
    window.location.reload();
  }

  function handleMigration() {
    setCUserError('');
    setCPasswordError('');
    if (!selectedOption) {
      setCUserError(intl.get('FolderIndex.no_user_selected'));
      return;
    }
    if (!cPassword) {
      setCPasswordError(intl.get('FolderIndex.incorrect_password'));
      return;
    }
    if (aSelectedItems.length < 1) {
      setCUserError(intl.get('FolderIndex.no_document_selected'));
      return;
    }

    const aItems = aSelectedItems.map(index => oAuditsRequisites[index]);

    Axios.post(`/audit/migrate-responsibilities/${nUserId}`, {
      aItems,
      nNewUser: selectedOption.value,
      cPassword
    })
      .then(oResponse => {
        setBIsShowingModal(false);
        setBIsShowingSuccessModal(true);
      })
      .catch(error => {
        setCPasswordError(error.response.data.cMessage);
      });
  }

  const handleCheckboxClick = event => {
    const nId = event.target.value;
    if (event.target.checked) {
      setASelectedItems([...aSelectedItems, nId]);
    } else {
      setASelectedItems(aSelectedItems.filter(id => id !== nId));
    }
  };

  const handleCheckAll = event => {
    const checkboxes = document.getElementsByName('document-checkbox');
    const newCheckedIds = [];

    checkboxes.forEach(checkbox => {
      checkbox.checked = event.target.checked;
      if (checkbox.checked) {
        newCheckedIds.push(checkbox.value);
      }
    });

    setASelectedItems(newCheckedIds);
  };

  const handleChange = value => {
    setSelectedOption(value);
  };

  const handlePassword = event => {
    setCPassword(event.target.value);
  };

  const fnHaveUniqueResponsible = setOAuditsRequisite => {
    return setOAuditsRequisite?.audit_auditors.filter(item => item.user_nid !== Number(nUserId)).length > 0 ||
      setOAuditsRequisite?.audit_responsibles.filter(item => item.user_nid !== Number(nUserId)).length > 0
      ? intl.get('nao')
      : intl.get('sim');
  };
  const fnUniqueResponsibleHint = setOAuditsRequisite => {
    if (fnHaveUniqueResponsible(setOAuditsRequisite) === intl.get('sim')) {
      return intl.get('MigrationBoard.unique_migration_hint');
    }
    const cRole = setOAuditsRequisite.audit_responsibles.some(item => item.responsible === true)
      ? intl.get('responsible')
      : intl.get('DashboardAudit.auditor');
    return intl.get('MigrationBoard.not_unique_migration_hint', { cRole });
  };

  return (
    <div>
      <Page
        loading={bIsLoading ? 1 : 0}
        rcmpBreadcrumb={rcmpBreadcrumb}
        cTitle={intl.get('User.migrate_responsibilities')}
        cImage={userImg}
        rcmpCardHeader={rcmpCardHeader}
        className="v2-document"
        cCurrentSideMenuOption={intl.get('Nav.document')}
      >
        <div className="filterList noselect">
          <span role="button" tabIndex="0" className="alt-checkbox-v2">
            <input type="checkbox" onClick={handleCheckAll} />
          </span>{' '}
          <span
            role="button"
            tabIndex="0"
            onClick={() => setFilter('cName', cOrderType)}
            onKeyPress={() => setFilter('cName', cOrderType)}
            className={`filterByName-v2 ${oFilterOrder.cOrderBy === 'cName' ? 'active' : ''}`}
          >
            {intl.get('Audit.norma_label')}
            {oFilterOrder.cOrderBy === 'cName' && (
              <img className="folderindex-list-icon" src={cIcon} alt={cOrderType} />
            )}
          </span>
          <span
            role="button"
            tabIndex="0"
            onClick={() => setFilter('cType', cOrderType)}
            onKeyPress={() => setFilter('cType', cOrderType)}
            className={`filterByPublish ${oFilterOrder.cOrderBy === 'cType' ? 'active' : ''}`}
          >
            {intl.get('FolderIndex.responsibilities')}
            {oFilterOrder.cOrderBy === 'cType' && (
              <img className="folderindex-list-icon" src={cIcon} alt={cOrderType} />
            )}
          </span>
          <span
            role="button"
            tabIndex="0"
            onClick={() => setFilter('cLocal', cOrderType)}
            onKeyPress={() => setFilter('cLocal', cOrderType)}
            className={`filterByPublish ${oFilterOrder.cOrderBy === 'cLocal' ? 'active' : ''}`}
          >
            {intl.get('Audit.audit_location')}
            {oFilterOrder.cOrderBy === 'cLocal' && (
              <img className="folderindex-list-icon" src={cIcon} alt={cOrderType} />
            )}
          </span>
          <span
            role="button"
            tabIndex="0"
            onClick={() => setFilter('cResponsibility', cOrderType)}
            onKeyPress={() => setFilter('cResponsibility', cOrderType)}
            className={`filterByPublish ${oFilterOrder.cOrderBy === 'cResponsibility' ? 'active' : ''}`}
            style={{ width: '21%' }}
          >
            {intl.get('PermissionGroup.sole_responsible')}
            {oFilterOrder.cOrderBy === 'cResponsibility' && (
              <img className="folderindex-list-icon" src={cIcon} alt={cOrderType} />
            )}
          </span>
          <span role="button" tabIndex="0" className={`filterByDue `} style={{ width: '10%' }}>
            {intl.get('Audit.periodo_label')}
          </span>
        </div>
        <div>
          <ul style={{ display: 'block' }} className="document-list list-shadow list-view">
            {!bIsLoading &&
              oAuditsRequisites.map((setOAuditsRequisite, i) => (
                <li role="presentation">
                  <>
                    <h2 className="alt-checkbox-v2 ">
                      <input type="checkbox" name="document-checkbox" value={i} onClick={handleCheckboxClick} />
                    </h2>
                    <h2 className="filterByName-v2" title={setOAuditsRequisite?.norm_cinitials}>
                      {setOAuditsRequisite?.norm?.norm_cinitials}
                    </h2>

                    <p className="filterByPublish">
                      {setOAuditsRequisite?.audit_responsibles?.some(item => item.responsible === true)
                        ? intl.get('responsible')
                        : null}
                      {setOAuditsRequisite?.audit_responsibles?.some(item => item.responsible === true) &&
                        setOAuditsRequisite?.auditAuditors?.some(item => item.auditor === true)
                        ? ' / '
                        : ''}
                      {setOAuditsRequisite?.auditAuditors?.some(item => item.auditor === true)
                        ? intl.get('DashboardAudit.auditor')
                        : null}
                    </p>

                    <p className="filterByPublish">{setOAuditsRequisite?.local_audit?.locl_cfantasyname}</p>

                    <p
                      className="filterByPublish"
                      title={fnUniqueResponsibleHint(setOAuditsRequisite)}
                      style={{ width: '17%' }}
                    >
                      {fnHaveUniqueResponsible(setOAuditsRequisite)}
                    </p>

                    <p className="filterByExecutionDate">
                      {formatDateToUserTZ(setOAuditsRequisite?.audi_dexecutionbegin, 'LL')}
                      {' - '}
                      {formatDateToUserTZ(setOAuditsRequisite?.audi_dexecutionend, 'LL')}
                    </p>
                  </>
                </li>
              ))}
          </ul>
          {bIsShowingModal && (
            <div className="docusign-modal-overlay">
              <div className="docusign-modal">
                <div className="docusign-header-modal">
                  <h3>{intl.get('User.migrate_responsibilities')}</h3>
                  <span className="docusign-close" onClick={handleCloseModal}>
                    &times;
                  </span>
                </div>
                <div className="docusign-modal-body">
                  <div className="icon-container">
                    <img className="block-title-icon" src={userConfigImg} />
                  </div>

                  <div className="docusign-modal-text">
                    <div className="margin-bottom-30">
                      <form autoComplete="off">
                        <label style={{ fontWeight: '700' }}>{intl.get('FolderIndex.new_responsible')}</label>
                        {cUserError && <p style={{ color: '#FF3541', fontSize: '12px' }}>{cUserError}</p>}
                        <label className="split1 colspan ">
                          <Select
                            className="selectCustom select"
                            classNamePrefix="react-select"
                            noOptionsMessage={() => intl.get('sem_opcoes')}
                            placeholder={`${intl.get('selecione')}...`}
                            isClearable
                            isSearchable
                            options={aFilteredOptions}
                            maxMenuHeight={130}
                            defaultValue={selectedOption}
                            onChange={handleChange}
                          />
                        </label>
                      </form>
                    </div>
                    <div>
                      <label style={{ fontWeight: '700' }}>{intl.get('FolderIndex.digit_password')}</label>
                      <div className="input-select ">
                        <input
                          type="password"
                          className={cPasswordError ? 'red-border' : ''}
                          value={cPassword}
                          onChange={handlePassword}
                        />
                      </div>
                      {cPasswordError && <p style={{ color: '#FF3541', fontSize: '12px' }}>{cPasswordError}</p>}
                    </div>
                  </div>
                </div>
                <div className="button-container">
                  <button className="btn alternate" onClick={handleCloseModal}>
                    {intl.get('cancel')}
                  </button>
                  <button className="btn" onClick={handleMigration}>
                    {intl.get('FolderIndex.migrate')}{' '}
                  </button>
                </div>
              </div>
            </div>
          )}
          {bIsShowingSuccessModal && (
            <div className="docusign-modal-overlay">
              <div className="docusign-modal">
                <div className="docusign-header-modal">
                  <h3>{intl.get('User.migrate_responsibilities')}</h3>
                  <span className="docusign-close" onClick={handleCloseSuccessModal}>
                    &times;
                  </span>
                </div>
                <div className="docusign-modal-body">
                  <div className="icon-container">
                    <img className="block-title-v2" src={successImg} />
                  </div>
                  <div className="docusign-modal-text align-center" style={{ fontWeight: '700' }}>
                    {intl.get('FolderIndex.success_migration')}
                  </div>
                </div>
                <div className="button-container align-center">
                  <button className="btn" onClick={handleCloseSuccessModal}>
                    {intl.get('close')}
                  </button>
                </div>
              </div>
            </div>
          )}
          <div className="button-stack">
            <Button type="button" className="btn-cancel" onClick={() => window.history.back()}>
              {intl.get('cancelar')}
            </Button>

            <Button
              type="submit"
              className="btn break-line-btn"
              disabled={aSelectedItems.length < 1}
              onClick={() => showModal()}
            >
              {intl.get('FolderIndex.migrate')} <br /> {intl.get('FolderIndex.selecteds')}
            </Button>
          </div>
        </div>
      </Page>
    </div>
  );
};

export default MigrateResponsibilityOperational;
