import axios from 'axios';
import intl from 'react-intl-universal';
import React, { Component } from 'react';
import { Button, Input } from 'reactstrap';
import imgfLogo from '../../assets/img/logo.png';
import { InputPassword } from '../../components/InputPassword';
import { signIn } from '../../config/Authentication';
import SweetAlert from 'react-bootstrap-sweetalert';

class Login extends Component {
  constructor(oProps) {
    super(oProps);

    document.body.classList.add('w-bkg');

    this.state = {
      cEmail: '',
      cPassword: '',
      bHasAlertError: false,
      cErrorMessage: '',
      bIsSend: false,
      rcmpAlert: ''
    };
  }

  componentDidMount() {
    const cValue = document.getElementById('root').dataset.logout;
    const cMessage = document.getElementById('root').dataset.message;

    if (cValue === '1') {
      this.setState({
        rcmpAlert: (
          <SweetAlert
            warning
            confirmBtnText={intl.get('confirmar')}
            title={intl.get('Login.logout')}
            onConfirm={() => {
              this.setState({
                rcmpAlert: ''
              });
            }}
            onCancel={this.hideAlert}
          >
            {cMessage !== undefined ? cMessage : intl.get('Login.logout_message')}
          </SweetAlert>
        )
      });
    }
  }

  closeAlert = () => {
    this.setState({
      cErrorMessage: '',
      bHasAlertError: false
    });
  };

  handleChange = evt => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  handleSubmit = evt => {
    evt.preventDefault();
    const { bIsSend } = this.state;
    if (bIsSend) {
      return;
    }

    this.setState({
      bIsSend: true
    });
    const { cEmail, cPassword } = this.state;
    const sHash = localStorage.getItem('sHash');
    const share_link = localStorage.getItem('share_link');

    axios
      .post('/login', {
        cEmail,
        cPassword,
        sHash
      })
      .then(oResponse => {
        if (oResponse.status === 200) {
          const oRegExpression = /requisite-audit\/[0-9]+\/[0-9]+/;
          const cUrl = window.location.pathname;
          signIn(oResponse.data.signInResult, true);
          document.body.classList.remove('w-bkg');

          if (sHash && oResponse.data.cReturnData) {
            window.location.href = share_link;
            localStorage.removeItem('sHash');

          } else if (!oRegExpression.test(cUrl)) {
            if (oResponse.data.bIsConsultant && !localStorage.getItem("automationUrl")) {
              window.location.href = '/quality-panel-consultant/?initial=1';
            } else if (localStorage.getItem("automationUrl")) { //redirect to automation page
              const automationUrl = localStorage.getItem("automationUrl");
              console.log(automationUrl);
              window.location.href = automationUrl;
              localStorage.removeItem("automationUrl");
            } else {
              window.location.href = '/';
            }
          } else {
            window.location.reload();
          }
        }
      })
      .catch(oError => {
        console.log(oError);
        let cErrorMessage = '';

        if (oError.message === 'Network Error') {
          cErrorMessage = oError.message;
        } else if (oError.response?.status === 307) {
          window.location = '/database/creating';
        } else if (oError.response?.status >= 500) {
          cErrorMessage = intl.get('erro_500');
        } else if (oError.response?.status === 403) {
          cErrorMessage = oError.response.data.message;
        } else {
          // eslint-disable-next-line no-restricted-syntax
          for (const cField in oError.response.data.errors) {
            if (typeof oError.response.data.errors[cField] === 'string') {
              cErrorMessage += oError.response.data.errors[cField];
            } else {
              cErrorMessage += oError.response.data.errors[cField].map(oDataError => oDataError);
            }
          }
        }

        this.setState({
          cErrorMessage: cErrorMessage,
          bHasAlertError: true
        });
      }).finally(() => {
        this.setState({
          bIsSend: false
        });
      });
  };

  render() {
    const { rcmpAlert, cEmail, cPassword, bIsSend, bHasAlertError, cErrorMessage } = this.state;
    return (
      <>
        {rcmpAlert}
        <section id="register">
          <div className="wrapper-login">
            <header>
              <div className="logo">
                <img src={imgfLogo} alt="Acredite.se" />
              </div>
              <h1>{intl.get('Login.login')}</h1>
            </header>
            <main className="form">
              <form className="form-horizontal" onSubmit={this.handleSubmit}>
                <Input
                  value={cEmail}
                  onChange={this.handleChange}
                  maxLength="250"
                  className="form-control"
                  placeholder={intl.get('cemail')}
                  id="cEmail"
                  required
                  autoFocus=""
                  name="cEmail"
                  type="email"
                  tabIndex="0"
                />
                <InputPassword value={cPassword} onChange={this.handleChange} tabIndex="0" />
                <p className="sc-login">
                  <a href="/reset-password">{intl.get('Login.esqueci_senha')}</a>
                </p>
                <br />
                <br />
                <p className="sc-login">
                  {intl.get('Login.sem_conta')}
                  <a href="/pre-register/">{intl.get('Login.teste_gratis')}</a>
                </p>

                <Button className={bIsSend ? 'loading disabled' : ''} type="submit" tabIndex="0">
                  {intl.get('Login.entrar')}
                </Button>
                {bHasAlertError && (
                  <div className="alert error">
                    <div dangerouslySetInnerHTML={{ __html: cErrorMessage }} />
                    <span
                      aria-labelledby="alert"
                      title="Fechar"
                      onClick={this.closeAlert}
                      onKeyPress={this.closeAlert}
                      className="close"
                      role="button"
                      // não consegui entender muito bem, mas é interessante pro futuro: https://github.com/GoogleChrome/accessibility-developer-tools/wiki/Audit-Rules#tabindex-usage
                      tabIndex="1"
                    />
                  </div>
                )}
              </form>
            </main>
          </div>
        </section>
      </>
    );
  }
}

export default Login;
