import { isExpired, decodeToken } from "react-jwt";

/** chave do localStorage onde o token é armazenado */
const C_TOKEN_LS_KEY = "cStorageToken";

class EncodedLocalStorage {
    static oStorage = {};

    constructor() {
        if (localStorage.getItem(C_TOKEN_LS_KEY) === null) {
            this.destroy();
        } else {
            this.decodeToken();
        }
    }

    /**
     * Decodifica a payload do token no localStorage, se for válido, e permite acessar seus valores nessa classe.
     */
    decodeToken = () => {
        const cStorageToken = localStorage.getItem(C_TOKEN_LS_KEY);
        const oDecodedToken = decodeToken(cStorageToken);
        if (isExpired(cStorageToken)) return this.destroy();
        EncodedLocalStorage.oStorage = oDecodedToken;
    }

    /**
     * @param {string} cAttribute Nome do atributo a ser retornado
     * @returns {any|null} Retorna o valor do atributo, ou null se não existir
     */
    get = (cAttribute) => {
        return EncodedLocalStorage.oStorage[cAttribute] ?? null;
    }

    /**
     * Sobrescreve temporariamente a propriedade no objeto decodificado.
     * Alterações serão descartadas ao decodificar novamente o token.
     * @param {string} cAttribute
     * @param {any} varValue
     */
    set = (cAttribute, varValue) => {
        EncodedLocalStorage.oStorage[cAttribute] = varValue;
    }

    /**
     * Exclui os dados do objeto atual e remove o token do localStorage.
     */
    destroy = () => {
        EncodedLocalStorage.oStorage = {};
        localStorage.removeItem(C_TOKEN_LS_KEY);
    }
}
export default EncodedLocalStorage;

const oLocalStorage = new EncodedLocalStorage();

export { EncodedLocalStorage, oLocalStorage };