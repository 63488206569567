import React, { Component } from 'react';
import { Route, NavLink } from 'react-router-dom';
import intl from 'react-intl-universal';
import withAlert from '../../components/withAlert';
import Customer from '../customer/Customer';
import ConfigHeaderFooter from '../document/ConfigHeaderFooter';
import ExtractIndex from '../financial/ExtractIndex';
import Breadcrumb from '../../components/Breadcrumbs';
import Page from '../default-v2/Page';
import { can, canAtLeast } from '../../config/Permissions';
import { Alert } from '../../components/Alert';

class Configurations extends Component {
  constructor(oProps) {
    super(oProps);

    this.state = {
      aPages: [{ cName: 'MyAccount.titulo_tab4', cLink: '/settings' }]
    };
  }

  render() {
    const { match, setAlert, history } = this.props;
    const { path } = match;
    const { aPages } = this.state;
    return (
      <Page loading={0}>
        {canAtLeast(['edit-customer', 'access-plan', 'admin']) ? (
          <>
            <h1>{intl.get('MyAccount.titulo_tab4')}</h1>
            <Breadcrumb aPages={aPages} />

            <div className="containerTabs">
              <div className="leftNav">
                <ul>
                  {can('edit-customer') && (
                    <li>
                      <NavLink to={`${path}customer/update/`} className="link">
                        {intl.get('Nav.informacoes_cadastrais')}
                      </NavLink>
                    </li>
                  )}
                  {can('access-plan') && (
                    <li>
                      <NavLink to={`${path}plan`} className="link">
                        {intl.get('Nav.planos')}
                      </NavLink>
                    </li>
                  )}
                  {can('admin') && (
                    <li>
                      <NavLink to={`${path}document/configure`} className="link">
                        {intl.get('Nav.config_footer')}
                      </NavLink>
                    </li>
                  )}

                  {can('admin') && (
                    <li>
                      <NavLink to={`${path}extract`} className="link">
                        {intl.get('Nav.extrato_financeiro')}
                      </NavLink>
                    </li>
                  )}
                </ul>
              </div>
              <div className="tabsContent">
                {can('edit-customer') && (
                  <Route
                    exact
                    path={`${match.path}customer/update/`}
                    render={oProps => <Customer props={oProps} setAlert={setAlert} bCanCancel history={history} />}
                  />
                )}
                {can('edit-customer') && (
                  <Route
                    exact
                    path={`${match.path}client/update/`}
                    render={oProps => <Customer props={oProps} setAlert={setAlert} bCanCancel history={history} />}
                  />
                )}
                {can('admin') && (
                  <Route exact path={`${match.path}document/configure`} component={withAlert(ConfigHeaderFooter)} />
                )}

                {can('admin') && <Route exact path={`${match.path}extract/`} component={withAlert(ExtractIndex)} />}
              </div>
            </div>
          </>
        ) : (
          <Alert type="error" isOpen onCloseAlert={() => {}}>
            {intl.get('Configurations.sem_permissao')}
          </Alert>
        )}
      </Page>
    );
  }
}

export default Configurations;
