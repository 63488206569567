import React, { useState } from 'react';
import Page from '../default/Page';
import intl from 'react-intl-universal';
import Axios from '../../config/Axios';
import { Alert } from '../../components/Alert';
//import React, { Component } from 'react';

const NormDuplicate = ({ oNormDuplicate, closeModal, onSuccess, onError }) => {
  const [bIsSending, setBIsSending] = useState(false);
  const [cInitials, setCInitials] = useState(oNormDuplicate ? oNormDuplicate.norm_cinitials : '');
  const [cName, setCName] = useState(oNormDuplicate ? oNormDuplicate.norm_cname : '');
  const [alertMessage, setAlertMessage] = useState('');

  const handleDuplicate = () => {
    if (bIsSending) {
      return; // Impede que o botão seja clicado novamente se já estiver em processo de envio
    }

    setBIsSending(true);

    const aData = {
      norm_cinitials: cInitials,
      norm_cname: cName
    };
    Axios.post('norm/checkDuplicated', aData)
      .then(oResponse => {
        if (oResponse.data.error) {
          setBIsSending(false);
          setAlertMessage(`${oResponse.data.error}`);
        } else {
          setAlertMessage('');
          handleSubmit();
        }
      })
      .catch(oError => {
        setAlertMessage('Erro: Todos os campos são obrigatórios e devem ser preenchidos corretamente.');
        setBIsSending(false);
      });
  };

  const handleSubmit = evtClick => {
    if (evtClick) {
      evtClick.preventDefault();
    }
    setBIsSending(true);

    const aData = {
      nId_norm: oNormDuplicate.norm_nid,
      norm_cinitials: cInitials,
      norm_cname: cName
    };

    // Utiliza o Axios para enviar as informações para o endpoint
    Axios.post('norm/duplicated', aData)
      .then(() => {
        // Chama a função onSuccess passada como propriedade para tratar o sucesso do envio
        if (onSuccess) {
          onSuccess(intl.get('QualityNormModal.duplicar_sucesso'));
        }
      })
      .catch(() => {
        // Chama a função onError passada como propriedade para tratar o erro do envio
        if (onError) {
          onError(intl.get('QualityNormModal.duplicar_erro'));
        }
      })
      .finally(() => {
        setBIsSending(false); // Permite que o botão seja clicado novamente após a resposta do servidor
      });
  };

  return (
    <Page>
      <form onSubmit={handleSubmit} className="form">
        <div style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.5rem' }}>
          {intl.getHTML('QualityNormModal.norm_name_duplicate')} {oNormDuplicate.norm_cinitials}.
        </div>
        <div style={{ fontWeight: 'bold', textAlign: 'center' }}>{intl.getHTML('QualityNormModal.texto_duplicar_norma')} {oNormDuplicate.norm_cinitials}?</div>
        <br />
        {alertMessage && (
          <div style={{ paddingTop: 5 }} className="norm-alert">
            <Alert type="error" isOpen onCloseAlert={() => { }}>
              {alertMessage}
            </Alert>
          </div>
        )}
        <div style={{ fontWeight: 'bold', textAlign: 'left' }}>
          <label className="split1">
            {intl.get('Norm.nome_da_norma_sigla')} <span className="required">*</span>
            <input
              onChange={evtChange => setCInitials(evtChange.target.value)}
              //onBlur={handleBlur}
              id="cInitials"
              name="cInitials"
              maxLength="25"
              value={cInitials || ''}
              required
              type="text"
              className="form-control"
            />
          </label>

          <label className="split1">
            {intl.get('Norm.descricao')}
            <span className="required">*</span>
            <input
              onChange={evtChange => setCName(evtChange.target.value)}
              id="cName"
              name="cName"
              maxLength="100"
              value={cName || ''}
              required
              type="text"
              className="form-control"
            />
          </label>
        </div>
        <div className="button-stack" style={{ justifyContent: 'center', marginTop: '20px' }}>
          <button type="button" onClick={() => closeModal()} className="btn alternate back btn btn-outline-secondary">
            {intl.get('cancelar')}
          </button>
          <button
            type="button"
            onClick={handleDuplicate}
            title={intl.get('QualityPanelIndex.duplicar')}
            className={`btn btn-secondary ${bIsSending ? 'disabled' : ''}`}
            disabled={bIsSending}
          >
            {intl.get('QualityPanelIndex.duplicar')}
          </button>
        </div>
      </form>
    </Page>
  );

};

export default NormDuplicate;
