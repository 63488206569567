import Axios from '../config/Axios';
import loadBreadcrumb from './ActionBreadcrumb';

export function confirmReading(nVersionId) {
  return Axios.post('/version/read', { ver_nid: nVersionId });
}

export function fileDocument(nDocumentId) {
  return Axios.put(`/file/${nDocumentId}`);
}

export function getDocumentDetails(nVersionId) {
  return Axios.get(`document/detail/${nVersionId}`).then(
    oResponse =>
      new Promise((resolve, reject) => {
        loadBreadcrumb(oResponse.data.oDocument.fldr_nid)
          .then(aBreadcrumbFolders => {
            resolve({
              ...oResponse.data,
              oDocument: oResponse.data.oDocument,
              oVersion: oResponse.data.oVersion,
              bCanEditInFolder: oResponse.data.bCanEditInFolder,
              bDocActive: oResponse.data.bDocActive,
              aBreadcrumbFolders
            });
          })
          .catch(oError => reject(oError));
      })
  );
}

function fnDownloadFileByUrl(cAttachmentUrl, cFileName) {
  const xhr = new XMLHttpRequest();
  xhr.responseType = "blob";
  xhr.onload = () => {
    const a = document.createElement("a");
    a.href = window.URL.createObjectURL(xhr.response);
    a.download = cFileName;
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
  };

  xhr.open("GET", cAttachmentUrl);
  xhr.send();
}

export function downloadPDF(nVersionId, cFileName, cFormat, cOrientation, cPreview) {
  return new Promise((resolve, reject) =>
    Axios.get(`version/pdf/download/${nVersionId}/${cFormat}/${cOrientation}/${cPreview}`, {
      responseType: 'blob'
    })
      .then((oResponse) => {
        const fFile = new Blob([oResponse.data], { type: oResponse.data.type });
        const cFileURL = URL.createObjectURL(fFile);
        const elLink = document.createElement('a');
        elLink.href = cFileURL;
        elLink.setAttribute('download', `${cFileName}.pdf`);
        document.body.appendChild(elLink);
        elLink.click();
        resolve();
      })
      .catch(oError => new Response(oError.response.data).text().then(cText => reject(cText)))
  );
}

export function fnGetLocalImageAttachment(nAttachmentId, cFileName) {
  return new Promise((resolve, reject) =>
    Axios.get(`attachment/download/${nAttachmentId}`, { responseType: "blob" })
      .then(oResponse => {
        resolve({
          cContent: URL.createObjectURL(oResponse.data),
          cType: "blob"
        });
      })
      .catch(oError => new Response(oError.response.data).text().then(cText => reject(cText)))
  );
}

export function fnGetImageAttachment(nAttachmentId, cFileName, bIsLocal) {
  if (bIsLocal) {
    return fnGetLocalImageAttachment(nAttachmentId, cFileName);
  }

  return Axios.get(`attachment/download/${nAttachmentId}`)
    .then(oResponse => {
      const cAttachmentUrl = oResponse.data?.data?.cUrl;
      const cUrlConvert = `${process.env.REACT_APP_CONVERT_URL}/convert`;
      // Verifique o conteúdo do arquivo para determinar se é .doc ou .docx
      if (['doc', 'docx', 'xls', 'xlsx', 'html', 'txt', 'ods'].some(ext => cFileName.includes(ext))) {
        // Se for um arquivo .doc ou .docx, envie-o para a API de conversão
        const formData = new FormData();
        formData.append('file', cAttachmentUrl); // Certifique-se de substituir 'cAttachmentUrl' pelo conteúdo real do arquivo
        return Axios.post(cUrlConvert, formData)
          .then(oConversionResponse => {
            // A resposta deve conter o PDF convertido
            const cConvertedPdfBase64 = oConversionResponse.data.pdf_content;

            return {
              cContent: cConvertedPdfBase64,
              cType: 'pdf',
            };
          })
          .catch(oConversionError => {
            // Lide com erros na conversão aqui, se necessário
            return Promise.reject('Erro na conversão para PDF');
          });
      } else {
        // Caso contrário, retorne a resposta normal da promise
        return {
          cContent: cAttachmentUrl,
          cType: 'url',
        };
      }
    })
    .catch(oError => {
      // Lide com erros aqui, se necessário
      return Promise.reject('Erro na obtenção do anexo');
    });
}



export function fnDownloadLocalAttachment(nAttachmentId, cFileName) {
  return new Promise((resolve, reject) =>
    Axios.get(`attachment/download/${nAttachmentId}`, {
      responseType: "blob"
    })
      .then(oResponse => {
        const fFile = new Blob([oResponse.data], { type: oResponse.data.type });
        const cFileURL = URL.createObjectURL(fFile);
        const elLink = document.createElement('a');
        elLink.href = cFileURL;
        elLink.setAttribute('download', `${cFileName}`);
        document.body.appendChild(elLink);
        elLink.click();
        resolve();
      })
      .catch(oError => new Response(oError.response.data).text().then(cText => reject(cText)))
  );
}

export function downloadAttachment(nAttachmentId, cFileName, cFileUrl = '') {
  return new Promise((resolve, reject) => {
    Axios.get(`attachment/download/${nAttachmentId}`)
      .then(oResponse => {
        const cAttachmentUrl = oResponse.data?.data?.cUrl;

        if (cFileUrl !== '') {
          // Se 'URL' for passado, retorna apenas a URL do anexo
          resolve({ cUrl: cAttachmentUrl });
        } else {
          // Caso contrário, baixa o arquivo
          fnDownloadFileByUrl(cAttachmentUrl, cFileName);
          resolve();
        }
      })
      .catch(oError => {
        new Response(oError.response.data).text().then(cText => reject(cText));
      });
  });
}



export function downloadZip(nVersionId, cFileName) {
  return new Promise((resolve, reject) =>
    Axios.get(`/version/zip/download/${nVersionId}`, { responseType: 'blob' })
      .then(oResponse => {
        const fFile = new Blob([oResponse.data], { type: oResponse.data.type });
        const cFileURL = URL.createObjectURL(fFile);
        const elLink = document.createElement('a');
        elLink.href = cFileURL;
        elLink.setAttribute('download', cFileName);
        document.body.appendChild(elLink);
        elLink.click();
        resolve();
      })
      .catch(oError => new Response(oError.response.data).text().then(cText => reject(cText)))
  );
}
