import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert/lib/dist/SweetAlert';
import intl from 'react-intl-universal';
import { Alert } from '../../components/Alert';
import Axios from '../../config/Axios';
import Page from '../default/Page';
import AddComment from './auditEvidences/AddComment';

import '../norm/styles/requisites.scss';
import { base64MimeType } from '../norm/RequisiteShow';

import ErrorIcon from '@material-ui/icons/Error';

import { checkModule } from '../../config/Permissions';
import FileIcon from '../../components/FileIcon';
class RequisiteShow extends Component {
  constructor(oProps) {
    super(oProps);
    const { oRequisite, nRequisiteId } = this.props;

    this.state = {
      nRequisiteId,
      oRequisite,
      bLoading: true,
      // aEvidences: oRequisite.audit.evidences,
      aEvidences: [],
      loadEvidences: false
    };
  }

  componentDidMount = async () => {
    const { history } = this.props;
    checkModule(history, 'audit-management');

    const { nRequisiteId, oRequisite } = this.state;
    await Promise.all([this.getRequisite(nRequisiteId), this.getEvidences()]);
    this.setState({ bLoading: false });
  };

  filterOldInvalidVersions = aEvidences => {
    const versionFilter = {};
    const filteredEvidences = [];
    aEvidences.forEach(evidence => {
      if (
        evidence?.version?.doc_nid !== undefined &&
        evidence.evid_etype === 'document' &&
        !evidence.evid_cattachment
      ) {
        if (evidence.version.doc_nid in versionFilter) {
          const evidenceDate = new Date(evidence.version.ver_dcreated);
          const sentinelDate = new Date(versionFilter[evidence.version.doc_nid].version.ver_dcreated);
          if (evidenceDate > sentinelDate) {
            versionFilter[evidence.version.doc_nid] = evidence;
          }
        } else {
          versionFilter[evidence?.version?.doc_nid] = evidence;
        }
      } else {
        filteredEvidences.push(evidence);
      }
    });
    Object.keys(versionFilter).forEach(key => filteredEvidences.push(versionFilter[key]));
    return filteredEvidences;
  };

  getEvidences = () => {
    const { nRequisiteId } = this.state;
    this.setState({
      loadEvidences: true
    });
    return Axios.get('evidence', {
      params: { nRequisiteId }
    })
      .then(oResponse => {
        this.setState({
          aEvidences: this.filterOldInvalidVersions(oResponse.data),
          loadEvidences: false
        });
      })
      .catch(oError => {
        const nErrorStatus = oError?.response?.status;
        if (nErrorStatus === 404 || nErrorStatus === 422) {
          this.setState({
            aEvidences: [],
            loadEvidences: false
          });
          return;
        }
        this.setState({
          rcmpAlertMsg: (
            <Alert type="error" isOpen onCloseAlert={() => { }}>
              {oError.msgErrors}
            </Alert>
          )
        });
      });
  };

  getRequisite = nRequisiteId =>
    Axios.get(`requisite/${nRequisiteId}`)
      .then(oResponse => {
        this.setState({ oRequisite: oResponse.data });
      })
      .catch(() => { });

  handleChange = evt => {
    this.setState({ [evt.target.name]: evt.target.type === 'checkbox' ? evt.target.checked : evt.target.value });
  };

  hideAlert = () => {
    this.setState({
      rcmpAlert: null
    });
  };

  openEvidencesAdd = () => {
    this.optionMore
      .closest('div')
      .getElementsByClassName('evidencesAdd')[0]
      .classList.toggle('open');
    this.optionMore.classList.toggle('open');
  };

  successMessage = cMsg => {
    this.hideAlert();
    this.componentDidMount();
    this.setAlert('success', cMsg);
  };

  setAlert = (cType, cMsg) => {
    this.setState({
      rcmpAlertMsg: (
        <Alert type={cType} isOpen onCloseAlert={() => { }}>
          {cMsg}
        </Alert>
      )
    });
  };

  showRemoveEvidenceAlert = oEvidence => {
    this.setState({
      rcmpAlert: (
        <SweetAlert
          closeOnClickOutside={false}
          showCancel
          confirmBtnText={intl.get('confirmar')}
          cancelBtnText={intl.get('cancelar')}
          cancelBtnBsStyle="danger"
          title=""
          onConfirm={() => {
            this.removeEvidence(oEvidence);
            this.hideAlert();
          }}
          onCancel={this.hideAlert}
        >
          {intl.get('mensagem_excluir', {
            tipo: intl.get('RequisiteShow.a_evidencia'),
            nome: oEvidence.evid_ctitle
              ? oEvidence.evid_ctitle >= 20
                ? `${oEvidence.evid_ctitle.substring(0, 20)}...`
                : oEvidence.evid_ctitle
              : oEvidence.evid_cdescription.replace(/&nbsp;/g, ' ').replace(/(<([^>]+)>)/gi, '')
          })}
        </SweetAlert>
      )
    });
  };

  removeEvidence = oEvidence => {
    Axios.delete(`evidence/${oEvidence.evid_nid}`)
      .then(() => {
        this.setState(oPrevState => ({
          aEvidences: oPrevState.aEvidences.filter(
            oFilteringEvidence => oFilteringEvidence.evid_nid !== oEvidence.evid_nid
          )
        }));
        this.componentDidMount();
      })
      .catch(oError => {
        this.setState({
          rcmpAlertMsg: (
            <Alert type="error" isOpen onCloseAlert={() => { }}>
              {oError.msgErrors}
            </Alert>
          )
        });
      });
  };

  editEvidence = (oEvidence, oUser) => {
    const { oRequisite } = this.state;

    if (oEvidence.evid_etype === 'comment') {
      this.setState({
        rcmpAlert: (
          <SweetAlert customClass="modal-edit lg" title="" onConfirm={this.hideAlert} showConfirm={false}>
            <div className="head-modal">
              {intl.get('RequisiteShow.edit_comment')}
              <span
                role="button"
                tabIndex="0"
                aria-labelledby="head-modal"
                onKeyPress={this.hideAlert}
                onClick={this.hideAlert}
                className="close"
                href=""
              />
            </div>
            <AddComment
              oEvidence={oEvidence}
              oRequisite={oRequisite}
              onSuccess={cMsg => {
                this.successMessage(cMsg);
              }}
              closeModal={this.hideAlert}
            />
          </SweetAlert>
        )
      });
    }
  };

  save = (e, cFileName, cUrlFile) => {
    e.preventDefault();
    this.setState({
      loadEvidences: true
    });
    fetch(cUrlFile)
      .then(response => response.blob())
      .then(blob => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = cFileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((oError) => {
        console.error('Error registration:', oError);
      }).finally(() => {
        this.setState({ loadEvidences: false });
      });
  };

  openDocument = oEvidence => {
    if (oEvidence?.version?.document?.stat_nid !== 21) {
      window.open(`/document/detail/${oEvidence.version.ver_nid}`, 'document', 'noopener');
    } else {
      this.setState({
        rcmpAlertMsg: (
          <Alert type="error" isOpen onCloseAlert={() => { }}>
            {intl.get('RequisiteShow.documento_arquivado')}
          </Alert>
        )
      });
    }
  };

  isExpiredDocument = oDocumentEvidence => {
    return (
      oDocumentEvidence.version?.stat_nid === 4 &&
      oDocumentEvidence.version?.dtReviewDate &&
      new Date() > new Date(oDocumentEvidence.version?.dtReviewDate)
    );
  };

  isArchivedDocument = oDocumentEvidence => {
    return oDocumentEvidence.version?.document?.stat_nid === 21;
  };

  doesDocumentHaveProblem = oDocumentEvidence => {
    if (this.isArchivedDocument(oDocumentEvidence)) {
      return true;
    }

    if (this.isExpiredDocument(oDocumentEvidence)) {
      return true;
    }

    return false;
  };

  render() {
    const { oRequisite, bLoading, aEvidences, rcmpAlert, rcmpAlertMsg, loadEvidences } = this.state;

    return (
      <Page loading={bLoading ? 1 : 0}>
        <div className="form">
          {rcmpAlert}
          {rcmpAlertMsg}
          {oRequisite && (
            <>
              <div id="editor-view" dangerouslySetInnerHTML={{ __html: oRequisite.req_cdescription }} />

              <div id="evidenceBox">
                {loadEvidences ? (
                  <>{intl.get('carregando')}</>
                ) : (
                  <>
                    {Object.keys(aEvidences).map(cKey => {
                      if (
                        !aEvidences[cKey].evid_cattachment &&
                        aEvidences[cKey].evid_etype === 'document' &&
                        aEvidences[cKey].version?.stat_nid === 8 &&
                        aEvidences[cKey].version?.document?.stat_nid !== 21
                      ) {
                        return;
                      }
                      if (aEvidences[cKey])
                        return (
                          <span
                            onClick={e => {
                              e.stopPropagation();
                              aEvidences[cKey].evid_etype === 'comment'
                                ? this.editEvidence(aEvidences[cKey], aEvidences[cKey].user)
                                : aEvidences[cKey].evid_cattachment
                                  ? this.save(e, aEvidences[cKey].evid_ctitle, aEvidences[cKey].evid_cattachment)
                                  : this.openDocument(aEvidences[cKey]);
                            }}
                            role="button"
                            tabIndex="0"
                            key={aEvidences[cKey].evid_nid}
                            className={`evidence`}
                            title={
                              aEvidences[cKey].evid_ctitle
                                ? aEvidences[cKey].evid_ctitle
                                : aEvidences[cKey].evid_cdescription
                                  .replace(/&nbsp;/g, ' ')
                                  .replace(/(<([^>]+)>)/gi, '')
                            }
                          >
                            <i
                              onClick={e => {
                                e.stopPropagation();
                                this.showRemoveEvidenceAlert(aEvidences[cKey]);
                              }}
                              className="icon icon-bin"
                            />
                            {aEvidences[cKey].evid_cattachment ? (
                              <FileIcon cExtension={aEvidences[cKey]?.evid_cmime_type} />
                            ) : aEvidences[cKey].evid_ntype ? (
                              <>
                                <FileIcon cExtension={aEvidences[cKey]?.evid_cmime_type} />
                                {this.doesDocumentHaveProblem(aEvidences[cKey]) && (
                                  <span
                                    title={
                                      this.isArchivedDocument(aEvidences[cKey])
                                        ? intl.get('RequisiteShow.documento_arquivado')
                                        : intl.get('RequisiteShow.documento_expirado')
                                    }
                                  >
                                    <ErrorIcon className="icon icon-error" />
                                  </span>
                                )}
                              </>
                            ) : (
                              <i className="icon icon-bubbles" />
                            )}
                            <p className="evidTitle">
                              {aEvidences[cKey].evid_ctitle
                                ? aEvidences[cKey].evid_ctitle.length >= 18
                                  ? `${aEvidences[cKey].evid_ctitle.substring(0, 18)}...`
                                  : aEvidences[cKey].evid_ctitle
                                : aEvidences[cKey].evid_cdescription
                                  .replace(/&nbsp;/g, ' ')
                                  .replace(/(<([^>]+)>)/gi, '')}
                            </p>
                          </span>
                        );
                    })}
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </Page>
    );
  }
}

export default RequisiteShow;
