import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import intl from 'react-intl-universal';
import Axios from '../../config/Axios';
import { can, canAtLeast } from '../../config/Permissions';
import Breadcrumb from '../../components/Breadcrumbs';
import Page from '../default-v2/Page';
import Filter from '../default-v2/Filter';
import CardHeader from '../default-v2/CardHeader';
import Button from '../../components/Button';
import './styles/permissiongroupindex.scss';
import { Table } from 'reactstrap';
import imgfNoPic from '../../assets/img/nopic.svg';
import url from '../../config/Url';
import teamsImg from '../../assets/img/teams.svg';

export class PermissionGroupIndex extends Component {
  constructor(oProps) {
    super(oProps);

    this.state = {
      bLoading: true,
      aGroups: [],
      rcmpAlert: null,
      cSearch: '',
      aPages: [
        {
          cName: 'PermissionGroupIndex.titulo',
          cLink: undefined
        }
      ]
    };
  }

  componentDidMount = () => {
    const { history } = this.props;

    if (!canAtLeast(['create-edit-team', 'admin'])) {
      history.push({
        pathname: '/',
        state: { aAlertMessages: { cType: 'error', cMsg: intl.get('sem_permissao') } }
      });
    }

    this.fetchGroups();
  };

  fetchGroups = () => {
    const { setAlert } = this.props;
    Axios.get('permissiongroup')
      .then(oRes => {
        this.setState({
          bLoading: false,
          aGroups: oRes.data
        });
      })
      .catch(oError => {
        this.setState({ bLoading: false });
        setAlert('error', oError.msgErrors);
      });
  };

  renderMembers = oGroup => {
    if (oGroup.customers_users === null || oGroup.customers_users === undefined) {
      return '';
    }

    if (oGroup.customers_users.length === 0) {
      return '';
    }

    let aCustList = oGroup.customers_users.slice(0, 5);
    let nSize = oGroup.customers_users.length - 5;
    return (
      <div>
        {aCustList.map(oCustItem => (
          <img
            src={
              oCustItem.user.user_cavatar
                ? oCustItem.user.user_cavatar
                : imgfNoPic
            }
            alt={oCustItem.user.user_cname}
            title={oCustItem.user.user_cname}
            key={oCustItem.user.user_nid}
          />
        ))}

        {nSize > 9 ? (
          <span>{`+ ${nSize} ${intl.get('PermissionGroupIndex.users')}`}</span>
        ) : nSize > 0 ? (
          <span>{`+ 0${nSize} ${intl.get('PermissionGroupIndex.users')}`}</span>
        ) : (
          ''
        )}
      </div>
    );
  };

  renderGroups = () => {
    const { history } = this.props;
    const { aGroups } = this.state;

    if (aGroups.length == 0) {
      return (
        <p className="empty-group">
          <strong>{intl.get('PermissionGroupIndex.no_result_groups')}</strong>
        </p>
      );
    }

    return (
      <Table responsive className="table-group">
        <thead>
          <tr>
            <th>{intl.get('PermissionGroupIndex.team')}</th>
            <th>{intl.get('PermissionGroupIndex.description')}</th>
            <th>{intl.get('PermissionGroupIndex.members')}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {aGroups.map(oGroup => (
            <tr key={oGroup.pgc_nid}>
              <td className="col-name" title={oGroup.pgc_cname}>
                <span>{oGroup.pgc_cname}</span>
              </td>
              <td className="col-description" title={oGroup.pgc_cdescription}>
                <span>{oGroup.pgc_cdescription}</span>
              </td>
              <td className="col-members">{this.renderMembers(oGroup)}</td>
              <td className="action-group-item">
                <div className="buttons">
                  {can('create-edit-team') && (
                    <button
                      type="button"
                      aria-labelledby="buttons"
                      onClick={() => history.push(`/team/update/${oGroup.pgc_nid}`)}
                      title={intl.get('PermissionGroupIndex.botao_editar')}
                      className="edit"
                    />
                  )}
                  {can('delete-team') && (
                    <button
                      type="button"
                      aria-labelledby="buttons"
                      onClick={evt => this.showDelete(evt, oGroup)}
                      title={intl.get('PermissionGroupIndex.botao_excluir')}
                      className="remove"
                    />
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  showDelete = (evt, oGroup) => {
    this.setState({
      rcmpAlert: (
        <SweetAlert
          warning
          showCancel
          confirmBtnText={intl.get('confirmar')}
          cancelBtnText={intl.get('cancelar')}
          cancelBtnBsStyle="danger"
          title={intl.get('remover')}
          onConfirm={() => this.deleteGroup(oGroup)}
          onCancel={this.hideAlert}
        >
          {intl.get('mensagem_excluir', { tipo: intl.get('equipe'), nome: oGroup.pgc_cname })}
        </SweetAlert>
      )
    });
  };

  hideAlert = () => {
    this.setState({
      rcmpAlert: null
    });
  };

  deleteGroup = oGroup => {
    Axios.delete(`permissiongroup/${oGroup.pgc_nid}`)
      .then(() => {
        const { clearAlert } = this.props;
        clearAlert();

        this.setState({
          rcmpAlert: (
            <SweetAlert
              success
              title={intl.get('removido')}
              onConfirm={() => {
                this.fetchGroups();
                this.hideAlert();
              }}
            >
              {intl.get('PermissionGroupIndex.alerta_exclusao')}
            </SweetAlert>
          )
        });
      })
      .catch(oError => {
        this.setState({
          rcmpAlert: (
            <SweetAlert error title={intl.get('ops')} onConfirm={this.hideAlert}>
              {intl.get('falhou')}
              <p>{oError.aMsgErrors}</p>
            </SweetAlert>
          )
        });
      });
  };

  createTeam = evt => {
    const { history } = this.props;
    history.push('/team/create');
  };

  handleNewFilter = (evt, oFilter) => {
    this.setState(
      {
        cSearch: oFilter.cText
      },
      () => {
        this.handleFilter(evt);
      }
    );
  };

  handleFilter = evt => {
    const { setAlert } = this.props;
    const { cSearch } = this.state;

    Axios.get('/permissiongroup/search', {
      params: { cSearch }
    })
      .then(oResponse => {
        this.setState({
          ...oResponse.data
        });
      })
      .catch(oError => {
        setAlert('error', oError.aMsgErrors);
      });
  };

  generateCardHeader = () => {
    const aButtons = [];
    let rcmpBtnNewDocument = null;
    if (canAtLeast(['create-edit-team', 'admin'])) {
      rcmpBtnNewDocument = (
        <Button onClick={evt => this.createTeam(evt)}>{intl.get('PermissionGroupIndex.botao_criar')}</Button>
      );
    }

    aButtons.push(rcmpBtnNewDocument);

    const rcmpFilter = <Filter aButtons={aButtons} fnOnType={this.handleNewFilter} />;

    const rcmpCardHeader = <CardHeader rcmpFilter={rcmpFilter} bIsItToInsertGoBackBtn={false} />;
    return rcmpCardHeader;
  };

  render() {
    const { aPages, bLoading, rcmpAlert } = this.state;
    const { getAlert } = this.props;

    const rcmpBreadcrumb = <Breadcrumb aPages={aPages} />;

    const rcmpCardHeader = this.generateCardHeader();

    const cPageTitle = intl.get('PermissionGroupIndex.titulo');

    return (
      <Page
        loading={bLoading ? 1 : 0}
        rcmpBreadcrumb={rcmpBreadcrumb}
        cTitle={cPageTitle}
        cImage={teamsImg}
        rcmpCardHeader={rcmpCardHeader}
        className="v2-group"
        cCurrentSideMenuOption={intl.get('Nav.teams')}
      >
        {rcmpAlert}
        {getAlert()}
        {this.renderGroups()}
      </Page>
    );
  }
}

export default PermissionGroupIndex;
