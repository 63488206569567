import React, { Component } from 'react';
import intl from 'react-intl-universal';
import imgfDev from '../../assets/img/dev.png';

class Index extends Component {
  constructor(oProps) {
    super(oProps);

    this.state = {};
  }

  componentDidMount = () => {};

  render() {
    return (
      <div
        style={{
          background: '#fff',
          width: '100vw',
          height: '100vh',
          position: 'fixed',
          left: '0px',
          top: '0px',
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <img
          style={{
            width: '80vw',
            height: '80vh',
            objectFit: 'contain',
            margin: '0 auto',
            verticalAlign: 'middle'
          }}
          alt={intl.get("Index.em_desenvolvimento")}
          src={imgfDev}
        />
      </div>
    );
  }
}

export default Index;
