import React, { Component } from 'react';
import { Button } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import intl from 'react-intl-universal';
import Axios from '../../config/Axios';
import Breadcrumb from '../../components/Breadcrumbs';
import Page from './Page';

export class Backup extends Component {
  constructor(oProps) {
    super(oProps);

    this.state = {
      bLoading: true,
      oConfigs: {},
      oDownloads: {},
      bAvailableRestore: false,
      bIsSending: false,
      cFileName: undefined,
      aPages: [{ cName: 'Backup.titulo', cLink: undefined }]
    };
  }

  componentDidMount = () => {
    const { setAlert } = this.props;
    Axios.get('backup')
      .then(oResponse => {
        const { oConfigs, oDownloads, bAvailableRestore } = oResponse.data;
        this.setState({
          bLoading: false,
          oConfigs,
          oDownloads,
          bAvailableRestore
        });
      })
      .catch(oError => {
        this.setState({ bLoading: false });
        setAlert('error', oError.aMsgErrors);
      });
  };

  downloadBackup = (evt, oDownloads) => {
    evt.preventDefault();

    Axios.get(`backup/download/${oDownloads}`, { responseType: 'blob' }).then(oResponse => {
      const fFile = new Blob([oResponse.data], { type: oResponse.data.type });
      const cFileURL = URL.createObjectURL(fFile);
      const elLink = document.createElement('a');

      elLink.href = cFileURL;
      elLink.setAttribute('download', oDownloads);
      document.body.appendChild(elLink);
      elLink.click();
    });
  };

  triggerUpload = evt => {
    evt.preventDefault();
    this.fileUpload.click();
  };

  fileChangedHandler = evt => {
    evt.preventDefault();
    if (evt.target.files.length > 0) {
      this.setState({
        cFileName: [evt.target.files[0].name],
        nFileSize: [evt.target.files[0].size]
      });
    }
  };

  restore = () => {
    this.setState({
      bIsSending: true
    });
    const fdataRestore = new FormData();
    const elImagefile = document.querySelector('#backup-upload');
    const { history, setAlert } = this.props;

    // for (const key in imagefile.files) {
    //   data.append('arquivo', imagefile.files[key]);
    // }

    Object.keys(elImagefile.files).forEach(key => {
      fdataRestore.append('file', elImagefile.files[key]);
    });

    Axios.post('restore', fdataRestore)
      .then(() => {
        history.push({
          pathname: '/',
          state: { aAlertMessages: { cType: 'success', cMsg: intl.get('Backup.restauracao_sucesso') } }
        });
      })
      .catch(oError => {
        this.setState({
          bIsSending: false,
          cFileName: undefined,
          cFileSize: ''
        });
        this.hideAlert();
        setAlert('error', oError.cMsgErrors);
      });
  };

  showConfirmRestore = evt => {
    evt.preventDefault();
    this.setState({
      rcmpAlert: (
        <SweetAlert
          warning
          showCancel
          confirmBtnText="Confirmar"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          title="Confirmação"
          // onConfirm={e => this.restore(e)}
          onConfirm={evtConfirm => this.restore(evtConfirm)}
          onCancel={this.hideAlert}
        >
          {intl.get('Backup.restauracao_confirmar')}
        </SweetAlert>
      )
    });
  };

  hideAlert = () => {
    this.setState({
      rcmpAlert: null
    });
  };

  renderListDownloads() {
    const { oDownloads } = this.state;
    const aDownloadList = Object.keys(oDownloads).map(cKey => {
      const cDownload = oDownloads[cKey];
      return (
        <li key={cKey}>
          <h3>
            <strong>{intl.get('Backup.arquivo')}: </strong>
            {cDownload}
          </h3>

          <a
            href="/"
            style={{ cursor: 'pointer' }}
            onClick={evt => this.downloadBackup(evt, cDownload)}
            title={cDownload}
          >
            <i className="icon-document-download" />
            {intl.get('Backup.baixar')}
          </a>
        </li>
      );
    });

    return (
      <ul>
        {aDownloadList.length > 0 && aDownloadList}
        {aDownloadList.length === 0 && <p>{intl.get('Backup.sem_backup')}</p>}
      </ul>
    );
  }

  render() {
    const { bLoading, bIsSending, rcmpAlert, aPages, oConfigs, bAvailableRestore, cFileName, cFileSize } = this.state;
    if (bIsSending) {
      return (
        <div style={{ display: 'block' }} className="fp-loader">
          <div className="inner">
            <i className="ico" style={{ display: 'block' }} />
            <p>{intl.getHTML('Backup.alerta')}</p>
          </div>
        </div>
      );
    }
    return (
      <Page loading={bLoading ? 1 : 0}>
        {rcmpAlert}
        <h1>{intl.get('Backup.titulo')}</h1>
        <Breadcrumb aPages={aPages} />
        <p className="notice backup-notice">
          {intl.get('Backup.mensagem1_parte1')}
          <b style={{ fontWeight: 'bold' }}>
            {intl.get('Backup.mensagem1_parte2', { num: oConfigs.PERIODICIDADEBACKUP })}
          </b>
          {intl.get('Backup.mensagem1_parte3')}
          <strong style={{ fontWeight: 'bold' }}> {intl.get('Backup.mensagem1_parte4', { hora: 0 })}</strong>.
          <br />
          <span>{intl.get('Backup.mensagem2')}</span>
        </p>

        <section id="backup">
          <div className="col">
            <h2>{intl.get('Backup.subtitulo1')}</h2>
            {this.renderListDownloads()}
          </div>

          <div className="col">
            <h2>{intl.get('Backup.subtitulo2')}</h2>
            {bAvailableRestore === true && (
              <div className="file-field-wrapper">
                <a
                  href="/"
                  style={{ cursor: 'pointer' }}
                  onClick={evt => this.triggerUpload(evt)}
                  className="attach-button"
                >
                  {cFileName
                    ? `${cFileName} (${(cFileSize / 1024).toFixed()}kb) `
                    : intl.get('Backup.selecione_arquivo')}
                </a>
                <input
                  onChange={this.fileChangedHandler}
                  className="no-display"
                  type="file"
                  // ref={ref => (this.fileUpload = ref)}
                  ref={cRef => {
                    this.fileUpload = cRef;
                  }}
                  id="backup-upload"
                  name="arquivo"
                  accept=".zip"
                />
                <ul id="backup-upload-return" />
                <Button
                  onClick={evt => this.showConfirmRestore(evt)}
                  className="btn alternate enviar-backup"
                  disabled={cFileName === undefined}
                >
                  Enviar
                </Button>
              </div>
            )}
            {bAvailableRestore === false && <p>{intl.get('Backup.mensagem3')}</p>}
          </div>
        </section>
      </Page>
    );
  }
}

export default Backup;
