import React, { Component } from 'react';
import intl from 'react-intl-universal';
import Axios from '../../config/Axios';
import { formatCurrency } from '../../config/Formatter';
import Page from '../default-v2/Page';

export class Extract extends Component {
  constructor(oProps) {
    super(oProps);

    this.state = {
      bLoading: true,
      aInvoices: []
    };
  }

  componentDidMount() {
    const { match, setAlert } = this.props;

    Axios.get(`/extract/${match.params.nInvoiceId}`)
      .then(oRes => {
        this.setState({
          aInvoices: oRes.data.aInvoices,
          cBilletUrl: oRes.data.cBilletUrl,
          bLoading: false
        });
      })
      .catch(oError => {
        this.setState({ bLoading: false });
        setAlert('error', oError.aMsgErrors);
      });
  }

  render() {
    const { aInvoices, cBilletUrl, bLoading } = this.state;
    const { getAlert } = this.props;

    return (
      <Page loading={bLoading ? 1 : 0}>
        {getAlert()}
        {aInvoices.length > 0 && (
          <>
            <h1>
              {intl.get('ExtractIndex.extrato_titulo')} | {aInvoices[0].invc_creferencemonth}
            </h1>
            <div className="financial-detail">
              <h2>{intl.get('ExtractIndex.mensalidade')}</h2>
              <p className="status alert">{intl.get(`Status.${aInvoices[0].stat_cname}`)}</p>
              <div className="lower-details">
                <p>
                  {intl.get('ExtractIndex.valor')}: <strong>{formatCurrency(aInvoices[0].invc_ntotalvalue)}</strong>
                </p>
                <p>
                  {intl.get('ExtractIndex.vencimento')}: <strong>{aInvoices[0].invc_ddue}</strong>
                </p>
                <p>
                  {intl.get('Extract.numero_documento')}: <strong>{aInvoices[0].invc_cdocnumber}</strong>
                </p>
              </div>
              <h3>{intl.get('Extract.fatura_detalhada')}</h3>
              <table>
                <tbody>
                  {aInvoices.map(oInvoice => (
                    <tr key={oInvoice.invc_nid}>
                      <td>{oInvoice.invc_cdescription}</td>
                      <td>
                        <strong>{formatCurrency(oInvoice.invc_nvalue)}</strong>
                      </td>
                    </tr>
                  ))}

                  <tr>
                    <td>
                      <strong>{intl.get('total')}</strong>
                    </td>
                    <td>
                      <strong className="alert">{formatCurrency(aInvoices[0].invc_ntotalvalue)}</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="button-stack">
                <a className="btn" href={cBilletUrl} rel="noopener noreferrer" target="_blank">
                  {intl.get('Extract.reimprimir_boleto')}
                </a>
              </div>
            </div>
          </>
        )}
      </Page>
    );
  }
}

export default Extract;
