import React, { Fragment, useEffect } from 'react';
import intl from 'react-intl-universal';
import { Link, NavLink } from 'react-router-dom';
import classNames from 'classnames';

import oClasses from './styles/FolderContentsList.module.scss';

import { oLocalStorage } from '../../config/EncodedLocalStorage';
import { can } from '../../config/Permissions';
import { formatDateToUserTZ } from '../../utils/time';
import imgfFolder from './img/folderindex-folder-small.svg';
import imgfDocument from './img/folderindex-document-small.svg';
import imgfSelectDown from '../default-v2/img/select-down.svg';
import imgfSelectUp from '../default-v2/img/select-up.svg';
import ActionButton from '../../components/molecules/ActionButton';
import IconStatus from '../../components/IconStatus';

/**
 * @param {number} nStatId
 */
const getVersionStatus = (nStatId, oDocument) => {
  let cTitle = '';
  switch (nStatId) {
    case 1:
      cTitle = `${intl.get('Status.em-redacao')} ${intl.get('Document.by')} ${oDocument.oWriter.user_cname}`;
      return <IconStatus cStatus="in-writing" cTitle={cTitle} />;
    case 2:
      cTitle = `${intl.get('Status.em-revisao')} ${intl.get('Document.by')} ${oDocument.oReviewer.user_cname}`;
      return <IconStatus cStatus="in-review" cTitle={cTitle} />;
    case 3:
      cTitle = `${intl.get('Status.aguardando-publicacao')} ${intl.get('Document.by')} ${oDocument.oResponsible.user_cname}`;
      return <IconStatus cStatus="awaiting-publish" cTitle={cTitle} />;
    case 4:
      return <IconStatus cStatus="published" />;
    default:
      return null;
  }
};

const canAccessDocument = (oDocument) => {
  let cKey = '';
  switch (oDocument.oVersion.stat_nid) {
    case 1: // in-writing
      cKey = 'oWriter';
      break;
    case 2: // in-review
      cKey = 'oReviewer';
      break;
    case 3: // awaiting-publish
      cKey = 'oResponsible';
      break;
    case 4:
    case 8:
      return true;
  }

  return oDocument[cKey]?.user_nid === +oLocalStorage.get('nUserId');
};

const getDocumentPath = (oDocument) => {
  const oVersion = oDocument.oVersion;

  if (!canAccessDocument(oDocument)) return null;

  let cPath = '/document/';
  switch (oVersion.stat_nid) {
    case 1: // in-writing
      cPath += 'editor/';
      break;
    case 2: // in-review
      cPath += 'review/';
      break;
    case 3: // awaiting-publish
      cPath += 'publish/';
      break;
    case 4: // published
    case 8: // inactive
      cPath += 'detail/';
      break;
  }
  return cPath + oVersion.ver_nid;
};

const HeaderColumn = ({
  cLabel,
  onSort,
  cSortOrder = null,
  className,
}) => {

  const oHeaderProps = {};
  let rcmpArrowIcon = null;
  if (onSort != null) {
    oHeaderProps.onClick = onSort;
    if (cSortOrder != null) {
      oHeaderProps['aria-sort'] = cSortOrder;
      rcmpArrowIcon = (
        <span className="folderindex-list-icon">
          <img
            className="folderindex-list-icon"
            src={cSortOrder === 'ascending' ? imgfSelectUp : imgfSelectDown}
          />
        </span>
      );
    } else {
      oHeaderProps['aria-sort'] = 'none';
    }
  }

  return (
    <div
      className={classNames(oClasses.col, onSort != null && oClasses.sortable, className)}
      role="columnheader"
      {...oHeaderProps}
    >
      <span>{cLabel}</span>
      {rcmpArrowIcon}
    </div>
  );
};

const Header = ({ bShowArchived, cSortHeader, cSortOrder, onSortingChange }) => {

  const handleSortingChange = (cColumn) => {
    onSortingChange(cColumn, (cSortOrder === 'asc' && cSortHeader === cColumn) ? 'desc' : 'asc');
  }


  const getColumnSortOrder = (cColumn) => {
    if (cColumn === cSortHeader) {
      return cSortOrder === 'asc' ? 'ascending' : 'descending';
    }
    return null;
  };

  return (
    <div
      className={oClasses['table-header']}
      role="rowgroup"
    >
      <div
        className={oClasses.row}
        role="row"
      >
        <HeaderColumn
          cLabel={intl.get('name')}
          onSort={() => handleSortingChange('cName')}
          cSortOrder={getColumnSortOrder('cName')}
          className={!bShowArchived ? oClasses['col-4'] : oClasses['col-5']}
        />
        {!bShowArchived && (
          <HeaderColumn
            cLabel={intl.get('status')}
            onSort={() => handleSortingChange('cStatus')}
            cSortOrder={getColumnSortOrder('cStatus')}
          />
        )}
        <HeaderColumn
          cLabel={intl.get('MasterList.version')}
          onSort={() => handleSortingChange('cVersion')}
          cSortOrder={getColumnSortOrder('cVersion')}
        />
        <HeaderColumn
          cLabel={intl.get('FolderIndex.responsible')}
          onSort={() => handleSortingChange('cResponsibleName')}
          cSortOrder={getColumnSortOrder('cResponsibleName')}
          className={oClasses['col-2']}
        />
        <HeaderColumn
          cLabel={intl.get('FolderIndex.published_at')}
          onSort={() => handleSortingChange('cPublishedDate')}
          cSortOrder={getColumnSortOrder('cPublishedDate')}
          className={oClasses['col-2']}
        />
        {!bShowArchived && (
          <HeaderColumn
            cLabel={intl.get('FolderIndex.due_at')}
            onSort={() => handleSortingChange('cDueDate')}
            cSortOrder={getColumnSortOrder('cDueDate')}
            className={oClasses['col-2']}
          />
        )}
        {bShowArchived && (
          <HeaderColumn
            cLabel={intl.get('Dashboard.archiving_data')}
            onSort={() => handleSortingChange('cArchiveDate')}
            cSortOrder={getColumnSortOrder('cArchiveDate')}
            className={oClasses['col-2']}
          />
        )}
        <HeaderColumn
          cLabel={intl.get('Dashboard.readings')}
          onSort={() => handleSortingChange('nReadings')}
          cSortOrder={getColumnSortOrder('nReadings')}
        />
        <HeaderColumn
          cLabel={intl.get('Dashboard.action')}
        />
      </div>
    </div>
  );
};

const FoldersList = ({
  aFolders,
  bShowArchived,
}) => {
  if (!aFolders) return null;

  return aFolders.map((oFolder) => (
    <Link key={oFolder.fldr_nid} to={`/folder/${oFolder.fldr_nid}`}>
      <div role="row" className={oClasses.row}>
        <div
          role="gridcell"
          className={classNames(
            bShowArchived ? oClasses['col-5'] : oClasses['col-4'],
            oClasses['icon-24']
          )}
          title={oFolder.fldr_cname}
        >
          <img src={imgfFolder} />
          <span className={oClasses.ellipsis}>
            {oFolder.fldr_cname}
          </span>
        </div>
        <div
          role="gridcell"
          className={bShowArchived ? oClasses['col-5'] : oClasses['col-10']}
        />
        {bShowArchived && (
          <>
            <div role="gridcell" className={oClasses['col-2']}>
              {formatDateToUserTZ(oFolder.fldr_dupdated, 'LL')}
            </div>
            <div role="gridcell" className={oClasses['col-2']} />
          </>
        )}
      </div>
    </Link>
  ));
};

const DocumentListItemWrapper = ({
  cPath,
  children,
}) => {
  if (!cPath) return children;
  return <Link to={cPath}>{children}</Link>;
}

const DocumentsListItem = ({
  oDocument,
  bShowArchived,
  onStageNotAllowed,
  onDocumentEdit,
  onDocumentDelete,
  onDocumentArchive,
  onDocumentRestore,
}) => {
  const cDocPath = getDocumentPath(oDocument);
  const oVersion = oDocument.oVersion;

  const handleStageNotAllowed = () => {
    if (cDocPath) return;
    switch (oVersion.stat_nid) {
      case 1:
        return onStageNotAllowed(intl.get(`Status.redaction`), oDocument.oWriter.user_cname);
      case 2:
        return onStageNotAllowed(intl.get(`Status.review`), oDocument.oReviewer.user_cname);
      case 3:
        return onStageNotAllowed(intl.get(`Status.publication`), oDocument.oResponsible.user_cname);
    }
  }

  const bCanUseActions =
    oDocument.oResponsible.user_nid === +oLocalStorage.get('nUserId') ||
    can('admin');

  /** @param {(oDocument) => void} fnHandler */
  const handleActionClick = (fnHandler) => {
    if (!bCanUseActions) return null;
    return (evtClick) => {
      evtClick.preventDefault();
      evtClick.stopPropagation();
      fnHandler(oDocument);
    }
  }

  const aActionButtons = [];
  if (bShowArchived) {
    aActionButtons.push(
      <ActionButton
        key="restore"
        cVariant="restore"
        title={intl.get('DocumentDetails.restore')}
        disabled={!bCanUseActions}
        onClick={handleActionClick(onDocumentRestore)}
      />
    );
  } else {
    aActionButtons.push(
      <ActionButton
        key="edit"
        cVariant="edit"
        title={intl.get('QualityPanelIndex.editar')}
        disabled={!bCanUseActions}
        onClick={handleActionClick(onDocumentEdit)}
      />
    );
    if (oVersion.stat_nid === 4) {
      aActionButtons.push(
        <ActionButton
          key="archive"
          cVariant="archive"
          title={intl.get('DocumentDetails.arquivar')}
          disabled={!bCanUseActions}
          onClick={handleActionClick(onDocumentArchive)}
        />
      );
    } else {
      aActionButtons.push(
        <ActionButton
          key="trash"
          cVariant="trash"
          title={intl.get('DocumentEditor.descartar_documento')}
          disabled={!bCanUseActions}
          onClick={handleActionClick(onDocumentDelete)}
        />
      );
    }
  }


  const cTitle = oDocument.doc_ctitle || intl.get('DocumentDetails.documento_sem_titulo');
  return (
    <DocumentListItemWrapper cPath={cDocPath} key={oDocument.oVersion.ver_nid} >
      <li role="row" className={oClasses.row} onClick={handleStageNotAllowed}>
        <div
          role="gridcell"
          className={classNames(
            !bShowArchived ? oClasses['col-4'] : oClasses['col-5'],
          )}
          title={cTitle}
        >
          <img src={imgfDocument} className="icon-documents" />
          <span className={oClasses.ellipsis}>{cTitle}</span>
        </div>
        {!bShowArchived && (
          <div
            role="gridcell"
            className={classNames(
              oClasses.col,
            )}
          >
            {getVersionStatus(oVersion.stat_nid, oDocument)}
          </div>
        )}
        <div
          role="gridcell"
          className={classNames(
            oClasses.col,
            // oClasses['horizontal-center']
          )}
        >
          <span>{oVersion.ver_cnumber}</span>
        </div>
        <div role="gridcell" className={oClasses['col-2']} title={oDocument.oResponsible.user_cname}>
          <span className={oClasses.ellipsis}>
            {oDocument.oResponsible.user_cname}
          </span>
        </div>
        <div role="gridcell" className={oClasses['col-2']}>
          {oVersion.ver_dpublished
            ? formatDateToUserTZ(oVersion.ver_dpublished, 'LL')
            : '-'
          }
        </div>
        {!bShowArchived && (
          oVersion.dtReviewDate ? (
            <div
              role="gridcell"
              className={classNames(
                oClasses['col-2'],
                new Date(oVersion.dtReviewDate) < new Date() && oClasses['overdue'],
              )}>
                {formatDateToUserTZ(oVersion.dtReviewDate, 'LL')}
            </div>
          ) : (
          <div role="gridcell" className={oClasses['col-2']}>
            {intl.get('FolderIndex.no_due')}
          </div>
          )
        )}
        {bShowArchived && (
          <div role="gridcell" className={oClasses['col-2']}>
            {formatDateToUserTZ(oVersion.ver_dupdated, 'LL')}
          </div>
        )}
        <div
          role="gridcell"
          className={classNames(
            oClasses.col,
            // oClasses['horizontal-center']
          )}
        >
          <span>
            {oVersion.stat_nid === 4
              ? ([
                oVersion.nReadingsConfirmed,
                intl.get('of').toLowerCase(),
                oVersion.nReadingsRequired
              ].join(' '))
              : '-'
            }
          </span>
        </div>
        <div
          role="gridcell"
          className={classNames(
            oClasses.col,
            oClasses.actions
          )}
        >
          {aActionButtons}
        </div>
      </li>
    </DocumentListItemWrapper>
  );
};

const DocumentsList = ({
  aDocuments,
  bShowArchived,
  onStageNotAllowed,
  onDocumentEdit,
  onDocumentDelete,
  onDocumentArchive,
  onDocumentRestore,
}) => {
  if (!aDocuments) return null;

  return aDocuments.map((oDocument) => (
    <DocumentsListItem
      key={oDocument.oVersion.ver_nid}
      oDocument={oDocument}
      onStageNotAllowed={onStageNotAllowed}
      bShowArchived={bShowArchived}
      onDocumentEdit={onDocumentEdit}
      onDocumentDelete={onDocumentDelete}
      onDocumentArchive={onDocumentArchive}
      onDocumentRestore={onDocumentRestore}
    />
  ));
};

const FolderContentsList = ({
  aDocuments,
  aFolders,
  bShowArchived,
  cSortHeader,
  cSortOrder,
  onStageNotAllowed = () => {},
  onSortingChange = () => {},
  onDocumentEdit = () => {},
  onDocumentDelete = () => {},
  onDocumentArchive = () => {},
  onDocumentRestore = () => {},
}) => {
  if (!aDocuments || !aFolders) return null;

  return (
    <div style={{overflowY: 'auto'}}>
      <div
        className={oClasses['folder-contents']}
        role="grid"
        aria-label={intl.get('Nav.document')}
      >
        <Header
          bShowArchived={bShowArchived}
          cSortHeader={cSortHeader}
          cSortOrder={cSortOrder}
          onSortingChange={onSortingChange}
        />
        <div
          className={oClasses['table-body']}
          role="rowgroup"
        >
          <FoldersList
            key="folders"
            aFolders={aFolders}
            bShowArchived={bShowArchived}
          />
          <DocumentsList
            key="documents"
            aDocuments={aDocuments}
            bShowArchived={bShowArchived}
            onStageNotAllowed={onStageNotAllowed}
            onDocumentEdit={onDocumentEdit}
            onDocumentDelete={onDocumentDelete}
            onDocumentArchive={onDocumentArchive}
            onDocumentRestore={onDocumentRestore}
          />
        </div>
      </div>
    </div>
  );
};

export default FolderContentsList;