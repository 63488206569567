import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/pt-br';
import React, { Component } from 'react';
import intl from 'react-intl-universal';
import SweetAlert from 'react-bootstrap-sweetalert/lib/dist/SweetAlert';
import { NavLink } from 'react-router-dom';
import { unfile, getVersionHistory } from '../../actions/ActionVersionHistory';
import imgfNoPic from '../../assets/img/nopic.svg';
import imgfLoading from '../../assets/img/preloader-black.gif';
import AltCheckbox from '../../components/AltCheckbox';
import Breadcrumb from '../../components/Breadcrumbs';
import OutsideHandlerClick from '../../components/OutsideHandlerClick';
import url from '../../config/Url';
import Page from '../default-v2/Page';
import CardHeader from '../default-v2/CardHeader';
import { Table } from 'reactstrap';
import './styles/versionhistory.scss';
import { checkModule } from '../../config/Permissions';
import documentImg from '../../assets/img/documents.svg';
import EncodedLocalStorage from '../../config/EncodedLocalStorage';
import { formatDateToUserTZ } from '../../utils/time';

const oLocalStorage = new EncodedLocalStorage();
export class VersionHistory extends Component {
  constructor(oProps) {
    super(oProps);

    moment.locale(oLocalStorage.get('cLanguage'));

    this.state = {
      bLoading: true,
      oDocument: {},
      nVerId: ''
    };
  }

  componentDidMount() {
    const { history } = this.props;
    checkModule(history, 'documents');

    this.fetchDocument();
  }

  fetchDocument = () => {
    const { setAlert } = this.props;
    const { match } = this.props;
    const nDocumentId = match.params.nId;

    getVersionHistory(nDocumentId)
      .then(oData => this.setState({ bLoading: false, ...oData }))
      .catch(oError => {
        this.setState({ bLoading: false });
        setAlert('error', oError.aMsgErrors);
      });
  };

  openInteractions = (evt, nVersionId) => {
    const { history } = this.props;

    if (
      evt.target.nodeName === 'INPUT' ||
      evt.target.nodeName === 'A' ||
      evt.target.className.includes('togglecheck') ||
      evt.target.className.includes('subForMark') ||
      evt.target.className.includes('right-menu') ||
      evt.target.className.includes('btn-menu') ||
      evt.target.className.includes('alt-checkbox') ||
      evt.target.className.includes('link-submenu')
    ) {
      return;
    }
    history.push(`/document/interaction/${nVersionId}`);
  };

  inputChangeCheckBoxHandler = (evt, cIdx) => {
    const { oDocument } = this.state;
    oDocument.versions[cIdx].checked = evt.target.checked;

    this.setState({
      oDocument
    });
  };

  handleSubmit = evt => {
    const { clearAlert, setAlert, history } = this.props;
    const { oDocument } = this.state;

    evt.preventDefault();
    clearAlert();

    const aVersions = oDocument.versions
      .filter(oVersion => oVersion.checked === true)
      .map(oVersion => oVersion.ver_nid);

    if (aVersions.length !== 2) {
      setAlert('error', intl.get('VersionHistory.selecione_corretamente'));
      return;
    }

    history.push(`/document/compare/${oDocument.doc_nid}/${aVersions[0]}/${aVersions[1]}`);
  };

  renderSubmenu = oVersion => {
    const rcmpList = (
      <>
        <li className="link-submenu">
          <NavLink to={`/document/detail/${oVersion.ver_nid}`} title="Visualizar versão">
            <i className="icon-eye-opened" /> {intl.get('VersionHistory.visualizar')}
          </NavLink>
        </li>
        <li className="link-submenu">
          <span
            role="button"
            tabIndex="0"
            onKeyPress={() => this.unfile(oVersion)}
            onClick={() => this.unfile(oVersion)}
            title="Desarquivar documento"
            className="link-submenu"
          >
            <i className="icon-move link-submenu" /> {intl.get('VersionHistory.arquivar')}
          </span>
        </li>
      </>
    );

    return (
      <OutsideHandlerClick handleClickOutside={this.closeSubmenu}>
        <ul className="submenu">{rcmpList}</ul>
      </OutsideHandlerClick>
    );
  };

  openSubmenu = evt => {
    if (evt.target.closest('div').getElementsByClassName('submenu')) {
      evt.target.closest('div').getElementsByClassName('submenu')[0].style = 'display: block';
    }
  };

  closeSubmenu = (evt, elWrapperRef) => {
    const elSubmenu = elWrapperRef;
    if (elSubmenu.getElementsByClassName('submenu')[0]) {
      elSubmenu.getElementsByClassName('submenu')[0].style = 'display: none';
    }
  };

  hideAlert = () => {
    this.setState({
      rcmpAlert: null
    });
  };

  redirect = () => {
    const { history } = this.props;
    history.push({
      pathname: '/document/filed/'
    });
  };

  unfile = oVersion => {
    this.setState({
      rcmpAlert: (
        <SweetAlert disabled showConfirm={false} title="Restaurando..." onConfirm={() => { }}>
          <img alt="Carregando" src={imgfLoading} />
        </SweetAlert>
      )
    });

    unfile(oVersion.ver_nid)
      .then(() =>
        this.setState({
          rcmpAlert: (
            <SweetAlert
              success
              title={intl.get('VersionHistory.restaurado')}
              onConfirm={() => {
                this.redirect();
              }}
            >
              {intl.get('VersionHistory.documento_restaurado')}
            </SweetAlert>
          )
        })
      )
      .catch(oError =>
        this.setState({
          rcmpAlert: (
            <SweetAlert error title={intl.get('opa')} onConfirm={this.hideAlert}>
              {oError.aMsgErrors}
            </SweetAlert>
          )
        })
      );
  };

  generateCardHeader = () => {
    const { history } = this.props;

    const rcmpCardHeader = <CardHeader history={history} />;
    return rcmpCardHeader;
  };

  render() {
    const { oDocument, nVerId, aBreadcrumbFolders, rcmpAlert, bLoading } = this.state;
    const { getAlert } = this.props;
    const bDocTitleLoaded = oDocument.doc_ctitle !== undefined && oDocument.doc_ctitle.length > 35;
    const rcmpBreadcrumb = (
      <div className="breadcrumbs-wrapper" style={{ fontSize: '14px' }}>
        <div className="inner-bread">
          <Breadcrumb bLastLink key={oDocument.doc_nid} aFolders={aBreadcrumbFolders} />
        </div>
        <NavLink to={`/document/detail/${nVerId}`} title={oDocument.doc_ctitle}>
          {bDocTitleLoaded ? oDocument.doc_ctitle.trunc(35) : oDocument.doc_ctitle}
        </NavLink>
        <span>></span>
        <span className="breacrumb-page">{intl.get('VersionHistory.breadcrumb_title')}</span>
      </div>
    );

    const rcmpCardHeader = this.generateCardHeader();

    const cPageTitle = intl.get('VersionHistory.title', { cName: (oDocument || {}).doc_ctitle });

    return (
      <Page
        loading={bLoading ? 1 : 0}
        rcmpBreadcrumb={rcmpBreadcrumb}
        cTitle={cPageTitle}
        cImage={documentImg}
        rcmpCardHeader={rcmpCardHeader}
        className="v2-version-history"
        cCurrentSideMenuOption={intl.get('Nav.document')}
      >
        {rcmpAlert}
        {getAlert()}

        {oDocument.doc_nid && (
          <form onSubmit={this.handleSubmit} className="form" name="comparar">
            <div className="heading-message">
              <p>{intl.get('VersionHistory.legend')}</p>
            </div>

            <Table responsive className="table-versions">
              <thead>
                <tr>
                  <th></th>
                  <th>{intl.get('VersionHistory.version')}</th>
                  <th>{intl.get('VersionHistory.justification')}</th>
                  <th>{intl.get('VersionHistory.data_hour')}</th>
                  <th>{intl.get('VersionHistory.responsable')}</th>
                </tr>
              </thead>
              <tbody>
                {(oDocument.versions || []).map((oVersion, cIndex) => {
                  const oVerResp = oDocument.document_responsibles.find(
                    oResp => oResp.user_nid === oVersion.user_responsible_nid
                  );
                  return (
                    <tr key={oVersion.ver_nid}>
                      <td>
                        <AltCheckbox
                          title={intl.get('AltCheckbox.status')}
                          checked={!!oVersion.checked}
                          value={!!oVersion.checked}
                          name="aSelected[]"
                          className=""
                          onChange={evt => this.inputChangeCheckBoxHandler(evt, cIndex)}
                        />
                      </td>
                      <td>{oVersion.ver_cnumber}</td>
                      <td>
                        <span title={oVersion.ver_cjustification}>{oVersion.ver_cjustification}</span>
                      </td>
                      <td>
                        {formatDateToUserTZ(oVersion.ver_dpublished, `L [${intl.get('VersionHistory.at')}] HH:mm`)}
                      </td>
                      <td>
                        {oVerResp && (
                          <div className="role-avatar">
                            <span className="avatar">
                              <img
                                src={oVerResp.user_cavatar ? oVerResp.user_cavatar : imgfNoPic}
                                alt={oVerResp.user_cname}
                              />
                            </span>
                            <span className="name" title={oVerResp.user_cname}>
                              {oVerResp.user_cname}
                            </span>
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>

            <button type="submit" className="btn">
              {intl.get('VersionHistory.button')}
            </button>
          </form>
        )}
      </Page>
    );
  }
}

export default VersionHistory;
