// depois pensar uma maneira de colocar divs dentro de li sem quebrar o layout. o elemento "li" por padrão não deve possuir atributo tabIndex
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import intl from 'react-intl-universal';
import { can, canAtLeast } from '../../config/Permissions';

// eslint-disable-next-line react/prefer-stateless-function
class NavMain extends Component {
  constructor(oProps) {
    super();
    this.state = {
      bWasMenuClicked: false
    };
  }

  closeMenu = evt => {
    evt.preventDefault();
    this.setState({
      bWasMenuClicked: true
    });
  };

  openMenu = evt => {
    evt.preventDefault();
    this.setState({
      bWasMenuClicked: false
    });
  };

  render() {
    const { bWasMenuClicked } = this.state;

    return (
      <nav>
        <ul className="nav">
          <li tabIndex="0" onMouseOver={this.openMenu}>
            {intl.get('Nav.document')}
            <ul
              className="submenu"
              style={{ visibility: bWasMenuClicked ? 'hidden' : 'visible' }}
              onClick={this.closeMenu}
            >
              <li>
                <NavLink to="/folder" title={intl.get('Nav.document')}>
                  {intl.get('Nav.document')}
                </NavLink>
              </li>
              <li>
                <NavLink to="/document/filed/" title={intl.get('Nav.filed_documents')}>
                  {intl.get('Nav.filed_documents')}
                </NavLink>
              </li>
              <li>
                <NavLink to="/trash" title={intl.get('Nav.lixeira')}>
                  {intl.get('Nav.lixeira')}
                </NavLink>
              </li>
            </ul>
          </li>

          <li className="withoutarrow">
            <NavLink to="/quality-panel" title={intl.get('Nav.quality_panel')}>
              {intl.get('Nav.quality_panel')}
            </NavLink>
          </li>

          <li className="withoutarrow">
            <NavLink to="/audit-management" title={intl.get('Nav.audit_management')}>
              {intl.get('Nav.audit_management')}
            </NavLink>
          </li>

          {canAtLeast(['user', 'team']) && (
            <li onMouseOver={this.openMenu}>
              {intl.get('Nav.usuarios')}
              <ul
                className="submenu"
                style={{ visibility: bWasMenuClicked ? 'hidden' : 'visible' }}
                onClick={this.closeMenu}
              >
                {can('team') && (
                  <li>
                    <NavLink to="/team" title={intl.get('Nav.equipes')}>
                      {intl.get('Nav.equipes')}
                    </NavLink>
                  </li>
                )}
                {can('user') && (
                  <li>
                    <NavLink to="/user" title={intl.get('Nav.usuarios')}>
                      {intl.get('Nav.usuarios')}
                    </NavLink>
                  </li>
                )}
              </ul>
            </li>
          )}

          {/* <li>Controle de equipamento</li>

        <li>Não conformidades</li>

        <li>Qualificação de fornecedores</li> */}

          {canAtLeast('report') && (
            <li tabIndex="0" onMouseOver={this.openMenu}>
              {intl.get('Nav.relatorio')}
              <ul
                className="submenu"
                style={{ visibility: bWasMenuClicked ? 'hidden' : 'visible' }}
                onClick={this.closeMenu}
              >
                <li>
                  <NavLink to="/document/download" title="Download">
                    {intl.get('Nav.download')}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/document/print" title="Impressão">
                    {intl.get('Nav.impressao')}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/report/reading-document/" title={intl.get('Nav.leitura_documento')}>
                    {intl.get('Nav.leitura_documento')}
                  </NavLink>
                </li>
              </ul>
            </li>
          )}
        </ul>
      </nav>
    );
  }
}

export default NavMain;
