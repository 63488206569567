import React from 'react';
const aIcons = {
    application: 'icon icon-document',
    image: 'icon icon-file-picture',
    other: 'icon icon-document',
    folder: 'icon icon-folder',
    video: 'icon icon-play',
};

export default function FileIcon(oProps) {
    const { cExtension } = oProps;

    let fIcon;

    if (!cExtension) {
        fIcon = 'icon icon-document';
    } else {
        fIcon = aIcons[cExtension.split('/').shift(0)] || 'icon icon-document';
    }

    return <i className={fIcon} />;
};

