/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import OutsideHandlerClick from '../../components/OutsideHandlerClick';

class SideMenuItem extends Component {
  constructor(oProps) {
    super(oProps);

    this.state = {
      bIsSubitemOpened: false,
      bIsBorderVisible: false,
      bIsListOpened: false
    };

    this.oRefImage = React.createRef();
  }

  clickItem = () => {
    const { bIsSubitemOpened, bIsListOpened } = this.state;
    const { cLink, aSubitems } = this.props;

    if (aSubitems.length === 0) {
      return;
    }

    if (bIsListOpened) {
      this.setState({ bIsSubitemOpened: false });
    } else {
      this.setState({ bIsSubitemOpened: true });
    }
  };

  renderSubitems = () => {
    const { aSubitems } = this.props;
    return Object.keys(aSubitems).map(cKey => aSubitems[cKey]);
  };

  handleHover = (evt, bIsHovering) => {
    evt.preventDefault();
    evt.stopPropagation();
    const { cIcon, cIconHover, cSelectedMenuName, cName } = this.props;
    const { bIsSubitemOpened } = this.state;
    if (cIconHover.length === 0) {
      this.setState({
        bIsBorderVisible: bIsHovering
      });
      return;
    }

    if (bIsSubitemOpened || cSelectedMenuName == cName) {
      return;
    }

    if (bIsHovering) {
      this.oRefImage.current.src = cIconHover;
      this.setState({
        bIsBorderVisible: true
      });
      return;
    }

    this.oRefImage.current.src = cIcon;
    this.setState({
      bIsBorderVisible: false
    });
  };

  renderSideMenuItem = () => {
    // eslint-disable-next-line react/destructuring-assignment
    const {
      cIcon,
      cLink,
      aSubitems,
      cIconHover,
      cName,
      cSideMenuIconClassName,
      cSideMenuItemClassName,
      bShowName,
      cSideMenuNameClassName,
      onClick,
      bShowBorder,
      cSelectedMenuName,
      bIsUserConfigMenu
    } = this.props;
    const { bIsSubitemOpened, bIsBorderVisible, bIsListOpened } = this.state;
    let cSubitemClassName = 'v2-sidemenuitem-subitems';
    let cLiClassName = cSideMenuItemClassName
      ? `v2-sidemenuitem-item ${cSideMenuItemClassName}`
      : 'v2-sidemenuitem-item';
    let cSubItemIcon = cSelectedMenuName == cName ? cIconHover : cIcon;

    if (bIsSubitemOpened) {
      cSubitemClassName += ' v2-sidemenuitem-subitems-opened';
      cLiClassName += ' v2-selected-sidemenuitem';
    }

    if (cSelectedMenuName == cName) {
      cLiClassName += ' v2-selected-sidemenuitem';
      cSubItemIcon = cIconHover;
    }

    if (bIsUserConfigMenu == true) cLiClassName += ' v2-selected-sidemenuitem';

    if (bIsBorderVisible) cLiClassName += ' v2-sidemenu-border-visible';

    return (
      <li className={`${cLiClassName} ${cName === 'Relatório' ? 'v2-sidemenuitem-report' : ''}`}>
        {aSubitems.length > 0 && (
          <OutsideHandlerClick
            cClassName={cSubitemClassName}
            handleClickOutside={evt => {
              if (!bIsSubitemOpened) return;
              this.setState({ bIsSubitemOpened: false });
              this.handleHover(evt, false);
            }}
            fnHandleRef={elSubItems => {
              if (!elSubItems) return;
              const elParent = elSubItems.parentElement;
              if (!elParent) return;
              const nTopPos = elParent.getBoundingClientRect().y;
              elSubItems.style.top = `${nTopPos}px`;
            }}
          >
            <ul className="v2-list">{aSubitems}</ul>
          </OutsideHandlerClick>
        )}
        <div
          role="button"
          tabIndex={0}
          className="v2-sidemenuitem-content"
          onKeyPress={() => null}
          onClick={() => {
            this.setState({
              bIsListOpened: !bIsListOpened
            });
            // if (bIsListOpened === false) {
            this.clickItem();
            // }

            // if (onClick) {
            // } else {
            // this.clickItem();
            // }
          }}
          onMouseEnter={evt => this.handleHover(evt, true)}
          onMouseLeave={evt => this.handleHover(evt, false)}
          onFocus={evt => this.handleHover(evt, true)}
          onBlur={evt => this.handleHover(evt, false)}
        >
          <p>
            <img
              className={`${
                cSideMenuIconClassName ? `v2-sidemenuitem-icon ${cSideMenuIconClassName}` : 'v2-sidemenuitem-icon'
              } `}
              src={cSubItemIcon}
              alt={cName}
              style={{ display: cSubItemIcon ? 'inline' : 'none' }}
              ref={this.oRefImage}
            />
            <span className={`${cSideMenuNameClassName || ''} ${cName === 'Relatório' ? 'cname' : ''} `}>
              {bShowName ? cName : ''}
            </span>
          </p>
        </div>

        {bShowBorder ? (
          <div className="v2-sidemenu-border">
            <span className="v2-sidemenuitem-border-solid" />
            <span className="v2-sidemenuitem-border-dotted" />
          </div>
        ) : (
          ''
        )}
      </li>
    );
  };

  render = () => {
    const { cLink, cExternalLink, aSubitems, cName, onClick } = this.props;
    if (aSubitems.length === 0) {
      if (cExternalLink) {
        return (
          <a href={cExternalLink} className={`v2-link ${cName === 'Painel Operacional' ? 'v2-menu-main' : ''}`}>
            {this.renderSideMenuItem()}
          </a>
        );
      }

      if (cName === 'Sair') {
        return (
          <div className="v2-link v2-menu-main" onClick={() => onClick()}>
            {this.renderSideMenuItem()}
          </div>
        );
      }
      return (
        <Link to={cLink} className="v2-link v2-menu-main">
          {this.renderSideMenuItem()}
        </Link>
      );
    }

    return (
      <div className={`v2-link ${cName === 'Relatório' ? 'v2-list-report' : ''}`}>{this.renderSideMenuItem()}</div>
    );
  };
}

SideMenuItem.defaultProps = {
  cName: '',
  cIcon: '',
  cIconHover: '',
  cLink: '',
  cExternalLink: '',
  aSubitems: [],
  bShowName: false,
  bShowBorder: false,
  cSideMenuIconClassName: '',
  cSideMenuItemClassName: '',
  cSelectedMenuName: '',
  cSideMenuNameClassName: '',
  bIsUserConfigMenu: false
};
export default SideMenuItem;
