import React, { Component } from 'react';
import { Input } from 'reactstrap';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';

export class InputPassword extends Component {
  constructor(oProps) {
    super(oProps);

    this.state = {
      "cValue": oProps.cValue,
      "bShowPassword": false
    };
  }

  showPasswordChange = () => {
    this.setState((oCurrentState) => ({
      "bShowPassword": !oCurrentState.bShowPassword
    }));
  };

  handleChange = (evt) => {
    const { onChange } = this.props;
    onChange(evt);
  };

  render() {
    const { bShowPassword, cValue } = this.state;
    const { cRequired } = this.props;
    return (
      <div className="password">
        <Input
          maxLength="250"
          className="form-control"
          id="cPassword"
          placeholder={intl.get('senha_placeholder')}
          name="cPassword"
          type={(bShowPassword)? 'text' : 'password'}
          value={cValue}
          required={cRequired}
          {...this.props}
          onChange={this.handleChange}
        />
        <span
          aria-hidden
          title={intl.get('senha_title')}
          onClick={this.showPasswordChange}
          className={`btn-hover ${(bShowPassword)? 'alt' : ''}`}
        />
      </div>
    );
  }
}
InputPassword.propTypes = {
  required: PropTypes.bool
};

InputPassword.defaultProps = {
  required: true
};

export default InputPassword;
