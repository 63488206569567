import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { Input } from 'reactstrap';
import NavItem from '../../default-v2/NavItem';
import '../../default-v2/styles/filter.scss';
import imgfSearch from '../../../assets/img/search.svg';
import imgfSelectLeft from '../../default-v2/img/select-left.svg';

class Filter extends Component {
  constructor(oProps) {
    super(oProps);

    this.state = {
      bSearching: false,
      cText: ''
    };
  }

  getFilterParams = () => {
    const { cText } = this.state;

    return {
      cText
    };
  };

  handleFilterChange = evt => {
    this.setState({
      cText: evt.target.value
    });
  };

  getSearching = () => {
    const { fnOnType, cFilterName, cPlaceholder } = this.props;
    const { cText } = this.state;
    return (
      <div className="v2-filter-searching">
        <span className="v2-filter-searching-icon">
          <img src={imgfSearch} alt={intl.get('search')} className="v2-filter-searching-image" />
        </span>
        <span
          className="v2-filter-searching-nav close"
          role="button"
          tabIndex={0}
          onClick={() => {
            this.setState({ bSearching: false });
          }}
          onKeyPress={() => {
            this.setState({ bSearching: false });
          }}
        >
          &nbsp;
        </span>
        <Input
          className="v2-filter-searching-input"
          type="text"
          autoFocus
          name={cFilterName}
          id={cFilterName}
          placeholder={cPlaceholder}
          value={cText}
          onChange={evt => {
            const cNewText = evt.target.value;
            this.handleFilterChange(evt);
            fnOnType(evt, {
              cText: cNewText
            });
          }}
        />
      </div>
    );
  };

  getNotSearching = () => {
    const { history } = this.props;
    return (
      <>
        <div className="v2-filter-notsearching-left">
          <div
            className="v2-page-btn-goback"
            onClick={event => {
              if (history) history.goBack();
            }}
          >
            <img src={imgfSelectLeft} alt="" />
          </div>
          {this.renderProgressBar()}
        </div>
        <div className="v2-filter-notsearching">
          {this.renderButtons()}
          {this.renderCheckboxes()}
          <ul>
            {this.renderItems()}
            <NavItem
              className="v2-filter-iconSearch"
              cIcon={imgfSearch}
              cIconFocus={imgfSearch}
              cText={intl.get('search')}
              onClick={() => {
                this.setState({ bSearching: true });
              }}
            />
            {this.renderRightMenu()}
          </ul>
        </div>
      </>
    );
  };

  renderProgressBar = () => {
    const { rcmpProgressBar } = this.props;
    return rcmpProgressBar ?? '';
  };

  renderRightMenu = () => {
    const { rcmpRightMenu } = this.props;
    return rcmpRightMenu ?? '';
  };

  renderButtons = () => {
    const { aButtons } = this.props;
    const rcmpButtons = Object.keys(aButtons).map(cKey => aButtons[cKey]);
    return rcmpButtons;
  };

  renderCheckboxes = () => {
    const { aCheckboxes } = this.props;
    if (typeof aCheckboxes === 'undefined') {
      return '';
    }

    const rcmpCheckboxes = Object.keys(aCheckboxes).map(cKey => aCheckboxes[cKey]);
    return rcmpCheckboxes;
  };

  renderItems = () => {
    const { aItems } = this.props;
    if (typeof aItems === 'undefined') {
      return '';
    }

    const rcmpNavItems = Object.keys(aItems).map(cKey => aItems[cKey]);
    return rcmpNavItems;
  };

  render = () => {
    const { bSearching, rcmpAlert } = this.state;
    const { className } = this.props;
    let result = '';
    let cClassName = 'v2-cardheader-filter ';

    if (bSearching) {
      result = this.getSearching();
    } else {
      result = this.getNotSearching();
    }

    if (className.length > 0) {
      cClassName += className;
    }
    return (
      <div className={cClassName}>
        {rcmpAlert}
        {result}
      </div>
    );
  };
}
Filter.defaultProps = {
  fnOnType: null,
  cFilterName: '',
  cPlaceholder: '',
  aButtons: [],
  aCheckboxes: [],
  aItems: [],
  rcmpRightMenu: null,
  rcmpProgressBar: null,
  history: null,
  className: ''
};
export default Filter;
