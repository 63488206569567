import React from 'react';
import './styles/requisites.scss';
import decodeHTMLEntities from '../../utils/decodeHTMLEntities';
import { formatDateToUserTZ } from '../../utils/time';

const fnGetIconClass = (cTitle) => {
  switch (cTitle) {
    case 'Requisito incluído':
      return 'icon-magic-wand';
    case 'Inclusão de Quadro':
    case 'Evidência Incluída':
      return 'icon-attachment';
    case 'Evidência Excluída':
    case 'Desvinculação de Quadro':
      return 'icon-bin';
    case 'Comentário Livre':
    case 'Comentário do auditor':
      return 'icon-speak';
    case 'Pendente':
      return 'icon-pending';
    case 'Concluído':
      return 'icon-done';
    case 'Não se aplica':
      return 'icon-not-applicable';
    case 'Requisito alterado':
      return 'icon-edit';
    default:
      return '';
  }
};

export default function HistoryRequisite(props) {
  return (
    <div className="historyBox">
      {props.historyReq.length > 0 && (
        <ul>
          {props.historyReq.map(history => (
            <li key={history.rh_nid}>
              <i className={`icon ${fnGetIconClass(history.rh_ctitle)}`} />
              <h4>
                {history.rh_ctitle}{' '}
                <span className="timeHistory">
                  {formatDateToUserTZ(history.rh_dupdated)}
                </span>
              </h4>

              <p>{decodeHTMLEntities(history.rh_cdescription.replace(/&nbsp;/g, ' ').replace(/(<([^>]+)>)/gi, ''))}</p>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
