import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert/lib/dist/SweetAlert';
import moment from 'moment';
import intl from 'react-intl-universal';
import Axios from '../../config/Axios';
import Page from '../default-v2/Page';
import DocumentEdit from '../document/DocumentEdit';
import ScrollBar from '../../components/ScrollBar';
import { getStatus } from '../default/Status';
import EncodedLocalStorage from '../../config/EncodedLocalStorage';

export class NoLink extends Component {
  constructor(oProps) {
    super(oProps);

    this.state = {
      bLoading: true,
      aUsers: [],
      aItems: []
    };
  }

  componentDidMount() {
    const { setAlert } = this.props;
    Axios.get('/document/no-bonds')
      .then(oRes => {
        this.setState({
          bLoading: false,
          aUsers: oRes.data.aUsers,
          aItems: oRes.data.aItems
        });
      })
      .catch(oError => {
        this.setState({ bLoading: false });
        setAlert('error', oError.aMsgErrors);
      });
  }

  renderRequest = oRequest => {
    const { aUsers } = this.state;
    const oLocalStorage = new EncodedLocalStorage();
    return (
      <li key={oRequest.rqst_nid} className="no-pointer">
        <h3>
          <i className="icon-edit" />

          {oRequest.document ? (
            <>{intl.getHTML('NoLink.solicitacao_edicao', { nome: oRequest.document.doc_ctitle })}</>
          ) : (
            <>{intl.getHTML('NoLink.solicitacao_documento')}</>
          )}
        </h3>

        <pre style={{ whiteSpace: "pre-wrap" }} className="message">{oRequest.rqst_cjustification}</pre>

        <p className="relevant-info">
          {intl.get('NoLink.solicitado_para')} <strong>{oRequest.user_destiny.user_cname}</strong>{' '}
          {moment
            .parseZone(oRequest.rqst_dcreated)
            .tz(oLocalStorage.get('cTimezone'))
            .format('DD/MM/YYYY HH:mm:ss')}
        </p>

        <form className="form ajaxsend" onSubmit={evt => this.changeResponsible(evt, oRequest)}>
          <label className="split3">
            {intl.get('NoLink.alterar_responsavel')} <span className="required">*</span>
            <select required name="user_nid_responsible">
              <option value="">{intl.get('select')}</option>
              {aUsers.map(oUser => (
                <option key={oUser.user_nid} value={oUser.user_nid}>
                  {oUser.user_cname}
                </option>
              ))}
            </select>
          </label>

          <button type="submit" value="salvar" className="btn" title={intl.get('salvar')}>
            {intl.get('salvar')}
          </button>
        </form>
      </li>
    );
  };

  changeResponsible = (evt, oRequest) => {
    evt.preventDefault();

    const nUserId = evt.target.getElementsByTagName('select')[0].value;

    Axios.put(`/request/update-responsible/${oRequest.rqst_nid}`, { user_nid_responsible: nUserId })
      .then(() => {
        this.setState({
          rcmpAlert: (
            <SweetAlert
              success
              title="Alterado!"
              onConfirm={() => {
                this.hideAlert();
                this.componentDidMount();
              }}
            >
              {intl.get('NoLink.responsavel_alterado')}
            </SweetAlert>
          )
        });
      })
      .catch(() => {
        this.setState({
          rcmpAlert: (
            <SweetAlert error title="Opa!" onConfirm={this.hideAlert}>
              {intl.get('NoLink.responsavel_nao_alterado')}
            </SweetAlert>
          )
        });
      });
  };

  hideAlert = () => {
    this.setState({
      rcmpAlert: null
    });
  };

  successMessage = cMsg => {
    const { setAlert } = this.props;
    this.hideAlert();
    this.componentDidMount();
    setAlert('success', cMsg);
  };

  openModalEditDocument = (evt, oDocument) => {
    evt.preventDefault();
    this.setState({
      rcmpAlert: (
        <SweetAlert customClass="modal-edit lg scrollBar" title="" onConfirm={this.hideAlert} showConfirm={false}>
          <div className="head-modal">
            {intl.get('DocumentDetails.editar_documento')}
            <span
              aria-labelledby="head-modal"
              tabIndex={0}
              onClick={this.hideAlert}
              className="close"
              role="button"
              onKeyPress={this.hideAlert}
            />
          </div>
          <ScrollBar>
            <DocumentEdit
              onSuccess={cMsg => {
                this.successMessage(cMsg);
              }}
              closeModal={this.hideAlert}
              oDocument={oDocument}
            />
          </ScrollBar>
        </SweetAlert>
      )
    });
  };

  renderDocument = oDocument => (
    <li>
      <div
        role="button"
        tabIndex="0"
        className="oDocument"
        key={oDocument.doc_nid}
        onClick={evt => this.openModalEditDocument(evt, oDocument)}
        onKeyPress={evt => this.openModalEditDocument(evt, oDocument)}
      >
        <h3>
          <i className="icon-document-create" />
          <strong> {oDocument.version.status.stat_cname}: </strong>
          {oDocument.doc_ctitle}
        </h3>

        {oDocument.version.ver_cjustification && <pre className="message">{oDocument.version.ver_cjustification}</pre>}

        {oDocument.version.interactions &&
          getStatus('in-review', 'Document', oDocument.version.status) &&
          getStatus('in-writing', 'Document', oDocument.version.status) && (
            <pre className="message response">{oDocument.version.interactions[0].intr_ctext}</pre>
          )}

        <p className="relevant-info">
          {intl.get('responsible')}:<strong> {oDocument.document_responsibles[0].user_cname}</strong>
        </p>
      </div>
    </li>
  );

  render() {
    const { rcmpAlert, aItems, bLoading } = this.state;
    const { getAlert } = this.props;

    return (
      <Page loading={bLoading ? 1 : 0}>
        {rcmpAlert}
        {getAlert()}
        <h1>{intl.get('NoLink.sem_vinculo')}</h1>

        <ul className="pending-list">
          {aItems.map(oItem =>
            'rqst_cjustification' in oItem ? this.renderRequest(oItem) : this.renderDocument(oItem)
          )}
        </ul>
      </Page>
    );
  }
}

export default NoLink;
