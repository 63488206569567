import Axios from '../config/Axios';
import loadBreadcrumb from './ActionBreadcrumb';

export default function getInteractions(nVersionId) {
  return Axios.get(`/document/interaction/${nVersionId}`).then(
    (oResponse) =>
      new Promise((resolve, reject) => {
        loadBreadcrumb(oResponse.data.oVersion.document.fldr_nid)
          .then((aBreadcrumbFolders) => {
            resolve({
              ...oResponse.data,
              "oDocument": oResponse.data.oDocument,
              aBreadcrumbFolders
            });
          })
          .catch((oError) => reject(oError));
      })
  );
}
