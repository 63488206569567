import React, { Component } from 'react';
import { Input } from 'reactstrap';
import moment from 'moment';

class DateLimitation extends Component {

  constructor(oProps) {
    super(oProps);

    const { cDiffTime, cValue } = oProps;

    this.state = {
      cValue,
      cDiffTime,
      nMax: this.getTime(cDiffTime)
    };
  }

  static getDerivedStateFromProps(oNextProps, oPrevState) {
    if (oNextProps.cDiffTime !== oPrevState.cDiffTime) {
      let nMax = 20000;

      if (oNextProps.cDiffTime === 'D')
        nMax = moment('2100-12-31').diff(moment(), "days");
      else if (oNextProps.cDiffTime === 'S')
        nMax = moment('2100-12-31').diff(moment(), "weeks");
      else if (oNextProps.cDiffTime === 'M')
        nMax = moment('2100-12-31').diff(moment(), "months");
      else
        nMax = moment('2100-12-31').diff(moment(), "years");

      return {
        cDiffTime: oNextProps.cDiffTime,
        nMax
      };
    }

    if (oNextProps.cValue !== oPrevState.cValue) {
      return {
        cValue: oNextProps.cValue
      };
    }

    return '';
  }

  getTime = (cDiffTime) => {
    let nMax = 20000;

    if (cDiffTime === 'D')
      nMax = moment('2100-12-31').diff(moment(), "days");
    else if (cDiffTime === 'S')
      nMax = moment('2100-12-31').diff(moment(), "weeks");
    else if (cDiffTime === 'M')
      nMax = moment('2100-12-31').diff(moment(), "months");
    else
      nMax = moment('2100-12-31').diff(moment(), "years");

    return nMax;
  }

  handleChange = (evtChange) => {
    const { fnHandleChange } = this.props;

    this.setState({
      cValue: evtChange.target.value
    });

    fnHandleChange(evtChange);
  }

  render() {
    const { cId, cName, bIsRequired, nMin, cClassName } = this.props;
    const { cValue, nMax } = this.state;

    return (
      <Input
        type="number"
        id={cId}
        name={cName}
        required={bIsRequired}
        min={nMin}
        max={nMax}
        className={cClassName}
        value={cValue}
        onChange={this.handleChange}
      />
    );
  }
}

DateLimitation.defaultProps = {
  cDiffTime: "D",
  cId: "",
  cName: "",
  bIsRequired: true,
  nMin: 0,
  cClassName: "",
  cValue: "",
  fnHandleChange: (evtHandleChange) => { }
};

export default DateLimitation;
