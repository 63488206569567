import React from "react";

export default function ActivitiesIcon(props) {
  return (
    <svg
      width="17"
      height="20"
      viewBox="0 0 20 16"
      fill={props.fill || "#6CB6E3"}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.874 15L15.164 10.32C15.7171 9.30114 16.0046 8.15933 16 7C16 5.14348 15.2625 3.36301 13.9497 2.05025C12.637 0.737498 10.8565 0 8.99997 0C7.14346 0 5.36298 0.737498 4.05023 2.05025C2.73747 3.36301 1.99997 5.14348 1.99997 7C1.99653 8.15967 2.28543 9.30151 2.83997 10.32L0.133975 15C0.0462073 15.152 1.11487e-06 15.3245 0 15.5C-1.11483e-06 15.6755 0.0462029 15.848 0.133968 16C0.221734 16.152 0.347968 16.2782 0.499983 16.366C0.651999 16.4538 0.82444 16.5 0.999975 16.5H3.86997L5.32997 18.96C5.42153 19.1095 5.54917 19.2337 5.70117 19.321C5.85318 19.4084 6.02469 19.4562 6.19997 19.46H6.33997C6.49094 19.4393 6.6352 19.3845 6.76171 19.2995C6.88823 19.2146 6.99367 19.1019 7.06997 18.97L8.99997 15.65L10.934 19C11.0114 19.13 11.1173 19.2408 11.2438 19.3239C11.3702 19.4071 11.5139 19.4604 11.664 19.48H11.804C12.0649 19.4816 12.3161 19.3811 12.504 19.2C12.5712 19.1393 12.6286 19.0684 12.674 18.99L14.134 16.53H17C17.1789 16.5301 17.3546 16.4828 17.5092 16.3929C17.6638 16.303 17.7919 16.1737 17.8803 16.0182C17.9687 15.8627 18.0144 15.6866 18.0125 15.5077C18.0107 15.3289 17.9615 15.1537 17.87 15H17.874ZM6.19397 16.53L5.29997 15.04C5.21234 14.8922 5.08808 14.7694 4.9392 14.6836C4.79031 14.5978 4.62182 14.5518 4.44997 14.55H2.72397L4.15397 12.07C5.1387 13.0189 6.37979 13.6585 7.72397 13.91L6.19397 16.53ZM8.99997 12C7.84321 11.9998 6.72231 11.5985 5.82825 10.8645C4.93419 10.1305 4.32229 9.10918 4.09681 7.9746C3.87133 6.84003 4.04623 5.66237 4.59169 4.64228C5.13716 3.6222 6.01944 2.8228 7.08822 2.3803C8.15701 1.93779 9.34615 1.87956 10.4531 2.21552C11.56 2.55148 12.5161 3.26085 13.1587 4.22275C13.8012 5.18466 14.0903 6.3396 13.9768 7.49078C13.8632 8.64195 13.354 9.71815 12.536 10.536C12.0716 11.0003 11.5203 11.3685 10.9136 11.6197C10.3069 11.8709 9.65663 12.0001 8.99997 12ZM13.55 14.55C13.3781 14.5518 13.2096 14.5978 13.0608 14.6836C12.9119 14.7694 12.7876 14.8922 12.7 15.04L11.81 16.53L10.29 13.88C11.6295 13.6234 12.8659 12.9843 13.85 12.04L15.28 14.52L13.55 14.55Z"
      />
    </svg>
  )
}
