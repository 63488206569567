/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Component } from 'react';
import intl from 'react-intl-universal';
import Axios from '../../config/Axios';
import imgfLoading from '../../assets/img/preloader-black.gif';
import Breadcrumb from '../../components/Breadcrumbs';
import Page from '../default-v2/Page';
import ButtonBack from '../../components/ButtonBack';
import SweetAlert from 'react-bootstrap-sweetalert/lib/dist/SweetAlert';
import SubMenu from '../../components/SubMenu';
import SubMenuChild from '../../components/SubMenuChild';
import { unfile } from '../../actions/ActionVersionHistory';
import { checkModule } from '../../config/Permissions';

export class Filed extends Component {
  constructor(oProps) {
    super(oProps);

    this.state = {
      bLoading: true,
      aDocuments: [],
      bIsLoad: false,
      aPages: [
        {
          cName: 'modulo_documentos',
          cLink: '/folder'
        },
        {
          cName: 'Filed.titulo',
          cLink: undefined
        }
      ]
    };
  }

  componentDidMount = () => {
    const { history } = this.props;
    checkModule(history, 'documents');

    this.fetchDocuments();
    this.setState({
      bIsLoad: true
    });
  };

  hideAlert = () => {
    this.setState({
      rcmpAlert: null
    });
  };

  fetchDocuments = () => {
    const { setAlert } = this.props;

    Axios.get('/filed')
      .then(oRes => {
        this.setState({
          bLoading: false,
          aDocuments: oRes.data,
          bIsLoad: false
        });
      })
      .catch(oError => {
        setAlert('error', oError.aMsgErrors);
        // handleError(oError);
        this.setState({
          bIsLoad: false,
          bLoading: false
        });
      });
  };

  openVersionHistory = (evt, nId) => {
    const { history } = this.props;

    if (evt.target.nodeName === 'A') {
      return;
    }

    history.push(`/document/history/${nId}`);
  };

  restoreFiledDocument = nDocId => {
    this.setState({
      rcmpAlert: (
        <SweetAlert disabled showConfirm={false} title="Restaurando..." onConfirm={() => {}}>
          <img alt="Carregando" src={imgfLoading} />
        </SweetAlert>
      )
    });

    unfile(nDocId)
      .then(() =>
        this.setState({
          rcmpAlert: (
            <SweetAlert
              success
              title={intl.get('VersionHistory.restaurado')}
              onConfirm={() => {
                this.fetchDocuments();
                this.hideAlert();
              }}
            >
              {intl.get('VersionHistory.documento_restaurado')}
            </SweetAlert>
          )
        })
      )
      .catch(oError =>
        this.setState({
          rcmpAlert: (
            <SweetAlert error title={intl.get('opa')} onConfirm={this.hideAlert}>
              {oError.aMsgErrors}
            </SweetAlert>
          )
        })
      );
  };

  showRestore = (evt, oDocument) => {
    this.setState({
      rcmpAlert: (
        <SweetAlert
          showCancel
          confirmBtnText={intl.get('confirmar')}
          cancelBtnText={intl.get('cancel')}
          cancelBtnBsStyle="danger"
          title={intl.get('restaurar')}
          onConfirm={evt => this.restoreFiledDocument(oDocument.doc_nid)}
          onCancel={this.hideAlert}
        >
          <p className="bold-text">{intl.getHTML('Filed.restore_document', { cName: oDocument.doc_ctitle })}</p>
        </SweetAlert>
      )
    });
  };

  render() {
    const { rcmpAlert, bIsLoad, aDocuments, aPages, bLoading } = this.state;
    return (
      <Page loading={bLoading ? 1 : 0}>
        {rcmpAlert}
        <h1>{intl.get('Filed.titulo')}</h1>
        <Breadcrumb aPages={aPages} />
        {bIsLoad ? (
          <div className="centered-text">
            {' '}
            <img alt={intl.get('carregando')} src={imgfLoading} /> <br /> {intl.get('carregando')}
          </div>
        ) : (
          <>
            {aDocuments.length === 0 && (
              <p className="empty-folder">
                <strong>{intl.get('Filed.sem_documento')}</strong>
                {intl.get('Filed.mensagem1')}
              </p>
            )}
          </>
        )}

        <ul className="document-list">
          {aDocuments.map(oDocument => (
            <li key={oDocument.doc_nid}>
              <h2>{oDocument.doc_ctitle}</h2>

              <SubMenu>
                <SubMenuChild
                  onkeyDown={this.handleKeyDown}
                  onClick={evt => this.showRestore(evt, oDocument)}
                  cTitle={''}
                  cClassName={'icon-document'}
                  cIconName={intl.get('restaurar')}
                />
              </SubMenu>

              <div className="saved-in">
                <strong>{intl.get('Filed.salvo')}</strong>
                <p className="breadcrumbs" style={{ display: 'block' }}>
                  <a href={`/folder/${oDocument.fldr_nid}`}>{oDocument.folder.fldr_cname}</a>
                </p>
              </div>
            </li>
          ))}
        </ul>

        <div className="button-stack">
          <ButtonBack />
        </div>
      </Page>
    );
  }
}

export default Filed;
