import React, { Component } from 'react';
import intl from 'react-intl-universal';
import imgfLoading from '../../assets/img/preloader-black.gif';

class DatabaseBeingCreated extends Component {
  constructor(oProps) {
    super(oProps);
    const { cToken } = this.props;

    document.body.classList.add('w-bkg');

    this.state = {
      "cToken": (cToken || undefined)
    };
  }

  render() {
    const { cToken } = this.state;
    return (
      <section className="box-creating-database">
        <h1>{intl.get('DatabaseBeingCreated.quase_pronto')}</h1>

        <p className="notice backup-notice">{intl.get('DatabaseBeingCreated.criando_banco')}</p>
        {cToken !== undefined && <img src={imgfLoading} alt="" />}
      </section>
    );
  }
}

export default DatabaseBeingCreated;
