import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { Button } from 'reactstrap';
import { getFolder, moveFolder } from '../../actions/ActionFolderMove';
import authorizedFolders from '../../actions/ActionAuthorizedFolders';
import Breadcrumb from '../../components/Breadcrumbs';
import ButtonBack from '../../components/ButtonBack';
import TreeView from '../../components/TreeView';
import PageClean from '../default-v2/PageClean';
import { Alert } from '../../components/Alert';
import { hasModule, checkModule } from '../../config/Permissions';

class FolderMove extends Component {
  constructor(oProps) {
    super(oProps);

    this.state = {
      bLoading: true,
      rcmpAlertMsg: '',
      nDestinyFolderId: null
    };
  }

  componentDidMount() {
    const { nFolderId, history } = this.props;
    const { fnErrorRequest } = this.props;
    checkModule(history, 'documents');

    Promise.all([
      getFolder(nFolderId).then(oFolder => this.setState({ oFolder })),
      authorizedFolders().then(aFolders => this.setState({ aTreeViewFolders: aFolders }))
    ])
      .then(() => this.setState({ bLoading: false }))
      .catch(oError => {
        fnErrorRequest(oError.aMsgErrors);
      });
  }

  inputChangeHandler = evt => {
    this.setState({ nDestinyFolderId: evt.target.value });
  };

  handleSubmit = evt => {
    evt.preventDefault();

    const { oFolder, nDestinyFolderId } = this.state;
    const { history, fnErrorRequest } = this.props;

    moveFolder(oFolder.fldr_nid, nDestinyFolderId)
      .then(() => {
        history.push({
          state: { aAlertMessages: { cType: 'success', cMsg: intl.get('FolderMove.pasta_movida') } }
        });
      })
      .catch(oError => {
        fnErrorRequest(oError.aMsgErrors);
      });
  };

  render() {
    const { oFolder, aTreeViewFolders, rcmpAlertMsg, bLoading } = this.state;
    const { fnCloseModal } = this.props;

    return (
      <PageClean loading={bLoading ? 1 : 0}>
        {rcmpAlertMsg}
        <form className="form" onSubmit={evt => this.handleSubmit(evt)}>
          <h2 className="inner-title">{intl.get('FolderMove.mover_para_pasta')}</h2>
          {oFolder && (
            <TreeView
              aFolders={aTreeViewFolders}
              nUnchangedFolderId={oFolder.fldr_nid}
              handleChange={this.inputChangeHandler}
              cHeight="250px"
              id="tree-view"
            />
          )}
          <div className="button-stack">
            <Button outline className="btn alternate back" onClick={fnCloseModal}>
              {intl.get('cancelar')}
            </Button>
            <Button type="submit">{intl.get('enviar')}</Button>
          </div>
        </form>
      </PageClean>
    );
  }
}

FolderMove.defaultProps = {
  nFolderId: null,
  fnCloseModal: evt => {},
  fnErrorRequest: evt => {}
};

export default FolderMove;
