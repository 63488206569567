import React, { Component } from 'react';


export class SubMenuChild extends Component {

  constructor(oProps) {
    super(oProps);
  }

  render() {
    const { onkeyDown, onClick, cTitle, cClassName, cIconName } = this.props;
    return (
      <li>
        <span role="button" tabIndex="0" onKeyDown={onkeyDown} onClick={onClick} title={cTitle}>
          <i className={cClassName} /> {cIconName}
        </span>
      </li>
    );
  }
};

export default SubMenuChild;
