import _ from 'lodash';
import Axios from '../config/Axios';
import loadBreadcrumb from './ActionBreadcrumb';

export default function getReadDetails(nVersionId, cName, cOrderField, cOrderType) {
  return Axios.get(`read/detail/${nVersionId}`, {
    params: { cName, cOrderField, cOrderType }
  }).then(
    (oResponse) =>
      new Promise((resolve, reject) => {
        loadBreadcrumb(oResponse.data.oVersion.document.fldr_nid)
          .then((aBreadcrumbFolders) => {
            const { oVersion, nQuantityUsers, nQuantityReaders, nQuantityNotRead, aUsers } = oResponse.data;

            resolve({
              oVersion,
              nQuantityUsers,
              nQuantityReaders,
              nQuantityNotRead,
              "aUsers": _.toArray(aUsers),
              aBreadcrumbFolders
            });
          })
          .catch((oError) => reject(oError));
      })
  );
}
