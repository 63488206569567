// atenção: na próxima vez que precisarmos usar HTML perigoso, lembremos de sanitizá-lo para aplicar a sanitização nesses dangerouslySetInnerHTML também
import moment from 'moment';
import React, { Component } from 'react';
import intl from 'react-intl-universal';
import getPrintDocument from '../../actions/ActionDocumentPrint';
import Breadcrumb from '../../components/Breadcrumbs';
import url from '../../config/Url';
import Page from '../default-v2/Page';
import CardHeader from '../default-v2/CardHeader';
import './styles/documentprint.scss';
import { NavLink } from 'react-router-dom';
import 'froala-editor/css/froala_style.min.css';
import '../../assets/scss/froala-custom.scss';
import { checkModule } from '../../config/Permissions';
import EncodedLocalStorage from '../../config/EncodedLocalStorage';

class DocumentPrint extends Component {
  constructor(oProps) {
    super(oProps);
    this.state = {
      bLoading: true,
      nDocumentId: oProps.match.params.nDocumentId,

      aHeaderFooter: [],
      oDocument: {
        oCustomer: {
          cLogo: undefined,
          cName: undefined
        }
      },
      oWriter: [],
      oResponsible: [],
      oReviewer: [],
      oVersion: []
    };
  }

  componentDidMount = () => {
    const { nDocumentId } = this.state;
    const { setAlert, history } = this.props;
    checkModule(history, 'documents');

    getPrintDocument(nDocumentId)
      .then(oData => {
        this.setState({ bLoading: false, ...oData });
      })
      .catch(oError => {
        this.setState({ bLoading: false });
        setAlert('error', oError.aMsgErrors);
      });
  };

  print = evt => {
    evt.preventDefault();
    window.print();
  };

  generateCardHeader = () => {
    const { history } = this.props;

    const rcmpCardHeader = <CardHeader history={history} />;
    return rcmpCardHeader;
  };

  truncate = (cInput, nLength) => {
    return cInput.length > nLength ? `${cInput.substr(0, nLength)}...` : cInput;
  };

  render() {
    const {
      aHeaderFooter,
      oDocument,
      oDocumentWriter,
      oDocumentReviewer,
      oDocumentResponsible,
      oVersion,
      aBreadcrumbFolders,
      bLoading
    } = this.state;
    const { getAlert } = this.props;
    const bDocTitleLoaded = oDocument.doc_ctitle !== undefined && oDocument.doc_ctitle.length > 35;
    const rcmpBreadcrumb = (
      <div className="breadcrumbs-wrapper" style={{ fontSize: '14px' }}>
        <div className="inner-bread">
          <Breadcrumb bLastLink key={oDocument.doc_nid} aFolders={aBreadcrumbFolders} />
        </div>
        <NavLink to={`/document/detail/${oVersion.ver_nid}`} title={oDocument.doc_ctitle}>
          {bDocTitleLoaded ? oDocument.doc_ctitle.trunc(35) : oDocument.doc_ctitle}
        </NavLink>
        <span>></span>
        <span className="breacrumb-page">{intl.get('Print.titulo')}</span>
      </div>
    );

    const cPageTitle = intl.get('DocumentPrint.title');
    const rcmpCardHeader = this.generateCardHeader();

    let cLogoPath = '';
    let cCompanyName = '';
    let cTitle = '';
    let cVersion = '';
    let cJustification = '';
    let cWriter = '';
    let cReviewer = '';
    let cResponsible = '';
    let cLocation = '';
    let cCreatedAt = '';
    let cPublishedAt = '';
    let cReducedSizeClass = '';
    const oLocalStorage = new EncodedLocalStorage();

    if (aHeaderFooter.includes('logo') && oDocument.customer.cust_clogo) {
      cLogoPath = oDocument.customer.cust_clogo;
      cReducedSizeClass = 'documentPrint-columnText-reduced';
    }
    if (aHeaderFooter.includes('customer-name')) {
      cCompanyName = oDocument.customer.cust_cname;
    }
    if (aHeaderFooter.includes('title')) {
      cTitle = oDocument.doc_ctitle;
    }
    if (aHeaderFooter.includes('version')) {
      cVersion = oVersion.ver_cnumber;
    }
    if (aHeaderFooter.includes('justification')) {
      cJustification = oVersion.ver_cjustification;
    }
    if (aHeaderFooter.includes('writer')) {
      cWriter = this.truncate(oDocumentWriter.user_cname, 44);
    }
    if (aHeaderFooter.includes('reviewer')) {
      cReviewer = this.truncate(oDocumentReviewer.user_cname, 44);
    }
    if (aHeaderFooter.includes('responsible')) {
      cResponsible = this.truncate(oDocumentResponsible.user_cname, 44);
    }
    if (aHeaderFooter.includes('folder')) {
      cLocation = 'Início';
      aBreadcrumbFolders.map(oFolder => {
        cLocation += ' / ';
        cLocation += oFolder.fldr_cname;
      });
    }
    if (aHeaderFooter.includes('creation')) {
      cCreatedAt = moment
        .parseZone(oVersion.ver_dwritten)
        .tz(oLocalStorage.get('cTimezone'))
        .format('DD/MM/YYYY HH:mm:ss');
    }
    if (aHeaderFooter.includes('publish')) {
      cPublishedAt = moment
        .parseZone(oVersion.ver_dpublished)
        .tz(oLocalStorage.get('cTimezone'))
        .format('DD/MM/YYYY HH:mm:ss');
    }

    return (
      <Page
        cId="editor-view-wrapper"
        loading={bLoading ? 1 : 0}
        rcmpBreadcrumb={rcmpBreadcrumb}
        cTitle={cPageTitle}
        rcmpCardHeader={rcmpCardHeader}
        className="v2-document-print"
        cCurrentSideMenuOption={intl.get('Nav.document')}
      >
        {getAlert()}
        <div id="print-view">
          <a
            href="#imprimir"
            onClick={evt => this.print(evt)}
            title={intl.get('DocumentPrint.imprimir')}
            className="floating-print-button"
          >
            <i className="icon-print" />
          </a>
        </div>

        {aHeaderFooter && aHeaderFooter.length > 0 && (
          <header id="documentPrint-header">
            {cLogoPath.length > 0 && (
              <div id="documentPrint-column-logo">
                <img src={cLogoPath} alt="" id="documentPrint-image-logo" />
              </div>
            )}
            <div className={cReducedSizeClass} id="documentPrint-column-text">
              {cCompanyName.length > 0 && (
                <div id="documentPrint-textRow-companyName">
                  <h3 id="documentPrint-companyName">{cCompanyName}</h3>
                </div>
              )}
              {cTitle.length > 0 && (
                <div id="documentPrint-textRow-title">
                  <h2 id="documentPrint-title">{cTitle}</h2>
                  {cVersion.length > 0 && <p id="documentPrint-version">Vs {cVersion}</p>}
                </div>
              )}
              {cJustification.length > 0 && (
                <div id="documentPrint-textRow-justification">
                  <label id="documentPrint-label-justification" htmlFor="documentPrint-justification">
                    {intl.get('ConfigHeaderFooter.justification')}
                  </label>
                  <p id="documentPrint-justification">{cJustification}</p>
                </div>
              )}
              <div id="documentPrint-textRowInfo-data">
                {cWriter.length > 0 && (
                  <div className="documentPrint-textRowInfo-column" id="documentPrint-textColumnInfo-writer">
                    <label id="documentPrint-label-writer" htmlFor="documentPrint-writer">
                      {intl.get('Document.writer')}
                    </label>
                    <p id="documentPrint-writer">{cWriter}</p>
                  </div>
                )}
                {cReviewer.length > 0 && (
                  <div className="documentPrint-textRowInfo-column" id="documentPrint-textColumnInfo-reviewer">
                    <label id="documentPrint-label-reviewer" htmlFor="documentPrint-reviewer">
                      {intl.get('Document.revisor')}
                    </label>
                    <p id="documentPrint-reviewer">{cReviewer}</p>
                  </div>
                )}
                {cResponsible.length > 0 && (
                  <div className="documentPrint-textRowInfo-column" id="documentPrint-textColumnInfo-responsible">
                    <label id="documentPrint-label-responsible" htmlFor="documentPrint-responsible">
                      {intl.get('Document.responsible')}
                    </label>
                    <p id="documentPrint-responsible">{cResponsible}</p>
                  </div>
                )}
                {cLocation !== '' && (
                  <div className="documentPrint-textRowInfo-column" id="documentPrint-textColumnInfo-location">
                    <label id="documentPrint-label-location" htmlFor="documentPrint-location">
                      {intl.get('DocumentPrint.location')}
                    </label>
                    <p id="documentPrint-location">{cLocation}</p>
                  </div>
                )}
                {cCreatedAt.length > 0 && (
                  <div className="documentPrint-textRowInfo-column" id="documentPrint-textColumnInfo-createdAt">
                    <label id="documentPrint-label-createdAt" htmlFor="documentPrint-createdAt">
                      {intl.get('Status.criado')}
                    </label>
                    <p id="documentPrint-createdAt">{cCreatedAt}</p>
                  </div>
                )}
                {cPublishedAt.length > 0 && (
                  <div className="documentPrint-textRowInfo-column" id="documentPrint-textColumnInfo-publishedAt">
                    <label id="documentPrint-label-publishedAt" htmlFor="documentPrint-publishedAt">
                      {intl.get('Status.publicado')}
                    </label>
                    <p id="documentPrint-publishedAt">{cPublishedAt}</p>
                  </div>
                )}
              </div>
            </div>
          </header>
        )}

        <div className="fr-wrapper">
          <article className="fr-element fr-view" dangerouslySetInnerHTML={{ __html: oVersion.ver_ctext }} />
        </div>
      </Page>
    );
  }
}

export default DocumentPrint;
