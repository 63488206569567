import React, { useEffect, useState } from 'react';
import Axios from '../../config/Axios';
import url from '../../config/Url';
import Page from '../default-v2/Page';
import Breadcrumb from '../../components/Breadcrumbs';
import { getNorm } from '../../actions/ActionRequisite';
import imgfNoPicLighter from '../../assets/img/nopic_lighter.svg';
import moment from 'moment';
import intl from 'react-intl-universal';
import CardHeader from '../default-v2/CardHeader';
import sanitizeHtml from 'sanitize-html';
import cQualityImg from '../../assets/img/quality.svg';

import './styles/norminteractions.scss';
import EncodedLocalStorage from '../../config/EncodedLocalStorage';

const NormInteractions = ({ history, match }) => {
  const [interactions, setInteractions] = useState([]);
  const [load, setLoad] = useState(true);
  const [norm, setNorm] = useState([]);
  const [aPages] = useState([{ cName: 'QualityPanelIndex.titulo', cLink: '/quality-panel' }]);

  const nNormId = match.params.nId;

  useEffect(() => {
    Promise.all([
      Axios.get(`norm/interactions/${nNormId}`).then(res => {
        setInteractions(res.data);
      }),
      getNorm(nNormId).then(oData => {
        setNorm(oData.oNorm);
      })
    ]).then(() => {
      setLoad(false);
    });
  }, []);

  const renderBreadcrumbs = () => {
    return (
      <div className="breadcrumbs-wrapper" style={{ fontSize: '14px' }}>
        <div className="inner-bread">
          <Breadcrumb
            bLastLink
            aPages={[
              ...aPages,
              {
                cName:
                  norm.norm_cinitials?.length > 30 ? `${norm.norm_cinitials.substring(0, 27)}...` : norm.norm_cinitials,
                cLink: `/requisite/${norm.norm_nid}`
              }
            ]}
          />
        </div>
        <span>></span>
        <span>{intl.get('NormInteractions.interacoes')}</span>
      </div>
    );
  };

  const generateCardHeader = () => {
    return <CardHeader bIsItToInsertGoBackBtn={true} history={history} />;
  };

  const oLocalStorage = new EncodedLocalStorage();

  return (
    !load && (
      <Page
        loading={load ? 1 : 0}
        rcmpBreadcrumb={renderBreadcrumbs()}
        rcmpCardHeader={generateCardHeader()}
        cImage={cQualityImg}
        cTitle={intl.get('NormInteractions.titulo')}
        cDescription={norm.norm_cname}
        cCurrentSideMenuOption={intl.get('Nav.quality_panel')}
        className="v2-norm-interactions"
      >
        {interactions.length ? (
          <ul className="interactions">
            {interactions.map((oInteraction, nIndex) => (
              <li key={`i-${nIndex}`}>
                <div className="title-wrapper">
                  <span className="avatar">
                    <img
                      src={oInteraction.user.user_cavatar ? oInteraction.user.user_cavatar : imgfNoPicLighter}
                      alt={oInteraction.user.user_cname}
                    />
                  </span>
                  <p className="name">{oInteraction.user.user_cname}</p>
                </div>

                <div className="message">
                  <b>
                    {oInteraction.rqin_ctext
                      ? intl.get('NormInteractions.requisito')
                      : intl.get('NormInteractions.norma')}
                  </b>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: sanitizeHtml(oInteraction?.rqin_ctext ?? oInteraction?.nrin_ccontent ?? '')
                    }}
                  />
                </div>

                <p className="date">
                  {moment
                    .parseZone(oInteraction.nrin_dcreated || oInteraction.rqin_dcreated)
                    .tz(oLocalStorage.get('cTimezone'))
                    .format('DD/MM/YYYY HH:mm:ss')}
                </p>
              </li>
            ))}
          </ul>
        ) : (
          <>
            <p className="empty-folder">
              <strong>{intl.get('NormInteractions.sem_interacao')}</strong>
            </p>
          </>
        )}
      </Page>
    )
  );
};

export default NormInteractions;
