import React, { useState, useEffect, useContext } from 'react';
import ErrorIcon from 'react-bootstrap-sweetalert/lib/dist/ErrorIcon';
import DocusignContext from './DocusignContext';
import axios from 'axios';
import intl from 'react-intl-universal';

const Modal = () => {
  const [showModal, setShowModal] = useState(true);
  const {
    showDeleteModal,
    setShowDeleteModal,
    showStatusDetailsModal,
    setShowStatusDetailsModal,
    signers,
    setSigners,
    showDifferentUserModal,
    setShowDifferentUserModal,
    docusignAccountId,
    setDocusignAccountId,
    docusignError
  } = useContext(DocusignContext);

  const code = new URL(window.location.href).searchParams.get('code');

  const handleCloseModal = () => {
    setShowModal(false);
    setShowDeleteModal(false);
    setShowStatusDetailsModal(false);
    setShowDifferentUserModal(false);
  };

  const translateStatus = status => {
    let response;
    switch (status) {
      case 'completed':
        response = intl.get('DocumentPublish.assinado');
        break;

      case 'autoresponded':
        response = intl.get('DocumentPublish.autorespondido');
        break;

      case 'sent':
        response = intl.get('DocumentPublish.enviado');
        break;

      default:
        break;
    }
    return response;
  };

  const logout = async e => {
    e.preventDefault();

    const redirectUrl = `${process.env.REACT_APP_DOCUSIGN_LOGOUT_URL}/?response_type=code&client_id=${process.env.REACT_APP_DOCUSIGN_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_DOCUSIGN_REDIRECT_URL}&scope=signature&response_mode=logout_redirect`;

    window.localStorage.removeItem('url');
    window.localStorage.setItem('url', JSON.stringify(window.location.href.split('?')[0]));

    window.location.href = redirectUrl;
  };

  const newAccount = async e => {
    e.preventDefault();

    await axios.post('docusign/change-credentials', {
      account_id: docusignAccountId
    });

    window.location.href = window.location.href;
  };

  const loginAndDelete = async () => {
    window.localStorage.removeItem('delete');

    const redirectUrl = `${process.env.REACT_APP_DOCUSIGN_BASE_URL}/auth?response_type=code&client_id=${process.env.REACT_APP_DOCUSIGN_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_DOCUSIGN_REDIRECT_URL}&scope=signature`;

    window.localStorage.removeItem('url');
    window.localStorage.setItem('url', JSON.stringify(window.location.href.split('?')[0]));

    window.location.href = redirectUrl;

    window.localStorage.setItem('delete', true);
  };

  const renderRedirectModal = () => {
    return (
      <div className="docusign-modal-overlay">
        <div className="docusign-modal">
          <div className="docusign-header-modal">
            <h3>{intl.get('DocumentPublish.redirecionando')}</h3>
            <span className="docusign-close" onClick={handleCloseModal}>
              &times;
            </span>
          </div>
          <div className="docusign-modal-body">
            <div className="docusign-spinner">
              <span className="sr-only">Loading...</span>
            </div>
            <div className="docusign-modal-text bold">{intl.get('DocumentPublish.login_sucesso')}</div>
            <div className="docusign-modal-text">{intl.get('DocumentPublish.aguarde_redirecionamento')}</div>
          </div>
        </div>
      </div>
    );
  };

  const renderErrorModal = () => {
    return (
      <div className="docusign-modal-overlay">
        <div className="docusign-modal">
          <div className="docusign-header-modal">
            <h3>{intl.get('DocumentPublish.tente_novamente')}</h3>
            <span className="docusign-close" onClick={handleCloseModal}>
              &times;
            </span>
          </div>
          <div className="docusign-modal-body">
            <div className="docusign warning-icon">
              <ErrorIcon className="icon icon-error" />
            </div>

            <div className="docusign-modal-text bold">{intl.get('DocumentPublish.tente_ou_contate')}</div>
          </div>
        </div>
      </div>
    );
  };

  const renderDeleteModal = () => {
    return (
      <div className="docusign-modal-overlay">
        <div className="docusign-modal">
          <div className="docusign-header-modal">
            <h3>{intl.get('DocumentPublish.cancelar_assinatura')}</h3>
            <span className="docusign-close" onClick={handleCloseModal}>
              &times;
            </span>
          </div>
          <div className="docusign-modal-body">
            <div className="icon-container">
              <img className="block-title-icon" src={require(`../assets/img/264-warning-blue.svg`)} />
            </div>

            <div className="docusign-modal-text align-center">
              {intl.get('DocumentPublish.cancelar_processo_assinatura')}
            </div>
          </div>
          <div className="button-container">
            <button className="btn alternate" onClick={handleCloseModal}>
              {intl.get('voltar')}
            </button>
            <button className="btn docusign-continue" onClick={loginAndDelete}>
              {intl.get('DocumentPublish.sim_continuar')}
            </button>
          </div>
        </div>
      </div>
    );
  };

  function renderStatusDetailsModal() {
    return (
      <div className="docusign-modal-overlay">
        <div className="docusign-modal">
          <div className="docusign-header-modal">
            <h3>{intl.get('DocumentPublish.status_assinatura')}</h3>
            <span className="docusign-close" onClick={handleCloseModal}>
              &times;
            </span>
          </div>
          <div className="docusign-modal-body">
            <table className="table-docusign-modal ">
              <tbody>
                <tr>
                  <td className="bold">{intl.get('name')}</td>
                  <td className="bold">{intl.get('status')}</td>
                </tr>
                {signers.map((item, index) => (
                  <tr key={index}>
                    <td>{item.ds_cname}</td>
                    <td>{translateStatus(item.ds_cstatus)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }

  function renderDifferentUserModal() {
    return (
      <div className="docusign-modal-overlay">
        <div className="docusign-modal">
          <div className="docusign-header-modal">
            <h3>{intl.get('DocumentPublish.problemas_login')}</h3>
            <span className="docusign-close" onClick={handleCloseModal}>
              &times;
            </span>
          </div>
          <div className="docusign-modal-body">
            <div className="icon-container">
              <img className="block-title-icon" src={require(`../assets/img/264-warning-blue.svg`)} />
            </div>
            <div className="docusign-modal-text align-center">{intl.get('DocumentPublish.conta_diferente')}</div>
          </div>
          <div className="button-container">
            <button className="btn alternate" onClick={logout}>
              {intl.get('DocumentPublish.logar_novamente')}
            </button>
            <button className="btn docusign-continue" onClick={newAccount}>
              {intl.get('DocumentPublish.configurar_conta_nova')}
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div key={docusignError}>
      {code && showModal && !docusignError && !showDifferentUserModal && renderRedirectModal()}
      {showModal && docusignError && renderErrorModal()}
      {showDeleteModal && renderDeleteModal()}
      {showStatusDetailsModal && renderStatusDetailsModal()}
      {showDifferentUserModal && renderDifferentUserModal()}
    </div>
  );
};

export default Modal;
