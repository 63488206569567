import React, { Component } from 'react';
import intl from 'react-intl-universal';
import Page from '../../default/Page';
import Axios from '../../../config/Axios';
import { Alert } from '../../../components/Alert';
import FroalaEditorComponent from '../../../config/FroalaEditorComponent';
import url from '../../../config/Url';
import sanitizeHtml from 'sanitize-html';
import { oLocalStorage } from '../../../config/EncodedLocalStorage';
import { formatDateToUserTZ } from '../../../utils/time';

class AddComment extends Component {
  constructor(oProps) {
    super(oProps);
    const { oEvidence } = this.props;
    let cDescription = '';
    if (oEvidence) {
      if (oProps.isAuditEvidence) {
        if (oProps.isCommentEvidence) {
          cDescription = oEvidence.arh_cauditorcomment;
        } else {
          cDescription = oEvidence.arev_cdescription;
        }
      } else {
        cDescription = oEvidence.evid_cdescription;
      }
    } else {
      cDescription = '';
    }

    this.state = {
      // ccomentario: '',
      bLoading: false,
      isCommentEvidence: oProps.isCommentEvidence ? oProps.isCommentEvidence : false,
      isAuditEvidence: oProps.isAuditEvidence ? oProps.isAuditEvidence : false,
      cDescription: cDescription,
      cEditorContent: cDescription
    };
  }

  componentDidMount = () => { };

  createAuditRequisiteHistory = () => {
    const { oRequisite, oAudit, onSuccess } = this.props;
    const { cEditorContent } = this.state;
    const oAuditEvidence = oRequisite.audit ?? oAudit;

    Axios.post(`audit-requisite-history`, {
      audi_nid: oAuditEvidence.audi_nid,
      arq_nid: oAuditEvidence.arq_nid,
      req_nid: oRequisite.req_nid,
      arh_cauditorcomment: cEditorContent
    })
      .then(() => {
        onSuccess(intl.get('AddComment.comentario_incluido_com_sucesso'));
      })
      .catch(oError => {

        this.setState({
          rcmpAlertMsg: (
            <Alert type="error" isOpen onCloseAlert={() => { }}>
              {oError.response.data}
            </Alert>
          )
        });
      });
  }

  createAuditTextEvidence = () => {
    const { oRequisite, oAudit, onSuccess } = this.props;
    const { cEditorContent } = this.state;
    const oAuditEvidence = oRequisite.audit ?? oAudit;

    Axios.post(`audit-evidence/${oAuditEvidence.audi_nid}`, {
      arq_nid: oAuditEvidence.arq_nid,
      norm_nid: oRequisite.norm_nid,
      req_nid: oRequisite.req_nid,
      arev_etype: 'comment',
      arev_ntype: 1,
      arh_cauditorcomment: cEditorContent,
      arev_bisactive: 1,
      arev_ctitle: sanitizeHtml(cEditorContent)
        .replace(/&nbsp;/g, ' ')
        .replace(/(<([^>]+)>)/gi, '')
        .substr(0, 20),
      arev_cdescription: cEditorContent
    })
      .then(() => {
        onSuccess(intl.get('AddComment.comentario_incluido_com_sucesso'));
      })
      .catch(oError => {
        this.setState({
          rcmpAlertMsg: (
            <Alert type="error" isOpen onCloseAlert={() => { }}>
              {oError.msgErrors}
            </Alert>
          )
        });
      });
  }

  updateAuditTextEvidence = () => {
    const { oRequisite, oEvidence, onSuccess } = this.props;
    const { cEditorContent } = this.state;

    Axios.put(`audit-evidence/${oEvidence.arev_nid}`, {
      user_nid: oLocalStorage.get('nUserId'),
      norm_nid: oRequisite.norm_nid,
      req_nid: oRequisite.req_nid,
      arev_etype: 'comment',
      arev_ctitle: sanitizeHtml(cEditorContent)
        .replace(/&nbsp;/g, ' ')
        .replace(/(<([^>]+)>)/gi, '')
        .substr(0, 20),
      arev_cdescription: cEditorContent
    })
      .then(() => {
        onSuccess(intl.get('AddComment.comentario_alterado_com_sucesso'));
      })
      .catch(oError => {
        this.setState({
          rcmpAlertMsg: (
            <Alert type="error" isOpen onCloseAlert={() => { }}>
              {oError.msgErrors}
            </Alert>
          )
        });
      });
  }

  updateNormTextEvidence = () => {
    const { oRequisite, oEvidence, onSuccess } = this.props;
    const { cEditorContent } = this.state;

    Axios.put(`evidence/${oEvidence.evid_nid}`, {
      user_nid: oLocalStorage.get('nUserId'),
      norm_nid: oRequisite.norm_nid,
      req_nid: oRequisite.req_nid,
      evid_etype: 'comment',
      evid_ntype: '',
      evid_cdescription: cEditorContent
    })
      .then(() => {
        onSuccess(intl.get('AddComment.comentario_alterado_com_sucesso'));
      })
      .catch(oError => {
        this.setState({
          rcmpAlertMsg: (
            <Alert type="error" isOpen onCloseAlert={() => { }}>
              {oError.msgErrors}
            </Alert>
          )
        });
      });
  }

  saveEvidence = evt => {
    evt.preventDefault();
    const { cEditorContent, isCommentEvidence, isAuditEvidence } = this.state;
    const { oEvidence } = this.props;

    if (!sanitizeHtml(cEditorContent)) {
      this.setState({
        rcmpAlertMsg: (
          <Alert type="error" isOpen onCloseAlert={() => { }}>
            {intl.get('AddComment.comentario_sem_conteudo')}
          </Alert>
        )
      });
      return;
    }

    if (isCommentEvidence) {
      if (!oEvidence) {
        // create audit-requisite-history evidence
        this.createAuditRequisiteHistory();
      }
    } else {
      if (!oEvidence) {
        // create audit text evidence
        this.createAuditTextEvidence();
      } else {
        if (isAuditEvidence) {
          // update audit text evidence
          this.updateAuditTextEvidence();
        } else {
          // update norm text evidence
          this.updateNormTextEvidence();
        }
      }
    }
  };

  handleChange = evt => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  onChangeEditor = cContent => {
    this.setState({
      cEditorContent: cContent
    });
  }

  editorInstance = oEditor => {
    this.setState({
      oEditor
    });
  }

  render() {
    const { cDescription, bLoading, rcmpAlertMsg, isAuditEvidence, cEditorContent } = this.state;
    const { oEvidence, closeModal } = this.props;

    let nEvidId = null;
    if (typeof (oEvidence) != "undefined") {
      if (isAuditEvidence) {
        nEvidId = oEvidence.arev_nid;
      } else {
        nEvidId = oEvidence.evid_nid;
      }
    }

    return (
      <Page loading={bLoading ? 1 : 0}>
        <form onSubmit={evt => this.saveEvidence(evt)} className="form">
          {rcmpAlertMsg}
          <div className="comment-box" id="section-desc">
            <p>
              {intl.get('AddComment.comentario')}
              {oEvidence && oEvidence.user?.user_cname ? (
                <span className="edit-by">
                  {intl.get("AddComment.ultima_edicao", {
                    cTime: formatDateToUserTZ(
                      isAuditEvidence ? oEvidence.arev_dupdated : oEvidence.evid_dupdated,
                      `DD/MM/YYYY [${intl.get("AddComment.ultima_edicao_as")}] HH:mm:ss`
                    ),
                    cUser: oEvidence.user.user_cname,
                  })}
                </span>
              ) : (
                ' '
              )}
            </p>

            <div className="document-editor__toolbar requisite-editor" />

            <FroalaEditorComponent
              bSimpleEditor
              model={cEditorContent}
              fnHandleChange={evtChange => this.onChangeEditor(evtChange)}
              config={{
                useClasses: true,
                videoUploadURL: `${url.cBase}api/audit/upload`,
                videoUploadParam: 'fVideo',
                videoUploadParams: {
                  bIsAuditEvidence: isAuditEvidence,
                  nAreaId: (typeof (nEvidId) != "undefined" && nEvidId) ? nEvidId : "3",
                  cArea: 'audit-evidences'
                },
                imageUploadURL: `${url.cBase}api/audit/upload`,
                imageUploadParam: 'fImage',
                imageUploadParams: {
                  bIsAuditEvidence: isAuditEvidence,
                  nAreaId: (typeof (nEvidId) != "undefined" && nEvidId) ? nEvidId : "3",
                  cArea: 'audit-evidences'
                },
                requestHeaders: {
                  Authorization: `Bearer ${localStorage.getItem('cAccessToken')}`
                },
                fontSizeUnit: 'pt'
              }}
              fnRef={this.editorInstance}
              disabled={oEvidence ? Number(oLocalStorage.get('nUserId')) !== Number(oEvidence.user?.user_nid) : false}
            />
          </div>
          <div className="button-stack">
            <button type="button" onClick={() => closeModal()} className="btn alternate back btn btn-outline-secondary">
              {intl.get('cancelar')}
            </button>
            <button
              disabled={oEvidence ? Number(oLocalStorage.get('nUserId')) !== Number(oEvidence.user?.user_nid) : false}
              type="submit"
              title="Salvar"
              className={`btn btn btn-secondary ${oEvidence
                ? Number(oLocalStorage.get('nUserId')) !== Number(oEvidence.user?.user_nid)
                  ? 'disabled'
                  : ''
                : ''
                }`}
            >
              {intl.get('salvar')}
            </button>
          </div>
        </form>
      </Page>
    );
  }
}

export default AddComment;
