import React, { Component } from 'react';
import url from '../../config/Url';
import imgfNoPic from '../../assets/img/nopic.svg';
import IconStatus from '../../components/IconStatus';
import EncodedLocalStorage from '../../config/EncodedLocalStorage';

export default class DocumentResponsibleUserAction extends Component {
  constructor(oProps) {
    super(oProps);
    this.state = {
      oUser: '',
      nUserId: ''
    }
  }

  componentDidMount = () => {
    const { cVersionStatus, oDocument } = this.props;

    const oUsers = {
      "oWriter": oDocument.document_writers[0],
      "oReviewer": oDocument.document_reviewers[0],
      "oResponsible": oDocument.document_responsibles[0]
    };
    const oLocalStorage = new EncodedLocalStorage();

    let oUser;
    let nUserId = oLocalStorage.get('nUserId');

    switch (cVersionStatus) {
      case 'in-writing':
        oUser = oUsers.oWriter;
        break;
      case 'in-review':
        oUser = oUsers.oReviewer;
        break;
      default:
        oUser = oUsers.oResponsible;
        break;
    }

    this.setState({
      oUser: oUser,
      nUserId: nUserId
    });
  };

  render() {
    const { cVersionStatus, oDocument, aDataDiff, onClick } = this.props;
    const { oUser, nUserId } = this.state;

    return (
      <>
        <strong >
          <img
            className="avatar"
            src={oUser.user_cavatar ? oUser.user_cavatar : imgfNoPic}
            alt=""
          />
          {oUser.user_cname}
        </strong>
        <IconStatus cStatus={cVersionStatus} onClick={(evt) => {
          if (nUserId && Number(nUserId) !== oUser.user_nid) onClick(evt, oDocument, cVersionStatus, aDataDiff);
        }} />
      </>
    );
  }
};
