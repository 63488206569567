import React, { Component } from 'react';
import intl from 'react-intl-universal';
import 'moment-timezone';
import moment from 'moment';
import Axios from '../../config/Axios';
import Breadcrumb from '../../components/Breadcrumbs';
import Page from '../default-v2/Page';
import CardHeader from '../default-v2/CardHeader';
import './styles/notification.scss';
import getNotification from '../../actions/ActionNotification';
import notificationImg from '../../assets/img/notification.svg';
import EncodedLocalStorage from '../../config/EncodedLocalStorage';

class Notification extends Component {
  bIsMounted = false;

  constructor(oProps) {
    super(oProps);

    const oConfig = JSON.parse(localStorage.getItem('cConfig'));

    this.state = {
      bNotificationRequest: true,
      bLoading: true,
      cNotificationLink: '',//oProps.match.params.cNotificationLink,
      aNotifications: {},
      aPages: [{ cName: 'Notification.title', cLink: undefined }],
      oConfig: {
        cViewType: oConfig && oConfig.cViewType ? oConfig.cViewType : 'list',
        cOrderBy: oConfig && oConfig.cOrderBy ? oConfig.cOrderBy : 'cName',
        cType: oConfig && oConfig.cType ? oConfig.cType : 'desc',
        bShowObsolete: oConfig && oConfig.bShowObsolete ? oConfig.bShowObsolete : false
      }
    };
  }

  componentDidMount = () => {
    this.bIsMounted = true;
    const { cNotificationLink } = this.state;

    document.addEventListener('mousedown', this.handleClickOutside);

    this.fetchNotification(cNotificationLink);
  };

  componentWillUnmount() {
    this.bIsMounted = false;
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setEventScroll = () => {
    const aContent = document.getElementsByClassName('v2-page-content');
    if ((typeof (aContent) !== 'undefined') && (typeof (aContent[0]) !== 'undefined')) {
      aContent[0].addEventListener('scroll', this.handleScroll);
    }
  };

  handleScroll = evtScroll => {
    const nZoom = Math.round(window.devicePixelRatio);
    const elScroll = evtScroll.target;
    const { cNotificationLink, bNotificationRequest } = this.state;
    if (elScroll.scrollHeight - elScroll.scrollTop + 100 >= elScroll.clientHeight / nZoom) {
      if (bNotificationRequest) this.fetchNotification(cNotificationLink);
    }
  };

  fetchNotification = async (cNotificationLink) => {
    const { setAlert } = this.props;
    const { oConfig, aNotifications } = this.state;

    if (cNotificationLink !== null) {
      getNotification(cNotificationLink)
        .then(oData => {
          const aNewNotifications = aNotifications.length > 0 ? aNotifications.concat(oData.aNotifications) : oData.aNotifications;
          let bNewMakeRequest = true;
          if ((aNewNotifications.length === 0) || (oData.cLinkNextPage === 'null')) {
            bNewMakeRequest = false;
          }

          this.setState(
            {
              aNotifications: aNewNotifications,
              bLoading: false,
              bNotificationRequest: bNewMakeRequest,
              cNotificationLink: oData.cLinkNextPage,
              ...oData
            },
            () => {
              this.setEventScroll();
            }
          );

          this.setFilter(String(oConfig.cOrderBy), String(oConfig.cType));
        })
        .catch(oError => {
          setAlert('error', oError.aMsgErrors);
          this.setState({ bLoading: false });
        });

    }
  };


  getClassName = (bIsRead, cRoute) => {
    return (bIsRead === 0 ? 'notification-read' : '') + (cRoute !== '' ? ' notification-hover' : '');
  };

  renderNotifications() {
    const { aNotifications } = this.state;
    const oLocalStorage = new EncodedLocalStorage();
    const rcmpList = Object.keys(aNotifications).map(cKey => (

      <li key={cKey} className={this.getClassName(aNotifications[cKey].notf_bisread, aNotifications[cKey].notf_croute)}>
        <div className="notification-header">
          <div className="left">
            <h2 className={(aNotifications[cKey].notf_croute !== undefined && aNotifications[cKey].notf_croute !== null && aNotifications[cKey].notf_croute !== "") ? "" : "notification-no-route"}>
              {(aNotifications[cKey].notf_croute !== undefined && aNotifications[cKey].notf_croute !== null && aNotifications[cKey].notf_croute !== "") ? (
                <a href={aNotifications[cKey].notf_croute} title={aNotifications[cKey].notf_ctitle}>
                  {aNotifications[cKey].notf_ctitle}
                </a>
              )
                : aNotifications[cKey].notf_ctitle}
            </h2>
          </div>
          <p className="date">
            {moment
              .parseZone(aNotifications[cKey].notf_dcreated)
              .tz(oLocalStorage.get('cTimezone'))
              .format('DD/MM/YYYY HH:mm:ss')}
          </p>
        </div>
        <p className="message">
          {aNotifications[cKey].notf_bisread === 0 && <span title={intl.get('nao_lida')} className="notread-mark" />}
          {aNotifications[cKey].notf_cmessage}
        </p>
      </li >
    ));

    if (rcmpList.length !== 0) {
      return <div>{rcmpList}</div>;
    }
    return (
      <ul>
        <span>{intl.get('Notification.nada_consta')}</span>
      </ul>
    );
  }

  render() {
    const { bLoading, aPages } = this.state;
    const { history, getAlert } = this.props;

    return (
      <div>
        <Page
          loading={bLoading ? 1 : 0}
          rcmpBreadcrumb={<Breadcrumb aPages={aPages} />}
          cTitle={intl.get('Notification.title')}
          cImage={notificationImg}
          rcmpCardHeader={<CardHeader history={history} />}
          cCurrentSideMenuOption={intl.get('Notification.title')}
        >
          {getAlert()}
          <div className="clearfix" />
          <ul className="notification-list">{this.renderNotifications()}</ul>
        </Page>
      </div>
    );
  }
}

export default Notification;
