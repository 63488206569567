import axios from 'axios';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import intl from 'react-intl-universal';
import { createBrowserHistory } from 'history';
import App from '../App';
import { signOut } from './Authentication';
import { oLocalStorage } from "./EncodedLocalStorage";
import url from './Url';

const Axios = axios.create();
const history = createBrowserHistory();

function getInitValues(cType) {
  if (localStorage.getItem(cType) === null)
    return true;
  return (localStorage.getItem(cType) === '1');
}

// Variável utilizada para determinar o início do processo de renovação do token.
let bIsStarting = true;
// Variável utilizada para determinar se deve ou não renovar o token.
let bIsRefreshing = getInitValues('bIsRefreshing');
// Variável utilizada para saber se o sistema está fazendo uma requisição automática.
let bNotRenewing = getInitValues('bNotRenewing');

export const extractErrorMessages = oError => {
  const aMsgErrors = [];

  if (typeof oError === 'string') {
    aMsgErrors.push(oError);
  } else if (oError.response && oError.response.status === 429) {
    aMsgErrors.push(intl.get('erro_429'));
  } else if (oError.response && oError.response.status >= 400 && oError.response.status < 500) {
    const { cError } = oError.response.data;
    let { errors } = oError.response.data;

    if (typeof errors === 'undefined') {
      errors = oError.response.data.errors;
    }
    if (typeof cError === 'string') {
      errors = [];
      errors.push(cError);
    }

    if (errors === undefined) {
      aMsgErrors.push(oError.response.data);
    } else if (typeof errors === 'string') {
      aMsgErrors.push(errors);
    } else {
      // eslint-disable-next-line no-restricted-syntax
      for (const cField in errors) {
        if (typeof errors[cField] === 'string') {
          aMsgErrors.push(errors[cField]);
        } else if (typeof errors[cField] === 'object' && typeof errors[cField].cMessage === 'string') {
          aMsgErrors.push(errors[cField].cMessage);
        } else {
          errors[cField].map(evt => aMsgErrors.push(evt));
        }
      }
    }
  } else {
    aMsgErrors.push(intl.get('erro_500'));
  }
  return aMsgErrors;
};

function LogoutUser(oError, aMsgErrors) {
  signOut();
  ReactDOM.unmountComponentAtNode(document.getElementById('root'));
  ReactDOM.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>,
    document.getElementById('root'),
    () => {
      document.getElementById('root').setAttribute('data-logout', '1');
      if (aMsgErrors !== undefined && aMsgErrors.length > 0 && typeof aMsgErrors[0] === 'string') {
        document.getElementById('root').setAttribute('data-message', aMsgErrors[0]);
      }
    }
  );
  return Promise.reject(oError);
}

function setRefreshing(nTime) {
  setTimeout(() => {
    bIsRefreshing = false;
    bNotRenewing = false;
  }, nTime);
}

function setValues() {
  localStorage.setItem("bIsRefreshing", bIsRefreshing ? "1" : "0");
  localStorage.setItem('bNotRenewing', bNotRenewing ? '1' : '0');
}

Axios.interceptors.request.use(
  oReqConfig => {
    return new Promise((resolve, reject) => {
      oLocalStorage.decodeToken();
      const nCustIdL = parseInt(oLocalStorage.get("nCustId"));
      let nCustIdS = parseInt(sessionStorage.getItem("nCustId"));

      if (!nCustIdS) {
        nCustIdS = nCustIdL;
        sessionStorage.setItem("nCustId", nCustIdL);
        sessionStorage.setItem("cOldCompanyName", oLocalStorage.get('cCompanyName'));
      }
      Axios.defaults.headers.common.nCustId = nCustIdS;

      if (nCustIdL && nCustIdS && nCustIdL !== nCustIdS) {
        sessionStorage.setItem('nCustId', nCustIdL);
        window.location.href = '/home/1';
        return false;
      }

      if (bIsStarting && oLocalStorage.get('nUserId') != null) {
        setRefreshing(300000);
        bIsStarting = false;
      }
      bNotRenewing = oReqConfig.params && !oReqConfig.params.bNotRenewing;

      if (!bIsRefreshing && !bNotRenewing) {
        bIsRefreshing = true;

        setValues();
        Axios.get('/login/oauth/token')
          .then(oResponse => {
            localStorage.setItem('cAccessToken', oResponse.data.cAccessToken);
            localStorage.setItem('cExpiresIn', oResponse.data.cExpiresIn);
            Axios.defaults.headers.common.Authorization = `Bearer ${oResponse.data.cAccessToken}`;
            setRefreshing(330000);
            resolve(oReqConfig);
          })
          .catch(oError => {
            setRefreshing(330000);
            reject(oReqConfig);
          });
      }

      setValues();
      resolve(oReqConfig);
    });
  },
  oError => {
    return Promise.reject(oError);
  }
);

Axios.interceptors.response.use(
  oResponse => {
    if (oResponse && oResponse.data.cRedirectTo && oResponse.status === 200) {
      const aParseURL = oResponse.data.cRedirectTo.split('.');
      let cUrlRedirect;
      if (url[aParseURL[0]] !== undefined) {
        cUrlRedirect = `/${url[aParseURL[0]][aParseURL[1]]}`;
      }

      if (cUrlRedirect === undefined) {
        window.location = oResponse.data.cRedirectTo;
      } else {
        if (oResponse.data.cMessage) {
          history.push(cUrlRedirect, { aAlertMessages: { cType: 'info', cMsg: oResponse.data.cMessage } });
        } else {
          history.push(cUrlRedirect);
        }
        window.location.reload();
        return Promise.reject();
      }
    }

    return oResponse;
  },
  oError => {
    if (oError.response && oError.response.status === 401) {
      const aMsgErrors = extractErrorMessages(oError);
      return LogoutUser(oError, aMsgErrors);
    }

    if (oError.response && oError.response.status === 403
      && oError.response.data.cMessage === intl.get("synchronization_error")) {
      oLocalStorage.decodeToken();
      oLocalStorage.set('nCustId', oError.response.data.nCustId);
      oLocalStorage.set('cCompanyName', oError.response.data.cCompanyName);
      sessionStorage.setItem('nCustId', oError.response.data.nCustId);
      window.location.href = '/home/1';
      return Promise.reject(oError);
    }

    // eslint-disable-next-line no-param-reassign
    oError.aMsgErrors = extractErrorMessages(oError);
    return Promise.reject(oError);
  }
);

export default Axios;
