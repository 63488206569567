// atenção: na próxima vez que precisarmos usar HTML perigoso, lembremos de sanitizá-lo para aplicar a sanitização nesses dangerouslySetInnerHTML também
import React, { Component } from 'react';
import Axios from '../../config/Axios';
import Page from '../default-v2/Page';
import Breadcrumb from '../../components/Breadcrumbs';
import CardHeader from '../default-v2/CardHeader';
import intl from 'react-intl-universal';
import { can } from '../../config/Permissions';
import imgfConfig from '../default-v2/img/icon-config.svg';

class AdhesionContract extends Component {
  constructor(oProps) {
    super(oProps);

    this.state = {
      oAdctPlan: '',
      bLoading: true,
      aPages: [
        {
          cName: 'configs',
          cLink: undefined
        },
        {
          cName: 'MyAccount.breadcrumb_terms_label',
          cLink: undefined
        }
      ]
    };
  }

  componentDidMount = () => {
    const { setAlert, history } = this.props;

    if (!can('admin')) {
      history.push({
        pathname: '/',
        state: { aAlertMessages: { cType: 'error', cMsg: intl.get('sem_permissao') } }
      });
    }

    Axios.get(`/adhesioncontract`)
      .then(oResponse => {
        this.setState({
          oAdctPlan: oResponse.data,
          bLoading: false
        });
      })
      .catch(oError => {
        this.setState({ bLoading: false });
        setAlert('error', oError.aMsgErrors);
      });
  };

  generateCardHeader = () => {
    const { history } = this.props;

    const rcmpCardHeader = <CardHeader history={history} />;
    return rcmpCardHeader;
  };

  renderAdhesionContract = () => {
    const { oAdctPlan } = this.state;

    if (oAdctPlan === null || oAdctPlan === undefined || oAdctPlan === '') {
      return (
        <>
          <p className="empty-result">
            <strong> {intl.get('MyAccount.empty_result')}</strong>
          </p>
        </>
      );
    }

    return (
      <>
        <h2>{oAdctPlan.adct_ctitle}</h2>
        <div id="editor-view" dangerouslySetInnerHTML={{ __html: oAdctPlan.adct_ctext }} />
      </>
    );
  };

  render() {
    const { bLoading, aPages } = this.state;
    const { getAlert } = this.props;

    const rcmpBreadcrumb = <Breadcrumb aPages={aPages} />;

    const rcmpCardHeader = this.generateCardHeader();

    const cPageTitle = intl.get('MyAccount.meu_termo_adesao');

    return (
      <Page
        loading={bLoading ? 1 : 0}
        rcmpBreadcrumb={rcmpBreadcrumb}
        cTitle={cPageTitle}
        cImage={imgfConfig}
        rcmpCardHeader={rcmpCardHeader}
        cCurrentSideMenuOption={intl.get('configs')}
      >
        {getAlert()}
        {this.renderAdhesionContract()}
      </Page>
    );
  }
}

export default AdhesionContract;
