import React from 'react';

import oClasses from './ActionButton.module.scss';
import classNames from 'classnames';

/**
 * @param {React.PropsWithChildren} props
 * @param {"view"|"edit"|"remove"|"resend"} props.cVariant
 */
const ActionButton = ({
  cVariant,
  className,
  children,
  ...props
}) => {
  return (
    <button
      type="button"
      className={classNames(
        oClasses['btn-action'],
        oClasses[cVariant],
        className
      )}
      {...props}
    >
      {children}
    </button>
  );
};

export default ActionButton;