import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert/lib/dist/SweetAlert';
import intl from 'react-intl-universal';
import { sendToTrashBin } from '../actions/ActionDocumentEditor';

export default class SendDocumentToTrashBin extends Component {
  constructor(oProps) {
    super(oProps);

    this.state = {
      "rcmpAlert": null,
      isSubmitting: false
    };
  }

  componentDidMount() {
    this.container = document.querySelector('.MessageHolder');
  }

  hideAlert = () => {
    this.setState({
      "rcmpAlert": null
    });
  };

  handleSendToTrashBin = (nVersionId) => {
    const { history } = this.props;

    if (this.state.isSubmitting) return; // Bloquear dedo nervoso
    this.setState({ isSubmitting: true }, () => {
      sendToTrashBin(nVersionId)
        .then(() => {
          this.setState({
            "rcmpAlert": (
              <SweetAlert
                success
                title=""
                onConfirm={() => {
                  this.hideAlert();
                  history.push(`/`);
                }}
              >
                {intl.get('DocumentEditor.mensagem_removido')}
              </SweetAlert>
            ),
            isSubmitting: false
          });
        })
        .catch(() => {
          this.setState({
            "rcmpAlert": (
              <SweetAlert error title={intl.get('opa')} onConfirm={this.hideAlert}>
                {intl.get('falhou')}
              </SweetAlert>
            ),
            isSubmitting: false
          });
        });
    });
  };

  showSendToTrashBin = (evt, oVersion, oDocument) => {
    evt.preventDefault();
    this.setState({
      "rcmpAlert": (
        <SweetAlert
          closeOnClickOutside={false}
          showCancel
          confirmBtnText={intl.get('confirmar')}
          cancelBtnText={intl.get('cancelar')}
          cancelBtnBsStyle="danger"
          title={intl.get('lixeira')}
          onConfirm={() => this.handleSendToTrashBin(oVersion.ver_nid)}
          onCancel={this.hideAlert}
          confirmBtnCssClass={this.state.isSubmitting ? 'disabled-btn-trashbin-confirm' : ''}
        >
          {intl.get('mensagem_excluir', { tipo: intl.get('o_documento'), nome: oDocument.doc_ctitle })}
        </SweetAlert>
      )
    });
  };


  render() {
    const { rcmpAlert } = this.state;
    const { oVersion, oDocument } = this.props;
    return (
      <>
        {this.container && ReactDOM.createPortal(rcmpAlert, this.container)}
        <a href="/#" onClick={(evt) => this.showSendToTrashBin(evt, oVersion, oDocument)}>
          <i className="icon-bin" />
          {intl.get('DocumentEditor.descartar_documento')}
        </a>
      </>
    );
  }
}
