import React from 'react';
import { Route, Switch } from 'react-router-dom';
import RegisterActivation from '../login/RegisterActivation';
import Login from '../login/Login';
import ResetPassword from '../login/ResetPassword';
import ResetPasswordRequest from '../login/ResetPasswordRequest';
import PreRegister from '../login/PreRegister';
import Register from '../login/Register';
import RegisterAccept from '../login/RegisterAccept';
import DatabaseBeingCreated from '../login/DatabaseBeingCreated';
import ChangeEmail from '../login/ChangeEmail';
import DocumentAccess from '../document/DocumentAccess';



const RouterExternal = () => (
  <Switch>
    <Route exact path="/" component={Login} />
    <Route exact path="/login" component={Login} />
    <Route exact path="/pre-register/" component={PreRegister} />
    <Route exact path="/customer/resend-activation/:cEmail" component={PreRegister} />
    <Route exact path="/customer/register/:cToken" component={Register} />
    <Route exact path="/customer/accept/:cToken" component={RegisterAccept} />
    <Route exact path="/database/create/:cToken?" component={DatabaseBeingCreated} />
    <Route exact path="/reset-password" component={ResetPasswordRequest} />
    <Route exact path="/reset-password/:cToken" component={ResetPassword} />
    <Route exact path="/reset-email/:cToken" component={ChangeEmail} />
    <Route exact path="/register/activate/:cToken" component={RegisterActivation} />
    <Route exact path="/register/resend-activation/:cEmail" component={RegisterActivation} />
    <Route exact path="/document-share/:hash" component={DocumentAccess} />
    <Route component={Login} />
  </Switch>
);

export default RouterExternal;
