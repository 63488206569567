import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/pt-br';
import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { documentsIAmResponsibleSearchFor } from '../../actions/ActionDocumentPending';
import Page from '../default-v2/Page';
import Breadcrumb from '../../components/Breadcrumbs';
import CardHeader from '../default-v2/CardHeader';
import Filter from '../default-v2/Filter';
import './styles/report.scss';
import './styles/readingdocumentdelegates.scss';
import Axios from '../../config/Axios';
import IconStatus from '../../components/IconStatus';
import ReportTable from './ReportTable';
import { checkModule } from '../../config/Permissions';
import reportsImg from '../../assets/img/reports.svg';
import EncodedLocalStorage from '../../config/EncodedLocalStorage';
import SweetAlert from 'react-bootstrap-sweetalert/lib/dist/SweetAlert';
import { sendToTrashBin } from '../../actions/ActionDocumentEditor';
import { formatDateToUserTZ } from '../../utils/time';
import { getDocumentTitle } from '../../utils/tools';

const oLocalStorage = new EncodedLocalStorage();
class EditsDocumentResponsible extends Component {
  constructor(oProps) {
    super(oProps);

    moment.locale(oLocalStorage.get('cLanguage'));

    this.state = {
      aPendingDocuments: [],
      bLoading: true,
      cSearch: '',
      cOrderBy: 'doc_ctitle',
      cOrderType: 'asc',
      bIsVisible: true,
      nDocmentId: 0,
      rcmpAlert: '',
      aPages: [
        {
          cName: 'Nav.reports',
          cLink: undefined
        },
        {
          cName: 'EditsDocumentResponsible.breadcrumb_label',
          cLink: undefined
        }
      ],
      isSubmitting: false
    };
  }

  componentDidMount() {
    const { setAlert, history } = this.props;
    checkModule(history, 'documents');

    documentsIAmResponsibleSearchFor()
      .then(aPendingDocuments => this.setState({ bLoading: false, aPendingDocuments, bIsMyAccount: true }))
      .catch(oError => {
        this.setState({ bLoading: false });
        setAlert('error', oError.aMsgErrors);
      });
  }

  handleClickTable = (evt, cNewOrderBy, cNewOrderType) => {
    this.setState(
      {
        cOrderBy: cNewOrderBy,
        cOrderType: cNewOrderType
      },
      () => {
        this.handleFilter(evt);
      }
    );
  };

  hideAlert = () => {
    this.setState({
      rcmpAlert: null
    });
    this.getEditsDocumentResponsible();
  };

  getEditsDocumentResponsible = () => {
    const { setAlert } = this.props;
    documentsIAmResponsibleSearchFor()
      .then(aPendingDocuments => this.setState({ bLoading: false, aPendingDocuments, bIsMyAccount: true }))
      .catch(oError => {
        this.setState({ bLoading: false });
        setAlert('error', oError.aMsgErrors);
      });
  };

  handleDeleteModal = (evt, oDocument, oVersion) => {
    evt.stopPropagation();
    this.setState({
      rcmpAlert: (
        <SweetAlert
          title={intl.get('lixeira')}
          confirmBtnText={intl.get('confirmar')}
          cancelBtnText={intl.get('cancelar')}
          cancelBtnBsStyle="danger"
          showCancel
          onConfirm={evt => {
            this.deleteDocument(oVersion.ver_nid);
          }}
          onCancel={this.hideAlert}
        >
          {intl.get('mensagem_excluir', { tipo: intl.get('o_documento'), nome: oDocument.doc_ctitle })}
        </SweetAlert>
      )
    });
  };

  deleteDocument = nVersionId => {
    const { fnUpdateComponent } = this.props;
    if (this.state.isSubmitting) return; // Bloquear dedo nervoso
    this.setState({ isSubmitting: true }, () => {
      sendToTrashBin(nVersionId)
        .then(() => {
          this.setState({
            rcmpAlert: (
              <SweetAlert
                success
                title=""
                onConfirm={() => {
                  this.hideAlert();
                  if (fnUpdateComponent) fnUpdateComponent();
                }}
              >
                {intl.get('DocumentEditor.mensagem_removido')}
              </SweetAlert>
            ),
            isSubmitting: false
          });
        })
        .catch(oError => {
          this.setState({
            rcmpAlert: (
              <SweetAlert error title="" onConfirm={this.hideAlert}>
                {oError.aMsgErrors}
              </SweetAlert>
            ),
            isSubmitting: false
          });
        });
    });
  };

  handleEditModal = (evt, oDocument, cVersionStatus, aDataDiff) => {
    if (evt !== null) evt.stopPropagation();

    let cStatus = '';
    let cUserName = '';
    switch (cVersionStatus) {
      case 'in-writing':
        cStatus = intl.get(`Status.edition`);
        cUserName = oDocument.document_writers[0].user_cname;
        break;
      case 'in-review':
        cStatus = intl.get(`Status.review`);
        cUserName = oDocument.document_reviewers[0].user_cname;
        break;
      default:
        cStatus = intl.get(`Status.publication`);
        cUserName = oDocument.document_responsibles[0].user_cname;
        break;
    }

    this.setState({
      rcmpAlert: (
        <SweetAlert
          showCancel
          showConfirm={false}
          closeOnClickOutside
          cancelBtnText={intl.get('cancelar')}
          onCancel={this.hideAlert}
          title=""
          customClass="modal-edit md disabled-overflow"
        >
          <div className="head-modal">
            {intl.get('Document.document_information')}
            <span
              aria-labelledby="head-modal"
              tabIndex={0}
              onClick={this.hideAlert}
              className="close"
              role="button"
              onKeyPress={this.hideAlert}
            />
          </div>
          <div>
            <p>
              {`${intl.get('Document.awaiting')} ${cStatus} ${intl.get('Document.by')} ${cUserName}`}
              {aDataDiff.bDataExpire ? `. ${intl.get('Document.deadline_expired')}` : ` ${intl.get('Document.until')}`}
              {` ${aDataDiff.cDataDiff}.`}
            </p>
          </div>
        </SweetAlert>
      )
    });
  };

  handleRowClick = oItem => {
    let cCurrDocUsersKey = 'document_';
    switch (oItem.oVersion.status.stat_cslugname) {
      case 'in-writing':
        cCurrDocUsersKey += 'writers';
        break;
      case 'in-review':
        cCurrDocUsersKey += 'reviewers';
        break;
      case 'awaiting-publish':
        cCurrDocUsersKey += 'responsibles';
        break;
      default:
        return;
    }
    if (oItem.oDocument[cCurrDocUsersKey][0].user_nid !== oLocalStorage.get('nUserId')) {
      this.handleEditModal(null, oItem.oDocument, oItem.oVersion.status.stat_cslugname, oItem.aDataDiff);
    } else {
      this.openRoute(oItem.cRoute, oItem.oVersion.ver_nid);
    }
  };

  renderSubMenu = oInformation => {
    return (
      <div
        onMouseOver={evt => {
          evt.stopPropagation();
          this.setState({ bIsVisible: false, nDocmentId: oInformation.oVersion.ver_nid });
        }}
        onMouseLeave={evt => {
          evt.stopPropagation();
          this.setState({ bIsVisible: true, nDocmentId: oInformation.oVersion.ver_nid });
        }}
        className="container-submenu"
      >
        <span className="submenu">
          <>
            <a
              title={intl.get('edit', { cType: '' })}
              className="insidespan"
              onClick={evt => {
                evt.stopPropagation();
                this.handleRowClick(oInformation);
              }}
            >
              <i className="icon icon-edit" />
            </a>
            <a
              title={intl.get('excluir', { tipo: '' })}
              className="insidespan"
              onClick={evt => {
                evt.stopPropagation();
                this.handleDeleteModal(evt, oInformation.oDocument, oInformation.oVersion);
              }}
            >
              <i className="icon icon-bin" />
            </a>
          </>
        </span>
      </div>
    );
  };

  renderEditsDocumentResponsible = () => {
    const { aPendingDocuments, bIsVisible, nDocmentId } = this.state;

    if (aPendingDocuments !== undefined && aPendingDocuments.length === 0) {
      return (
        <>
          <p className="empty-result">
            <strong> {intl.get('EditsDocumentResponsible.empty_result')}</strong>
          </p>
        </>
      );
    }

    const rcmpEditsDocumentResponsible = aPendingDocuments.map(oInformation => (
      <tr
        key={oInformation.oDocument.doc_nid}
        onClick={() => this.handleRowClick(oInformation)}
        onKeyPress={() => this.handleRowClick(oInformation)}
      >
        <td title={getDocumentTitle(oInformation.oDocument.doc_ctitle)} className="col-doc-name">
          <span
            className="doc-name"
            style={{ visibility: !bIsVisible && nDocmentId === oInformation.oVersion.ver_nid ? 'hidden' : 'visible' }}
          >
            {getDocumentTitle(oInformation.oDocument.doc_ctitle)}
          </span>
          {this.renderSubMenu(oInformation)}
        </td>
        <td title={oInformation.oDocument.document_responsibles?.[0]?.user_cname || ''} className="col-user-name">
          <span>{oInformation.oDocument.document_responsibles?.[0]?.user_cname || ''}</span>
        </td>
        <td>
          <IconStatus cStatus={oInformation.oVersion.status.stat_cslugname} onClick={evt => { }} />
        </td>
        <td>{formatDateToUserTZ(oInformation.aDataDiff.cDataDiffStandard, 'LL')}</td>
      </tr>
    ));

    return (
      <ReportTable
        aColumn={[
          {
            cName: intl.get('EditsDocumentResponsible.document'),
            cOrderBy: 'doc_ctitle',
            fnHandleClick: this.handleClickTable
          },
          {
            cName: intl.get('EditsDocumentResponsible.request_by'),
            cOrderBy: 'request_by',
            fnHandleClick: this.handleClickTable
          },
          {
            cName: intl.get('EditsDocumentResponsible.action'),
            cOrderBy: 'action',
            fnHandleClick: this.handleClickTable
          },
          // {
          //   cName: intl.get('EditsDocumentResponsible.published_at'),
          //   cOrderBy: 'ver_dcreated',
          //   fnHandleClick: this.handleClickTable
          // },
          {
            cName: intl.get('EditsDocumentResponsible.expiration_on'),
            cOrderBy: 'expiration_on',
            fnHandleClick: this.handleClickTable
          }
        ]}
      >
        {rcmpEditsDocumentResponsible}
      </ReportTable>
    );
  };

  openRoute = (cRoute, nVersionId) => {
    const { history } = this.props;
    if (cRoute === 'version.show') history.push(`/document/detail/${nVersionId}`);
    else if (cRoute === 'version.edit') history.push(`/document/editor/${nVersionId}`);
    else if (cRoute === 'version.review') history.push(`/document/review/${nVersionId}`);
    else if (cRoute === 'version.publish') history.push(`/document/publish/${nVersionId}`);
  };

  handleFilter = evt => {
    const { cSearch, cOrderBy, cOrderType } = this.state;
    const { setAlert } = this.props;

    Axios.get('my-account/pending-document-search', {
      params: {
        bResponsible: true,
        cSearch,
        cOrderBy,
        cOrderType
      }
    })
      .then(oResponse => {
        this.setState({
          aPendingDocuments: oResponse.data
        });
      })
      .catch(oError => {
        setAlert('error', oError.aMsgErrors);
      });
  };

  handleNewFilter = (evt, oFilter) => {
    this.setState(
      {
        cSearch: oFilter.cText
      },
      () => {
        this.handleFilter(evt);
      }
    );
  };

  generateCardHeader = () => {
    const { history } = this.props;

    const rcmpFilter = <Filter fnOnType={this.handleNewFilter} />;

    const rcmpCardHeader = <CardHeader history={history} rcmpFilter={rcmpFilter} />;
    return rcmpCardHeader;
  };

  render() {
    const { bLoading, aPages, rcmpAlert } = this.state;
    const { getAlert } = this.props;

    const rcmpBreadcrumb = <Breadcrumb aPages={aPages} />;

    const rcmpCardHeader = this.generateCardHeader();

    const cPageTitle = intl.get('EditsDocumentResponsible.title');

    return (
      <Page
        loading={bLoading ? 1 : 0}
        rcmpBreadcrumb={rcmpBreadcrumb}
        cTitle={cPageTitle}
        cImage={reportsImg}
        rcmpCardHeader={rcmpCardHeader}
        className="v2-edits-document-responsible"
        cCurrentSideMenuOption={intl.get('Nav.report')}
      >
        {getAlert()}
        {rcmpAlert}
        {this.renderEditsDocumentResponsible()}
      </Page>
    );
  }
}

export default EditsDocumentResponsible;
