import React, { Component } from 'react';
import intl from 'react-intl-universal';
import Page from '../default/Page';
import Axios from '../../config/Axios';
import { Alert } from '../../components/Alert';
import FroalaEditorComponent from '../../config/FroalaEditorComponent';
import sanitizeHtml from 'sanitize-html';
import styles from './styles/evidences.scss';
import url from '../../config/Url';
import { oLocalStorage } from '../../config/EncodedLocalStorage';
import { formatDateToUserTZ } from '../../utils/time';

class AddComment extends Component {
  constructor(oProps) {
    super(oProps);
    const { oEvidence } = this.props;

    this.state = {
      // ccomentario: '',
      bLoading: false,
      cDescription: oEvidence ? oEvidence.evid_cdescription : '',
      cEditorContent: oEvidence ? oEvidence.evid_cdescription : ''
    };
  }

  saveEvidence = evt => {
    evt.preventDefault();
    const { cEditorContent } = this.state;
    const { onSuccess, oRequisite, oEvidence, cTypeComment } = this.props;

    if (!sanitizeHtml(cEditorContent)) {
      this.setState({
        rcmpAlertMsg: (
          <Alert type="error" isOpen onCloseAlert={() => { }}>
            {intl.get('AddComment.comentario_sem_conteudo')}
          </Alert>
        )
      });
      return;
    }

    if (!oEvidence) {
      Axios.post('evidence', {
        norm_nid: oRequisite.norm_nid,
        req_nid: oRequisite.req_nid,
        evid_etype: 'comment',
        evid_ntype: '',
        evid_ccomment: cTypeComment || '',
        evid_cdescription: cEditorContent,
        evid_ctitle: cTypeComment === 'X' ? 'comentario_livre' : null
      })
        .then(() => {
          onSuccess(intl.get('AddComment.comentario_incluido_com_sucesso'));
        })
        .catch(oError => {
          this.setState({
            rcmpAlertMsg: (
              <Alert type="error" isOpen onCloseAlert={() => { }}>
                {oError.msgErrors}
              </Alert>
            )
          });
        });
    } else {
      Axios.put(`evidence/${oEvidence.evid_nid}`, {
        norm_nid: oRequisite.norm_nid,
        req_nid: oRequisite.req_nid,
        evid_etype: 'comment',
        evid_ntype: '',
        evid_ccomment: cTypeComment || '',
        evid_ctitle: cTypeComment === 'X' ? 'comentario_livre' : null,
        evid_cdescription: sanitizeHtml(cEditorContent, {
          allowedTags: ['p', 'img'],
          allowedAttributes: {
            'img': ['src', 'alt', 'width', 'height', 'style', 'class']
          }
        })
      })
        .then(() => {
          onSuccess(intl.get('AddComment.comentario_alterado_com_sucesso'));
        })
        .catch(oError => {
          this.setState({
            rcmpAlertMsg: (
              <Alert type="error" isOpen onCloseAlert={() => { }}>
                {oError.msgErrors}
              </Alert>
            )
          });
        });
    }
  };

  handleChange = evt => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  onChangeEditor = cContent => {
    this.setState({
      cEditorContent: cContent
    });
  }

  editorInstance = oEditor => {
    this.setState({
      oEditor
    });
  }

  render() {
    const { cDescription, bLoading, rcmpAlertMsg, cEditorContent } = this.state;
    const { oEvidence, closeModal, cTypeComment } = this.props;

    const bDisableButton = oEvidence &&
      Number(oLocalStorage.get('nUserId')) !== Number(oEvidence.user?.user_nid);

    return (

      <Page loading={bLoading ? 1 : 0}>
        <form onSubmit={evt => this.saveEvidence(evt)} className="form">
          {rcmpAlertMsg}
          <div className="comment-box" id="section-desc">
            <p>
              {intl.get('AddComment.comentario')}
              {oEvidence ? (
                <span className="edit-by">
                  {intl.get("AddComment.ultima_edicao", {
                    cTime: formatDateToUserTZ(
                      oEvidence.evid_dupdated,
                      `DD/MM/YYYY [${intl.get("AddComment.ultima_edicao_as")}] HH:mm:ss`
                    ),
                    cUser: oEvidence.user.user_cname
                  })}
                </span>
              ) : (
                ' '
              )}
            </p>

            <div className="document-editor__toolbar requisite-editor" />

            <FroalaEditorComponent
              bSimpleEditor
              cTypeComment={cTypeComment}
              model={cEditorContent}
              fnHandleChange={evtChange => this.onChangeEditor(evtChange)}
              config={{
                useClasses: true,
                ...(cTypeComment === 'X'
                  ? {
                    imageUpload: false,
                    imageAllowDragAndDrop: false,
                    imagePaste: false,
                    fileUpLoad: false,
                  } // Vazio para desativar o upload quando cTypeComment for 'X'
                  : {
                    videoUploadURL: `${url.cBase}api/evidence/upload`,
                    videoUploadParam: 'fVideo',
                    videoUploadParams: {
                      nAreaId: typeof oEvidence !== "undefined" && oEvidence.evid_nid ? oEvidence.evid_nid : "3",
                      cArea: 'audit-evidences'
                    },
                    imageUploadURL: `${url.cBase}api/evidence/upload`,
                    imageUploadParam: 'fImage',
                    imageUploadParams: {
                      nAreaId: typeof oEvidence !== "undefined" && oEvidence.evid_nid ? oEvidence.evid_nid : "3",
                      cArea: 'audit-evidences'
                    }
                  }),
                requestHeaders: {
                  Authorization: `Bearer ${localStorage.getItem('cAccessToken')}`
                },
                fontSizeUnit: 'pt'
              }}
              fnRef={this.editorInstance}
              disabled={bDisableButton}
            />
          </div>
          <div className="button-stack">
            <button type="button" onClick={() => closeModal()} className="btn alternate back btn btn-outline-secondary">
              {intl.get('cancelar')}
            </button>
            <button
              disabled={bDisableButton}
              type="submit"
              title={intl.get("salvar")}
              className={`btn btn btn-secondary ${bDisableButton ? 'disabled' : ''}`}
            >
              {intl.get('salvar')}
            </button>
          </div>
        </form>
      </Page>
    );
  }
}

export default AddComment;
