import _ from 'lodash';
import Axios from '../config/Axios';
import loadBreadcrumb from './ActionBreadcrumb';

export default async function getFolders(nFolderId, nSize, nQtyFolders, nQtyDocuments, cOrderBy, cOrderType, cSearch) {
  const oResponse = await Axios.get('/folder', {
    params: {
      nFolderId,
      nSize,
      nQtyFolders,
      nQtyDocuments,
      cOrderBy,
      cOrderType,
      cSearch,
    }
  });

  return {
    aFolders: oResponse.data.aFolders,
    aDocuments: _.toArray(oResponse.data.aDocuments),
    oFolder: oResponse.data.oFolder,
    nTotal: oResponse.data.nTotal
  };
}
