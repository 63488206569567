import React from 'react';
import ReactDOM from 'react-dom';
import { Alert } from './Alert';

const withAlert = WrappedComponent =>
  class extends React.Component {
    bIsMounted = false;
    constructor(oProps) {
      super(oProps);

      this.state = {
        aAlertMessages: []
      };
    }

    componentWillUnmount() {
      this.bIsMounted = false;
    }

    componentDidMount() {
      this.bIsMounted = true;
      const { location } = this.props;
      this.container = document.querySelector('.MessageHolder');

      if (location && location.state && location.state.aAlertMessages !== undefined) {
        this.setState(oCurrentState => ({
          aAlertMessages: oCurrentState.aAlertMessages.concat(location.state.aAlertMessages)
        }));
      }
    }

    setAlert = (cType, cMsg) => {
      if (this.bIsMounted && cMsg) {
        this.setState({
          aAlertMessages: [{ cType, cMsg }]
        });
        const elPageContent = document.getElementsByClassName('v2-page-content')[0];
        if (elPageContent !== undefined) elPageContent.scrollTo(0, 0);
      }
    };

    addAlert = (cType, cMsg) => {
      if (cMsg) {
        this.setState(oCurrentState => ({
          aAlertMessages: oCurrentState.aAlertMessages.concat({ cType, cMsg })
        }));
      }
    };

    clearAlert = () => {
      this.setState({
        aAlertMessages: []
      });
    };

    onCloseAlert = cKey => {
      const { aAlertMessages } = this.state;

      this.setState({
        aAlertMessages: aAlertMessages.filter((cValue, cIndex) => cIndex !== cKey)
      });
    };

    getAlert = (cClassName = '') => {
      const { aAlertMessages } = this.state;
      if (aAlertMessages.length === 0) return null;

      const aAlerts = aAlertMessages.map((oAlert, cKey) => (
        <Alert type={oAlert.cType} className={cClassName} isOpen key={cKey} onCloseAlert={() => this.onCloseAlert(cKey)}>
          {typeof oAlert.cMsg === 'string'
            ? oAlert.cMsg
            : oAlert.cMsg.map(cMsg => (
              <p key={Math.random()}>{typeof cMsg === 'string' ? cMsg : JSON.stringify(cMsg)}</p>
            ))}
        </Alert>
      ));

      if (aAlerts.length === 0) return null;

      return aAlerts;
    }

    render() {
      return (
        <>
          <WrappedComponent
            {...this.props}
            addAlert={this.addAlert}
            setAlert={this.setAlert}
            clearAlert={this.clearAlert}
            getAlert={this.getAlert}
          />
        </>
      );
    }
  };
export default withAlert;
