import React, { Component } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import fLoading from '../assets/img/preloader-black.gif';
import imgfSelectLeft from '../pages/default-v2/img/select-left.svg';
import loadTreeView from '../actions/ActionTreeView';

class TreeViewLayer extends Component {
  aParentFolders = [];

  oFolder = null;

  constructor(oProps) {
    super(oProps);

    const { nFolderId, nDefaultFolderId, oFolderTree } = this.props;

    this.recursiveSearch(oFolderTree, nFolderId);
    if (this.aParentFolders && this.aParentFolders.length === 0) {
      this.recursiveSearch(oFolderTree, nDefaultFolderId);
    }
    const bIsOpen = this.aParentFolders.length > 0;

    this.state = {
      oFolderTree: this.oFolder,
      aLayers: this.aParentFolders,
      bIsOpen,
      bLoading: (oFolderTree === null),
      nOutsideFolderId: nFolderId
    };
  }

  componentDidMount() {
    const { bRequest } = this.props;
    if (bRequest === null) {
      loadTreeView()
        .then(aFolders => {
          const { nFolderId } = this.props;
          this.recursiveSearch(aFolders, nFolderId);
          const bIsOpen = this.aParentFolders.length > 0;

          this.setState({
            oFolderTree: this.oFolder,
            aLayers: this.aParentFolders,
            bIsOpen,
            bLoading: false
          });
        })
    }
  }

  recursiveSearch = (oFolder, nFolderId) => {
    if (oFolder === null)
      return false;

    if (oFolder.fldr_nid === nFolderId) {
      this.oFolder = oFolder;
      return true;
    }

    if (oFolder.recursive_folders === null || oFolder.recursive_folders === undefined || oFolder.recursive_folders.length === 0) return false;
    this.aParentFolders.push(oFolder);
    let bIsToContinue = false;

    oFolder.recursive_folders.some(oFolderItem => {
      bIsToContinue = this.recursiveSearch(oFolderItem, nFolderId);

      if (bIsToContinue) return true;

      if (oFolder.fldr_nid !== this.aParentFolders[this.aParentFolders.length - 1].fldr_nid) {
        this.aParentFolders.pop();
      }
      return false;
    });

    if (!bIsToContinue) {
      this.aParentFolders.pop();
    }

    return bIsToContinue;
  };

  handleChange = (evtChange) => {
    this.setState({ [evtChange.target.name]: evtChange.target.value });
  };

  returnLayer = (evtClick) => {
    evtClick.preventDefault();
    const { aLayers } = this.state;

    if (aLayers.length === 0)
      return;

    this.setState({
      oFolderTree: aLayers[aLayers.length - 1],
      aLayers: aLayers.splice(0, aLayers.length - 1)
    }, () => {
      const { oFolderTree } = this.state;
      this.selectFolder(null, oFolderTree.fldr_nid);
    });

  }

  selectLayer = (evtClick, oFolder) => {
    evtClick.preventDefault();
    const { aLayers, oFolderTree } = this.state;

    aLayers.push(oFolderTree);

    this.setState({
      oFolderTree: oFolder,
      bIsOpen: true,
      aLayers
    });

    this.selectFolder(evtClick, oFolder.fldr_nid);
  }

  selectFolder = (evtChange, nFolderId) => {
    const { fnSelectFolder, oFolderTree, bOutsideRender } = this.props;

    if (evtChange !== null) evtChange.preventDefault();
    if (oFolderTree.fldr_nid === nFolderId && oFolderTree.fldr_nid_parent === null && !oFolderTree.canCreate) {
      return;
    }

    this.setState({
      nFolderId,
      nOutsideFolderId: nFolderId
    });

    if (fnSelectFolder) {
      const evtChangeNew = {
        target: {
          name: 'fldr_nid',
          value: nFolderId
        }
      };
      fnSelectFolder(evtChangeNew);
    }
  };

  openSubs = (evtClick) => {
    evtClick.preventDefault();
    const elLi = evtClick.target.closest('li');
    const elA = evtClick.target.closest('a');
    elA.classList.toggle('opened');
    if (elLi.getElementsByTagName('ul')[0]) {
      if (elLi.getElementsByTagName('ul')[0].getBoundingClientRect().width === 0) {
        elLi.getElementsByTagName('ul')[0].style = 'display:block';
      } else {
        elLi.getElementsByTagName('ul')[0].style = 'display:none';
      }
    }
  };

  buildSub = (aFolder) => {
    const { nFolderId, bOutsideRender } = this.props;
    const { nOutsideFolderId } = this.state;

    let nFinalFolderId = nFolderId;
    if (bOutsideRender) {
      nFinalFolderId = nOutsideFolderId;
    }

    if (!Array.isArray(aFolder)) {
      return null;
    }

    const aItems = aFolder.map((oItem) => (
      <li key={oItem.fldr_nid}>
        {oItem.recursive_folders.length > 0 && (
          <a
            href="#open-subs"
            onClick={(evtClick) => this.selectLayer(evtClick, oItem)}
            title={intl.get("TreeView.folder")}
            className="open-folder"
          >
            <i className="icon-next" />
          </a>
        )}

        <a
          href="#select-folder"
          className={oItem.fldr_nid === nFinalFolderId ? 'selected' : ''}
          onClick={(evtClick) => this.selectFolder(evtClick, oItem.fldr_nid)}
        >
          <i className="icon-folder" /> &nbsp;
          {oItem.fldr_cname}
        </a>
      </li>
    ));
    return aItems;
  };

  render() {
    const { bLoading, oFolderTree, bIsOpen, aLayers, nOutsideFolderId } = this.state;
    const { className, bIsClosed, nFolderId, cHeight, bOutsideRender } = this.props;

    let nFinalFolderId = nFolderId;
    if (bOutsideRender) {
      nFinalFolderId = nOutsideFolderId;
    }

    const cClasses = `${bLoading ? 'load' : ''} tree-view-layer ${className}`;

    return (
      <div className={cClasses} style={{ maxHeight: cHeight }}>
        <input
          onChange={this.handleChange}
          value={nFinalFolderId || ''}
          type="hidden"
          name="nFolderId"
          id="nFolderId"
        />

        {!bLoading ? (
          <ul className="root">
            {oFolderTree &&
              [oFolderTree].map((oItem) => (
                <li key={oItem.fldr_nid}>
                  <a
                    onClick={(evtClick) => this.openSubs(evtClick)}
                    href="#open-subs"
                    title="Abrir/fechar pasta"
                    className={bIsOpen ? "open-folder opened" : "open-folder"}
                  >
                    <i className="icon-next" />
                  </a>
                  <a
                    className={oItem.fldr_nid === nFinalFolderId ? 'selected folder-name' : 'folder-name'}
                    onClick={(evtClick) => this.selectFolder(evtClick, oItem.fldr_nid)}
                    href="#select-folder"
                    title={oItem.fldr_cname}
                  >
                    <i className="icon-folder" /> &nbsp;
                    {oItem.fldr_cname}
                  </a>
                  {aLayers.length > 0 && (
                    <button
                      type="button"
                      className="goback"
                      onClick={this.returnLayer}
                    >
                      <img src={imgfSelectLeft} alt="" />
                    </button>
                  )}
                  {oItem.recursive_folders && oItem.recursive_folders.length > 0 && (
                    <ul style={{ display: bIsClosed && !bIsOpen && oItem.fldr_nid === nFinalFolderId ? 'none' : 'block' }}>
                      {this.buildSub(oItem.recursive_folders)}
                    </ul>
                  )}
                </li>
              ))}
          </ul>
        ) : (
          <span className="centered-title">
            <img alt="Carregando" src={fLoading} />
          </span>
        )}
      </div>
    );
  }
}

TreeViewLayer.propTypes = {
  nFolderId: PropTypes.number,
  nDefaultFolderId: PropTypes.number,
  oFolderTree: PropTypes.object,
  bIsClosed: PropTypes.bool,
  fnSelectFolder: PropTypes.func,
  cHeight: PropTypes.string,
  bRequest: PropTypes.bool
};

TreeViewLayer.defaultProps = {
  nFolderId: 1,
  nDefaultFolderId: 1,
  oFolderTree: null,
  bIsClosed: true,
  fnSelectFolder: () => { },
  cHeight: "auto",
  bRequest: false,
  bOutsideRender: false
};

export default TreeViewLayer;
