import React, { Component } from 'react';
import queryString from 'query-string';
import { Button } from 'reactstrap';
import Page from '../default-v2/Page';
import CardHeader from '../default-v2/CardHeader';
import Breadcrumb from '../../components/Breadcrumbs';
import intl from 'react-intl-universal';
import OrderingTable from '../../components/OrderingTable';
import Axios from '../../config/Axios';
import { can } from '../../config/Permissions';
import cLoadingImage from '../../assets/img/preloader-black.gif';
import './styles/extracthistory.scss';
import EncodedLocalStorage from '../../config/EncodedLocalStorage';

export class ExtractHistory extends Component {
  constructor(oProps) {
    super(oProps);

    this.N_DEFAULT_ITEMS_PER_PAGE = 50;
    this.N_DEFAULT_PAGE = 1;

    this.state = {
      bLoading: true,
      cOrderBy: 'doc_ctitle',
      cOrderType: 'asc',
      aPages: [
        { cName: 'configs', cLink: undefined },
        { cName: 'ExtractIndex.bill_history', cLink: undefined }
      ],
      aInvoices: [],
      nPage: this.N_DEFAULT_PAGE,
      nItemsPerPage: this.N_DEFAULT_ITEMS_PER_PAGE
    };
  }

  componentDidMount() {
    const { history } = this.props;

    if (!can('admin')) {
      history.push({
        pathname: '/',
        state: { aAlertMessages: { cType: 'error', cMsg: intl.get('sem_permissao') } }
      });
    }

    const nPage = Number(queryString.parse(this.props.location.search).nPage);
    const nItemsPerPage = Number(queryString.parse(this.props.location.search).nItemsPerPage);
    this.setState({
      nPage,
      nItemsPerPage
    });

    Axios.get(
      `/extract-history/${isNaN(nPage) ? this.N_DEFAULT_PAGE : nPage}/${isNaN(nItemsPerPage) ? this.N_DEFAULT_ITEMS_PER_PAGE : nItemsPerPage
      }`
    )
      .then(oResponse => {
        this.setState({
          aInvoices: oResponse.data
        });
      })
      .catch(oError => {
        //TODO: set alert indicating error
      })
      .finally(() => {
        this.setState({ bLoading: false });
      });
  }

  handleFilter = evt => { };

  handleClickTable = (evt, cNewOrderBy, cNewOrderType) => {
    this.setState(
      {
        cOrderBy: cNewOrderBy,
        cOrderType: cNewOrderType
      },
      () => {
        this.handleFilter(evt);
      }
    );
  };

  getInvoiceStatus = oInvoice => {
    if (oInvoice?.cancelled_date !== '') {
      return intl.get('ExtractIndex.cancelled');
    }
    if (oInvoice?.payment_date !== '') {
      return intl.get('ExtractIndex.paid');
    }
    return intl.get('ExtractIndex.pending');
  };

  openInvoice = cCounterpartLink => {
    const oClientAreaWindow = window.open(cCounterpartLink, '_blank', 'noopener,noreferrer');
    if (oClientAreaWindow) oClientAreaWindow.opener = null;
  };

  filterPage = nPage => {
    const { nItemsPerPage } = this.state;
    const { history } = this.props;
    history.push(`/extract-history/${nPage}/${nItemsPerPage}`);
  };

  formatSuperlogicaDate = cDate => {
    const oLocalStorage = new EncodedLocalStorage();
    const cLanguage = oLocalStorage.get('cLanguage');
    return new Date(cDate).toLocaleString(cLanguage, {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    });
  };

  renderList() {
    const { aInvoices, nPage, nItemsPerPage } = this.state;

    return (
      <>
        <OrderingTable
          aColumn={[
            {
              cName: intl.get('ExtractIndex.payment_date_column'),
              cOrderBy: '',
              fnHandleClick: this.handleClickTable
            },
            { cName: intl.get('ExtractIndex.bill_column'), cOrderBy: '', fnHandleClick: this.handleClickTable },
            { cName: intl.get('ExtractIndex.value_column'), cOrderBy: '', fnHandleClick: this.handleClickTable },
            {
              cName: intl.get('ExtractIndex.payment_column'),
              cOrderBy: '',
              fnHandleClick: this.handleClickTable
            }
          ]}
          cTableClassName="extract-history-table"
        >
          {aInvoices?.length > 0 &&
            aInvoices.map(oInvoice => (
              <tr
                key={`invoice-row-${oInvoice.id}`}
                onClick={oEvent => {
                  oEvent.preventDefault();
                  this.openInvoice(oInvoice?.invoice_counterpart);
                }}
              >
                <td>{oInvoice.payment_date !== '' ? this.formatSuperlogicaDate(oInvoice.payment_date) : 'N/A'}</td>
                <td>{`#${oInvoice.id} `}</td>
                <td>{`R$ ${oInvoice.total_value.replace(',', '').replace('.', ',')}`}</td>
                <td>{this.getInvoiceStatus(oInvoice)}</td>
              </tr>
            ))}
        </OrderingTable>
        <div className="table-pagination">
          <div>
            <Button
              disabled={isNaN(nPage) || nPage <= 1}
              onClick={oEvent => {
                oEvent.preventDefault();
                if (nPage > 1) {
                  this.filterPage(nPage - 1);
                }
              }}
            >
              {intl.get('anterior')}
            </Button>
          </div>
          <div>
            <Button
              disabled={!aInvoices || aInvoices.length <= 50}
              onClick={oEvent => {
                oEvent.preventDefault();
                this.filterPage(nPage + 1);
              }}
            >
              {intl.get('proximo')}
            </Button>
          </div>
        </div>
      </>
    );
  }

  render() {
    const { bLoading, aPages } = this.state;
    const { history } = this.props;

    return (
      <Page
        loading={bLoading ? 1 : 0}
        rcmpBreadcrumb={<Breadcrumb aPages={aPages} />}
        cTitle={intl.get('ExtractIndex.extrato_titulo')}
        rcmpCardHeader={<CardHeader history={history} />}
        className="v2-extractindex"
      >
        {bLoading ? (
          <div className="loading-container">
            <img alt={intl.get('loading')} src={cLoadingImage} />
          </div>
        ) : (
          this.renderList()
        )}
      </Page>
    );
  }
}

export default ExtractHistory;
