import intl from 'react-intl-universal';
import React, { Component } from 'react';
import Axios from '../../config/Axios';
import Page from '../default-v2/Page';
import { checkModule } from '../../config/Permissions';
import managementImg from '../../assets/img/management.svg';

import './styles/pdf.scss';

class Pdf extends Component {
  constructor(props) {
    super(props);
    const { match } = this.props;

    this.state = {
      nAuditId: match.params.nAuditId,
      html: true,
      oLoad: true
    };
  }

  print = evt => {
    evt.preventDefault();
    window.print();
  };

  componentDidMount = () => {
    const { history } = this.props;
    checkModule(history, 'audit-management');
    const { nAuditId } = this.state;
    Axios.get(`audit/print/${nAuditId}`).then(oResponse => {
      let htmlReplaced = oResponse.data.replace(/\>[\t\n ]+\</g, '><');
      this.setState(
        {
          html: htmlReplaced,
          oLoad: false
        },
        () => {
          this.setOnClickEvents();
        }
      );
    });
  };

  setOnClickEvents = () => {
    const aLinks = document.getElementsByClassName('attachment-download');
    if (aLinks !== undefined) {
      for (let n = 0; n < aLinks.length; n += 1) {
        aLinks[n].addEventListener('click', this.handleDownloadAttachment);
      }
    }
  };

  handleDownloadAttachment = evtClick => {
    evtClick.preventDefault();
    const { setAlert } = this.props;
    const nId = evtClick.target.getAttribute('data-nid');

    Axios.get(`audit-evidence/attachment/${nId}`)
      .then(oResult => {
        fetch(oResult.data.cAttachment)
          .then(response => response.blob())
          .then(fBlob => {
            const oLink = document.createElement("a");
            oLink.href = URL.createObjectURL(fBlob);
            document.body.appendChild(oLink);
            oLink.download = oResult.data.cDocName;
            oLink.click();
            document.body.removeChild(oLink);
          }).catch(oError => {
            setAlert('error', oError.msgErrors);
          });
      })
      .catch(oError => {
        setAlert('error', oError.msgErrors);
      });
  };

  render() {
    const { html, oLoad } = this.state;
    return (
      <Page loading={oLoad ? 1 : 0} cImage={managementImg} className="v2-pdf-page">
        <div>
          <a
            href="#imprimir"
            onClick={evt => this.print(evt)}
            title={intl.get('DocumentPrint.imprimir')}
            className="floating-print-button"
          >
            <i className="icon-print" />
          </a>

          <div id="report-to-print" dangerouslySetInnerHTML={{ __html: html }} />
        </div>
      </Page>
    );
  }
}

export default Pdf;
