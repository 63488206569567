import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Input, Table } from 'reactstrap';
import intl from 'react-intl-universal';
import AltCheckbox from '../../components/AltCheckbox';
import _ from 'lodash';
import Axios from '../../config/Axios';
import url from '../../config/Url';
import imgfNoPic from '../../assets/img/nopic.svg';
import { can, canAtLeast } from '../../config/Permissions';
import Breadcrumb from '../../components/Breadcrumbs';
import Page from '../default-v2/Page';
import Filter from '../default-v2/Filter';
import CardHeader from '../default-v2/CardHeader';
import './styles/userindex.scss';
import userImg from '../../assets/img/users.svg';
import Button from '../../components/Button';
import EncodedLocalStorage from '../../config/EncodedLocalStorage';
import { formatDateToUserTZ } from '../../utils/time';

const oLocalStorage = new EncodedLocalStorage();
const RESEND_INVITES_DELAY_SECONDS = 10;
class UserIndex extends Component {
  constructor(oProps) {
    super(oProps);
    this.state = {
      bLoading: true,
      oLimits: [],
      aUsers: [],
      aRoles: {},
      cSearch: '',
      cOrderBy: '',
      cOrderType: 'asc',
      cRole: '',
      bIsSending: false,
      aPages: [{ cName: 'UserIndex.titulo', cLink: undefined }],
      bShowInactivated: false,
      bAwaitingResendDelay: false,
    };
  }

  componentDidMount = () => {
    const { setAlert, history } = this.props;

    if (!canAtLeast(['create-edit-user', 'admin'])) {
      history.push({
        pathname: '/',
        state: { aAlertMessages: { cType: 'error', cMsg: intl.get('sem_permissao') } }
      });
      return;
    }

    this.loadData();
  };

  loadData = () => {
    const { setAlert } = this.props;

    Promise.all([
      Axios.get('/user/all').then(oRes => {
        const { aUsers, aRoles } = oRes.data;
        this.setState({
          aUsers: aUsers,
          aRoles: aRoles
        });
      })
    ])
      .then(() => this.setState({ bLoading: false }))
      .catch(oError => {
        this.setState({ bLoading: false });
        setAlert('error', oError.aMsgErrors);
      });
  };

  editUser = nId => {
    const { history } = this.props;
    history.push(`/user/update/${nId}`);
  };

  deleteUser = oUser => {
    const { bIsSending } = this.state;
    if (bIsSending) return;

    this.setState({ bIsSending: true });
    const cDeleteURL = `/user/${oUser.user_nid}`;
    Axios.delete(cDeleteURL)
      .then(() => {
        this.setState({
          bIsSending: false,
          rcmpAlert: (
            <SweetAlert
              success
              title={intl.get('removido')}
              onConfirm={() => {
                this.loadData();
                this.hideAlert();
              }}
            >
              {intl.get('UserIndex.excluir_sucesso')}
            </SweetAlert>
          )
        });
      })
      .catch(oError => {
        this.setState({
          bIsSending: false,
          rcmpAlert: (
            <SweetAlert error title={intl.get('opa')} onConfirm={this.hideAlert}>
              {oError.aMsgErrors.map((cMsg => <p>{cMsg}</p>))}
            </SweetAlert>
          )
        });
      });
  };

  handleChange = evt => {
    evt.preventDefault();
    this.setState({ [evt.target.name]: evt.target.value });
  };

  showDelete = (evt, oUser) => {
    this.setState({
      rcmpAlert: (
        <SweetAlert
          warning
          showCancel
          confirmBtnText={intl.get('confirmar')}
          cancelBtnText={intl.get('cancelar')}
          cancelBtnBsStyle="danger"
          title={intl.get('remover')}
          onConfirm={() => this.deleteUser(oUser)}
          onCancel={this.hideAlert}
        >
          {intl.get('mensagem_excluir', { tipo: intl.get('usuario'), nome: oUser.user_cname })}
        </SweetAlert>
      )
    });
  };

  hideAlert = () => {
    this.setState({
      rcmpAlert: null
    });
  };

  resendInvite = async (evt, nId) => {
    evt.preventDefault();
    const { setAlert } = this.props;

    try {
      await Axios.put(`user-invite/${nId}`);
      setAlert('success', intl.get('UserIndex.reenvio_convite_sucesso'));
    } catch (oError) {
      setAlert('error', oError.aMsgErrors);
    }
  };

  resendInvites = evt => {
    evt.preventDefault();
    const { bIsSending, bAwaitingResendDelay } = this.state;
    const { setAlert } = this.props;

    if (bIsSending || bAwaitingResendDelay) return;

    this.setState({ bIsSending: true });

    Axios.post(`user-invite/resend`)
      .then(() => {
        this.setState({
          bIsSending: false,
          bAwaitingResendDelay: true,
        });
        setAlert('success', intl.get('UserIndex.reenvio_convite_sucesso_todos'));
        setTimeout(() => this.setState({ bAwaitingResendDelay: false }), RESEND_INVITES_DELAY_SECONDS * 1000);
      })
      .catch(oError => {
        this.setState({ bIsSending: false });
        setAlert('error', oError.aMsgErrors);
      });
  };

  canDelete = oUser => {
    const { aUsers } = this.state;
    if (oUser.user_nid === Number(oLocalStorage.get('nUserId'))) return false;
    if (oUser.bIsAdmin && _.values(aUsers).filter(oUserFiltered => oUserFiltered.bIsAdmin).length <= 1) return false;
    if (!can('admin') && oUser.bIsAdmin) return false;
    return true;
  };

  renderListUser() {
    const { aUsers, bShowInactivated } = this.state;
    if (aUsers.length == 0) {
      return (
        <p className="empty-user">
          <strong>{intl.get('UserIndex.no_result_user')}</strong>
        </p>
      );
    }

    return (
      <Table responsive className={'table-user'}>
        <thead>
          <tr>
            <th className="column-user-name">{intl.get('UserIndex.column_user_name')}</th>
            <th className="column-teams">{intl.get('UserIndex.column_teams')}</th>
            <th className="column-created-by">{intl.get('UserIndex.column_created_by')}</th>
            {bShowInactivated ? <th className="column-removed-by">{intl.get('UserIndex.column_removed_by')}</th> : ''}
            <th className="column-last-acess">{intl.get('UserIndex.column_last_acess')}</th>
            {bShowInactivated ? (
              <th className="column-removal-date">{intl.get('UserIndex.column_removal_date')}</th>
            ) : (
              ''
            )}
            <th className="column-buttons"></th>
          </tr>
        </thead>
        <tbody>
          {aUsers.map(oUser => (
            <tr key={oUser.user_nid} className={`${!oUser.oCstUsrInactivatedBy ? 'active' : 'deleted'}`}>
              <td className="user-info">
                <div
                  title={!oUser.user_bisregistered ? intl.get('UserIndex.pendente') : ''}
                  className={`avatar ${!oUser.user_bisregistered ? 'pending' : ''}`}
                >
                  <img src={oUser.user_cavatar ? oUser.user_cavatar : imgfNoPic} alt={oUser.user_cname} />
                </div>

                <div className="info">
                  <h2 title={oUser.user_cname}>{oUser.user_cname}</h2>
                </div>
              </td>

              <td>
                <div className="badges">
                  {oUser.myTeamsName
                    ? Object.keys(oUser.myTeamsName).map(nId => (
                      <span key={nId} className="team-badge" title={oUser.myTeamsName[nId]}>
                        {oUser.myTeamsName[nId]}
                      </span>
                    ))
                    : '-'}
                </div>
              </td>

              <td>
                <div className="create-by" title={oUser.oCstUsrCreatedBy ? oUser.oCstUsrCreatedBy.user_cname : ''}>
                  {oUser.oCstUsrCreatedBy ? oUser.oCstUsrCreatedBy.user_cname : '-'}
                </div>
              </td>

              {bShowInactivated ? (
                <td>
                  <div
                    className="removed-by"
                    title={oUser.oCstUsrInactivatedBy ? oUser.oCstUsrInactivatedBy.user_cname : ''}
                  >
                    {oUser.oCstUsrInactivatedBy ? oUser.oCstUsrInactivatedBy.user_cname : '-'}
                  </div>
                </td>
              ) : (
                ''
              )}

              <td>
                <div
                  className="last-acess"
                  title={oUser.user_dlastacess ? formatDateToUserTZ(oUser.user_dlastacess) : '-'}
                >
                  {oUser.user_dlastacess ? formatDateToUserTZ(oUser.user_dlastacess) : '-'}
                </div>
              </td>

              {bShowInactivated ? (
                <td>
                  <div
                    className="removal-date"
                    title={oUser.cCstUsrInactivatedAt ? formatDateToUserTZ(oUser.cCstUsrInactivatedAt) : '-'}
                  >
                    {oUser.cCstUsrInactivatedAt ? formatDateToUserTZ(oUser.cCstUsrInactivatedAt) : '-'}
                  </div>
                </td>
              ) : (
                ''
              )}

              <td className="action-user-item">
                <div className="buttons">
                  {canAtLeast(['create-edit-user', 'admin']) &&
                    oUser.user_bisregistered === false &&
                    oUser.oCstUsrInactivatedBy === null && (
                      <UserActionButton
                        cTitle={intl.get('UserIndex.reenviar_convite')}
                        cType="resend"
                        fnOnClick={evt => this.resendInvite(evt, oUser.user_nid)}
                        nRateLimitSecs={RESEND_INVITES_DELAY_SECONDS}
                      />
                    )}
                  {(canAtLeast(['create-edit-user', 'admin']) ||
                    oUser.user_nid === Number(oLocalStorage.get('nUserId'))) && (
                      <UserActionButton
                        cTitle={intl.get('UserIndex.editar')}
                        cType="edit"
                        fnOnClick={() => this.editUser(oUser.user_nid)}
                      />
                    )}
                  {can('delete-user') && this.canDelete(oUser) && (
                    <UserActionButton
                      cTitle={intl.get('excluir', { tipo: intl.get('usuario') })}
                      cType="remove"
                      fnOnClick={evt => this.showDelete(evt, oUser)}
                    />
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }

  renderListOption() {
    const { aRoles } = this.state;
    const rcmpList = Object.keys(aRoles).map(cKey => (
      <option key={aRoles[cKey]} value={aRoles[cKey]}>
        {aRoles[cKey]}
      </option>
    ));

    return (
      <Input type="select" name="user_crole" id="user_crole" onChange={this.handleChange}>
        <option value="">{intl.get('UserIndex.filtro_cargo_funcao')}</option>
        {rcmpList}
      </Input>
    );
  }

  handleNewFilter = (evt, oFilter) => {
    this.setState(
      {
        cSearch: oFilter.cText,
        cOrderBy: oFilter.cOrderBy,
        cOrderType: oFilter.cOrderType
      },
      () => {
        this.handleFilter(evt);
      }
    );
  };

  handleFilter = evt => {
    const { cSearch, cOrderType, bShowInactivated } = this.state;
    const { setAlert } = this.props;

    Axios.get('/user/search', {
      params: {
        cSearch,
        cOrderType,
        bShowInactivated
      }
    })
      .then(oResponse => {
        const { aUsers } = oResponse.data;
        this.setState({
          aUsers: aUsers
        });
      })
      .catch(oError => {
        setAlert('error', oError.aMsgErrors);
      });
  };

  handleChangeState = evtChange => {
    this.setState(
      {
        [evtChange.target.name]: evtChange.target.checked
      },
      () => {
        this.handleFilter(evtChange);
      }
    );
  };

  generateCardHeader = () => {
    const { history } = this.props;
    const { bShowInactivated, bAwaitingResendDelay } = this.state;

    const aButtons = [];

    let rcmpBtnInvitation = null;
    let rcmpBtnNewDocument = null;
    let rcmpBtnFilterUser = null;

    if (canAtLeast(['create-edit-user', 'admin'])) {
      rcmpBtnFilterUser = (
        <AltCheckbox
          className="btn-filter-user"
          name="bShowInactivated"
          id="bShowInactivated"
          label={intl.get('UserIndex.show_inactivated')}
          onChange={this.handleChangeState}
          checked={bShowInactivated}
        />
      );
    }

    if (canAtLeast(['create-edit-user', 'admin'])) {
      rcmpBtnInvitation = <Button
        onClick={this.resendInvites}
        style={bAwaitingResendDelay && {
          pointerEvents: 'none',
          cursor: 'not-allowed',
          opacity: 0.5
        }}
      >{intl.get('UserIndex.botao_reenviar_convites')}</Button>;
    }

    if (canAtLeast(['create-edit-user', 'admin'])) {
      rcmpBtnNewDocument = (
        <Button
          onClick={evt => {
            history.push('/invite-users');
          }}
        >
          {intl.get('UserIndex.botao_novo_usuario')}
        </Button>
      );
    }

    aButtons.push(rcmpBtnFilterUser);
    aButtons.push(rcmpBtnInvitation);
    aButtons.push(rcmpBtnNewDocument);

    let aOrderItems = [];
    aOrderItems = [{ value: 'cName', label: intl.get('name') }];
    let cDefaultOrder = aOrderItems[0].value;

    const rcmpFilter = (
      <Filter
        aButtons={aButtons}
        aOrderItems={aOrderItems}
        cDefaultOrder={cDefaultOrder}
        fnOnType={this.handleNewFilter}
      />
    );

    const rcmpCardHeader = <CardHeader rcmpFilter={rcmpFilter} bIsItToInsertGoBackBtn={false} />;
    return rcmpCardHeader;
  };

  render() {
    const { rcmpAlert, aPages, bLoading } = this.state;
    const { getAlert } = this.props;

    const rcmpBreadcrumb = <Breadcrumb aPages={aPages} />;

    const rcmpCardHeader = this.generateCardHeader();

    const cPageTitle = intl.get('UserIndex.titulo');

    return (
      <Page
        loading={bLoading ? 1 : 0}
        rcmpBreadcrumb={rcmpBreadcrumb}
        cTitle={cPageTitle}
        cImage={userImg}
        rcmpCardHeader={rcmpCardHeader}
        className="v2-user"
        cCurrentSideMenuOption={intl.get('Nav.users')}
      >
        {rcmpAlert}
        {getAlert()}
        {this.renderListUser()}
      </Page>
    );
  }
}


class UserActionButton extends React.Component {
  static defaultProps = {
    fnOnClick: () => { },
    nRateLimit: 0,
  }

  static propTypes = {
    fnOnClick: PropTypes.func,
    cType: PropTypes.string,
    cTitle: PropTypes.string,
    nRateLimitSecs: PropTypes.number,
  }

  constructor(props) {
    super(props);
    this.state = {
      bAwaitingDelay: false,
    }
  }

  handleClick = (evtClick) => {
    const { bIsAwaitingDelay } = this.state;
    const { fnOnClick, nRateLimitSecs } = this.props;

    if (bIsAwaitingDelay) return;

    fnOnClick(evtClick);
    if (nRateLimitSecs > 0) {
      this.setState({ bIsAwaitingDelay: true });
      setTimeout(() => {
        this.setState({ bIsAwaitingDelay: false });
      }, nRateLimitSecs * 1_000);
    }
  }

  render() {
    const { bIsAwaitingDelay } = this.state;
    const { cType, cTitle } = this.props;
    return (
      <button
        aria-labelledby="buttons"
        type="button"
        onClick={this.handleClick}
        title={cTitle}
        className={cType}
        disabled={bIsAwaitingDelay}
      />
    );
  }
}


export default UserIndex;
