import React, { Component } from 'react';

class DashboardGroupBlock extends Component {
  constructor(oProps) {
    super(oProps);
    this.state = {
    };
  }

  render() {
    const { cIcon, cIconName, cTitle, cGroupBlockClassName, cGroupBlockIconClassName, children } = this.props;

    return (
      <section className={cGroupBlockClassName ? `v2-dashboard-group ${cGroupBlockClassName}` : 'v2-dashboard-group'}>
        <div className="v2-dashboard-group-head">
          {cIcon ? (
            <img className={cGroupBlockIconClassName ? `v2-dashboard-group-icon ${cGroupBlockIconClassName}` : 'v2-dashboard-group-icon'} src={cIcon} alt={cIconName} />
          ) : ''}
          <h2 className="v2-dashboard-group-title">
            {cTitle}
          </h2>
        </div>

        {children}

      </section>
    );
  };

}

DashboardGroupBlock.defaultProps = {
  cIcon: '',
  cIconName: '',
  cTitle: '',
  cGroupBlockClassName: '',
  cGroupBlockIconClassName: ''
};

export default DashboardGroupBlock;
