import React, { Component } from 'react';
import getReadDetails from '../../actions/ActionDocumentDetailsRead';
import imgfUser from '../../assets/img/nopic.svg';
import Breadcrumb from '../../components/Breadcrumbs';
import url from '../../config/Url';
import Page from '../default-v2/Page';
import intl from 'react-intl-universal';
import './styles/documentdetailsread.scss';
import CardHeader from '../default-v2/CardHeader';
import { Table } from 'react-bootstrap';
import Filter from '../default-v2/Filter';
import { checkModule } from '../../config/Permissions';
import reportsImg from '../../assets/img/reports.svg';
import { formatDateToUserTZ } from '../../utils/time';

class DocumentDetailsRead extends Component {
  constructor(oProps) {
    super(oProps);

    this.state = {
      bLoading: true,
      oVersion: {
        document: []
      },
      nVersionId: oProps.match.params.nVersionId,
      nQuantityUsers: [],
      nQuantityReaders: [],
      nQuantityNotRead: [],
      aUsers: [],
      cSearch: '',
      cOrderBy: '',
      cOrderType: 'asc',
      aPages: [{ cName: 'Nav.reports' }, { cName: 'DocumentReading.title', cLink: '/report/reading-document' }]
    };
  }

  componentDidMount = () => {
    const { nVersionId, cSearch, cOrderBy, cOrderType } = this.state;
    const { setAlert, history } = this.props;
    checkModule(history, 'documents');

    getReadDetails(nVersionId, cSearch, cOrderBy, cOrderType)
      .then(oData => this.setState({ bLoading: false, ...oData }))
      .catch(oError => {
        this.setState({ bLoading: false });
        setAlert('error', oError.aMsgErrors);
      });
  };

  handleFilter = evt => {
    const { nVersionId, cSearch, cOrderBy, cOrderType } = this.state;
    const { setAlert } = this.props;

    getReadDetails(nVersionId, cSearch, cOrderBy, cOrderType)
      .then(oData => this.setState({ ...oData }))
      .catch(oError => setAlert('error', oError.aMsgErrors));
  };

  handleChange = evt => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  changeOrder = evt => {
    evt.preventDefault();
    this.setState(oPrevState => ({
      cOrderType: oPrevState.cOrderType === 'asc' ? 'desc' : 'asc'
    }));
  };

  handleNewFilter = (evt, oFilter) => {
    this.setState(
      {
        cSearch: oFilter.cText,
        cOrderBy: oFilter.cOrderBy,
        cOrderType: oFilter.cOrderType
      },
      () => {
        this.handleFilter(evt);
      }
    );
  };

  generateCardHeader = () => {
    const { history } = this.props;
    let aOrderItems = [];
    aOrderItems = [
      { value: 'cName', label: intl.get('name') },
      { value: 'cCreatedDate', label: intl.get('DocumentDetailsRead.created_at') }
    ];
    let cDefaultOrder = aOrderItems[0].value;

    const rcmpFilter = (
      <Filter aOrderItems={aOrderItems} cDefaultOrder={cDefaultOrder} fnOnType={this.handleNewFilter} />
    );

    const rcmpCardHeader = <CardHeader rcmpFilter={rcmpFilter} history={history} />;
    return rcmpCardHeader;
  };

  render() {
    const { oVersion, nQuantityUsers, nQuantityNotRead, nQuantityReaders, aUsers, bLoading, aPages } = this.state;
    const { getAlert } = this.props;
    const bDocTitleLoaded = oVersion.document.doc_ctitle !== undefined && oVersion.document.doc_ctitle.length > 35;

    const rcmpBreadcrumb = (
      <>
        {oVersion && (
          <div className="breadcrumbs-wrapper" style={{ fontSize: '14px' }}>
            <div className="inner-bread">
              <Breadcrumb bLastLink key={oVersion.document.ver_nid} aPages={aPages} cType="document" />
            </div>
            <span> > </span>
            <em title={bDocTitleLoaded ? oVersion.document.doc_ctitle : ''}>
              {bDocTitleLoaded ? oVersion.document.doc_ctitle.trunc(35) : oVersion.document.doc_ctitle}
            </em>
          </div>
        )}
      </>
    );

    const rcmpCardHeader = this.generateCardHeader();

    const cPageTitle = intl.get('DocumentDetailsRead.titulo');

    return (
      <Page
        loading={bLoading ? 1 : 0}
        rcmpBreadcrumb={rcmpBreadcrumb}
        cTitle={cPageTitle}
        cImage={reportsImg}
        rcmpCardHeader={rcmpCardHeader}
        className="v2-details-read"
        cCurrentSideMenuOption={intl.get('Nav.document')}
      >
        {getAlert()}
        <section id="highlights">
          <div>
            <span>
              <strong>{nQuantityUsers}</strong>
            </span>
            <span>{intl.get('DocumentDetailsRead.qtdUsuario')}</span>
          </div>
          <div>
            <span>
              <strong>{nQuantityReaders}</strong>
            </span>
            <span>{intl.get('DocumentDetailsRead.qtdLeitura')}</span>
          </div>
          <div>
            <span>
              <strong>{nQuantityNotRead}</strong>
            </span>
            <span>{intl.get('DocumentDetailsRead.qtdPendente')}</span>
          </div>
        </section>

        <Table responsive className="table-details-read">
          <thead>
            <tr>
              <th>{intl.get('DocumentDetailsRead.user')}</th>
              <th>{intl.get('DocumentDetailsRead.read')}</th>
            </tr>
          </thead>
          <tbody>
            {aUsers.map(oUser => (
              <tr key={oUser.user_nid}>
                <td className="user-info">
                  <img
                    src={
                      oUser.user_cavatar
                        ? oUser.user_cavatar
                        : imgfUser
                    }
                    alt={intl.get('DocumentDetailsRead.imagem_usuario')}
                  />
                  <span title={oUser.user_cname}>
                    <p>{oUser.user_cname}</p>
                  </span>
                </td>
                <td>
                  {oUser.oVnRdSpecific ? (
                    <p className="read">
                      {formatDateToUserTZ(
                        oUser.oVnRdSpecific.vnrd_dcreated,
                        `DD/MM/YYYY [${intl.get('DocumentDetailsRead.at')}] HH:mm`
                      )}
                    </p>
                  ) : (
                    <p className="pending-read">{intl.get('DocumentDetailsRead.pending')}</p>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Page>
    );
  }
}

export default DocumentDetailsRead;
