import React, { Component } from 'react';
import intl from 'react-intl-universal';
import imgfGroup from '../../assets/img/group.svg';
import imgfNoPic from '../../assets/img/nopic.svg';
import url from '../../config/Url';
import Axios from '../../config/Axios';
import { Alert } from '../../components/Alert';
import PageClean from '../default-v2/PageClean';
import './styles/foldershow.scss';
import { checkModule } from '../../config/Permissions';

class FolderShow extends Component {
  constructor(oProps) {
    super(oProps);

    this.state = {
      rcmpAlertMsg: '',
      aTeams: [],
      aUsers: [],
      bLoading: true
    };
  }

  componentDidMount() {
    const { nFolderId, history } = this.props;
    checkModule(history, 'documents');

    Axios.get(`/folder/access-info/${nFolderId}`)
      .then(oResponse => {
        this.setState({
          ...oResponse.data,
          bLoading: false
        });
      })
      .catch(oError => {
        this.setState({
          bLoading: false,
          rcmpAlertMsg: (
            <Alert type="error" isOpen onCloseAlert={() => { }}>
              {oError.aMsgErrors}
            </Alert>
          )
        });
      });
  }

  avatarTeam = oTeam => {
    let imgfSrc = imgfGroup;

    if (oTeam.team_cavatar) {
      imgfSrc = `${oTeam.team_cavatar}`;
    }

    return <img src={imgfSrc} alt={oTeam.team_cname} />;
  };

  avatarUser = oUser => {
    let imgfSrc = imgfNoPic;

    if (oUser.user_cavatar) {
      imgfSrc = oUser.user_cavatar
    }

    return <img src={imgfSrc} alt={oUser.user_cname} />;
  };

  render() {
    const { bLoading, rcmpAlertMsg, aTeams, aUsers } = this.state;
    return (
      <PageClean loading={bLoading ? 1 : 0} className="containerInfo">
        {rcmpAlertMsg}
        <div>
          <h2 className="includeMargin" style={{ fontWeight: 'bold' }}>
            {intl.get('FolderShow.access_settings')}
          </h2>

          <div className="containerInfo-data">
            <ul className="list-folder-info-group">
              {aTeams
                ? aTeams.map((oTeam, nIndex) => (
                  <li className="list-folder-info-group-item" key={oTeam.team_nid}>
                    {this.avatarTeam(oTeam)}
                    <p className="element-name" title={oTeam.team_cname}>
                      {oTeam.team_cname}
                    </p>
                    <div className="containerPermission">
                      <p className="element-permission">
                        {intl.get('FolderShow.permission')}:{' '}
                        {oTeam.pivot.tf_bcreatesfolder
                          ? intl.get('FolderShow.can_create')
                          : intl.get('FolderShow.can_view')}
                      </p>
                    </div>
                  </li>
                ))
                : ''}
              {aUsers
                ? aUsers.map((oUser, nIndex) => (
                  <li className="list-folder-info-group-item" key={oUser.user_nid}>
                    {this.avatarUser(oUser)}
                    <p className="element-name" title={oUser.user_cname}>
                      {oUser.user_cname}
                    </p>
                    <div className="containerPermission">
                      <p className="element-permission">
                        {intl.get('FolderShow.permission')}:{' '}
                        {oUser.pivot.uf_bcreatesdocument
                          ? intl.get('FolderShow.can_create')
                          : intl.get('FolderShow.can_view')}
                      </p>
                    </div>
                  </li>
                ))
                : ''}
              {aTeams && aTeams.length === 0 && aUsers && aUsers.length === 0 ? (
                <p>{intl.get('FolderShow.nenhuma_especificacao')}</p>
              ) : (
                ''
              )}
            </ul>
          </div>
        </div>
      </PageClean>
    );
  }
}

FolderShow.defaultProps = {
  nFolderId: null
};

export default FolderShow;
