import Axios from '../config/Axios';

export default function myPendingReadingResponsible(page = 1, perPage = 50, orderBy = 'doc_ctitle', order = 'ASC', search) {
  return Axios.get('/my-account/pending-read-responsible', {
    params: {
      page,
      perPage,
      orderBy,
      order,
      search
    }
  }).then((oResponse) => oResponse.data);
}
