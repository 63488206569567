import React, { Component } from 'react';

class SideMenuGroup extends Component {
  constructor(oProps) {
    super(oProps);
  }

  renderSideMenuItems = () => {
    const { aItems } = this.props;

    if (!aItems.length) return null;

    return (
      <ul className='box-user'>
        {Object.keys(aItems).map(cKey => aItems[cKey])}
      </ul>
    );
  };

  render = () => {
    const { rcmpContent, bLowerBorder, bUperBorder, className } = this.props;
    const cClassName = `v2-sidemenugroup ${className}`;
    let rcmpLowerBorder = '';
    let rcmpUperBorder = '';
    if (bLowerBorder) {
      rcmpLowerBorder = (
        <div className="v2-sidemenugroup-lowerborder">
          {/* <div>&nbsp;</div> */}
        </div>
      );
    }

    if (bUperBorder) {
      rcmpUperBorder = <div className="v2-sidemenugroup-lowerborder">&nbsp;</div>;
    }

    return (
      <div className={cClassName}>
        {rcmpUperBorder}
        {rcmpContent}

        {this.renderSideMenuItems()}
        {rcmpLowerBorder}
      </div>
    );
  };
}

SideMenuGroup.defaultProps = {
  rcmpContent: '',
  bLowerBorder: false,
  className: '',
  aItems: []
};
export default SideMenuGroup;
