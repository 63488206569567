import React from 'react';
import Pagination from 'react-js-pagination';
import intl from 'react-intl-universal';


const PaginationMenu = ({ nCurrentPage, nItemsPerPage, nTotalPages, nTotal, handlePageChange, handleItemsPerPageChange }) => {
    return (
        <div className="pagination-container">
            <Pagination
                activePage={nCurrentPage}
                itemsCountPerPage={nItemsPerPage}
                totalItemsCount={nItemsPerPage * nTotalPages}
                pageRangeDisplayed={3}
                onChange={handlePageChange}
            />
            <div className="items-per-page">
                <span className="per-page-label">{intl.get('Report.pagination.total_per_page', { nTotal })}</span>
                <select value={nItemsPerPage} onChange={handleItemsPerPageChange}>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="30">30</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="150">150</option>
                    <option value="200">200</option>
                    <option value="250">250</option>
                    <option value="300">300</option>
                </select>
            </div>
        </div>
    );
};

export default PaginationMenu;