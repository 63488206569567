import Axios from '../config/Axios';

export default function documentReading(page = 1, perPage = 50, orderBy = 'dpublished', order = 'ASC', search) {
  return Axios.get('read', {
    params: {
      page,
      perPage,
      orderBy,
      order,
      search
    }
  }).then(oResponse => oResponse.data);
}
