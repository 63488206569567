import moment from 'moment';

export default function checkTime(cDiffTime, nTime, cEndDate, cDate = null) {
  let dCurrentDate = moment();
  let nQuantity = 0;

  if (nTime < 0)
    return false;

  if (cDate !== null)
    dCurrentDate = moment(cDate);

  switch (cDiffTime) {
    case 'D':
      nQuantity = moment(cEndDate).diff(dCurrentDate, "days");
      break;
    case 'S':
      nQuantity = moment(cEndDate).diff(dCurrentDate, "weeks");
      break;
    case 'M':
      nQuantity = moment(cEndDate).diff(dCurrentDate, "months");
      break;
    case 'A':
      nQuantity = moment(cEndDate).diff(dCurrentDate, "years");
      break;
    default:
      return false;
  }

  if (nTime <= nQuantity)
    return true;

  return false;
};
