/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Component } from 'react';
import moment from 'moment';
import intl from 'react-intl-universal';
import Axios from '../../config/Axios';
import Page from '../default-v2/Page';
import Breadcrumb from '../../components/Breadcrumbs';
import ButtonBack from '../../components/ButtonBack';
import EncodedLocalStorage from '../../config/EncodedLocalStorage';

export class Prints extends Component {
  constructor(oProps) {
    super(oProps);

    this.state = {
      bLoading: true,
      aPrintsControl: [],
      aPages: [{ cName: 'Print.titulo', cLink: undefined }]
    };
  }

  componentDidMount() {
    const { setAlert } = this.props;
    Axios.get('/document/print')
      .then(oRes => {
        this.setState({
          bLoading: false,
          aPrintsControl: oRes.data
        });
      })
      .catch(oError => {
        this.setState({ bLoading: false });
        setAlert('error', oError.aMsgErrors);
      });
  }

  render() {
    const { aPrintsControl, bLoading, aPages } = this.state;
    const { history, getAlert } = this.props;
    const oLocalStorage = new EncodedLocalStorage();
    return (
      <Page loading={bLoading ? 1 : 0}>
        <h1>{intl.get('Print.titulo')}</h1>
        <Breadcrumb aPages={aPages} />
        <div className="button-stack">
          <ButtonBack />
        </div>
        {getAlert()}
        <ul className="controls-list">
          {aPrintsControl.map(oItem => (
            <li
              onClick={() => history.push(`/document/detail/${oItem.oDocument.version.ver_nid}`)}
              onKeyPress={() => history.push(`/document/detail/${oItem.oDocument.version.ver_nid}`)}
              key={oItem.oDocument.version.ver_nid}
            >
              <h3> {oItem.oDocument.doc_ctitle}</h3>
              <div className="upper-details">
                <p>
                  <i className="icon-print" />
                  <strong>{oItem.oDocument.version.ver_nprints}</strong> {intl.get('Print.prints')}
                </p>
              </div>
              {oItem.oDocumentAccessLog && (
                <p className="lower-details">
                  {intl.get('Print.last_time')}{' '}
                  <strong>
                    {moment
                      .parseZone(oItem.oDocumentAccessLog.dacl_dcreated)
                      .tz(oLocalStorage.get('cTimezone'))
                      .format('DD/MM/YYYY HH:mm:ss')}
                  </strong>
                </p>
              )}
            </li>
          ))}
        </ul>
      </Page>
    );
  }
}

export default Prints;
