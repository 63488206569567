import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Table } from 'reactstrap';
import intl from 'react-intl-universal';

class DashboardBlock extends Component {
  constructor(oProps) {
    super(oProps);

    this.state = {
      bIsClose: false,
      cClassNameTBody: "",
      cIconArrow: "icon-arrow-down",
      cPosition: "table-row-group"
    }
  }

  handleClose = (evt) => {
    const { bIsClose } = this.state;
    let cNewClassNameTBody = '', cNewIconArrow = '', cNewPosition = '';

    if (bIsClose) {
      cNewClassNameTBody = 'visible-tbody';
      cNewIconArrow = 'icon-arrow-down';
      cNewPosition = "unset";
    }
    else {
      cNewClassNameTBody = 'hidden-tbody';
      cNewIconArrow = 'icon-arrow-right';
    }

    this.setState({
      bIsClose: !bIsClose,
      cClassNameTBody: cNewClassNameTBody,
      cIconArrow: cNewIconArrow,
      cPosition: cNewPosition
    });
  };

  handleTransitionEnd = (evt) => {
    const { bIsClose } = this.state;
    let cNewPosition = '';

    if (bIsClose) {
      cNewPosition = "absolute";
    }

    this.setState({
      cPosition: cNewPosition
    });
  };

  getClassName = (nIndex, cClassName) => {
    if (nIndex === 0) {
      return cClassName ? `block-row-first ${cClassName}` : "block-row-first";
    }
    return cClassName;
  }

  getHeaderRow = (nIndex, cIconArrow, cName) => {
    if (nIndex === 0) {
      return (
        <>
          <i className={cIconArrow} onClick={this.handleClose} /> <span>{cName}</span>
        </>
      );
    }
    return cName;
  }

  render() {
    const { cBlockClassName, cBlockTableClassName, cLowerLinkText, cLowerLink, aColumn, bIsFirstBlock, children } = this.props;
    const { cClassNameTBody, cIconArrow, cPosition } = this.state;

    return (
      <div className={cBlockClassName ? `v2-dashboard-block ${cBlockClassName}` : 'v2-dashboard-block'}>
        <div className={bIsFirstBlock ? "lower-link first-lower-link" : "lower-link"}>
          <NavLink title={cLowerLinkText} to={cLowerLink}>
            <i className="icon-plus" />
            {cLowerLinkText}
          </NavLink>
        </div>


        <Table responsive striped className={children ? (cBlockTableClassName ? `table-block ${cBlockTableClassName}` : "table-block") : "table-block table-row-space"}>
          <thead>
            <tr>
              {aColumn.map((oColumn, nIndex) => (
                <th
                  key={nIndex}
                  className={this.getClassName(nIndex, oColumn.cClassName)}
                >
                  {this.getHeaderRow(nIndex, cIconArrow, oColumn.cName)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className={cClassNameTBody} onTransitionEnd={this.handleTransitionEnd} style={{ position: cPosition }}>
            {children ? children : (
              <tr className="block-message-row">
                <td colSpan={aColumn.length} className="block-message">
                  <p>
                    {intl.get("Dashboard.no_pending_issues")}
                  </p>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    );
  }
};

DashboardBlock.defaultProps = {
  cLowerLinkText: '',
  cLowerLink: '',
  cBlockClassName: '',
  cBlockTableClassName: '',
  aColumn: [],
  bIsFirstBlock: false,
  children: null
}

export default DashboardBlock;
