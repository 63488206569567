import React, { useEffect, useMemo, useState } from 'react';
import Page from '../default-v2/Page';
import Breadcrumb from '../../components/Breadcrumbs';
import intl from 'react-intl-universal';
import userImg from '../../assets/img/users.svg';
import CardHeader from '../default-v2/CardHeader';
import Axios from '../../config/Axios';
import imgfSelectDown from '../default-v2/img/select-down.svg';
import imgfSelectUp from '../default-v2/img/select-up.svg';
import moment from 'moment';
import { Button } from 'reactstrap';
import userConfigImg from '../../assets/img/user-config-blue.svg';
import Select from 'react-select';
import successImg from '../../assets/img/success.svg';
import 'moment/locale/pt';
import { can, canAtLeast } from '../../config/Permissions';
import EncodedLocalStorage from '../../config/EncodedLocalStorage';

const MigrateResponsibilityDocument = () => {
  const [bIsLoading, setBIsLoading] = useState(true);
  const [aDocumentsToShow, setADocumentsToShow] = useState([]);
  const [oFilterOrder, setOFilterOrder] = useState({ cOrderBy: 'cName', cType: 'asc' });
  const [bIsShowingModal, setBIsShowingModal] = useState(false);
  const [aSelectedDocuments, setASelectedDocuments] = useState([]);
  const [aFilteredOptions, setAFilteredOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');
  const [cPassword, setCPassword] = useState('');
  const [cUserError, setCUserError] = useState('');
  const [cPasswordError, setCPasswordError] = useState('');
  const [bIsShowingSuccessModal, setBIsShowingSuccessModal] = useState(false);

  const aPages = [
    { cName: 'user', cLink: '/user' },
    { cName: 'modulo_documentos', cLink: '/folder' },
    { cName: 'User.migrate_responsibilities', cLink: undefined }
  ];
  const rcmpBreadcrumb = <Breadcrumb aPages={aPages} />;

  const rcmpCardHeader = <CardHeader bIsItToInsertGoBackBtn={true} windowHistory={true} />;

  useEffect(() => {
    const cCurrentUrl = window.location.href;
    const nUserId = cCurrentUrl.substring(cCurrentUrl.lastIndexOf('/') + 1);
    Axios.get(`/document/get-responsibilities/${nUserId}`).then(oResponse => {
      setADocumentsToShow(oResponse.data);
      setBIsLoading(false);
    });
  }, []);

  useEffect(() => {
    const cCurrentUrl = window.location.href;
    const nUserId = cCurrentUrl.substring(cCurrentUrl.lastIndexOf('/') + 1);
    if (!can('admin')) {
      window.location.href = '/';
    }
    Axios.get(`/user/all`).then(oResponse => {
      const aUserOptions = oResponse.data.aUsers
        .filter(item => item.user_nid !== Number(nUserId))
        .map(oUser => ({ value: oUser.user_nid, label: oUser.user_cname }));

      setAFilteredOptions(aUserOptions);
    });
  }, []);

  const cOrderType = oFilterOrder.cType === 'asc' ? 'desc' : 'asc';

  let cIcon = imgfSelectDown;
  if (cOrderType === 'asc') {
    cIcon = imgfSelectUp;
  }

  function setFilter(cOrder, cType) {
    if (cOrder === 'cName') {
      const sortedDocuments = aDocumentsToShow.sort((a, b) => {
        if (!a.doc_ctitle || !b.doc_ctitle) {
          return 0;
        }
        if (cType === 'asc') {
          return a.doc_ctitle.localeCompare(b.doc_ctitle);
        } else if (cType === 'desc') {
          return b.doc_ctitle.localeCompare(a.doc_ctitle);
        } else {
          return 0;
        }
      });

      setADocumentsToShow(sortedDocuments);
      setOFilterOrder({ cOrderBy: 'cName', cType: cType });
    } else if (cOrder === 'cPublishedDate') {
      const sortedDocuments = aDocumentsToShow.sort((a, b) => {
        const aPublishedDate = a?.version?.ver_dpublished
          ? moment.parseZone(a.version.ver_dpublished).valueOf()
          : Number.MIN_SAFE_INTEGER;
        const bPublishedDate = b?.version?.ver_dpublished
          ? moment.parseZone(b.version.ver_dpublished).valueOf()
          : Number.MIN_SAFE_INTEGER;

        if (cType === 'asc') {
          return aPublishedDate - bPublishedDate;
        } else if (cType === 'desc') {
          return bPublishedDate - aPublishedDate;
        } else {
          return 0;
        }
      });
      setADocumentsToShow(sortedDocuments);
      setOFilterOrder({ cOrderBy: 'cPublishedDate', cType: cType });
    } else if (cOrder === 'cDueDate') {
      const sortedDocuments = aDocumentsToShow.sort((a, b) => {
        const aDueDate = a?.version?.dtReviewDate
          ? moment.parseZone(a.version.dtReviewDate).valueOf()
          : Number.MIN_SAFE_INTEGER;
        const bDueDate = b?.version?.dtReviewDate
          ? moment.parseZone(b.version.dtReviewDate).valueOf()
          : Number.MIN_SAFE_INTEGER;

        if (cType === 'asc') {
          return aDueDate - bDueDate;
        } else if (cType === 'desc') {
          return bDueDate - aDueDate;
        } else {
          return 0;
        }
      });
      setADocumentsToShow(sortedDocuments);
      setOFilterOrder({ cOrderBy: 'cDueDate', cType: cType });
    } else if (cOrder === 'nEnvelopeId') {
      const sortedDocuments = aDocumentsToShow.sort((a, b) => {
        const aEnvelope = a?.version?.ver_cenvelope_id ?? Number.MAX_SAFE_INTEGER;
        const bEnvelpe = b?.version?.ver_cenvelope_id ?? Number.MAX_SAFE_INTEGER;
        if (aEnvelope === bEnvelpe) {
          return 0;
        } else if (cType === 'desc') {
          if (aEnvelope === Number.MAX_SAFE_INTEGER) {
            return 1;
          } else if (bEnvelpe === Number.MAX_SAFE_INTEGER) {
            return -1;
          } else {
            return aEnvelope - bEnvelpe;
          }
        } else if (cType === 'asc') {
          if (aEnvelope === Number.MAX_SAFE_INTEGER) {
            return -1;
          } else if (bEnvelpe === Number.MAX_SAFE_INTEGER) {
            return 1;
          } else {
            return bEnvelpe - aEnvelope;
          }
        } else {
          return 0;
        }
      });
      setADocumentsToShow(sortedDocuments);
      setOFilterOrder({ cOrderBy: 'nEnvelopeId', cType: cType });
    }

    localStorage.setItem('cConfig', JSON.stringify(oFilterOrder));
  }

  function showModal() {
    setBIsShowingModal(true);
  }

  function handleCloseModal() {
    setBIsShowingModal(false);
    setBIsShowingSuccessModal(false);
  }

  function handleCloseSuccessModal() {
    setBIsShowingSuccessModal(false);
    window.location.reload();
  }

  function handleMigration() {
    setCUserError('');
    setCPasswordError('');
    if (!selectedOption) {
      setCUserError(intl.get('FolderIndex.no_user_selected'));
      return;
    }
    if (!cPassword) {
      setCPasswordError(intl.get('FolderIndex.incorrect_password'));
      return;
    }
    if (aSelectedDocuments.length < 1) {
      setCUserError(intl.get('FolderIndex.no_document_selected'));
      return;
    }

    const cCurrentUrl = window.location.href;
    const nUserId = cCurrentUrl.substring(cCurrentUrl.lastIndexOf('/') + 1);

    Axios.post(`/document/migrate-responsibilities`, {
      aDocuments: aSelectedDocuments,
      nNewUser: selectedOption.value,
      cPassword: cPassword,
      nOldUser: Number(nUserId)
    })
      .then(oResponse => {
        setBIsShowingModal(false);
        setBIsShowingSuccessModal(true);
      })
      .catch(error => {
        setCPasswordError(error.response.data.cMessage);
      });
  }

  const handleCheckboxClick = event => {
    const nDocId = event.target.value;
    if (event.target.checked) {
      setASelectedDocuments([...aSelectedDocuments, nDocId]);
    } else {
      setASelectedDocuments(aSelectedDocuments.filter(id => id !== nDocId));
    }
  };

  const handleCheckAll = event => {
    const checkboxes = document.getElementsByName('document-checkbox');
    const newCheckedIds = [];

    checkboxes.forEach(checkbox => {
      checkbox.checked = event.target.checked;
      if (checkbox.checked) {
        newCheckedIds.push(checkbox.value);
      }
    });

    setASelectedDocuments(newCheckedIds);
  };

  const handleChange = value => {
    setSelectedOption(value);
  };

  const handlePassword = event => {
    setCPassword(event.target.value);
  };
  const oLocalStorage = new EncodedLocalStorage();

  return (
    <div>
      <Page
        loading={bIsLoading ? 1 : 0}
        rcmpBreadcrumb={rcmpBreadcrumb}
        cTitle={intl.get('User.migrate_responsibilities')}
        cImage={userImg}
        rcmpCardHeader={rcmpCardHeader}
        className="v2-document"
        cCurrentSideMenuOption={intl.get('Nav.document')}
      >
        <div className="filterList noselect">
          <span role="button" tabIndex="0" className="alt-checkbox-v2">
            <input type="checkbox" onClick={handleCheckAll} />
          </span>{' '}
          <span
            role="button"
            tabIndex="0"
            onClick={() => setFilter('cName', cOrderType)}
            onKeyPress={() => setFilter('cName', cOrderType)}
            className={`filterByName-v2 ${oFilterOrder.cOrderBy === 'cName' ? 'active' : ''}`}
          >
            {intl.get('name')}
            {oFilterOrder.cOrderBy === 'cName' && (
              <img className="folderindex-list-icon" src={cIcon} alt={cOrderType} />
            )}
          </span>
          <span
            role="button"
            tabIndex="0"
            onClick={() => setFilter('cPublishedDate', cOrderType)}
            onKeyPress={() => setFilter('cPublishedDate', cOrderType)}
            className={`filterByPublish ${oFilterOrder.cOrderBy === 'cPublishedDate' ? 'active' : ''}`}
          >
            {intl.get('FolderIndex.published_at')}
            {oFilterOrder.cOrderBy === 'cPublishedDate' && (
              <img className="folderindex-list-icon" src={cIcon} alt={cOrderType} />
            )}
          </span>
          <span
            role="button"
            tabIndex="0"
            onClick={() => setFilter('cDueDate', cOrderType)}
            onKeyPress={() => setFilter('cDueDate', cOrderType)}
            className={`filterByDue ${oFilterOrder.cOrderBy === 'cDueDate' ? 'active' : ''}`}
          >
            {intl.get('FolderIndex.finish_at')}
            {oFilterOrder.cOrderBy === 'cDueDate' && (
              <img className="folderindex-list-icon" src={cIcon} alt={cOrderType} />
            )}{' '}
          </span>
          <span role="button" tabIndex="0" className="filterByResponsible-v2">
            {intl.get('FolderIndex.responsibilities')}
          </span>
          <span
            role="button"
            tabIndex="0"
            onClick={() => setFilter('nEnvelopeId', cOrderType)}
            onKeyPress={() => setFilter('nEnvelopeId', cOrderType)}
            className={`filterByEnvelope ${oFilterOrder.cOrderBy === 'nEnvelopeId' ? 'active' : ''}`}
          >
            {intl.get('FolderIndex.docusign_signature')}
            {oFilterOrder.cOrderBy === 'nEnvelopeId' && (
              <img className="folderindex-list-icon" src={cIcon} alt={cOrderType} />
            )}{' '}
          </span>
        </div>
        <div>
          <ul style={{ display: 'block' }} className={'document-list list-shadow list-view'}>
            {!bIsLoading &&
              aDocumentsToShow.map((oDocument, i) => (
                <li role="presentation" key={oDocument.doc_nid}>
                  <>
                    <h2 className="alt-checkbox-v2 ">
                      <input
                        type="checkbox"
                        name="document-checkbox"
                        value={oDocument.doc_nid}
                        onClick={handleCheckboxClick}
                      />
                    </h2>
                    <h2 className="filterByName-v2" title={oDocument.doc_ctitle}>
                      {oDocument.doc_ctitle}
                    </h2>

                    <p className="filterByPublish">
                      {oDocument?.version?.ver_dpublished
                        ? moment
                          .parseZone(new Date(oDocument.version.ver_dpublished))
                          .tz(oLocalStorage.get('cTimezone'))
                          .format('LL')
                        : ''}
                    </p>
                    <p className="filterByDue">
                      {oDocument?.version?.dtReviewDate
                        ? moment
                          .parseZone(new Date(oDocument.version.dtReviewDate))
                          .tz(oLocalStorage.get('cTimezone'))
                          .format('LL')
                        : intl.get('no_due')}
                    </p>
                    <p className="filterByResponsible-v2" style={{ display: 'flex', textAlign: 'center' }}>
                      <ResponsibilitiesColumn oDocument={oDocument} />
                    </p>
                    <p className="filterByPublish" style={{ textAlign: 'center' }}>
                      {oDocument?.version?.ver_cenvelope_id ? intl.get('sim') : intl.get('nao')}
                    </p>
                  </>
                </li>
              ))}
          </ul>
          {bIsShowingModal && (
            <div className="docusign-modal-overlay">
              <div className="docusign-modal">
                <div className="docusign-header-modal">
                  <h3>{intl.get('User.migrate_responsibilities')}</h3>
                  <span className="docusign-close" onClick={handleCloseModal}>
                    &times;
                  </span>
                </div>
                <div className="docusign-modal-body">
                  <div className="icon-container">
                    <img className="block-title-icon" src={userConfigImg} />
                  </div>

                  <div className="docusign-modal-text">
                    <div className="margin-bottom-30">
                      <form autoComplete="off">
                        <label style={{ fontWeight: '700' }}>{intl.get('FolderIndex.new_responsible')}</label>
                        {cUserError && <p style={{ color: '#FF3541', fontSize: '12px' }}>{cUserError}</p>}
                        <label className="split1 colspan ">
                          <Select
                            className="selectCustom select"
                            classNamePrefix="react-select"
                            noOptionsMessage={() => intl.get('sem_opcoes')}
                            placeholder={intl.get('selecione') + '...'}
                            isClearable
                            isSearchable
                            options={aFilteredOptions}
                            maxMenuHeight={130}
                            defaultValue={selectedOption}
                            onChange={handleChange}
                          />
                        </label>
                      </form>
                    </div>
                    <div>
                      <label style={{ fontWeight: '700' }}>{intl.get('FolderIndex.digit_password')}</label>
                      <div className="input-select ">
                        <input
                          type="password"
                          className={cPasswordError ? 'red-border' : ''}
                          value={cPassword}
                          onChange={handlePassword}
                        />
                      </div>
                      {cPasswordError && <p style={{ color: '#FF3541', fontSize: '12px' }}>{cPasswordError}</p>}
                    </div>
                  </div>
                </div>
                <div className="button-container">
                  <button className="btn alternate" onClick={handleCloseModal}>
                    {intl.get('cancel')}
                  </button>
                  <button className="btn" onClick={handleMigration}>
                    {intl.get('FolderIndex.migrate')}{' '}
                  </button>
                </div>
              </div>
            </div>
          )}
          {bIsShowingSuccessModal && (
            <div className="docusign-modal-overlay">
              <div className="docusign-modal">
                <div className="docusign-header-modal">
                  <h3>{intl.get('User.migrate_responsibilities')}</h3>
                  <span className="docusign-close" onClick={handleCloseSuccessModal}>
                    &times;
                  </span>
                </div>
                <div className="docusign-modal-body">
                  <div className="icon-container">
                    <img className="block-title-v2" src={successImg} />
                  </div>
                  <div className="docusign-modal-text align-center" style={{ fontWeight: '700' }}>
                    {intl.get('FolderIndex.success_migration')}
                  </div>
                </div>
                <div className="button-container align-center">
                  <button className="btn" onClick={handleCloseSuccessModal}>
                    {intl.get('close')}
                  </button>
                </div>
              </div>
            </div>
          )}
          <div className="button-stack">
            <Button type="button" className="btn-cancel" onClick={() => window.history.back()}>
              {intl.get('cancelar')}
            </Button>

            <Button
              type="submit"
              className="btn break-line-btn"
              disabled={aSelectedDocuments.length < 1}
              onClick={() => showModal()}
            >
              {intl.get('FolderIndex.migrate')} <br /> {intl.get('FolderIndex.selecteds')}
            </Button>
          </div>
        </div>
      </Page>
    </div>
  );
};

const ResponsibilitiesColumn = ({oDocument = {}}) => {
  const aResponsibilities = useMemo(() => {
    const aLabels = [];
    if (oDocument.writer) {
      aLabels.push(intl.get('FolderIndex.responsibility_write'));
    }
    if (oDocument.reviewer) {
      aLabels.push(intl.get('FolderIndex.responsibility_review'));
    }
    if (oDocument.responsible) {
      aLabels.push(intl.get('FolderIndex.responsibility_publish'));
    }
    return aLabels;
  }, [oDocument]);

  return (
    <span style={{ textWrap: 'nowrap' }}>
      {aResponsibilities.join(' / ')}
    </span>
  );
}

export default MigrateResponsibilityDocument;
