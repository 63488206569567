import React, { Component } from 'react';
import intl from 'react-intl-universal';
import fLoading from '../assets/img/preloader-black.gif';
import PropTypes from 'prop-types';

class TreeView extends Component {
  constructor(oProps) {
    super(oProps);

    const { nFolderId, nUnchangedFolderId } = this.props;
    const aFolders = this.foldersSearch(oProps.aFolders, nFolderId);
    this.state = {
      nFolderId,
      aFolders,
      nUnchangedFolderId
    };
  }

  static getDerivedStateFromProps(oNextProps, oPrevState) {
    if (oNextProps.aFolders !== oPrevState.aFolders) {
      return { aFolders: oNextProps.aFolders };
    }
    return null;
  }

  handleChange = (evtChange) => {
    this.setState({ [evtChange.target.name]: evtChange.target.value });
  };

  buildSub = (aFolder) => {
    const { nUnchangedFolderId, nFolderId } = this.state;

    if (!Array.isArray(aFolder)) {
      return null;
    }

    const aItems = aFolder.map((oItem) => (
      <li style={{ display: oItem.fldr_nid === nUnchangedFolderId ? 'none' : 'block' }} key={oItem.fldr_nid}>
        {oItem.recursive_folders.length > 0 &&
          oItem.nid !== nUnchangedFolderId &&
          oItem.recursive_folders.some((oItemCompare) => oItemCompare.fldr_nid !== nUnchangedFolderId) && (
            <a onClick={(evt) => this.openSubs(evt)} href="#open-subs" title={intl.get("TreeView.folder")} className={oItem.bIsItTheParentFolder != null && oItem.bIsItTheParentFolder ? "open-folder opened" : "open-folder"}>
              <i className="icon-next" />
            </a>
          )}

        <a
          href="#select-folder"
          className={oItem.fldr_nid === nFolderId ? 'selected folder-name' : 'folder-name'}
          onClick={(evtClick) => this.selectFolder(evtClick, oItem.fldr_nid)}
        >
          <i className="icon-folder" /> &nbsp;
          {oItem.fldr_cname}
        </a>
        {oItem.recursive_folders.length > 0 && oItem.fldr_nid !== nUnchangedFolderId && (
          <ul style={{ display: oItem.bIsItTheParentFolder != null && oItem.bIsItTheParentFolder ? 'block' : 'none' }}>{this.buildSub(oItem.recursive_folders)}</ul>
        )}
      </li>
    ));
    return aItems;
  };

  selectFolder = (evtChange, nId) => {
    const { handleChange } = this.props;
    const { aFolders } = this.state;

    evtChange.preventDefault();
    if (nId === aFolders.fldr_nid && aFolders.fldr_nid_parent === null && !aFolders.canCreate) {
      return;
    }

    this.setState({
      "nFolderId": nId
    });

    if (handleChange) {
      const evtNewChange = {
        target: {
          name: 'fldr_nid',
          value: nId
        }
      };
      handleChange(evtNewChange);
    }
  };

  openSubs = (evtOpen) => {
    evtOpen.preventDefault();
    const elLi = evtOpen.target.closest('li');
    const elA = evtOpen.target.closest('a');
    elA.classList.toggle('opened');
    if (elLi.getElementsByTagName('ul')[0]) {
      if (elLi.getElementsByTagName('ul')[0].getBoundingClientRect().width === 0) {
        elLi.getElementsByTagName('ul')[0].style = 'display:block';
      } else {
        elLi.getElementsByTagName('ul')[0].style = 'display:none';
      }
    }
  };

  foldersSearch = (oFolders, nFolderId) => {
    let bIsItToContinue = true;
    if (oFolders === null || oFolders === undefined)
      return null;
    if (oFolders.recursive_folders === undefined)
      return null;
    const nSize = oFolders.recursive_folders.length;
    for (let nIndex = 0; nIndex < nSize; nIndex += 1) {
      if (bIsItToContinue) {
        bIsItToContinue = this.recursiveFolders(oFolders.recursive_folders[nIndex], nFolderId);
      }
    }
    Object.assign(oFolders, { bIsItTheParentFolder: bIsItToContinue });
    return oFolders;
  };

  recursiveFolders = (oFoldersChild, nFolderId) => {
    if (oFoldersChild.fldr_nid === nFolderId) {
      // Object.assign(oFoldersChild, { bIsItTheParentFolder: true });
      return false;
    }
    if (oFoldersChild.recursive_folders.length === 0) {
      Object.assign(oFoldersChild, { bIsItTheParentFolder: false });
      return true;
    }
    let bIsItToContinue = true;
    const nSize = oFoldersChild.recursive_folders.length;
    for (let nIndex = 0; nIndex < nSize; nIndex += 1) {
      if (bIsItToContinue) {
        bIsItToContinue = this.recursiveFolders(oFoldersChild.recursive_folders[nIndex], nFolderId);
      }
    }
    Object.assign(oFoldersChild, { bIsItTheParentFolder: !bIsItToContinue });
    return bIsItToContinue;
  };

  render() {
    const { bLoading, nFolderId, aFolders } = this.state;
    const { className, cClosed, cHeight } = this.props;

    const cClasses = `${bLoading ? 'load' : ''} tree-view ${className}`;

    return (
      <div className={cClasses} style={{ maxHeight: cHeight }}>
        <input
          onChange={this.handleChange}
          value={nFolderId || ''}
          type="hidden"
          name="nFolderId"
          id="nFolderId"
          tabIndex="-1"
        />

        {!bLoading ? (
          <ul className="root">
            {aFolders &&
              [aFolders].map((oItem, nIndex) => (
                <li key={nIndex}>
                  <a
                    onClick={(evtClick) => this.openSubs(evtClick)}
                    href="#open-subs"
                    title="Abrir/fechar pasta"
                    className="open-folder opened"
                  >
                    <i className="icon-next" />
                  </a>
                  <a
                    className={oItem.fldr_nid === nFolderId ? 'selected folder-name' : 'folder-name'}
                    onClick={(evtClick) => this.selectFolder(evtClick, oItem.fldr_nid)}
                    href="#select-folder"
                  >
                    <i className="icon-folder" /> &nbsp;
                    {oItem.fldr_cname}
                  </a>
                  {oItem.recursive_folders && oItem.recursive_folders.length > 0 && (
                    <ul style={{ display: cClosed && oItem.fldr_nid === nFolderId ? 'none' : 'block' }}>
                      {this.buildSub(oItem.recursive_folders)}
                    </ul>
                  )}
                </li>
              ))}
          </ul>
        ) : (
          <span className="centered-title">
            <img alt="Carregando" src={fLoading} />
          </span>
        )}
      </div>
    );
  }
}

TreeView.propTypes = {
  nFolderId: PropTypes.number,
  nUnchangedFolderId: PropTypes.number,
  aFolders: PropTypes.object
};

TreeView.defaultProps = {
  nFolderId: 1,
  nUnchangedFolderId: 1,
  aFolders: null
};

export default TreeView;
