import React, { Component } from 'react';

export class Alert extends Component {
  constructor(props) {
    super(props);

    const { type, isOpen = true } = this.props;

    this.state = {
      type,
      isOpen
    };
  }

  componentWillReceiveProps(nextProps) {
    const { type, isOpen = true } = nextProps;

    this.setState({
      type,
      isOpen
    });
  }

  closeAlert = () => {
    const { onCloseAlert } = this.props;
    this.setState({
      isOpen: false
    });
    onCloseAlert();
  };

  render() {
    const { isOpen, type } = this.state;
    const { children, className } = this.props;
    return (
      isOpen && (
        <div className={`alert ${type} ${className}`}>
          {children}

          <span
            role="button"
            tabIndex="0"
            onKeyPress={this.closeAlert}
            onClick={this.closeAlert}
            href="#"
            title="Fechar"
            aria-label="Fechar"
            className="close"
          />
        </div>
      )
    );
  }
}

Alert.defaultProps = {
  className: ''
};

export default Alert;
