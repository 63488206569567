import React, { Component } from 'react';
import { Input, Button, Form } from 'reactstrap';
import intl from 'react-intl-universal';
import Select from 'react-select';
import Axios from '../../config/Axios';
import PageClean from '../default-v2/PageClean';
import { Alert } from '../../components/Alert';
import AltCheckbox from '../../components/AltCheckbox';
import { checkModule } from '../../config/Permissions';

class NewVersion extends Component {
  constructor(oProps) {
    super(oProps);
    const { nNumberVersion } = this.props;
    this.state = {
      bLoading: true,
      nVersionId: oProps.match ? oProps.match.params.nVersionId : oProps.nVersionId,
      nRequestId: oProps.match ? oProps.match.params.nRequestId : oProps.nRequestId,
      oDocument: [],
      oRequest: [],
      aRequests: [],
      bIsSend: false,
      cNumber: '',
      aSelectedRequisites: [],
      bPublishWithoutChanges: false,
      nValueReviewPeriod: 0,
      nValueNotification: 0,
      nNumberVersion: nNumberVersion || 1,
      cReviewPeriodVersion: 'S',
      cVersionNotification: 'D',
      bVerValNotification: true,
      bVerValPeriod: true,
    };
  }

  componentDidMount = () => {
    const { nVersionId, nRequestId } = this.state;
    const { history } = this.props;
    checkModule(history, 'documents');

    const url = nVersionId ? `version/create/new-version/${nVersionId}` : `version/accept/new/${nRequestId}`;

    Axios.get(url)
      .then(oResponse => {
        const { oDocument, oRequest, aRequests, aRequisites, aSelectedRequisites } = oResponse.data;

        let aRequestsIndex = [];
        if (aRequests && nRequestId && nRequestId != undefined) {
          aRequestsIndex = aRequests.map(oReqItem => {
            if (oReqItem.rqst_nid == nRequestId) {
              oReqItem.rqst_bisaccepted = true;
            }
            return oReqItem;
          });
        }

        this.setState({
          bLoading: false,
          oDocument,
          oRequest,
          aRequests: aRequestsIndex,
          aSelectedRequisites,
          aRequisites
        });
      })
      .catch(oError => {
        this.setState({
          bLoading: false,
          rcmpAlertMsg: (
            <Alert type="error" isOpen onCloseAlert={() => { }}>
              {oError.aMsgErrors}
            </Alert>
          )
        });
      });
  };

  handleCheckboxChange = () => {
    this.setState(prevState => ({
      bPublishWithoutChanges: !prevState.bPublishWithoutChanges
    }));
  };

  handleChange = evt => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  handleChangeCustom = elSelectedOption => {
    this.setState({
      aSelectedRequisites: elSelectedOption
    });
  };

  handleChangeVersion = evtVersion => {
    const { name, value } = evtVersion.target;
    let oUpdateVer = { [name]: value };

    // Lógica para o campo cReviewPeriodVersion
    if (name === 'cReviewPeriodVersion') {
      if (value === 'T') {
        oUpdateVer = {
          ...oUpdateVer,
          cReviewPeriodVersion: 'T',
          bVerValPeriod: false
        };
      } else {
        oUpdateVer.bVerValPeriod = true;
      }
    }

    // Lógica para o campo cVersionNotification
    if (name === 'cVersionNotification') {
      if (value === 'T') {
        oUpdateVer.bVerValNotification = false;
      } else {
        oUpdateVer.bVerValNotification = true;
      }
    }

    this.setState(oUpdateVer);
  };

  handleSubmit = evt => {
    evt.preventDefault();

    this.setState({
      bIsSend: true
    });

    const { oDocument, cNorm, cJustification, aRequests, cNumber, aSelectedRequisites } = this.state;
    const { history, onSuccess, match } = this.props;

    const aFilteredRequests =
      aRequests !== undefined
        ? aRequests.filter(oRequest => oRequest.rqst_bisaccepted === true).map(oRequest => oRequest.rqst_nid)
        : [];

    Axios.post(`/version/create/new/${oDocument.doc_nid}`, {
      aSelectedRequisites:
        aSelectedRequisites && aSelectedRequisites != undefined ? aSelectedRequisites.map(oReq => oReq.value) : [],
      cNorm,
      cJustification,
      aRequestIds: aFilteredRequests,
      cNumber
    })
      .then(oResponse => {
        if (oResponse.data.nVersionId && oResponse.data.bAmWriter) {
          history.push({
            pathname: `/document/editor/${oResponse.data.nVersionId}`,
            state: {
              aAlertMessages: { cType: 'success', cMsg: intl.get('NewVersion.nova_versao_gerada') }
            }
          });
          return;
        } else if (match) {
          history.push({
            pathname: '/',
            state: { aAlertMessages: { cType: 'success', cMsg: intl.get('NewVersion.enviado_redacao') } }
          });
        } else {
          onSuccess(intl.get('NewVersion.enviado_redacao'));
        }

        this.setState({
          bIsSend: false
        });
      })
      .catch(oError => {
        this.setState({
          bIsSend: false,
          rcmpAlertMsg: (
            <Alert type="error" isOpen onCloseAlert={() => { }}>
              {oError.aMsgErrors}
            </Alert>
          )
        });
      });
  };


  handleSubmitVersion = evtNoChangeVersion => {
    evtNoChangeVersion.preventDefault();

    this.setState({
      bIsSend: true
    });

    const { nVersionId, aRequests, nNumberVersion, nValueReviewPeriod, cReviewPeriodVersion,
      nValueNotification, cVersionNotification
    } = this.state;
    const { history, onSuccess, match } = this.props;

    Axios.post(`/version/create/no-changes/${nVersionId}`, {
      nValueReviewPeriod,
      cReviewPeriodVersion,
      nValueNotification,
      cVersionNotification,
      nNumberVersion
    })
      .then(oResponse => {
        if (oResponse.data.nVersionId && oResponse.data.bAmWriter) {
          history.push({
            pathname: `/document/editor/${oResponse.data.nVersionId}`,
            state: {
              aAlertMessages: { cType: 'success', cMsg: intl.get('NewVersion.nova_versao_gerada') }
            }
          });
          return;
        } else if (match) {
          history.push({
            pathname: '/',
            state: { aAlertMessages: { cType: 'success', cMsg: intl.get('NewVersion.publica_versao_duplicada') } }
          });
        } else {
          onSuccess(intl.get('NewVersion.publica_versao_duplicada'));
        }

        this.setState({
          bIsSend: false
        });
      })
      .catch(oError => {
        this.setState({
          bIsSend: false,
          rcmpAlertMsg: (
            <Alert type="error" isOpen onCloseAlert={() => { }}>
              {oError.aMsgErrors}
            </Alert>
          )
        });
      });
  };

  inputChangeCheckBoxHandler = (evt, cIndex, cKey) => {
    const { aRequests } = this.state;
    aRequests[cKey].rqst_bisaccepted = evt.target.checked;
    this.setState({ aRequests });
  };

  renderRequests() {
    const { oRequest, aRequests, nRequestId } = this.state;

    const rcmpRequests = Object.keys(aRequests).map(cKey => (
      <li key={cKey}>
        <AltCheckbox
          key={aRequests[cKey].rqst_nid}
          title={intl.get('Document.aceitar_solicitacao')}
          className="split"
          checked={aRequests[cKey].rqst_bisaccepted}
          value={aRequests[cKey].rqst_bisaccepted}
          id={cKey}
          disabled={aRequests[cKey].rqst_nid == nRequestId ? true : false}
          name="nRequestId[]"
          label={`${intl.get('solicitacao')} ${aRequests[cKey].rqst_nid}`}
          message={aRequests[cKey].rqst_cjustification}
          onChange={evt => this.inputChangeCheckBoxHandler(evt, aRequests[cKey].rqst_nid, cKey)}
        />
      </li>
    ));

    return (
      <div className="request-wrapper">
        <h2>{intl.get('Document.solicitacoes_atendidas')}</h2>
        <p>{intl.get('Document.subtitulo_solicitacoes')}</p>
        <ul>{rcmpRequests}</ul>
        <input name="nRequestId" type="hidden" defaultValue={oRequest.rqst_nid} />
      </div>
    );
  }

  render() {
    const {
      cJustification,
      oDocument,
      cNumber,
      bIsSend,
      bLoading,
      rcmpAlertMsg,
      aRequests,
      aRequisites,
      aSelectedRequisites,
      nValueReviewPeriod,
      nValueNotification,
      cReviewPeriodVersion,
      cVersionNotification,
      bVerValNotification,
      bVerValPeriod,
    } = this.state;
    const { closeModal } = this.props;
    return (
      <PageClean loading={bLoading ? 1 : 0}>
        {rcmpAlertMsg}

        <h1>{intl.get('NewVersion.nova_versao_doc')}</h1>
        {oDocument.doc_ctitle && (
          <>
            <h2>{intl.get('documento')}</h2>
            <header id="inactive-header">
              <div className="info">
                <p>{oDocument.doc_ctitle}</p>
              </div>
            </header>
            <div style={{ width: "40%", marginLeft: "-20px", marginTop: "-5%" }}>
              <AltCheckbox
                title="Publicar sem alterações"
                label="Publicar sem alterações"
                checked={this.state.bPublishWithoutChanges}
                onChange={this.handleCheckboxChange}
                className="split1"
              />
            </div>
          </>
        )
        }
        {
          !this.state.bPublishWithoutChanges ? (
            <div className="">
              <Form onSubmit={this.handleSubmit} className="form">
                <label>
                  {intl.get('NewVersion.norma_relacionada')}
                  {/* <Input maxLength={250} name="cnorma" onChange={this.handleChange} value={cnorma || ''} type="text" /> */}
                  <Select
                    onChange={elSelectedOption => this.handleChangeCustom(elSelectedOption)}
                    isMulti
                    options={aRequisites}
                    defaultValue={aSelectedRequisites}
                    className="selectCustom"
                    classNamePrefix="react-select"
                    noOptionsMessage={() => intl.get('NewVersion.requisito_nao_encontrado')}
                    placeholder={intl.get('NewVersion.selecione')}
                  />
                </label>
                <label>
                  {intl.get('NewVersion.descricao_versao')} <span className="required">*</span>
                  <Input
                    type="textarea"
                    maxLength={255}
                    required
                    name="cJustification"
                    onChange={this.handleChange}
                    value={cJustification || ''}
                    cols={50}
                    rows={10}
                  />
                </label>

                {oDocument.doc_eversiontype === 'M' && (
                  <label className="split6" id="version-manual">
                    {intl.get('Document.versao')} <span className="required">*</span>
                    <Input name="cNumber" required maxLength={10} onChange={this.handleChange} value={cNumber || ''} />
                  </label>
                )}

                {aRequests && aRequests.length > 0 && this.renderRequests()}

                <div className="button-stack">
                  <Button type="button" onClick={closeModal} className="btn btn-cancel">
                    {intl.get('cancel')}
                  </Button>
                  <Button type="submit" disabled={bIsSend} className="btn">
                    {intl.get('criar')}
                  </Button>
                </div>
              </Form>
            </div>
          ) : (
            <div className="">
              <div className="">
                <Form onSubmit={this.handleSubmitVersion} className="form">
                  <label className="split2 no-spacing" htmlFor="nValueReviewPeriod">
                    {intl.get('DocumentPublish.revisar_a_cada')}
                  </label>
                  <label className="split2 no-spacing" htmlFor="nValueNotification">
                    {intl.get('DocumentPublish.notificar_antes_de')}
                  </label>
                  <div className="row split4">
                    <Input type="select" onChange={this.handleChangeVersion} required name="cReviewPeriodVersion" id="cReviewPeriodVersion" value={cReviewPeriodVersion}>
                      <option value="S">{intl.get('DocumentPublish.semanas')}</option>
                      <option value="M">{intl.get('Document.meses')}</option>
                      <option value="A">{intl.get('DocumentPublish.anos')}</option>
                      <option value="T">{intl.get('DocumentPublish.date_doc')}</option>
                    </Input>
                  </div>
                  {bVerValPeriod ? (
                    <div className="row split4">
                      <Input
                        onChange={this.handleChangeVersion}
                        min="0"
                        max="999"
                        id="nValueReviewPeriod"
                        name="nValueReviewPeriod"
                        type="number"
                        value={nValueReviewPeriod}
                        maxLength="255"
                      />
                    </div>
                  ) : (
                    <div className="row split4">
                      <Input
                        onChange={this.handleChangeVersion}
                        id="nValueReviewPeriod"
                        name="nValueReviewPeriod"
                        type="date"
                        min="2023-01-01"
                        max="2100-12-31"
                      />
                    </div>
                  )}
                  <div className="row split4">
                    <Input type="select" onChange={this.handleChangeVersion} required name="cVersionNotification" id="cVersionNotification" value={cVersionNotification}>
                      <option value="D">{intl.get('Document.dias')}</option>
                      <option value="S">{intl.get('DocumentPublish.semanas')}</option>
                      <option value="T" disabled={cReviewPeriodVersion !== 'T'}>{intl.get('DocumentPublish.date_doc')}</option>
                    </Input>
                  </div>
                  {bVerValNotification ? (
                    <div className="row split4">
                      <Input
                        onChange={this.handleChangeVersion}
                        min="0"
                        max="999"
                        id="nValueNotification"
                        name="nValueNotification"
                        type="number"
                        value={nValueNotification}
                        maxLength="255"
                      />
                    </div>
                  ) : (
                    <div className="row split4">
                      <Input
                        onChange={this.handleChangeVersion}
                        id="nValueNotification"
                        name="nValueNotification"
                        type="date"
                        min="2023-01-01"
                        max="2100-12-31"
                      />
                    </div>
                  )}
                  <div className="button-stack">
                    <Button type="button" onClick={closeModal} className="btn btn-cancel">
                      {intl.get('cancel')}
                    </Button>
                    <Button type="submit" disabled={bIsSend} className="btn">
                      {intl.get('publicar')}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          )
        }
      </PageClean >
    );
  }
}

export default NewVersion;
