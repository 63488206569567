// atenção: na próxima vez que precisarmos usar HTML perigoso, lembremos de sanitizá-lo para aplicar a sanitização nesses dangerouslySetInnerHTML também
import React, { Component } from 'react';
import $ from 'jquery';
import intl from 'react-intl-universal';
import Axios from '../../config/Axios';
import Breadcrumb from '../../components/Breadcrumbs';
import Page from '../default-v2/Page';
import CardHeader from '../default-v2/CardHeader';
import { Table } from 'react-bootstrap';
import './styles/versiondiff.scss';
import url from '../../config/Url';
import imgfNoPic from '../../assets/img/nopic.svg';
import loadBreadcrumb from '../../actions/ActionBreadcrumb';
import { NavLink } from 'react-router-dom';
import 'froala-editor/css/froala_style.min.css';
import '../../assets/scss/froala-custom.scss';
import { checkModule } from '../../config/Permissions';
import EncodedLocalStorage from '../../config/EncodedLocalStorage';
import { formatDateToUserTZ } from '../../utils/time';

export class VersionDiff extends Component {
  constructor(oProps) {
    super(oProps);

    this.state = {
      bLoading: true,
      cDiff: '',
      oDocument: {}
    };
  }

  componentDidMount() {
    const { match, setAlert, history } = this.props;
    checkModule(history, 'documents');

    Axios.post('/document/compare', {
      aVersions: [match.params.cV1, match.params.cV2],
      nDocId: match.params.nId
    })
      .then(oRes => {
        loadBreadcrumb(oRes.data.oDocument.fldr_nid)
          .then(aBreadcrumbFolders => {
            this.setState(
              {
                bLoading: false,
                cDiff: oRes.data.cDiff,
                oDocument: oRes.data.oDocument,
                nVerId: oRes.data.nVerId,
                aBreadcrumbFolders
              },
              () => {
                // aqui desabilitarei a func-names porque uma função arrow não é capaz de capturar eventos do DOM
                // eslint-disable-next-line func-names
                $('#versions-compared .del').click(function (evt) {
                  evt.preventDefault();
                  $(this).toggleClass('expanded');
                });
              }
            );
          })
          .catch(oError => {
            this.setState({ bLoading: false });
            setAlert('error', oError.aMsgErrors);
          });
      })
      .catch(oError => {
        this.setState({ bLoading: false });
        setAlert('error', oError.aMsgErrors);
      });
  }

  generateCardHeader = () => {
    const { history } = this.props;

    const rcmpCardHeader = <CardHeader history={history} />;
    return rcmpCardHeader;
  };

  render() {
    const { cDiff, bLoading, oDocument, nVerId, aBreadcrumbFolders } = this.state;
    const { match, getAlert } = this.props;
    const bDocTitleLoaded = oDocument.doc_ctitle !== undefined && oDocument.doc_ctitle.length > 35;
    const rcmpBreadcrumb = (
      <div className="breadcrumbs-wrapper">
        <div className="inner-bread">
          <Breadcrumb bLastLink key={oDocument.doc_nid} aFolders={aBreadcrumbFolders} />
        </div>
        <NavLink to={`/document/detail/${nVerId}`} title={oDocument.doc_ctitle}>
          {bDocTitleLoaded ? oDocument.doc_ctitle.trunc(35) : oDocument.doc_ctitle}
        </NavLink>
        <span>></span>
        <NavLink to={`/document/history/${match.params.nId}`} title={intl.get('VersionHistory.titulo_breadcump')}>
          {intl.get('VersionDiff.breadcrumb_history_version')}
        </NavLink>
        <span>></span>
        <span className="breacrumb-page">{intl.get('VersionDiff.breadcrumb_title')}</span>
      </div>
    );

    const rcmpCardHeader = this.generateCardHeader();

    const cPageTitle = `${intl.get('VersionDiff.title')} | ${oDocument ? oDocument.doc_ctitle : ''}`;

    return (
      <Page
        loading={bLoading ? 1 : 0}
        rcmpBreadcrumb={rcmpBreadcrumb}
        cTitle={cPageTitle}
        rcmpCardHeader={rcmpCardHeader}
        className="v2-version-compare"
        cCurrentSideMenuOption={intl.get('Nav.document')}
      >
        {getAlert()}
        <section id="versions-compared">
          <Table responsive className="table-versions">
            <thead>
              <tr>
                <th>{intl.get('VersionHistory.version')}</th>
                <th>{intl.get('VersionHistory.justification')}</th>
                <th>{intl.get('VersionHistory.data_hour')}</th>
                <th>{intl.get('VersionHistory.responsable')}</th>
              </tr>
            </thead>
            <tbody>
              {(oDocument.versions || []).map(oVersion => {
                const oVerResp = oDocument.document_responsibles.find(
                  oResp => oResp.user_nid === oVersion.user_responsible_nid
                );
                return (
                  <tr key={oVersion.ver_nid}>
                    <td>{oVersion.ver_cnumber}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{oVersion.ver_cjustification}</td>
                    <td>
                      {formatDateToUserTZ(
                        oVersion.ver_dpublished,
                        `DD/MM/YYYY [${intl.get('VersionHistory.at')}] HH:mm`
                      )}
                    </td>
                    <td>
                      {oVerResp && (
                        <div className="role-avatar">
                          <span className="avatar">
                            <img
                              src={oVerResp.user_cavatar ? `${oVerResp.user_cavatar}` : imgfNoPic}
                              alt={oVerResp.user_cname}
                            />
                          </span>
                          <span className="name">{oVerResp.user_cname}</span>
                        </div>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>

          <div className="label">
            <h2> {intl.get('VersionDiff.legenda')}</h2>
            <span className="ins">{intl.get('VersionDiff.legenda_adicionado')}</span>
            <span className="del">{intl.get('VersionDiff.legenda_removido')}</span>
          </div>

          {cDiff && (
            <div className="compared-text fr-wrapper">
              <article className="fr-element fr-view" dangerouslySetInnerHTML={{ __html: cDiff }} />
            </div>
          )}
        </section>
      </Page>
    );
  }
}

export default VersionDiff;
