import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/pt-br';
import React, { Component } from 'react';
import intl from 'react-intl-universal';
import Page from '../default-v2/Page';
import Breadcrumb from '../../components/Breadcrumbs';
import CardHeader from '../default-v2/CardHeader';
import Filter from '../default-v2/Filter';
import './styles/report.scss';
import './styles/readingdocumentresponsible.scss';
import './styles/v2-reports.scss';
import Axios from '../../config/Axios';
import Pagination from 'react-js-pagination';
import ResponsibleReadModal from '../dashboard/ResponsibleReadModal';
import ScrollBar from '../../components/ScrollBar';
import SweetAlert from 'react-bootstrap-sweetalert/lib/dist/SweetAlert';
import ReportTable from './ReportTable';
import { checkModule } from '../../config/Permissions';
import reportsImg from '../../assets/img/reports.svg';
import EncodedLocalStorage from '../../config/EncodedLocalStorage';
import myPendingReadingResponsible from '../../actions/ActionReadPendingResponsible';
import PaginationMenu from '../default-v2/PaginationMenu';

const oLocalStorage = new EncodedLocalStorage();
class ReadingDocumentResponsible extends Component {
  constructor(oProps) {
    super(oProps);

    moment.locale(oLocalStorage.get('cLanguage'));

    this.state = {
      aReadingDocumentResponsible: [],
      bLoading: true,
      cSearch: '',
      cOrderBy: 'doc_ctitle',
      cOrderType: 'asc',
      rcmpAlert: '',
      aPages: [
        {
          cName: 'Nav.reports',
          cLink: undefined
        },
        {
          cName: 'ReadingDocumentResponsible.breadcrumb_label',
          cLink: undefined
        }
      ],
      nCurrentPage: 1,
      nTotalPages: 0,
      nItemsPerPage: 50,
      nTotal: 0,
    };
  }

  componentDidMount() {
    const { setAlert, history } = this.props;
    checkModule(history, 'documents');

    this.fetchData();
  }

  getPending = (nPage, nPerPage, orderBy, order, cSearch) => {
    return myPendingReadingResponsible(nPage, nPerPage, orderBy, order, cSearch);
  };

  fetchData = () => {
    const { setAlert, clearAlert, match } = this.props;
    const { nCurrentPage, nItemsPerPage, cSearch, cOrderBy, cOrderType } = this.state;

    this.setState({ bLoading: true });

    this.getPending(nCurrentPage, nItemsPerPage)
      .then(aReadingDocumentResponsible => {
        this.setState({
          bLoading: false,
          aReadingDocumentResponsible: aReadingDocumentResponsible.data,
          nTotalPages: aReadingDocumentResponsible.last_page,
          nTotal: aReadingDocumentResponsible.total
        });
      })
      .catch(oError => {
        this.setState({ bLoading: false, aReadingDocumentResponsible: [] });
        setAlert('error', oError.aMsgErrors);
      });
  };

  handlePageChange = nPageNumber => {
    this.setState({ nCurrentPage: nPageNumber }, this.fetchData);
  };

  hideAlert = () => {
    this.setState({
      rcmpAlert: null
    });
  };

  successMessage = cMsg => {
    const { setAlert } = this.props;
    this.hideAlert();
    setAlert('success', cMsg);
  };

  handleClickTable = (evt, cNewOrderBy, cNewOrderType) => {
    this.setState(
      {
        cOrderBy: cNewOrderBy,
        cOrderType: cNewOrderType
      },
      () => {
        this.handleFilter(evt);
      }
    );
  };

  openResponsibleReadModal = nVersionId => {
    const { history, setAlert } = this.props;

    this.setState({
      rcmpAlert: (
        <SweetAlert
          customClass="modal-edit md disabled-overflow scrollBar"
          title=""
          onConfirm={this.hideAlert}
          showConfirm={false}
        >
          <div className="head-modal">
            {intl.get('DocumentReading.title')}
            <span
              aria-labelledby="head-modal"
              tabIndex={0}
              onClick={this.hideAlert}
              className="close"
              role="button"
              onKeyPress={this.hideAlert}
            />
          </div>
          <ScrollBar>
            <ResponsibleReadModal nVersionId={nVersionId} history={history} setAlert={setAlert} />
          </ScrollBar>
        </SweetAlert>
      )
    });
  };

  renderReadingDocumentResponsible = () => {
    const { aReadingDocumentResponsible } = this.state;

    if (aReadingDocumentResponsible !== undefined && aReadingDocumentResponsible.length === 0) {
      return (
        <>
          <p className="empty-result">
            <strong> {intl.get('ReadingDocumentResponsible.empty_result')}</strong>
          </p>
        </>
      );
    }

    const rcmpReadingDocumentResponsible = Object.keys(aReadingDocumentResponsible).map(cKey => {
      const oInformation = aReadingDocumentResponsible[cKey];

      return (
        <tr
          key={cKey}
          onClick={() => this.openResponsibleReadModal(oInformation.published_version.ver_nid)}
          onKeyPress={() => this.openResponsibleReadModal(oInformation.published_version.ver_nid)}
        >
          <td title={oInformation.doc_ctitle} className="col-doc-name">
            <span>{oInformation.doc_ctitle}</span>
          </td>
          <td
            title={
              oInformation.document_responsibles && oInformation.document_responsibles.length > 0
                ? oInformation.document_responsibles[0].user_cname
                : ''
            }
            className="col-user-name"
          >
            <span>
              {oInformation.document_responsibles && oInformation.document_responsibles.length > 0
                ? oInformation.document_responsibles[0].user_cname
                : ''}
            </span>
          </td>
          <td>
            <i className="icon-reading" title={intl.get('Status.awaiting_reading')} />
          </td>
          <td>
            {oInformation.published_version.ver_dpublished && (
              <>
                {moment
                  .parseZone(oInformation.published_version.ver_dpublished)
                  .tz(oLocalStorage.get('cTimezone'))
                  .format('LL')}
              </>
            )}
          </td>
        </tr>
      );
    });

    return (
      <ReportTable
        aColumn={[
          {
            cName: intl.get('ReadingDocumentResponsible.document'),
            cOrderBy: 'doc_ctitle',
            fnHandleClick: this.handleClickTable
          },
          {
            cName: intl.get('ReadingDocumentResponsible.request_by'),
            cOrderBy: 'request_by',
            bIsDisable: true,
            fnHandleClick: this.handleClickTable
          },
          {
            cName: intl.get('ReadingDocumentResponsible.action'),
            cOrderBy: 'acc_version.stat_nid',
            fnHandleClick: this.handleClickTable
          },
          {
            cName: intl.get('ReadingDocumentResponsible.published_at'),
            cOrderBy: 'acc_version.ver_dpublished',
            fnHandleClick: this.handleClickTable
          }
        ]}
      >
        {rcmpReadingDocumentResponsible}
      </ReportTable>
    );
  };

  handleFilter = evt => {
    const { setAlert } = this.props;
    const { nCurrentPage, nItemsPerPage, cSearch, cOrderBy, cOrderType } = this.state;

    this.getPending(nCurrentPage, nItemsPerPage, cOrderBy, cOrderType, cSearch)
      .then(aReadingDocumentResponsible => {
        this.setState({
          bLoading: false,
          aReadingDocumentResponsible: aReadingDocumentResponsible.data,
          nTotalPages: aReadingDocumentResponsible.last_page,
          nTotal: aReadingDocumentResponsible.total
        });
      })
      .catch(oError => {
        this.setState({ bLoading: false, aReadingDocumentResponsible: [] });
        setAlert('error', oError.aMsgErrors);
      });
  };

  handleNewFilter = (evt, oFilter) => {
    this.setState(
      {
        cSearch: oFilter.cText,
        cOrderType: oFilter.cOrderType
      },
      () => {
        this.handleFilter(evt);
      }
    );
  };

  generateCardHeader = () => {
    const { history } = this.props;

    const rcmpFilter = <Filter fnOnType={this.handleNewFilter} />;

    const rcmpCardHeader = <CardHeader history={history} rcmpFilter={rcmpFilter} />;
    return rcmpCardHeader;
  };

  handleItemsPerPageChange = eventPagination => {
    const nItemsPerPage = parseInt(eventPagination.target.value, 10);
    this.setState({ nItemsPerPage, nCurrentPage: 1 }, this.fetchData);
  };

  render() {
    const { bLoading, aPages, rcmpAlert, nCurrentPage, nItemsPerPage, nTotalPages, nTotal } = this.state;
    const { getAlert } = this.props;

    const rcmpBreadcrumb = <Breadcrumb aPages={aPages} />;

    const rcmpCardHeader = this.generateCardHeader();

    const cPageTitle = intl.get('ReadingDocumentResponsible.title');

    return (
      <Page
        loading={bLoading ? 1 : 0}
        rcmpBreadcrumb={rcmpBreadcrumb}
        cTitle={cPageTitle}
        cImage={reportsImg}
        rcmpCardHeader={rcmpCardHeader}
        className="v2-report"
        cCurrentSideMenuOption={intl.get('Nav.report')}
      >
        {rcmpAlert}
        {getAlert()}

        {this.renderReadingDocumentResponsible()}
        <PaginationMenu
          nCurrentPage={nCurrentPage}
          nItemsPerPage={nItemsPerPage}
          nTotalPages={nTotalPages}
          nTotal={nTotal}
          handlePageChange={this.handlePageChange}
          handleItemsPerPageChange={this.handleItemsPerPageChange} />
      </Page>
    );
  }
}

export default ReadingDocumentResponsible;
