import _ from 'lodash';
import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { Button, Input } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import imgfNoPic from '../../assets/img/nopic.svg';
import Breadcrumb from '../../components/Breadcrumbs';

import OutsideHandlerClick from '../../components/OutsideHandlerClick';
import Axios from '../../config/Axios';
import url from '../../config/Url';
import { can, canAtLeast } from '../../config/Permissions';
import Page from '../default-v2/Page';
import LimitWidget from '../../components/LimitWidget';
import CardHeader from '../default-v2/CardHeader';
import './styles/permissiongroup.scss';
import Permissions from './Permissions';
import teamsImg from '../../assets/img/teams.svg';

export class PermissionGroup extends Component {
  constructor(oProps) {
    super(oProps);

    this.state = {
      bLoading: true,
      oPermGroupCustomer: {},
      oLimits: [],
      cSearch: '',
      aInvites: [],
      aPermissions: [],
      aSelectedUsers: [],
      aSelectedPermissions: [],
      aTeamGroups: [],
      bIsSend: false,
      focused: false,
      aFilteredUsers: [],
      aPages: [
        {
          cName: 'PermissionGroup.title',
          cLink: '/team'
        },
        {
          cName: oProps.match.params.nId ? 'PermissionGroup.edit_team' : 'PermissionGroup.new_team',
          cLink: undefined
        }
      ],
      rcmpAlert: ''
    };
  }

  componentDidMount = () => {
    const { setAlert, history } = this.props;

    if (!canAtLeast(['create-edit-team', 'admin'])) {
      history.push({
        pathname: '/team',
        state: { aAlertMessages: { cType: 'error', cMsg: intl.get('sem_permissao') } }
      });
    }

    Promise.all([
      this.fetchForm(),
      Axios.get('customer/users-limit').then(oResponse => {
        const oLimits = {
          oUser: oResponse.data.aUser
        };
        this.setState({
          oLimits
        });
      })
    ])
      .then(() => this.setState({ bLoading: false }))
      .catch(oError => {
        this.setState({ bLoading: false });
        setAlert('error', oError.msgErrors);
      });
  };

  updateMain = (oObj, aElements) => {
    const { aSelectedPermissions } = this.state;

    let bCheck = false;
    for (let i in aElements) {
      let elItem = aElements[i];
      if (typeof elItem == 'object' && elItem.getElementsByTagName('input')[0].checked === true) {
        bCheck = true;
      }
    }

    if (bCheck === true) {
      const oExists = _.find(aSelectedPermissions, oPermission => oPermission.perm_nid === oObj.perm_nid);
      if (oExists === undefined) {
        this.setState(oPrevState => ({
          aSelectedPermissions: [...oPrevState.aSelectedPermissions, oObj]
        }));
      }
    } else {
      this.setState({
        aSelectedPermissions: aSelectedPermissions.filter(
          oSelectedPermission => oSelectedPermission.perm_nid !== oObj.perm_nid
        )
      });
    }
  };

  inputChangeHandlerPermission = (evt, oPermission) => {
    if (evt.target.checked === true) {
      this.setState(oPrevState => ({
        aSelectedPermissions: [...oPrevState.aSelectedPermissions, oPermission]
      }));
    } else {
      this.setState(oPrevState => ({
        aSelectedPermissions: oPrevState.aSelectedPermissions.filter(
          oSelectedPermission => oSelectedPermission.perm_nid !== oPermission.perm_nid
        )
      }));
    }
  };

  handleChange = evt => {
    const { oPermGroupCustomer } = this.state;
    oPermGroupCustomer[evt.target.name] = evt.target.value;
    this.setState({ oPermGroupCustomer });
  };

  onSubmitGroup = evtSubmit => {
    evtSubmit.preventDefault();
    const { match } = this.props;
    const { aSelectedPermissions, aPermissions } = this.state;
    const aParentPermissions = [];
    let aNewSelectedPermissions = [];
    Object.assign(aNewSelectedPermissions, aSelectedPermissions);
    let nCount = 0;

    for (let n = 0; n < aPermissions.length; n += 1) {
      nCount = 0;
      for (let i = 0; i < aPermissions[n].children_permissions.length; i += 1) {
        const bWasFound = aNewSelectedPermissions.some(
          oSelectedPermissionItem =>
            oSelectedPermissionItem.perm_nid === aPermissions[n].children_permissions[i].perm_nid
        );
        const bWasFoundParent = aNewSelectedPermissions.some(
          oSelectedPermissionItem => oSelectedPermissionItem.perm_nid === aPermissions[n].perm_nid
        );

        //Insere a permissão raiz apenas se um filho foi selecionado e se ela já não existir.
        if (bWasFound) {
          if (!bWasFoundParent) {
            aParentPermissions.push({
              perm_cname: aPermissions[n].perm_cname,
              perm_nid: aPermissions[n].perm_nid
            });
          }
          break;
        } else if (aPermissions[n].perm_cslug === 'documents') {
          if (!bWasFoundParent) {
            aParentPermissions.push({
              perm_cname: aPermissions[n].perm_cname,
              perm_nid: aPermissions[n].perm_nid
            });
          }
          break;
        }
        if (bWasFoundParent) {
          nCount += 1;
        }
      }

      //Remove as permissões que não possuem filhos selecionados
      if (nCount === aPermissions[n].children_permissions.length) {
        aNewSelectedPermissions = aNewSelectedPermissions.filter(
          oPermissionItem => oPermissionItem.perm_nid !== aPermissions[n].perm_nid
        );
      }
      nCount = 0;
    }

    this.setState(
      {
        bIsSend: true,
        aSelectedPermissions: aParentPermissions.concat(aNewSelectedPermissions)
      },
      () => {
        if (match.params.nId) {
          this.updateGroup();
        } else {
          this.createGroup();
        }
      }
    );
  };

  handleChangeInvites = evt => {
    evt.preventDefault();
    this.setState({ [evt.target.name]: evt.target.value });
  };

  addInvite = () => {
    const { setAlert } = this.props;
    const { cInviteName, cInviteEmail } = this.state;

    const oInvite = {
      cName: cInviteName,
      cEmail: cInviteEmail
    };
    if (!this.validateEmail(cInviteEmail)) return;

    if (oInvite.cName !== '' && oInvite.cEmail !== '') {
      this.setState(oPrevState => ({
        aInvites: [...oPrevState.aInvites, oInvite],
        cInviteName: '',
        cInviteEmail: ''
      }));
    } else {
      setAlert('error', intl.get('PermissionGroup.convite_sem_email_nome'));
    }
  };

  removeInvite = oUser => {
    const { aInvites } = this.state;
    this.setState({
      aInvites: aInvites.filter(oActualInvite => oActualInvite.cEmail !== oUser.user_cemail)
    });
  };

  validateEmail = cEmail => {
    const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regexp.test(cEmail);
  };

  sendInvites = (oTeam, cType) => {
    const { history, setAlert } = this.props;
    const { aInvites } = this.state;

    const aSendInvites = aInvites.map(({ cName, cEmail }) => ({
      cName,
      cEmail,
      bIsAdmin: false,
      aTeams: [oTeam]
    }));

    Axios.post('user-invite', aSendInvites)
      .then(() => {
        history.push({
          pathname: '/team',
          state: {
            aAlertMessages: {
              cType: 'success',
              cMsg:
                cType === 'PUT'
                  ? intl.get('PermissionGroup.equipe_alterada')
                  : intl.get('PermissionGroup.equipe_cadastrada')
            }
          }
        });
      })
      .catch(oError => {
        setAlert('error', oError.aMsgErrors);
      });
  };

  createGroup = () => {
    const { aSelectedUsers, aSelectedPermissions, oPermGroupCustomer, aTeamGroups, aInvites } = this.state;
    const { history, setAlert } = this.props;

    Axios.post('permissiongroup', {
      pgc_cname: oPermGroupCustomer.pgc_cname,
      pgc_cdescription: oPermGroupCustomer.pgc_cdescription,
      customersUsers: aSelectedUsers.map(oUser => oUser.cu_nid),
      permissions: aSelectedPermissions.map(oPermission => oPermission.perm_nid),
      teams: aTeamGroups.map(oTeam => oTeam.value)
    })
      .then(oResponse => {
        if (aInvites.length) {
          this.sendInvites(oResponse.data.aTeam, 'POST');
        } else {
          history.push({
            pathname: '/team',
            state: { aAlertMessages: { cType: 'success', cMsg: intl.get('PermissionGroup.equipe_cadastrada') } }
          });
        }
      })
      .catch(oError => {
        this.setState({
          bIsSend: false
        });
        setAlert('error', oError.aMsgErrors);
      });
  };

  updateGroup = () => {
    const {
      oPermGroupCustomer,
      aSelectedUsers,
      aSelectedPermissions,
      aTeamGroups,
      nTeamUpdateId,
      aInvites
    } = this.state;
    const { history, setAlert } = this.props;

    Axios.put(`permissiongroup/${oPermGroupCustomer.pgc_nid}`, {
      nTeamUpdateId,
      pgc_cname: oPermGroupCustomer.pgc_cname,
      pgc_cdescription: oPermGroupCustomer.pgc_cdescription,
      customersUsers: aSelectedUsers.map(oUser => oUser.cu_nid),
      permissions: aSelectedPermissions.map(oPermission => oPermission.perm_nid),
      teams: aTeamGroups.map(oTeam => oTeam.value)
    })
      .then(oResponse => {
        if (aInvites.length) {
          this.sendInvites(oResponse.data.aTeam, 'PUT');
        } else {
          history.push({
            pathname: '/team',
            state: { aAlertMessages: { cType: 'success', cMsg: intl.get('PermissionGroup.equipe_alterada') } }
          });
        }
      })
      .catch(oError => {
        this.setState({
          bIsSend: false
        });
        setAlert('error', oError.aMsgErrors);
      });
  };

  onFocusSearch = () => {
    this.setState({ focused: true });
  };

  fetchForm = () => {
    const { match, setAlert } = this.props;

    if (match.params.nId) {
      return Axios.get(`permissiongroup/form/${match.params.nId}`)
        .then(oRes => {
          this.setState({
            nTeamUpdateId: oRes.data.nTeamId,
            aFilteredUsers: oRes.data.aUsers.map(
              ({ aTeamsIds, bIsAdmin, cu_nid, user_cname, user_cavatar, user_crole, user_bisregistered }) => ({
                aTeamsIds,
                bIsAdmin,
                user_cname,
                cu_nid,
                user_cavatar,
                user_crole,
                user_bisregistered
              })
            ),
            oPermGroupCustomer: oRes.data.oPermGroupCustomer,
            aSelectedPermissions: oRes.data.aSelectedPermissions.map(({ perm_nid, perm_cname }) => ({
              perm_cname,
              perm_nid
            })),
            aPermissions: oRes.data.aPermissions,
            aSelectedUsers: oRes.data.aSelectedUsers.map(
              ({ aTeamsIds, bIsAdmin, cu_nid, user_cname, user_cavatar, user_crole, user_bisregistered }) => ({
                aTeamsIds,
                bIsAdmin,
                user_cname,
                cu_nid,
                user_cavatar,
                user_crole,
                user_bisregistered
              })
            )
          });
          this.updateFiltredList();
        })
        .catch(oError => {
          this.setState({ bLoading: false });
          setAlert('error', oError.msgErrors);
        });
    }
    return Axios.get('permissiongroup/form/new')
      .then(oRes => {
        this.setState({
          aFilteredUsers: oRes.data.aUsers.map(
            ({ cu_nid, user_cname, user_cavatar, user_crole, user_bisregistered }) => ({
              user_cname,
              cu_nid,
              user_cavatar,
              user_crole,
              user_bisregistered
            })
          ),
          aPermissions: oRes.data.aPermissions
        });
      })
      .catch(oError => {
        this.setState({ bLoading: false });
        setAlert('error', oError.msgErrors);
      });
  };

  addUser = oUser => {
    this.setState(
      oPrevState => ({
        aSelectedUsers: [...oPrevState.aSelectedUsers, oUser]
      }),
      () => {
        this.updateFiltredList();
      }
    );
  };

  hideAlert = () => {
    this.setState({
      rcmpAlert: null
    });
  };

  removeUser = oUser => {
    const { aSelectedUsers, nTeamUpdateId } = this.state;

    if (
      nTeamUpdateId &&
      oUser.aTeamsIds.length === 1 &&
      nTeamUpdateId === Number(oUser.aTeamsIds[0])
    ) {
      this.setState({
        rcmpAlert: (
          <SweetAlert
            warning
            confirmBtnText={intl.get('confirmar')}
            title={intl.get('remover')}
            onConfirm={this.hideAlert}
          >
            {intl.get('PermissionGroup.user_remove_message')}
          </SweetAlert>
        )
      });
    } else {
      this.setState(
        () => ({
          aSelectedUsers: aSelectedUsers.filter(oSelectedUser => oSelectedUser.cu_nid !== oUser.cu_nid)
        }),
        () => {
          this.setState(oPrevState => ({
            aFilteredUsers: [...oPrevState.aFilteredUsers, oUser]
          }));
        }
      );
    }
  };

  updateFiltredList = () => {
    const { aFilteredUsers } = this.state;
    const aUpdated = aFilteredUsers;
    const { aSelectedUsers } = this.state;

    for (let i = aUpdated.length - 1; i >= 0; i -= 1) {
      for (let j = 0; j < aSelectedUsers.length; j += 1) {
        if (aUpdated[i] && aUpdated[i].user_cname === aSelectedUsers[j].user_cname) {
          aUpdated.splice(i, 1);
        }
      }
    }

    this.setState({ aFilteredUsers: aUpdated });
  };

  renderUsers = aItems => {
    const { focused, cInviteName, cInviteEmail, cSearch, oLimits, aInvites } = this.state;

    return (
      <OutsideHandlerClick handleClickOutside={this.closeSubmenu}>
        <div
          style={{ display: focused === true ? 'block' : 'none' }}
          className={`searchBox ${aItems.length === 0 ? 'notfound' : ''}`}
          id="searchResult"
        >
          {canAtLeast(['create-edit-user', 'admin']) ? (
            ''
          ) : (
            <p className="notice backup-notice">{intl.get('PermissionGroup.sem_permissao')}</p>
          )}

          <ul className="list-group">
            {aItems
              .filter(oItem => oItem.user_cname.toLowerCase().indexOf(cSearch.toLowerCase()) !== -1)
              .map(oItem => (
                <li className={`list-group-item ${!oItem.user_bisregistered ? 'pending' : ''}`} key={oItem.cu_nid}>
                  <img
                    src={oItem.user_cavatar ? oItem.user_cavatar : imgfNoPic}
                    alt="Usuário"
                  />
                  <p className="infos">
                    <b>{oItem.user_cname}</b> <br /> {oItem.user_crole}
                  </p>
                  <span
                    role="button"
                    tabIndex="0"
                    onKeyPress={() => this.addUser(oItem)}
                    onClick={() => this.addUser(oItem)}
                    className="btn btn-primary"
                  >
                    {intl.get('adicionar')}
                  </span>
                </li>
              ))}
          </ul>

          {aItems.filter(oItem => oItem.user_cname.toLowerCase().indexOf(cSearch.toLowerCase()) !== -1).length === 0 &&
            focused === true &&
            aInvites.length < oLimits.oUser.nLeft &&
            canAtLeast(['create-edit-user', 'admin']) && (
              <div className="boxInvite">
                <LimitWidget oLimits={oLimits} />
                <p>{intl.get('PermissionGroup.membros_busca_descricao')}</p>
                <div className="form">
                  <label className="split3">
                    {intl.get('name')}
                    <Input
                      onChange={this.handleChangeInvites}
                      maxLength="250"
                      value={cInviteName || ''}
                      name="cInviteName"
                      type="text"
                    />
                  </label>

                  <label className="split3">
                    Email
                    <Input
                      onChange={this.handleChangeInvites}
                      value={cInviteEmail || ''}
                      maxLength="250"
                      name="cInviteEmail"
                      type="email"
                    />
                  </label>

                  <label className="split3">
                    <span
                      role="button"
                      tabIndex="0"
                      onKeyPress={evt => this.addInvite(evt)}
                      onClick={evt => this.addInvite(evt)}
                      className="btn btn-primary"
                    >
                      {intl.get('PermissionGroup.botao_adicionar_usuario')}
                    </span>
                  </label>
                </div>
              </div>
            )}
        </div>
      </OutsideHandlerClick>
    );
  };

  closeSubmenu = (evt, elWrapperRef) => {
    const elElement = elWrapperRef;
    if (elElement.getElementsByClassName('searchBox')[0]) {
      elElement.getElementsByClassName('searchBox')[0].style = 'display: none';
      this.setState({ focused: false });
    }
  };

  generateCardHeader = () => {
    const { history } = this.props;

    const rcmpCardHeader = <CardHeader history={history} />;
    return rcmpCardHeader;
  };

  render() {
    const {
      aPermissions,
      oPermGroupCustomer,
      aInvites,
      aSelectedUsers,
      aFilteredUsers,
      aSelectedPermissions,
      bIsSend,
      aPages,
      bLoading,
      rcmpAlert
    } = this.state;

    const { history, getAlert } = this.props;

    const rcmpBreadcrumb = <Breadcrumb aPages={aPages} />;

    const cPageTitle = intl.get('PermissionGroup.title');
    const rcmpCardHeader = this.generateCardHeader();

    console.log('aSelectedPermissions', aSelectedPermissions);
    console.log('aPermissions', aPermissions);

    return (
      <Page
        loading={bLoading ? 1 : 0}
        cTitle={cPageTitle}
        cImage={teamsImg}
        rcmpBreadcrumb={rcmpBreadcrumb}
        rcmpCardHeader={rcmpCardHeader}
        className="v2-new-group"
        cCurrentSideMenuOption={intl.get('Nav.teams')}
      >
        {rcmpAlert}
        {getAlert()}
        <form autoComplete="off" onSubmit={this.onSubmitGroup}>
          <label>
            {intl.get('name')}
            <span className="required"> *</span>
            <Input
              type="text"
              id="pgc_cname"
              name="pgc_cname"
              maxLength="250"
              onChange={this.handleChange}
              value={oPermGroupCustomer.pgc_cname || ''}
              placeholder={intl.get('PermissionGroup.team_name')}
              required
            />
          </label>

          <label>
            {intl.get('cdescricao')}
            <Input
              type="text"
              id="pgc_cdescription"
              name="pgc_cdescription"
              maxLength="250"
              onChange={this.handleChange}
              value={oPermGroupCustomer.pgc_cdescription || ''}
              placeholder={intl.get('PermissionGroup.team_description')}
            />
          </label>

          <div className="container-group">
            <label>
              <b>{intl.get('PermissionGroup.membros_equipe')}</b>
              <Input
                type="text"
                id="cSearch"
                name="cSearch"
                maxLength="250"
                placeholder={intl.get('PermissionGroup.membros_equipe_placeholder')}
                // onChange={this.filterList}
                onChange={evt => {
                  this.setState({ cSearch: evt.target.value });
                }}
                onFocus={this.onFocusSearch}
                onClick={this.onFocusSearch}
              />
              {this.renderUsers(_.orderBy(aFilteredUsers, [oUser => oUser.user_cname.toLowerCase()], ['asc']))}
            </label>

            <div id="selectedUsers">
              {aSelectedUsers.map((oUser, index) => (
                <span key={index} className={`userBox ${!oUser.user_bisregistered ? 'pending' : ''}`}>
                  <span
                    role="button"
                    tabIndex="0"
                    onKeyPress={() => this.removeUser(oUser)}
                    onClick={() => this.removeUser(oUser)}
                    className="removeUser"
                    title={intl.get('remover')}
                  ></span>
                  <div>
                    <img
                      src={
                        oUser.user_cavatar ? oUser.user_cavatar : imgfNoPic
                      }
                      alt="Usuário"
                    />
                  </div>
                  <div>
                    <p className="name" title={oUser.user_cname}>
                      {oUser.user_cname}
                    </p>
                    <p className="role">{oUser.user_crole}</p>
                  </div>
                </span>
              ))}

              {aInvites.map((oUser, index) => (
                <span key={index} className="userBox pending">
                  <span
                    role="button"
                    tabIndex="0"
                    onKeyPress={() => this.removeInvite(oUser)}
                    onClick={() => this.removeInvite(oUser)}
                    className="removeUser"
                  >
                    {intl.get('remover')}
                  </span>
                  <div>
                    <img src={imgfNoPic} alt={intl.get('usuario')} />
                  </div>
                  <div>
                    <p className="name" title={oUser.cnome}>
                      {oUser.cnome}
                    </p>
                  </div>
                </span>
              ))}
            </div>

            <div className="permission-group">
              <h2>{intl.get('PermissionGroup.label_permission')}</h2>
              {aPermissions.map((oPermission, nIndex) => (
                <Permissions
                  key={nIndex}
                  cTitle={intl.get(`PermissionGroup.${oPermission.perm_cslug}`)}
                  aCheckBoxItems={oPermission.children_permissions}
                  aSelectedCheckBox={aSelectedPermissions}
                  fnOnChange={this.inputChangeHandlerPermission}
                />
              ))}
            </div>
          </div>

          <div className="button-stack">
            <Button
              disabled={bIsSend}
              type="submit"
              className="btn-cancel"
              onClick={evt => {
                history.goBack();
              }}
            >
              {intl.get('cancelar')}
            </Button>
            <Button disabled={bIsSend} type="submit" className="btn">
              {intl.get('salvar')}
            </Button>
          </div>
        </form>
      </Page>
    );
  }
}

export default PermissionGroup;
