import Axios from '../config/Axios';
import loadBreadcrumb from './ActionBreadcrumb';

export function getDocumentEssay(nVersionId) {
  return Axios.get(`document/form/${nVersionId}`).then(
    (oResponse) =>
      new Promise((resolve, reject) => {
        loadBreadcrumb(oResponse.data.oDocument
          .fldr_nid)
          .then((aBreadcrumbFolders) => {
            resolve({
              ...oResponse.data,
              "oDocument": oResponse.data.oDocument,
              aBreadcrumbFolders
            });
          })
          .catch((oError) => reject(oError));
      })
  );
}

export function sendToReview(nVersionId, cText) {
  return Axios.post(`version/${nVersionId}`, { "cAction": 'review', cText });
}

export function createAndRedirect(cCreateType, nId) {
  let cEndpoint = '';
  switch (cCreateType) {
    case 'folder': cEndpoint = 'store-by-folder'; break;
    case 'request': cEndpoint = 'store-by-request'; break;
    case 'requisite': cEndpoint = 'store-by-requisite'; break;
  }
  return Axios.post(`document/${cEndpoint}`, { nId })
    .then((oResponse) => window.location.href = `/document/editor/${oResponse.data.nVersionId}`)
    .catch((oError) => Promise.reject(oError));
}

export function uploadAttachment (nVersionId, aAttachments) {
  const fdataVersion = new FormData();
  Array.from(aAttachments).forEach((fFile) => {
    fdataVersion.append('aAttachments[]', fFile);
  });
  return Axios.post(`/attachment/upload/${nVersionId}`, fdataVersion);
}

export function getAttachments(nVersionId) {
  return Axios.get(`/version/attachments/${nVersionId}`).then((oResponse) => oResponse.data);
}

export function deleteAttachment(nAttachmentId) {
  return Axios.delete(`attachment/removed/${nAttachmentId}`);
}

export function sendToTrashBin(nVersionId) {
  return Axios.put(`/trashbin/send-version/${nVersionId}`);
}

export function uploadImage(nVersionId, fFile) {
  const fdataImage = new FormData();
  fdataImage.append('fImage', fFile);
  fdataImage.append('nVersionId', nVersionId);
  return Axios.post(`/version/upload-image`, fdataImage).then((oRes) => oRes.data);
}

export function saveEssay(nVersionId, cText, cTitle, cJustification) {
  const fdataVersion = new FormData();
  fdataVersion.append('cAction', 'save');
  fdataVersion.append('ver_ctext', cText);
  fdataVersion.append('ver_cjustification', cJustification);
  fdataVersion.append('doc_ctitle', cTitle);

  return Axios.post(`version/${nVersionId}`, fdataVersion).then((oResponse) => oResponse.data.aAttachments);
}

export function removeImage(cImageName, cVersionIdImage, nVersionId) {
  return Axios.delete(`/version/uploaded-image/${cVersionIdImage}/${nVersionId}/${cImageName}`).then((oRes) => oRes.data);
}
