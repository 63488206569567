/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import moment from 'moment';
import React, { Component } from 'react';
import intl from 'react-intl-universal';
import Axios from '../../config/Axios';
import Page from '../default-v2/Page';
import Breadcrumb from '../../components/Breadcrumbs';
import ButtonBack from '../../components/ButtonBack';
import EncodedLocalStorage from '../../config/EncodedLocalStorage';

export class Downloads extends Component {
  constructor(oProps) {
    super(oProps);

    this.state = {
      bLoading: true,
      aPdfDownloadControl: [],
      aPages: [{ cName: 'Downloads.titulo', cLink: undefined }]
    };
  }

  componentDidMount() {
    const { setAlert } = this.props;

    Axios.get('/document/download')
      .then(oRes => {
        this.setState({
          bLoading: false,
          aPdfDownloadControl: oRes.data
        });
      })
      .catch(oError => {
        this.setState({ bLoading: false });
        setAlert('error', oError.aMsgErrors);
      });
  }

  render() {
    const { history, getAlert } = this.props;
    const { aPdfDownloadControl, bLoading, aPages } = this.state;
    const oLocalStorage = new EncodedLocalStorage();
    return (
      <Page loading={bLoading ? 1 : 0}>
        <h1>{intl.get('Downloads.titulo')}</h1>
        <Breadcrumb aPages={aPages} />
        <div className="button-stack">
          <ButtonBack />
        </div>
        {getAlert()}
        <ul className="controls-list">
          {aPdfDownloadControl.map(oItem => (
            <li
              onClick={() => history.push(`/document/detail/${oItem.oDocument.version.ver_nid}`)}
              onKeyPress={() => history.push(`/document/detail/${oItem.oDocument.version.ver_nid}`)}
              key={oItem.oDocument.version.ver_nid}
            >
              <h3> {oItem.oDocument.doc_ctitle}</h3>
              <div className="upper-details">
                <p>
                  <i className="icon-pdf-download" />
                  <strong>{oItem.oDocument.version.ver_ndownloadspdf}</strong> download(s)
                </p>
              </div>
              {oItem.oDocumentAccessLog && (
                <p className="lower-details">
                  {intl.get('Downloads.baixado_em')}{' '}
                  <strong>
                    {moment
                      .parseZone(oItem.oDocumentAccessLog.dacl_dcreated)
                      .tz(oLocalStorage.get('cTimezone'))
                      .format('DD/MM/YYYY HH:mm:ss')}
                  </strong>
                </p>
              )}
            </li>
          ))}
        </ul>
      </Page>
    );
  }
}

export default Downloads;
