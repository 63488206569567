import React, { Component } from 'react';
import Page from '../default/Page';
import { getRequisites } from '../../actions/ActionRequisite';
import intl from 'react-intl-universal';

class ScopeEdit extends Component {
  constructor(oProps) {
    super(oProps);

    const { oRequisites } = this.props;

    this.state = {
      nNormId: oProps.NormNid,
      bLoading: true,
      aRequisites: [],
      selectedRequisites: oRequisites ? oRequisites : []
    };
  }

  handleChange = evt => {
    this.setState({ [evt.target.name]: evt.target.type === 'checkbox' ? evt.target.checked : evt.target.value });
  };

  componentDidMount = () => {
    const { nNormId } = this.state;
    Promise.all([
      getRequisites(nNormId).then(oData => {
        this.setState({
          ...oData
        });
      })
    ]).then(() => {
      this.setState({
        bLoading: false
      });
    });
  };

  renderChilds = aReqRecChildrens => {
    const { selectedRequisites } = this.state;

    return aReqRecChildrens.map(aRequisite => (
      <label className="reqChild" key={aRequisite.req_nid}>
        <input
          checked={selectedRequisites.includes(aRequisite.req_nid)}
          onChange={() => this.toggleSelectedRequisites(aRequisite)}
          type="checkbox"
        />
        <span>{aRequisite.req_cname}</span>

        {aRequisite.children_requisites_count > 0 && this.renderChilds(aRequisite.recursive_children_requisite)}
      </label>
    ));
  };

  toggleSelectedRequisites = ({ req_nid }) => {
    this.setState(prevState => ({
      selectedRequisites: !prevState.selectedRequisites.includes(req_nid)
        ? [...prevState.selectedRequisites, req_nid]
        : prevState.selectedRequisites.filter(reqNid => reqNid !== req_nid)
    }));
  };

  render() {
    const { bLoading, aRequisites, selectedRequisites } = this.state;
    const { setRequisites, closeModal } = this.props;
    return (
      <Page loading={bLoading ? 1 : 0}>
        <form
          onSubmit={e => {
            e.preventDefault();
            setRequisites(selectedRequisites);
            closeModal();
          }}
          className="form requisiteScopeList"
        >
          {aRequisites.map(aRequisite => (
            <label key={aRequisite.req_nid}>
              <input
                checked={selectedRequisites.includes(aRequisite.req_nid)}
                onChange={() => this.toggleSelectedRequisites(aRequisite)}
                type="checkbox"
              />
              <span>{aRequisite.req_cname}</span>
              {aRequisite.children_requisites_count > 0 && this.renderChilds(aRequisite.recursive_children_requisite)}
            </label>
          ))}

          <div className="button-stack">
            <button type="button" title={intl.get("cancelar")} onClick={() => closeModal()} className="btn alternate back btn btn-outline-secondary">
              {intl.get('cancelar')}
            </button>
            <button type="submit" title={intl.get("salvar")} className={`btn btn-secondary}`}>
              {intl.get('salvar')}
            </button>
          </div>
        </form>
      </Page>
    );
  }
}

export default ScopeEdit;
