import React, { useEffect } from 'react';

const ScriptLoader = () => {
  const cPathName = window.location.pathname;

  useEffect(() => {
    const aExcludeRoutes = [
      '/login',
      '/pre-register/',
      // Rotas com expressões regulares para parâmetros
      /\/customer\/resend-activation\/[^/]+/,
      /\/customer\/register\/[^/]+/,
      /\/customer\/accept\/[^/]+/,
      /\/database\/create\/[^/]*?/,
      '/reset-password',
      /\/reset-password\/[^/]+/,
      /\/reset-email\/[^/]+/,
      /\/register\/activate\/[^/]+/,
      /\/register\/resend-activation\/[^/]+/
    ];

    const bIsExcluded = aExcludeRoutes.some(route =>
      typeof route === 'string' ? route === cPathName : route.test(cPathName)
    );

    if (bIsExcluded) {
      return; // Não carrega os scripts nessas rotas
    }

    if (localStorage.getItem("cStorageToken") && localStorage.getItem("cStorageToken").length > 0) {
      (function (window, document, elScriptTagName, elUserGuidingGlobal, elUserGuidingLayer, elUserGuidingScriptID) {
        // Cria um array no objeto global se não existir
        window[elUserGuidingLayer] = window[elUserGuidingLayer] || [];

        // Encontra o primeiro script na página
        var elFirstScriptTag = document.getElementsByTagName(elScriptTagName)[0];

        // Cria um novo elemento de script
        var elNewScriptTag = document.createElement(elScriptTagName);
        elNewScriptTag.async = true;
        elNewScriptTag.src = 'https://static.userguiding.com/media/user-guiding-' + elUserGuidingScriptID + '-embedded.js';

        // Insere o novo script antes do primeiro script existente
        elFirstScriptTag.parentNode.insertBefore(elNewScriptTag, elFirstScriptTag);

        // Verifica se o objeto global já foi definido
        if (window[elUserGuidingGlobal]) return;

        // Cria um novo objeto global para UserGuiding e uma fila de comandos
        var elUserGuiding = window[elUserGuidingGlobal] = { q: [] };

        // Função para criar métodos que adicionam comandos à fila
        elUserGuiding.createMethod = function (methodName) {
          return function () {
            elUserGuiding.q.push([methodName, arguments]);
          };
        };

        // Lista de métodos disponíveis
        var methods = ['previewGuide', 'finishPreview', 'track', 'triggerNps', 'hideChecklist', 'launchChecklist'];

        // Adiciona cada método ao objeto global
        for (var i = 0; i < methods.length; i += 1) {
          elUserGuiding[methods[i]] = elUserGuiding.createMethod(methods[i]);
        }
      })(window, document, 'script', 'userGuiding', 'userGuidingLayer', '895571212ID');
    }
  }, [window.location.pathname]);

  return null;
};
export default ScriptLoader;
