import moment from 'moment';
import React, { Component } from 'react';
import Axios from '../../config/Axios';
import intl from 'react-intl-universal';
import PageClean from '../default-v2/PageClean';
import EncodedLocalStorage from '../../config/EncodedLocalStorage';

export class ResponsibleReadModal extends Component {
  constructor(oProps) {
    super(oProps);

    this.state = {
      bLoading: true,
      nVersionId: oProps.match ? oProps.match.params.nVersionId : oProps.nVersionId,
      aPendingReads: [],
      aConfirmedReads: []
    };
  }

  componentDidMount() {
    const { setAlert, history } = this.props;
    const { nVersionId } = this.state;
    const oLocalStorage = new EncodedLocalStorage();

    Axios.get(`/read/detail/${nVersionId}`)
      .then(oResponse => {
        const { aPendingReads, aConfirmedReads } = oResponse.data;

        Object.keys(aPendingReads).map(cKey => {
          const oUser = aPendingReads[cKey];
          if (oUser.user_nid == oLocalStorage.get('nUserId')) {
            history.push(`/document/detail/${nVersionId}`);
            return true;
          }
          return false;
        });

        this.setState({
          aPendingReads,
          aConfirmedReads
        });
      })
      .then(() => {
        this.setState({
          bLoading: false
        });
      })
      .catch(oError => {
        setAlert('error', oError.aMsgErrors);
        this.setState({
          bLoading: false
        });
      });
  }

  renderPendingReads() {
    const { aPendingReads } = this.state;
    const elTable = Object.keys(aPendingReads).map(cKey => {
      const oUser = aPendingReads[cKey];
      return (
        <li role="button" tabIndex="0" key={oUser.user_nid} className="responsibleRead-pendingRead">
          <h3>{oUser.user_cname}</h3>
        </li>
      );
    });
    return elTable;
  }

  renderConfirmedReads() {
    const { aConfirmedReads } = this.state;
    const oLocalStorage = new EncodedLocalStorage();
    const elTable = Object.keys(aConfirmedReads).map(cKey => {
      const oUser = aConfirmedReads[cKey];
      const nLastReading = oUser.version_readings.length - 1;
      return (
        <li
          role="button"
          tabIndex="0"
          key={oUser.version_readings[0].vnrd_nid}
          className="responsibleRead-finishedRead"
        >
          <h3>{oUser.user_cname}</h3>
          <p className="responsibleRead-finishedRead-vnrd_dcreated">
            {moment
              .parseZone(oUser.version_readings[nLastReading].vnrd_dcreated)
              .tz(oLocalStorage.get('cTimezone'))
              .format('DD/MM/YYYY  HH:mm:ss')}
          </p>
        </li>
      );
    });
    return elTable;
  }

  render() {
    const { aPendingReads, aConfirmedReads, bLoading } = this.state;

    return (
      <PageClean loading={bLoading ? 1 : 0}>
        {(aPendingReads.length > 0 || aConfirmedReads.length > 0) && (
          <div className="responsibleRead-info">
            {aPendingReads.length > 0 && (
              <ul className="responsibleRead-pending">
                <h2 className="responsibleRead-title">{intl.get('DocumentReading.pending_reads')}</h2>
                <>
                  <li className="thead no-pointer">
                    <b>{intl.get('user')}</b>
                  </li>
                  {this.renderPendingReads()}
                </>
              </ul>
            )}
            {aConfirmedReads.length > 0 && (
              <ul className="responsibleRead-read">
                <h2 className="responsibleRead-title">{intl.get('DocumentReading.confirmed_reads')}</h2>
                <>
                  <li className="thead no-pointer">
                    <b>{intl.get('user')}</b>
                    <b>{intl.get('DocumentReading.date')}</b>
                  </li>
                  {this.renderConfirmedReads()}
                </>
              </ul>
            )}
          </div>
        )}
      </PageClean>
    );
  }
}

export default ResponsibleReadModal;
