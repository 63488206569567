import React, { Component } from 'react';
import Select from 'react-select';
import intl from 'react-intl-universal';
import Page from '../default/Page';
import Axios from '../../config/Axios';
import { Alert } from '../../components/Alert';
import styles from './styles/evidences.scss';
import EncodedLocalStorage from '../../config/EncodedLocalStorage';
import { fnDecodeHTMLEntities } from '../../config/Formatter';

const oLocalStorage = new EncodedLocalStorage();
class LinkDocument extends Component {
  constructor(oProps) {
    super(oProps);

    this.state = {
      bLoading: true,
      // etipo: '',

      aDocuments: [],
      cProgress: 0,
      iLocalSend: false
    };
  }

  componentDidMount = () => {
    const { oRequisite } = this.props;
    const { aDocuments } = this.state;
    Axios.get(`document/pending/${oRequisite.req_nid}`).then(oResponse => {
      Object.keys(oResponse.data).forEach(cKey => {
        aDocuments.push({
          label: fnDecodeHTMLEntities(
            oResponse.data[cKey].cName),
          title: oResponse.data[cKey].cTitle,
          value: Number(oResponse.data[cKey].nId),
          bIsFolder: Boolean(oResponse.data[cKey].bIsFolder)
        });
      });

      this.setState({
        aDocuments,
        bLoading: false
      });
    });
  };

  handleChangeDoc = (elSelectedOption, type) => {
    this.setState({
      [type]: elSelectedOption.map(opt => ({
        value: opt.value,
        label: opt.label,
        title: opt.title,
        etype: opt.bIsFolder ? 'folder' : 'document'
      }))
    });
  };

  saveEvidence = evt => {
    evt.preventDefault();
    const { onSuccess, oRequisite } = this.props;
    const { aSelectedDocuments } = this.state;
    this.setState({
      cProgress: 0,
      iLocalSend: true
    });
    if (aSelectedDocuments?.length > 0) {
      aSelectedDocuments.forEach(aSelectedDocument => {
        Axios.post('evidence', {
          user_nid: oLocalStorage.get('nUserId'),
          norm_nid: oRequisite.norm_nid,
          req_nid: oRequisite.req_nid,
          evid_etype: aSelectedDocument.etype,
          evid_ntype: aSelectedDocument.value,
          evid_cdescription: null,
          evid_ctitle: aSelectedDocument.title
        })
          .then(() => {
            const { cProgress } = this.state;
            this.setState(oPrevState => ({
              cProgress: oPrevState.cProgress + 1
            }));

            if (cProgress + 1 === aSelectedDocuments.length) {
              setTimeout(() => {
                onSuccess(intl.get('LinkDocument.requisito_incluido_com_sucesso'));
              }, 2000);
            }
          })
          .catch(oError => {
            this.setState({
              rcmpAlertMsg: (
                <Alert type="error" isOpen onCloseAlert={() => { }}>
                  {oError.msgErrors}
                </Alert>
              )
            });
          });
      });
    } else {
      this.setState({
        iLocalSend: false,
        rcmpAlertMsg: (
          <Alert type="error" isOpen onCloseAlert={() => { }}>
            {intl.get("LinkDocument.selecionar_documento")}
          </Alert>
        )
      });
    }
  };

  render() {
    const { bLoading, rcmpAlertMsg, aDocuments, aSelectedDocuments, cProgress, iLocalSend } = this.state;
    const { closeModal } = this.props;

    const customSelectStyle = {
      menuList: (provided, state) => ({
        ...provided,
        minHeight: '120px',
        height: '80px'
      })
    };

    return (
      <Page loading={bLoading ? 1 : 0}>
        <form onSubmit={evt => this.saveEvidence(evt)} className="form">
          {rcmpAlertMsg}
          <p>
            <strong>{intl.get('LinkDocument.busque_e_selecione')}</strong>
          </p>
          <br />

          <label className="split1 colspan select-label" style={{ marginBottom: 90 }}>
            {intl.get('LinkDocument.documento')}
            <Select
              isDisabled={iLocalSend}
              onChange={elSelectedOption => this.handleChangeDoc(elSelectedOption, 'aSelectedDocuments')}
              className="selectCustom"
              classNamePrefix="react-select"
              isMulti
              maxMenuHeight={130}
              noOptionsMessage={() => {
                intl.get('LinkDocument.nao_encontrado');
              }}
              placeholder={intl.get('LinkDocument.documento')}
              styles={customSelectStyle}
              options={aDocuments}
            />
          </label>
          {aSelectedDocuments && aSelectedDocuments.length !== 0 && (
            <>
              <ul className="listSelectedDocuments">
                {aSelectedDocuments.map(aSelectedDocument => (
                  <li title={aSelectedDocument.label} key={aSelectedDocument.value}>
                    {aSelectedDocument.label}
                  </li>
                ))}
              </ul>
              <div
                className="progress-evidences"
                style={{
                  width: `${(100 / (aSelectedDocuments.length || 100)) * cProgress}%`
                }}
              >{`${(100 / (aSelectedDocuments.length || 100)) * cProgress == 100
                ? intl.get("LinkDocument.concluido")
                : ((100 / (aSelectedDocuments.length || 100)) * cProgress).toFixed(2) + '%'
                }`}</div>
            </>
          )}

          <div className="button-stack">
            <button type="button" onClick={() => closeModal()} className="btn alternate back btn btn-outline-secondary">
              {intl.get('cancelar')}
            </button>
            <button
              disabled={iLocalSend}
              type="button"
              className={`btn ${iLocalSend ? 'disabled' : ''}`}
              onClick={evt => this.saveEvidence(evt)}
              href="#/saveEvidence"
            >
              {intl.get('salvar')}
            </button>
          </div>
        </form>
      </Page>
    );
  }
}

export default LinkDocument;
