import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/pt-br';
import React, { Component } from 'react';
import Pagination from 'react-js-pagination';
import intl from 'react-intl-universal';
import pendingPerUser, { searchPendingPerUser, searchPendingPerUserStatus } from '../../actions/ActionPendingPerUser';
import Page from '../default-v2/Page';
import Breadcrumb from '../../components/Breadcrumbs';
import CardHeader from '../default-v2/CardHeader';
import Filter from '../default-v2/Filter';
import './styles/report.scss';
import './styles/v2-reports.scss';
import './styles/activityPending.scss';
import ReportTable from './ReportTable';
import reportsImg from '../../assets/img/reports.svg';
import EncodedLocalStorage from '../../config/EncodedLocalStorage';
import activitiesPending from '../../actions/ActionActivitiesPending';
import PaginationMenu from '../default-v2/PaginationMenu';

const DOCUMENT_STATUS = ['publishing', 'review', 'publishing'];
const oLocalStorage = new EncodedLocalStorage();
class PendingPerUser extends Component {
  constructor(oProps) {
    super(oProps);

    moment.locale(oLocalStorage.get('cLanguage'));

    this.state = {
      aPendingPerUser: [],
      bLoading: true,
      cSearch: '',
      cOrderBy: '',
      cOrderType: 'asc',
      aPages: [
        {
          cName: 'Nav.reports',
          cLink: undefined
        },
        {
          cName: 'PendingPerUser.breadcrumb_label',
          cLink: undefined
        }
      ],
      nCurrentPage: 1,
      nTotalPages: 0,
      nItemsPerPage: 50
    };
  }

  componentDidMount() {
    const { match } = this.props;

    this.fetchData(match.params.cPendingType);
  }

  fetchData = cDocumentStatus => {
    const { setAlert, clearAlert, match } = this.props;
    const { nCurrentPage, nItemsPerPage, cSearch, cOrderBy, cOrderType } = this.state;
    let aDocumentStatus = cDocumentStatus;
    if (cDocumentStatus === undefined) {
      aDocumentStatus = match.params.cPendingType;
    }

    this.setState({ bLoading: true });

    this.getPending(nCurrentPage, nItemsPerPage)
      .then(aPendingPerUser => {
        this.setState({
          bLoading: false,
          aPendingPerUser: aPendingPerUser.data,
          nTotalPages: aPendingPerUser.last_page
        });
      })
      .catch(oError => {
        this.setState({ bLoading: false, aPendingPerUser: [] });
        setAlert('error', oError.aMsgErrors);
      });
  };

  getPending = (nPage, nPerPage, orderBy, order, cSearch) => {
    return activitiesPending(nPage, nPerPage, orderBy, order, cSearch);
  };

  handlePageChange = nPageNumber => {
    this.setState({ nCurrentPage: nPageNumber }, this.fetchData);
  };

  handleItemsPerPageChange = eventPagination => {
    const nItemsPerPage = parseInt(eventPagination.target.value, 10);
    this.setState({ nItemsPerPage, nCurrentPage: 1 }, this.fetchData);
  };

  handleClickTable = (evt, cNewOrderBy, cNewOrderType) => {
    this.setState(
      {
        cOrderBy: cNewOrderBy,
        cOrderType: cNewOrderType
      },
      () => {
        this.handleFilter(evt);
      }
    );
  };

  getDate = oPendingActivityItem => {
    if (!oPendingActivityItem || !oPendingActivityItem.activity_ddeadline)
      return intl.get('ActivitiesPending.without_deadline');
    return (
      moment
        .parseZone(oPendingActivityItem.activity_ddeadline)
        .tz(oLocalStorage.get('cTimezone'))
        .format('DD/MM/YYYY') ?? intl.get('ActivitiesPending.without_deadline')
    );
  };

  renderPendingPerUser = () => {
    const { aPendingPerUser } = this.state;
    if (aPendingPerUser !== undefined && aPendingPerUser.length === 0) {
      return (
        <>
          <p className="empty-result">
            <strong> {intl.get('ActivitiesPending.empty_result')}</strong>
          </p>
        </>
      );
    }

    const rcmpPendingPerUser = aPendingPerUser
      ? Object.keys(aPendingPerUser).map(cKey => {
        const oInformation = aPendingPerUser[cKey];
        const compareDate = oPendingActivityItem => {
          if (!oPendingActivityItem || !oPendingActivityItem.activity_ddeadline) return false;
          // return true or false when the date is greater than today
          return moment.parseZone(oPendingActivityItem.activity_ddeadline).isBefore(moment());
        };

        const fnCreateMarkup = htmlString => ({ __html: htmlString });
        return (
          <tr key={cKey}>
            <td className="col-id">
              <span>
                <div
                  dangerouslySetInnerHTML={oInformation ? fnCreateMarkup(oInformation.activity_nid) : { __html: '' }}
                />
              </span>
            </td>
            <td className="col-deadline">
              {oInformation && (
                <div
                  className={compareDate(oInformation) ? 'expired' : ''}
                  dangerouslySetInnerHTML={fnCreateMarkup(this.getDate(oInformation))}
                />
              )}
            </td>
            <td title={oInformation ? oInformation.panel_cname : ''} className="col-pending-activity">
              <span>
                <div
                  dangerouslySetInnerHTML={oInformation ? fnCreateMarkup(oInformation.panel_cname) : { __html: '' }}
                />
              </span>
            </td>
            <td title={oInformation ? oInformation.board_cname : ''} className="col-pending-activity">
              <span>
                <div
                  dangerouslySetInnerHTML={oInformation ? fnCreateMarkup(oInformation.board_cname) : { __html: '' }}
                />
              </span>
            </td>
            <td title={oInformation ? oInformation.block_cname : ''} className="col-pending-activity">
              <span>
                <div
                  dangerouslySetInnerHTML={oInformation ? fnCreateMarkup(oInformation.block_cname) : { __html: '' }}
                />
              </span>
            </td>
          </tr>
        );
      })
      : [];

    return (
      <>
        <ReportTable
          aColumn={[
            { cName: 'ID', cOrderBy: 'activity_nid', fnHandleClick: this.handleClickTable },
            {
              cName: intl.get('ActivitiesPending.deadline'),
              cOrderBy: 'activity_ddeadline',
              fnHandleClick: this.handleClickTable
            },
            {
              cName: intl.get('ActivitiesPending.panel'),
              cOrderBy: 'panel_cname',
              fnHandleClick: this.handleClickTable
            },
            {
              cName: intl.get('ActivitiesPending.board'),
              cOrderBy: 'board_cname',
              fnHandleClick: this.handleClickTable
            },
            {
              cName: intl.get('ActivitiesPending.block'),
              cOrderBy: 'block_cname',
              fnHandleClick: this.handleClickTable
            }
          ]}
        >
          {rcmpPendingPerUser}
        </ReportTable>
      </>
    );
  };

  handleFilter = evt => {
    const { cSearch, cOrderBy, cOrderType, nCurrentPage, nItemsPerPage } = this.state;
    const { setAlert, clearAlert, match } = this.props;

    const aDocumentStatus = match?.params?.cPendingType ?? '';

    this.getPending(nCurrentPage, nItemsPerPage, cOrderBy, cOrderType, cSearch)
      .then(aPendingPerUser => {
        this.setState({
          aPendingPerUser: aPendingPerUser.data,
          nTotalPages: aPendingPerUser.last_page
        });
        clearAlert();
      })
      .catch(oError => {
        this.setState({ bLoading: false, aPendingPerUser: -1 });
        setAlert('error', oError.aMsgErrors);
      });
  };

  handleNewFilter = (evt, oFilter) => {
    this.setState(
      {
        cSearch: oFilter.cText,
        cOrderType: oFilter.cOrderType
      },
      () => {
        this.handleFilter(evt);
      }
    );
  };

  generateCardHeader = () => {
    const { history } = this.props;

    /* const rcmpFilter = <Filter fnOnType={this.handleNewFilter} />; */

    const rcmpCardHeader = <CardHeader history={history} /* rcmpFilter={rcmpFilter} */ />;
    return rcmpCardHeader;
  };

  render() {
    const { bLoading, aPages } = this.state;
    const { getAlert } = this.props;

    const rcmpBreadcrumb = <Breadcrumb aPages={aPages} />;

    const rcmpCardHeader = this.generateCardHeader();

    const cPageTitle = intl.get('ActivitiesPending.title');

    return (
      <Page
        loading={bLoading ? 1 : 0}
        rcmpBreadcrumb={rcmpBreadcrumb}
        cTitle={cPageTitle}
        cImage={reportsImg}
        rcmpCardHeader={rcmpCardHeader}
        className="v2-report"
        cCurrentSideMenuOption={intl.get('Nav.report')}
      >
        {getAlert()}
        {this.renderPendingPerUser()}
        <PaginationMenu
          nCurrentPage={this.state.nCurrentPage}
          nItemsPerPage={this.state.nItemsPerPage}
          nTotalPages={this.state.nTotalPages}
          handlePageChange={this.handlePageChange}
          handleItemsPerPageChange={this.handleItemsPerPageChange} />
      </Page>
    );
  }
}

export default PendingPerUser;
