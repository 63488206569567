import React, { Component } from 'react';
import intl from 'react-intl-universal';
import SweetAlert from 'react-bootstrap-sweetalert/lib/dist/SweetAlert';
import imgfLoading from '../../assets/img/preloader-black.gif';
import OutsideHandlerClick from '../../components/OutsideHandlerClick';
import Axios from '../../config/Axios';
import Breadcrumb from '../../components/Breadcrumbs';
import Page from '../default-v2/Page';
import Button from '../../components/Button';
import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/pt-br';
import Filter from '../default-v2/Filter';
import CardHeader from '../default-v2/CardHeader';
import './styles/trashbin.scss';
import imgIconEdit from './img/icon-edit.svg';
import imgIconComment from './img/icon-comment.svg';
import imgIconClick from './img/icon-click.svg';
import { can } from '../../config/Permissions';
import imgfFolderLarge from '../folder/img/folderindex-folder-large.svg';
import trashImg from '../../assets/img/trash.svg';
import ButtonBack from '../../components/ButtonBack';
import EncodedLocalStorage from '../../config/EncodedLocalStorage';
const oLocalStorage = new EncodedLocalStorage();

export class TrashBin extends Component {
  constructor(oProps) {
    super(oProps);
    moment.locale(oLocalStorage.get('cLanguage'));

    this.state = {
      bLoading: true,
      aVersions: [],
      aFolders: [],
      aPages: [{ cName: 'TrashBin.titulo', cLink: undefined }],
      cSearch: '',
      cOrderBy: '',
      cOrderType: '',
      bIsLoad: true,
      aPreviousIds: [],
      nFolderId: null,
      isSubmitting: false
    };
  }

  componentDidMount = () => {
    const { history, aPreviousIds } = this.props;
    const cModule = 'documents';

    if (!can(['admin'], cModule)) {
      history.push({
        pathname: '/',
        state: { aAlertMessages: { cType: 'error', cMsg: intl.get('sem_permissao') } }
      });
    }

    this.fetchTrash(null);
  };

  fetchTrash = nFolderId => {
    const { setAlert } = this.props;

    Axios.get('trashbin', {
      params: {
        nFolderId
      }
    })
      .then(oRes => {
        this.setState({
          bLoading: false,
          bIsLoad: false,
          aVersions: oRes.data.aVersions,
          aFolders: oRes.data.aFolders,
          nFolderId
        });
      })
      .catch(oError => {
        this.setState({ bLoading: false, bIsLoad: false });
        setAlert('error', oError.aMsgErrors);
      });
  };

  goBackFolder = evtClick => {
    evtClick.stopPropagation();
    const { aPreviousIds } = this.state;
    const nFolderId = aPreviousIds.pop();
    this.fetchTrash(nFolderId);
  };

  openSubmenu = evtClick => {
    evtClick.stopPropagation();
    if (evtClick.target.closest('div').getElementsByClassName('submenu')) {
      evtClick.target.closest('div').getElementsByClassName('submenu')[0].style = 'display: block';
    }
  };

  closeSubmenu = (evt, elWrapperRef) => {
    const elSubmenu = elWrapperRef.getElementsByClassName('submenu')[0];
    if (elSubmenu) {
      elSubmenu.style = 'display: none';
    }
  };

  showRestore = (evt, oVersion) => {
    if (oVersion.bIsDocActive) {
      let rcmpMessage = null;

      if (oVersion.document.folder.fldr_ddeleted === null) {
        rcmpMessage = (
          <p className="bold-text">
            {intl.getHTML('TrashBin.restaurar', {
              cFolderName: oVersion.document.folder.fldr_cname,
              cDocName: oVersion.document.doc_ctitle,
              cVersion: oVersion.ver_cnumber
            })}
          </p>
        );
      } else {
        rcmpMessage = intl.get('TrashBin.version_restore_message');
      }
      this.setState({
        rcmpAlert: (
          <SweetAlert
            showCancel
            confirmBtnText={intl.get('confirmar')}
            cancelBtnText={intl.get('cancel')}
            cancelBtnBsStyle="danger"
            title={intl.get('restaurar')}
            onConfirm={() => this.restoreDocument(oVersion.ver_nid)}
            confirmBtnBsStyle={this.state.isSubmitting ? 'default' : 'primary'}
            confirmBtnCssClass={this.state.isSubmitting ? 'disabled-btn-trashbin-confirm' : ''}
            onCancel={this.hideAlert}
          >
            {rcmpMessage}
          </SweetAlert>
        )
      });
    }
  };

  showDelete = (evt, oVersion) => {
    this.setState({
      rcmpAlert: (
        <SweetAlert
          showCancel
          confirmBtnText={intl.get('confirmar')}
          cancelBtnText={intl.get('cancel')}
          cancelBtnBsStyle="danger"
          title={intl.get('remover')}
          onConfirm={() => this.deleteDocument(oVersion.ver_nid)}
          onCancel={this.hideAlert}
        >
          {intl.get('TrashBin.remover', { cVersion: oVersion.ver_cnumber, cTitle: oVersion.document.doc_ctitle })}
        </SweetAlert>
      )
    });
  };

  restoreFolder = nFldrId => {
    Axios.put(`/trashbin/restore-folder/${nFldrId}`)
      .then(() => {
        const { nFolderId } = this.state;
        this.setState({
          rcmpAlert: (
            <SweetAlert
              success
              title={intl.get('restaurado')}
              onConfirm={() => {
                this.fetchTrash(nFolderId);
                this.hideAlert();
              }}
            >
              {intl.get('TrashBin.folder_restore_success')}
            </SweetAlert>
          )
        });
      })
      .catch(oError => {
        this.setState({
          rcmpAlert: (
            <SweetAlert error title="" onConfirm={this.hideAlert}>
              {oError.aMsgErrors}
            </SweetAlert>
          )
        });
      });
  };

  showFolderRestore = (evtClick, oFolder) => {
    evtClick.stopPropagation();
    if (oFolder.all_parent_folder.fldr_ddeleted !== null) {
      this.setState({
        rcmpAlert: (
          <SweetAlert
            showCancel
            confirmBtnText={intl.get('confirmar')}
            cancelBtnText={intl.get('cancel')}
            cancelBtnBsStyle="danger"
            title={intl.get('restaurar')}
            onConfirm={() => this.restoreFolder(oFolder.fldr_nid)}
            onCancel={this.hideAlert}
          >
            {intl.get('TrashBin.folder_restore_message')}
          </SweetAlert>
        )
      });
    } else {
      this.restoreFolder(oFolder.fldr_nid);
    }
  };

  deleteFolder = nFldrId => {
    this.setState({
      rcmpAlert: (
        <SweetAlert disabled showConfirm={false} title={intl.get('removendo')} onConfirm={() => { }}>
          <img alt={intl.get('carregando')} src={imgfLoading} />
        </SweetAlert>
      )
    });

    Axios.delete(`/trashbin/remove-folder/${nFldrId}`)
      .then(() => {
        const { nFolderId } = this.state;
        this.setState({
          rcmpAlert: (
            <SweetAlert
              success
              title={intl.get('removido')}
              onConfirm={() => {
                this.fetchTrash(nFolderId);
                this.hideAlert();
              }}
            >
              {intl.get('TrashBin.folder_remove_success')}
            </SweetAlert>
          )
        });
      })
      .catch(oError => {
        this.setState({
          rcmpAlert: (
            <SweetAlert error title="" onConfirm={this.hideAlert}>
              {oError.aMsgErrors}
            </SweetAlert>
          )
        });
      });
  };

  showFolderDelete = (evtClick, oFolder) => {
    evtClick.stopPropagation();
    this.setState({
      rcmpAlert: (
        <SweetAlert
          showCancel
          confirmBtnText={intl.get('confirmar')}
          cancelBtnText={intl.get('cancel')}
          cancelBtnBsStyle="danger"
          title={intl.get('remover')}
          onConfirm={() => this.deleteFolder(oFolder.fldr_nid)}
          onCancel={this.hideAlert}
        >
          {intl.get('TrashBin.folder_remove_message')}
        </SweetAlert>
      )
    });
  };

  hideAlert = () => {
    const { nFolderId } = this.state;
    this.setState({
      rcmpAlert: null
    });
    this.fetchTrash(nFolderId);
  };

  restoreDocument = nVersionId => {
    if (this.state.isSubmitting) return; // Bloquear dedo nervoso
    this.setState({ isSubmitting: true }, () => {
      Axios.put(`/trashbin/restore-version/${nVersionId}`)
        .then(() => {
          const { nFolderId } = this.state;
          this.setState({
            rcmpAlert: (
              <SweetAlert
                success
                title={intl.get('restaurado')}
                onConfirm={() => {
                  this.fetchTrash(nFolderId);
                  this.hideAlert();
                }}
              >
                {intl.get('TrashBin.restaurada')}
              </SweetAlert>
            ),
            isSubmitting: false
          });
        })
        .catch(oError => {
          this.setState({
            rcmpAlert: (
              <SweetAlert error title="" onConfirm={this.hideAlert}>
                {oError.aMsgErrors}
              </SweetAlert>
            ),
            isSubmitting: false
          });
        });
    });
  };

  deleteDocument = nVersionId => {
    this.setState({
      rcmpAlert: (
        <SweetAlert disabled showConfirm={false} title={intl.get('removendo')} onConfirm={() => { }}>
          <img alt={intl.get('carregando')} src={imgfLoading} />
        </SweetAlert>
      )
    });

    Axios.delete(`/trashbin/remove-version/${nVersionId}`)
      .then(() => {
        const { nFolderId } = this.state;
        this.setState({
          rcmpAlert: (
            <SweetAlert
              success
              title={intl.get('removido')}
              onConfirm={() => {
                this.fetchTrash(nFolderId);
                this.hideAlert();
              }}
            >
              {intl.get('TrashBin.removida')}
            </SweetAlert>
          )
        });
      })
      .catch(oError => {
        this.setState({
          rcmpAlert: (
            <SweetAlert error title="" onConfirm={this.hideAlert}>
              {oError.aMsgErrors}
            </SweetAlert>
          )
        });
      });
  };

  handleChange = evt => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  handleFilter = evt => {
    const { cSearch, cOrderBy, cOrderType, nFolderId } = this.state;

    this.setState({ bIsLoad: true });

    Axios.get('/trashbin', {
      params: {
        cSearch,
        cOrderBy,
        cOrderType,
        nFolderId
      }
    })
      .then(oResponse => {
        this.setState({
          bIsLoad: false,
          aVersions: oResponse.data.aVersions,
          aFolders: oResponse.data.aFolders
        });
      })
      .catch(oError => {
        this.setState({
          rcmpAlert: (
            <SweetAlert error title={intl.get('opa')} onConfirm={this.hideAlert}>
              {intl.get('falhou')}
            </SweetAlert>
          ),
          bIsLoad: false
        });
      });
  };

  getStatusIcon = cStatus => {
    switch (cStatus) {
      case 'in-review':
        return <img src={imgIconComment} alt={intl.get(`Status.em-revisao`)} title={intl.get(`Status.em-revisao`)} />;
      case 'awaiting-publish':
        return (
          <img
            src={imgIconClick}
            alt={intl.get(`Status.aguardando-publicacao`)}
            title={intl.get(`Status.aguardando-publicacao`)}
          />
        );
      case 'in-writing':
        return <img src={imgIconEdit} alt={intl.get(`Status.em-redacao`)} title={intl.get(`Status.em-redacao`)} />;
      default:
        return '';
    }
  };

  renderList = () => {
    const { bIsLoad, aVersions, aFolders } = this.state;

    const rcmpDocVersion = Object.keys(aVersions).map(cKey => {
      const slCmpElement = aVersions[cKey];

      return (
        <li key={slCmpElement.ver_nid} className="doc-items">
          <div className="doc-status">
            {slCmpElement.status_pre_removal ? this.getStatusIcon(slCmpElement.status_pre_removal.stat_cslugname) : this.getStatusIcon('in-writing')}
          </div>

          <div className="block-right-menu">
            <span
              role="button"
              tabIndex="0"
              onKeyDown={this.handleKeyDown}
              onClick={evt => this.openSubmenu(evt)}
              title=""
              className="btn-menu"
            >
              <span />
            </span>
            <OutsideHandlerClick handleClickOutside={this.closeSubmenu}>
              <ul className="submenu">
                <li>
                  <span
                    role="button"
                    tabIndex="0"
                    onKeyDown={this.handleKeyDown}
                    onClick={evt => this.showRestore(evt, slCmpElement)}
                    title={intl.get('TrashBin.clique_restaurar')}
                  >
                    {intl.get('restaurar')}
                  </span>
                </li>

                <li>
                  <span
                    role="button"
                    tabIndex="0"
                    onKeyDown={this.handleKeyDown}
                    onClick={evt => this.showDelete(evt, slCmpElement)}
                    title={intl.get('TrashBin.clique_excluir')}
                  >
                    {intl.get('remover')}
                  </span>
                </li>
              </ul>
            </OutsideHandlerClick>
          </div>

          <div className="doc-info">
            <p title={slCmpElement.document.doc_ctitle}>{slCmpElement.document.doc_ctitle}</p>
            <p>
              {intl.get('TrashBin.removed_in')}
              {moment
                .parseZone(slCmpElement.ver_dtrashbin)
                .tz(oLocalStorage.get('cTimezone'))
                .format('LL')}
            </p>
          </div>
        </li>
      );
    });

    const rcmpFolder = Object.keys(aFolders).map(cKey => {
      const slCmpElement = aFolders[cKey];

      return (
        <li
          key={slCmpElement.fldr_nid}
          className="folder-items"
          onClick={evtClick => {
            evtClick.stopPropagation();
            const { aPreviousIds, nFolderId } = this.state;
            this.setState({
              bIsLoad: true,
              aPreviousIds: [...aPreviousIds, nFolderId]
            });
            this.fetchTrash(slCmpElement.fldr_nid);
          }}
        >
          <div className="folder-status">
            <img className="folderindex-grid-folderIconImage" src={imgfFolderLarge} alt={slCmpElement.fldr_cname} />
          </div>

          <div className="block-right-menu">
            <span
              role="button"
              tabIndex="0"
              // onKeyDown={this.handleKeyDown}
              onClick={evt => this.openSubmenu(evt)}
              title=""
              className="btn-menu"
            >
              <span />
            </span>
            <OutsideHandlerClick handleClickOutside={this.closeSubmenu}>
              <ul className="submenu">
                <li>
                  <span
                    role="button"
                    tabIndex="0"
                    // onKeyDown={this.handleKeyDown}
                    onClick={evt => this.showFolderRestore(evt, slCmpElement)}
                    title={intl.get('TrashBin.clique_restaurar')}
                  >
                    {intl.get('restaurar')}
                  </span>
                </li>

                <li>
                  <span
                    role="button"
                    tabIndex="0"
                    // onKeyDown={this.handleKeyDown}
                    onClick={evt => this.showFolderDelete(evt, slCmpElement)}
                    title={intl.get('TrashBin.clique_excluir')}
                  >
                    {intl.get('remover')}
                  </span>
                </li>
              </ul>
            </OutsideHandlerClick>
          </div>

          <div className="folder-info">
            <p title={slCmpElement.fldr_cname}>{slCmpElement.fldr_cname}</p>
            <p>
              {intl.get('TrashBin.removed_in')}
              {moment
                .parseZone(slCmpElement.fldr_ddeleted)
                .tz(oLocalStorage.get('cTimezone'))
                .format('LL')}
            </p>
          </div>
        </li>
      );
    });

    return (
      <>
        {!bIsLoad ? (
          <>
            {rcmpDocVersion.length === 0 && rcmpFolder.length === 0 ? (
              <p className="empty-folder">
                <strong>{intl.get('TrashBin.vazio')}</strong>
                {intl.get('TrashBin.vazio_mensagem')}
              </p>
            ) : (
              <ul className="trash-bin">
                {rcmpDocVersion}
                {rcmpFolder}
              </ul>
            )}
          </>
        ) : (
          <div className="centered-text">
            {' '}
            <img alt="Carregando" src={imgfLoading} /> <br /> {intl.get('carregando')}
          </div>
        )}
      </>
    );
  };

  handleNewFilter = (evt, oFilter) => {
    this.setState(
      {
        cSearch: oFilter.cText,
        cOrderBy: oFilter.cOrderBy,
        cOrderType: oFilter.cOrderType
      },
      () => {
        this.handleFilter(evt);
      }
    );
  };

  deleteAll = () => {
    const { nFolderId } = this.state;

    this.setState({
      rcmpAlert: (
        <SweetAlert disabled showConfirm={false} title={intl.get('removendo')} onConfirm={() => { }}>
          <img alt={intl.get('carregando')} src={imgfLoading} />
        </SweetAlert>
      )
    });

    Axios.delete(`/trashbin/empty`)
      .then(() => {
        this.setState({
          rcmpAlert: (
            <SweetAlert
              success
              title={intl.get('TrashBin.empty_trashbin')}
              onConfirm={() => {
                this.fetchTrash(nFolderId);
                this.hideAlert();
              }}
            >
              {intl.get('TrashBin.empty_trashbin_success')}
            </SweetAlert>
          )
        });
      })
      .catch(oError => {
        this.setState({
          rcmpAlert: (
            <SweetAlert error title="" onConfirm={this.hideAlert}>
              {oError.aMsgErrors}
            </SweetAlert>
          )
        });
      });
  };

  handleEmptyTrashBin = evt => {
    this.setState({
      rcmpAlert: (
        <SweetAlert
          showCancel
          confirmBtnText={intl.get('confirmar')}
          cancelBtnText={intl.get('cancelar')}
          cancelBtnBsStyle="danger"
          title={intl.get('TrashBin.empty_trashbin')}
          onConfirm={() => this.deleteAll()}
          onCancel={this.hideAlert}
        >
          {intl.get('TrashBin.empty_trahbin_message')}
        </SweetAlert>
      )
    });
  };

  generateCardHeader = () => {
    const aButtons = [];
    const { nFolderId } = this.state;

    const rcmpBtnNewDocument = (
      <Button
        key="button-new-document"
        // className="btn-empty-trashbin"
        color="primary"
        onClick={this.handleEmptyTrashBin}
      >
        {intl.get('TrashBin.empty_trashbin')}
      </Button>
    );

    aButtons.push(rcmpBtnNewDocument);

    let aOrderItems = [];
    aOrderItems = [{ value: 'cName', label: intl.get('name') }];
    let cDefaultOrder = aOrderItems[0].value;

    const rcmpFilter = (
      <Filter
        aButtons={aButtons}
        aOrderItems={aOrderItems}
        cDefaultOrder={cDefaultOrder}
        fnOnType={this.handleNewFilter}
        flex={true}
      />
    );

    const rcmpCardHeader = (
      <CardHeader bIsItToInsertGoBackBtn={nFolderId !== null} fnGoBack={this.goBackFolder} rcmpFilter={rcmpFilter} />
    );

    return rcmpCardHeader;
  };

  render() {
    const { aPages, bLoading, rcmpAlert } = this.state;
    const { getAlert } = this.props;

    const rcmpBreadcrumb = <Breadcrumb aPages={aPages} />;

    const rcmpCardHeader = this.generateCardHeader();

    const cPageTitle = intl.get('TrashBin.titulo');

    return (
      <Page
        loading={bLoading ? 1 : 0}
        rcmpBreadcrumb={rcmpBreadcrumb}
        cTitle={cPageTitle}
        cImage={trashImg}
        rcmpCardHeader={rcmpCardHeader}
        className="v2-trash-bin"
        cCurrentSideMenuOption={intl.get('Nav.trash')}
      >
        {rcmpAlert}
        {getAlert()}

        {this.renderList()}
      </Page>
    );
  }
}

export default TrashBin;
