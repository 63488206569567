export function formatCurrency(nValue) {
  const oFormatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2
  });
  return oFormatter.format(nValue);
}

export function fnDecodeHTMLEntities(cText) {
  if (!cText) return '';
  
  const elTextArea = document.createElement('textarea');
  elTextArea.innerHTML = cText;
  return elTextArea.value;
}
