import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert/lib/dist/SweetAlert';
import intl from 'react-intl-universal';
import ErrorIcon from '@material-ui/icons/Error';
import { Alert } from '../../components/Alert';
import Axios from '../../config/Axios';
import Page from '../default/Page';
import Document from '../document/Document';
import AddComment from '../evidence/AddComment';
import LinkDocument from '../evidence/LinkDocument';
import UploadFiles from '../evidence/UploadFiles';
import CaptureMedia from '../evidence/CaptureMedia';
import HistoryRequisite from './HistoryRequisite';
import { can, checkModule } from '../../config/Permissions';
import AltCheckbox from '../../components/AltCheckbox';
import ScrollBar from '../../components/ScrollBar';
import { createAndRedirect } from '../../actions/ActionDocumentEditor';
import './styles/requisites.scss';
import './styles/requisiteshow.scss';
import imgfModuleOperational from '../default-v2/img/new-sidebar/icon-module-operational-panel.svg';
import decodeHTMLEntities from '../../utils/decodeHTMLEntities';
import FileIcon from '../../components/FileIcon';
import Typography from '@material-ui/core/Typography';

const cEvidenceTypeBoard = 'operational_panel_board';
const cUnlinkBoardDescription = 'Desvinculação de quadro';

export function base64MimeType(encoded) {
  let result = null;

  if (typeof encoded !== 'string') {
    return result;
  }

  const mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);

  if (mime && mime.length) {
    result = mime[1];
  }

  return result;
}

class RequisiteShow extends Component {
  constructor(oProps) {
    super(oProps);
    const { nRequisiteId, nNormId } = this.props;

    this.state = {
      nRequisiteId,
      nNormId,
      oRequisite: '',
      oNorm: '',
      bLoading: true,
      aEvidences: [],
      showHistory: true,
      bLoadEvidences: false,
      cLoadMessage: intl.get('carregando')
    };
  }

  componentDidMount = async () => {
    const { nRequisiteId, nNormId } = this.state;
    const { history } = this.props;
    checkModule(history, 'quality-panel');
    await Promise.all([this.getRequisite(nRequisiteId), this.getNorm(nNormId), this.getEvidences()]);
    this.setState({ bLoading: false });
  };

  onClick = (evt, cIndex1, cIndex2) => {
    const { oRequisite } = this.state;
    oRequisite.requisite_section[cIndex1].item_checklist[cIndex2].ichk_bisselected = evt.target.checked;
    this.setState({ oRequisite });
    Axios.post(`requisite/itemchecklist/${oRequisite.requisite_section[cIndex1].item_checklist[cIndex2].ichk_nid}`, {
      bSelected: evt.target.checked
    }).then(() => { });
  };

  getRequisite = nRequisiteId =>
    Axios.get(`requisite/${nRequisiteId}`)
      .then(oResponse => {
        this.setState({ oRequisite: oResponse.data });
      })
      .catch(() => { });

  getNorm = nNormId =>
    Axios.get(`norm/${nNormId}`)
      .then(oResponse => {
        this.setState({ oNorm: oResponse.data });
      })
      .catch(() => { });

  filterOldInvalidVersions = aEvidences => {
    const versionFilter = {};
    const filteredEvidences = [];
    aEvidences.forEach(evidence => {
      if (
        evidence?.version?.doc_nid !== undefined &&
        evidence.evid_etype === 'document' &&
        !evidence.evid_cattachment
      ) {
        if (evidence.version.doc_nid in versionFilter) {
          const evidenceDate = new Date(evidence.version.ver_dcreated);
          const sentinelDate = new Date(versionFilter[evidence.version.doc_nid].version.ver_dcreated);
          if (evidenceDate > sentinelDate) {
            versionFilter[evidence.version.doc_nid] = evidence;
          }
        } else {
          versionFilter[evidence?.version?.doc_nid] = evidence;
        }
      } else {
        filteredEvidences.push(evidence);
      }
    });
    Object.keys(versionFilter).forEach(key => filteredEvidences.push(versionFilter[key]));
    return filteredEvidences;
  };

  getEvidences = () => {
    const { nRequisiteId } = this.state;
    this.setState({
      bLoadEvidences: true
    });
    return Axios.get('evidence', {
      params: { nRequisiteId }
    })
      .then(oResponse => {
        this.setState({
          aEvidences: this.filterOldInvalidVersions(oResponse.data),
          bLoadEvidences: false
        });
      })
      .catch(oError => {
        if (oError.response && oError.response.status === 422) {
          if (oError.response.data && oError.response.data === 'Evidências não encontrada.') {
            // Caso especial, não mostrar alerta
            this.setState({
              bLoadEvidences: false
            });
          } else {
            // Outros casos de erro 422
            this.setState({
              rcmpAlertMsg: (
                <Alert type="error" isOpen onCloseAlert={() => { }}>
                  {oError.response.data}
                </Alert>
              )
            });
          }
        } else {
          // Caso contrário, trate como um erro
          this.setState({
            rcmpAlertMsg: (
              <Alert type="error" isOpen onCloseAlert={() => { }}>
                {oError.msgErrors}
              </Alert>
            )
          });
        }
      });
  };

  handleChange = evt => {
    this.setState({ [evt.target.name]: evt.target.type === 'checkbox' ? evt.target.checked : evt.target.value });
  };

  hideAlert = () => {
    this.setState({
      rcmpAlert: null
    });
  };

  openEvidencesAdd = () => {
    this.optionMore
      .closest('div')
      .getElementsByClassName('evidencesAdd')[0]
      .classList.toggle('open');
    this.optionMore.classList.toggle('open');
  };

  successMessage = cMsg => {
    this.hideAlert();
    this.componentDidMount();
    this.setAlert('success', cMsg);
  };

  setAlert = (cType, cMsg) => {
    this.setState({
      rcmpAlertMsg: (
        <Alert type={cType} isOpen onCloseAlert={() => { }}>
          {cMsg}
        </Alert>
      )
    });
  };

  openModalNewEvidence = cType => {
    const { oRequisite, aEvidences } = this.state;
    if (!oRequisite) {
      return;
    }

    if (cType === 'newdoc') {
      this.setState({
        rcmpAlert: (
          <SweetAlert
            title={intl.get('new_document')}
            onConfirm={createAndRedirect('requisite', oRequisite.req_nid).catch(oError => {
              this.setState({
                rcmpAlert: (
                  <SweetAlert
                    warning
                    showCancel
                    customClass="modal-edit modalMedium scrollBar"
                    cancelBtnText={intl.get('close')}
                    cancelBtnBsStyle="danger"
                    onCancel={this.hideAlert}
                    title={intl.get('create_document_error')}
                    showConfirm={false}
                  >
                    {oError.response.data}
                  </SweetAlert>
                )
              });
            })}
            showConfirm
          >
            {intl.get('RequisiteShow.continue_new_document')}
          </SweetAlert>
        )
      });
    } else {
      this.setState({
        rcmpAlert: (
          <SweetAlert
            customClass={`modal-edit lg ScrollBar disabled-overflow`}
            title=""
            onConfirm={this.hideAlert}
            showConfirm={false}
          >
            <div className="head-modal">
              {cType === 'linkdocument' && intl.get('vincular_documento')}
              {cType === 'comment' && intl.get('novo_comentario')}
              {cType === 'comment-free' && intl.get('novo_comentario')}
              {(cType === 'upload' || cType === 'media') && intl.get('RequisiteShow.anexar_arquivos')}

              <span
                role="button"
                tabIndex="0"
                aria-labelledby="head-modal"
                onKeyPress={this.hideAlert}
                onClick={this.hideAlert}
                className="close"
                href=""
              />
            </div>
            {cType === 'upload' && (
              <UploadFiles
                oRequisite={oRequisite}
                onSuccess={cMsg => {
                  this.successMessage(cMsg);
                }}
                closeModal={this.hideAlert}
              />
            )}
            {cType === 'media' && (
              <CaptureMedia
                oRequisite={oRequisite}
                aEvidences={aEvidences}
                onSuccess={cMsg => {
                  this.successMessage(cMsg);
                }}
                closeModal={this.hideAlert}
              />
            )}

            {cType === 'linkdocument' && (
              <ScrollBar>
                <LinkDocument
                  oRequisite={oRequisite}
                  onSuccess={cMsg => {
                    this.successMessage(cMsg);
                  }}
                  closeModal={this.hideAlert}
                />
              </ScrollBar>
            )}

            {cType === 'comment' && (
              <AddComment
                oRequisite={oRequisite}
                onSuccess={cMsg => {
                  this.successMessage(cMsg);
                }}
                closeModal={this.hideAlert}
              />
            )}

            {cType === 'comment-free' && (
              <AddComment
                cTypeComment='X'
                oRequisite={oRequisite}
                onSuccess={cMsg => {
                  this.successMessage(cMsg);
                }}
                closeModal={this.hideAlert}
              />
            )}
          </SweetAlert>
        )
      });
    }
  };

  newEvidence = (evt, cType) => {
    evt.preventDefault();
    this.openModalNewEvidence(cType);
  };

  showRemoveEvidenceAlert = oEvidence => {
    this.setState({
      rcmpAlert: (
        <SweetAlert
          closeOnClickOutside={false}
          showCancel
          confirmBtnText={intl.get('confirmar')}
          cancelBtnText={intl.get('cancelar')}
          cancelBtnBsStyle="danger"
          title=""
          onConfirm={() => {
            this.removeEvidence(oEvidence);
            this.hideAlert();
          }}
          onCancel={this.hideAlert}
        >
          <p className="scrollable-remove-alert">
            {intl.get('mensagem_excluir', {
              tipo: intl.get('RequisiteShow.a_evidencia'),
              nome: oEvidence.evid_ctitle
                ? oEvidence.evid_ctitle?.length >= 20
                  ? `${oEvidence.evid_ctitle.substring(0, 20)}...`
                  : oEvidence.evid_ctitle
                : oEvidence.evid_cdescription?.replace(/&nbsp;/g, ' ')?.replace(/(<([^>]+)>)/gi, '') ?? ''
            })}
          </p>
        </SweetAlert>
      )
    });
  };

  removeEvidence = oEvidence => {
    Axios.delete(`evidence/${oEvidence.evid_nid}`)
      .then(() => {
        this.setState(oPrevState => ({
          aEvidences: oPrevState.aEvidences.filter(
            oFilteringEvidence => oFilteringEvidence.evid_nid !== oEvidence.evid_nid
          )
        }));
        this.componentDidMount();
      })
      .catch(oError => {
        this.setState({
          rcmpAlertMsg: (
            <Alert type="error" isOpen onCloseAlert={() => { }}>
              {oError.msgErrors}
            </Alert>
          )
        });
      });
  };

  editEvidence = (oEvidence, oUser) => {
    const { oRequisite } = this.state;

    if (oEvidence.evid_etype === 'comment') {
      this.setState({
        rcmpAlert: (
          <SweetAlert customClass="modal-edit lg" title="" onConfirm={this.hideAlert} showConfirm={false}>
            <div className="head-modal">
              {intl.get('RequisiteShow.edit_comment')}
              <span
                role="button"
                tabIndex="0"
                aria-labelledby="head-modal"
                onKeyPress={this.hideAlert}
                onClick={this.hideAlert}
                className="close"
                href=""
              />
            </div>
            <AddComment
              oEvidence={oEvidence}
              oRequisite={oRequisite}
              onSuccess={cMsg => {
                this.successMessage(cMsg);
              }}
              closeModal={this.hideAlert}
            />
          </SweetAlert>
        )
      });
    }
    if (oEvidence.evid_etype === 'comment-free') {
      this.setState({
        rcmpAlert: (
          <SweetAlert customClass="modal-edit lg" title="" onConfirm={this.hideAlert} showConfirm={false}>
            <div className="head-modal">
              {intl.get('RequisiteShow.edit_comment')}
              <span
                role="button"
                tabIndex="0"
                aria-labelledby="head-modal"
                onKeyPress={this.hideAlert}
                onClick={this.hideAlert}
                className="close"
                href=""
              />
            </div>
            <AddComment
              cTypeComment='X'
              oEvidence={oEvidence}
              oRequisite={oRequisite}
              onSuccess={cMsg => {
                this.successMessage(cMsg);
              }}
              closeModal={this.hideAlert}
            />
          </SweetAlert>
        )
      });
    }
  };

  save = (e, cFileName, cUrlFile) => {
    e.preventDefault();
    this.setState({
      bLoadEvidences: true,
      cLoadMessage: `AGUARDE DOWNLOAD - ${cFileName}`
    });

    fetch(cUrlFile)
      .then(response => response.blob())
      .then(blob => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = cFileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }).catch((oError) => {
        console.error('Error registration:', oError);
      }).finally(() => {
        this.setState({ bLoadEvidences: false, cLoadMessage: intl.get('carregando') });
      });
  };

  openDocument = oEvidence => {
    if (oEvidence?.evid_etype === 'operational_panel_board') {
      // redirect to operational panel board
      const cBoardUrl = `/dashboard/${oEvidence?.operational_panel_board?.operational_panel_id}/boards/${oEvidence?.operational_panel_board?.id}`;
      window.open(cBoardUrl, 'document', 'noopener');
      return;
    }
    if (oEvidence?.evid_etype === 'folder') {
      window.open(`/folder/${oEvidence.version.ver_nid}`, 'folder', 'noopener', 'noreferrer');
    }

    if (oEvidence?.version && oEvidence?.version?.document?.stat_nid !== 21) {
      window.open(`/document/detail/${oEvidence.version.ver_nid}`, 'document', 'noopener');
    } else {
      this.setState({
        rcmpAlertMsg: (
          <Alert type="error" isOpen onCloseAlert={() => { }}>
            {intl.get('RequisiteShow.documento_arquivado')}
          </Alert>
        )
      });
    }
  };

  isExpiredDocument = oDocumentEvidence => {
    return (
      oDocumentEvidence.version?.stat_nid === 4 &&
      oDocumentEvidence.version?.dtReviewDate &&
      new Date() > new Date(oDocumentEvidence.version?.dtReviewDate)
    );
  };

  isArchivedDocument = oDocumentEvidence => {
    return oDocumentEvidence.version?.document?.stat_nid === 21;
  };

  doesDocumentHaveProblem = oDocumentEvidence => {
    if (this.isArchivedDocument(oDocumentEvidence)) {
      return true;
    }

    if (this.isExpiredDocument(oDocumentEvidence)) {
      return true;
    }

    return false;
  };

  render() {
    const { oRequisite, oNorm, bLoading, aEvidences, rcmpAlert, rcmpAlertMsg, showHistory, bLoadEvidences, cLoadMessage } = this.state;

    return (
      <Page loading={bLoading ? 1 : 0}>
        <div className="form scrollable-form">
          {rcmpAlert}
          {rcmpAlertMsg}
          {oRequisite && (
            <>
              <div id="editor-view" dangerouslySetInnerHTML={{ __html: oRequisite.req_cdescription }} />
              {oRequisite.req_etitletype !== 'T' && (
                <label className="split1">
                  <div className="optionEvidence">
                    {intl.get('RequisiteShow.evidencias_do_requisito')}
                    {can('create-edit-norm-qualitypanel', 'quality-panel') && (
                      <>
                        {Boolean(oNorm.norm_bisactive) && (
                          <div className="evidencesAdd open">
                            <a
                              onClick={evt => this.newEvidence(evt, 'linkdocument')}
                              href="#add-doc"
                              title={intl.get('vincular_documento')}
                            >
                              <i className="icon icon-file-empty" />
                            </a>
                            <a
                              onClick={evt => this.newEvidence(evt, 'upload')}
                              href="#add-attach"
                              title={intl.get('RequisiteShow.adicionar_anexo')}
                            >
                              <i className="icon icon-attachment" />
                            </a>
                            <a
                              onClick={evt => this.newEvidence(evt, 'media')}
                              href="#add-pic-vid"
                              title={intl.get('RequisiteShow.adicionar_foto_video')}
                            >
                              <i className="icon icon-camera" />
                            </a>
                            <a
                              onClick={evt => this.newEvidence(evt, 'comment')}
                              href="#add-comment"
                              title={intl.get('novo_comentario')}
                            >
                              <i className="icon icon-bubble" />
                            </a>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </label>
              )}

              <div id="evidenceBox">
                {bLoadEvidences ? (
                  <>{cLoadMessage}</>
                ) : (
                  <>
                    {Object.keys(aEvidences).map(cKey => {
                      if (
                        aEvidences[cKey].evid_cdescription === cUnlinkBoardDescription ||
                        (aEvidences[cKey].evid_etype === 'comment' && aEvidences[cKey].evid_ctitle === 'comentario_livre')
                      ) {
                        return null; // Não renderiza o elemento
                      }

                      if (aEvidences[cKey].evid_cdescription === cUnlinkBoardDescription) return;
                      if (
                        !aEvidences[cKey].evid_cattachment &&
                        aEvidences[cKey].evid_etype === 'document' &&
                        aEvidences[cKey].version?.stat_nid === 8 &&
                        aEvidences[cKey].version?.document?.stat_nid !== 21
                      ) {
                        return;
                      }
                      if (aEvidences[cKey] && aEvidences[cKey]?.evid_cdescription !== cUnlinkBoardDescription) {
                        return (
                          <span
                            sx={{ borderRadius: '16px' }}
                            onClick={e => {
                              e.stopPropagation();
                              aEvidences[cKey].evid_etype === 'comment'
                                ? this.editEvidence(aEvidences[cKey], aEvidences[cKey].user)
                                : aEvidences[cKey].evid_cattachment
                                  ? this.save(e, aEvidences[cKey].evid_ctitle, aEvidences[cKey].evid_cattachment)
                                  : this.openDocument(aEvidences[cKey]);
                            }}
                            role="button"
                            tabIndex="0"
                            key={aEvidences[cKey].evid_nid}
                            className="evidence"
                            title={
                              aEvidences[cKey]?.evid_etype === cEvidenceTypeBoard
                                ? aEvidences[cKey]?.operational_panel_board?.name
                                : aEvidences[cKey].evid_ctitle
                                  ? aEvidences[cKey].evid_ctitle
                                  : aEvidences[cKey].evid_cdescription
                                    ?.replace(/&nbsp;/g, ' ')
                                    ?.replace(/(<([^>]+)>)/gi, '') ?? ''
                            }
                          >
                            {aEvidences[cKey]?.evid_etype !== cEvidenceTypeBoard && Boolean(oNorm.norm_bisactive) && (
                              <i
                                onClick={e => {
                                  e.stopPropagation();
                                  this.showRemoveEvidenceAlert(aEvidences[cKey]);
                                }}
                                className="icon icon-bin"
                              />
                            )}
                            {aEvidences[cKey].evid_cattachment ? (
                              <FileIcon cExtension={aEvidences[cKey]?.evid_cmime_type} />
                            ) : aEvidences[cKey]?.evid_etype === cEvidenceTypeBoard ? (
                              <img src={imgfModuleOperational} />
                            ) : aEvidences[cKey].evid_ntype ? (aEvidences[cKey].evid_etype === 'document' ? (
                              <>
                                <FileIcon cExtension={'other'} />
                                {this.doesDocumentHaveProblem(aEvidences[cKey]) && (
                                  <span
                                    title={
                                      this.isArchivedDocument(aEvidences[cKey])
                                        ? intl.get('RequisiteShow.documento_arquivado')
                                        : intl.get('RequisiteShow.documento_expirado')
                                    }
                                  >
                                    <ErrorIcon className="icon icon-error" />
                                  </span>
                                )}
                              </>
                            ) : aEvidences[cKey].evid_etype === 'folder' && (
                              <FileIcon cExtension={'folder'} />
                            )) : (
                              <i className="icon icon-bubbles" />
                            )}
                            <Typography
                              className={
                                `evidTitle ${aEvidences[cKey]?.evid_etype === cEvidenceTypeBoard ? 'no-margin' : ''}`
                              }
                              variant="subtitle2"
                            >
                              {aEvidences[cKey]?.evid_etype === cEvidenceTypeBoard
                                ? aEvidences[cKey]?.operational_panel_board?.name?.length >= 18
                                  ? `${aEvidences[cKey]?.operational_panel_board?.name.substring(0, 18)}...`
                                  : aEvidences[cKey]?.operational_panel_board?.name
                                : aEvidences[cKey].evid_ctitle
                                  ? aEvidences[cKey].evid_ctitle.length >= 18
                                    ? `${aEvidences[cKey].evid_ctitle.substring(0, 18)}...`
                                    : aEvidences[cKey].evid_ctitle
                                  : aEvidences[cKey].evid_cdescription
                                    ?.replace(/&nbsp;/g, ' ')
                                    ?.replace(/(<([^>]+)>)/gi, '') ?? ''}
                            </Typography>
                          </span>
                        );
                      }
                    })}
                  </>
                )}
              </div>

              <AltCheckbox
                title={intl.get('RequisiteShow.exibir_historico')}
                label={intl.get('RequisiteShow.exibir_historico')}
                localeLabel="start"
                className="split1 no-width-label"
                name="showHistory"
                onChange={e => this.handleChange(e)}
                checked={showHistory}
              />
              <div className="icon-radius">
                <a
                  onClick={evt => this.newEvidence(evt, 'comment-free')}
                  href="#add-comment"
                  title={intl.get('novo_comentario')}
                >
                  <i className="icon icon-speak" />
                </a>
              </div>
              {showHistory === true && <HistoryRequisite historyReq={oRequisite.requisite_history} />}
            </>
          )}
        </div>
      </Page>
    );
  }
}

export default RequisiteShow;
