/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { oLocalStorage } from '../../config/EncodedLocalStorage';
import { can } from '../../config/Permissions';

export class Balloons extends Component {
  constructor(oProps) {
    super(oProps);
    this.state = {
      bActive: oLocalStorage.get('bActive'),
      bPaymentFailed: oLocalStorage.get('bPaymentFailed'),
      bPaymentPending: oLocalStorage.get('bPaymentPending'),
      bReceivesPaymentAlert: oLocalStorage.get('bReceivesPaymentAlert'),
      dDaysRemaining: oLocalStorage.get('dDaysRemaining'),
      bHideReadOnly: false,
      rcmpAlert: null
    };
  }

  onClosed = cType => {
    this.setState({
      [cType]: cType === 'bActive' ? false : true
    });
  };

  renderSuspendAccount = () => {
    const { bHideReadOnly } = this.state;

    if (bHideReadOnly) {
      return null;
    }

    return (
      <li className="bubble-alert alert">
        <span
          onKeyPress={() => this.setState({ bHideReadOnly: true })}
          onClick={() => this.setState({ bHideReadOnly: true })}
          title={intl.get('Balloons.clique_fechar')}
          className="close"
        />
        <p>
          <strong>{intl.get('Balloons.somente_leitura')}</strong>
          {intl.get('Balloons.suspenso_somente_leitura')}{' '}
          {oLocalStorage.get('bAdmin') !== false && (
            <a href="/settings/extract">{intl.get('Balloons.ver_extrato')}</a>
          )}
        </p>
      </li>
    );
  };

  renderTrialMessage() {
    return (
      <li className="bubble-alert info">
        <span
          onKeyPress={() => this.setState({ bActive: true })}
          onClick={() => this.setState({ bActive: true })}
          title=" Clique para fechar"
          className="close"
        />
        <p>
          <strong>{intl.get('Balloons.trial_titulo1')}</strong>
        </p>
      </li>
    );
  }

  renderPaymentFailedMessage() {
    return (
      <li className="bubble-alert info">
        <span
          onKeyPress={() => this.setState({ bPaymentFailed: false })}
          onClick={() => this.setState({ bPaymentFailed: false })}
          title=" Clique para fechar"
          className="close"
        />
        <p>
          <strong>{intl.get('Balloons.payment_failed_message')}</strong>
        </p>
      </li>
    );
  }

  renderPaymentPendingMessage() {
    return (
      <li className="bubble-alert info">
        <span
          onKeyPress={() => this.setState({ bPaymentPending: false })}
          onClick={() => this.setState({ bPaymentPending: false })}
          title=" Clique para fechar"
          className="close"
        />
        <p>
          <strong>{intl.get('Balloons.payment_pending_message')}</strong>
        </p>
      </li>
    );
  }

  renderPaymentAlert = () => {
    const { dDaysRemaining } = this.state;
    return (
      <li className="bubble-alert info">
        <span
          onKeyPress={() => this.setState({ bPaymentPending: false, bReceivesPaymentAlert: false })}
          onClick={() => this.setState({ bPaymentPending: false, bReceivesPaymentAlert: false })}
          title="Clique para fechar"
          className="close"
        />
        <p>
          <strong>{intl.get('Balloons.payment_alert_message_1')}</strong>
        </p>
        <p>{intl.get("Balloons.payment_alert_message_2", { cDias: dDaysRemaining })}</p><br></br>
        <button class="btn btn-primary" onClick={() => window.location.href = "/modules"}>{intl.get('Balloons.payment_alert_button')}</button>
      </li>
    );
  }

  render() {
    const { bActive, bPaymentFailed, bPaymentPending, bReceivesPaymentAlert } = this.state;
    console.log(oLocalStorage.get('bActive'));
    console.log(oLocalStorage.get('bPaymentPending'));
    console.log(oLocalStorage.get('bReceivesPaymentAlert'));

    return (
      <div>
        <ul id="bubble-alerts">
          {bActive === false && this.renderTrialMessage()}
          {bPaymentFailed === true && this.renderPaymentFailedMessage()}
          {bReceivesPaymentAlert === false && bPaymentPending === true && this.renderPaymentPendingMessage()}
          {can('admin') && bReceivesPaymentAlert === true && this.renderPaymentAlert()}
        </ul>
      </div>
    );
  }
}

export default Balloons;
