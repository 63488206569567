// atenção: na próxima vez que precisarmos usar HTML perigoso, lembremos de sanitizá-lo para aplicar a sanitização nesses dangerouslySetInnerHTML também
import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import intl from 'react-intl-universal';
import { NavLink } from 'react-router-dom';
import { Button } from 'reactstrap';
import { approveReview, getDocumentReview } from '../../actions/ActionDocumentReview';
import { viewPDF } from '../../actions/ActionDocumentPublish';
import { downloadAttachment, fnDownloadLocalAttachment, fnGetImageAttachment } from '../../actions/ActionDocumentDetails';
import Breadcrumb from '../../components/Breadcrumbs';
import SendDocumentToTrashBin from '../../components/SendDocumentToTrashBin';
import { OptionMenu } from '../../components/OptionMenu';
import Page from '../default-v2/Page';
import { SuggestEdition } from './SuggestEdition';
import Axios from '../../config/Axios';
import ScrollBar from '../../components/ScrollBar';
import { getStatus } from '../default/Status';
import './styles/documentreview.scss';
import CardHeader from '../default-v2/CardHeader';
import Attachment from '../document/Attachments';
import 'froala-editor/css/froala_style.min.css';
import '../../assets/scss/froala-custom.scss';
import { checkModule, can, hasModule } from '../../config/Permissions';
import DocumentHeader from './DocumentHeader';
import Document from './Document';
import documentImg from '../../assets/img/documents.svg';
import cImageDownload from './img/icon-download.svg';
//import { TailSpin } from 'react-loader-spinner';

class DocumentReview extends Component {
  constructor(oProps) {
    super(oProps);

    this.state = {
      bLoading: true,
      aAttachments: [],
      nVersionId: oProps.match.params.nVersionId,
      aImgFiles: ['.png', '.jpg', '.jpeg', '.bmp', '.ai', '.eps', '.psd'],
      bIsSending: false,
      bIsModalOpen: false,
      oActiveAttachment: null,
      oActiveAttachmentType: null,
      bShowSpinner: false
    };
  }

  getDocumentHeader = () => {
    return <DocumentHeader bStepEdit bStepReview />;
  };

  viewReviewPDF = evt => {
    evt.preventDefault();
    const { setAlert } = this.props;
    const { nVersionId } = this.state;

    this.setState({ bIsSending: true });

    viewPDF(nVersionId)
      .then(this.setState({ bIsSending: false }))
      .catch(oError => {
        this.setState({ bIsSending: false });
        setAlert('error', oError);
      });
  };

  approve = evt => {
    const { nVersionId } = this.state;
    const { history, setAlert } = this.props;

    evt.preventDefault();
    this.setState({ bIsSending: true });

    approveReview(nVersionId)
      .then(nVersionApprovedId =>
        history.push({
          pathname: nVersionApprovedId ? `/document/publish/${nVersionApprovedId}` : '/',
          state: { aAlertMessages: { cType: 'success', cMsg: intl.get('DocumentReview.documento_enviado_aprovacao') } }
        })
      )
      .catch(oError => {
        if (oError.response !== undefined && oError.response.status === 406) {
          history.push({
            pathname: '/',
            state: { aAlertMessages: { cType: 'error', cMsg: oError.aMsgErrors } }
          });
        } else {
          setAlert('error', oError.aMsgErrors || 'erro desconhecido');
        }
      });
  };

  handleChange = evt => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  componentDidMount = () => {
    const { nVersionId } = this.state;
    const { history, setAlert } = this.props;
    checkModule(history, 'documents');

    getDocumentReview(nVersionId)
      .then(oData => {
        this.setState({ bLoading: false, ...oData });

        if (getStatus('awaiting-publish', 'Document', oData.oVersion.status) && oData.bAmResponsible === true) {
          history.push(`/document/publish/${oData.oVersion.ver_nid}`);
        } else if (getStatus('published', 'Document', oData.oVersion.status)) {
          history.push(`/document/detail/${oData.oVersion.ver_nid}`);
        } else if (getStatus('in-writing', 'Document', oData.oVersion.status) && oData.bAmWriter === true) {
          history.push(`/document/editor/${oData.oVersion.ver_nid}`);
        } else if (!getStatus('in-review', 'Document', oData.oVersion.status) && !oData.bAmReviewer === true) {
          history.push({
            pathname: '/',
            state: { aAlertMessages: { cType: 'error', cMsg: intl.get('DocumentReview.documento_indisponivel') } }
          });
        }
      })
      .catch(oError => {
        if ([406, 403].includes(oError.response?.status)) {
          this.setState({
            bLoading: false,
            rcmpAlert: (
            <SweetAlert
                confirmBtnText={intl.get('ok')}
                onConfirm={() => history.push({pathname: '/'})}
              >
                {oError.aMsgErrors.join('\n')}
              </SweetAlert>
          )});
        } else {
          this.setState({ bLoading: false });
          setAlert('error', oError.aMsgErrors);
        }
      });
  };

  openAttachmentImageModal = (oAttachment, fileType) => {
    const bIsLocal = oAttachment.atch_clocal.startsWith("customer\\");
    fnGetImageAttachment(oAttachment.atch_nid, oAttachment.atch_cname, bIsLocal).then(oData => {
      let cImageSource = oData.cContent;

      if (oData.cType == "base64") {
        cImageSource = "data:image/png;base64, " + oData.cContent;
      }

      if (oData.cType === 'pdf') {
        const base64Content = oData.cContent;
        cImageSource = `data:application/pdf;base64, ${base64Content}`;
      }

      this.setState({
        rcmpAlert: (
          <SweetAlert
            customClass="modal-edit modalMedium scrollBar"
            title=""
            onConfirm={this.hideAlert}
            showConfirm={false}
          >
            <div className="head-modal">
              {intl.get("DocumentEditor.view_attachment")}
              <span
                role="button"
                tabIndex="0"
                aria-labelledby="head-modal"
                onClick={() => (bIsLocal) ?
                  fnDownloadLocalAttachment(oAttachment.atch_nid, oAttachment.atch_cname) : downloadAttachment(oAttachment.atch_nid, oAttachment.atch_cname)}
                className="attachment-download"
                href=""
              ><img src={cImageDownload} /></span>
              <span
                role="button"
                tabIndex="0"
                aria-labelledby="head-modal"
                onKeyDown={this.handleKeyDown}
                onClick={this.hideAlert}
                className="close"
                href=""
              />
            </div>
            {['png', 'jpg', 'jpeg', 'bmp'].includes(fileType) ? (
              <img className="attachment-image" src={cImageSource} />
            ) : (
              <iframe src={cImageSource} style={{ width: '100%', height: '500px' }}></iframe>
            )}
          </SweetAlert>
        )
      });
      this.setState({ bShowSpinner: false });
    }).catch(oError => {
      this.setState({ bShowSpinner: false });
    });
  }

  handleDownloadAttachment = (evt, oAttachment) => {
    evt.preventDefault();

    const { setAlert } = this.props; // Extraia setAlert de props
    const attachmentType = oAttachment.atch_cname.split('.').pop().toLowerCase();

    if (['png', 'jpg', 'jpeg', 'bmp', 'pdf', 'docx', 'doc', 'xls', 'xlsx', 'html', 'txt', 'ods'].includes(attachmentType)) {
      // Obtém a URL do anexo e abre o modal
      this.setState({ bShowSpinner: true });
      this.openAttachmentImageModal(oAttachment, attachmentType);
    } else {
      // Download direto para outros tipos de arquivos
      downloadAttachment(oAttachment.atch_nid, oAttachment.atch_cname)
        .catch(oError => setAlert('error', oError));
    }
  };




  openModalRequest = () => {
    const { oRequest } = this.state;

    this.setState({
      rcmpAlert: (
        <SweetAlert
          customClass="modal-edit modal-edit-v2 lg scrollBar"
          title=""
          onConfirm={this.hideAlert}
          showConfirm={false}
        >
          <div className="head-modal">
            {intl.get('suggest_editing')}
            <span
              role="button"
              tabIndex="0"
              aria-labelledby="head-modal"
              onKeyDown={this.handleKeyDown}
              onClick={this.hideAlert}
              className="close"
              href=""
            />
          </div>
          <ScrollBar>
            <SuggestEdition
              onSuccess={cMsg => {
                this.successMessage(cMsg);
              }}
              closeModal={this.hideAlert}
              eType="review"
              nRequestId={oRequest.rqst_nid}
            />
          </ScrollBar>
        </SweetAlert>
      ),
      bIsSending: false
    });
  };

  sendToEditor = () => {
    const { oRequest } = this.state;
    const { setAlert, history } = this.props;

    Axios.post(`/version/reject/${oRequest.ver_nid}`, {
      cText: oRequest.rqst_cjustification,
      nRequestId: oRequest.rqst_nid
    })
      .then(() => {
        history.push(`/document/editor/${oRequest.ver_nid}`);
      })
      .catch(oError => {
        if (oError.response !== undefined && oError.response.status === 406) {
          history.push({
            pathname: '/',
            state: { aAlertMessages: { cType: 'error', cMsg: oError.aMsgErrors } }
          });
        } else {
          this.setState({
            bLoading: false,
            bIsSending: false
          });
          setAlert('error', oError.aMsgErrors);
        }
      });
  };

  changeRequest = evt => {
    evt.preventDefault();
    const { bAmWriter, bAmReviewer } = this.state;
    this.setState({ bIsSending: true });

    if (!bAmReviewer) {
      return;
    }

    if (!bAmWriter) {
      this.openModalRequest(evt);
    } else {
      this.sendToEditor();
    }
  };

  hideAlert = () => {
    this.setState({
      rcmpAlert: null
    });
  };

  successMessage = cMsg => {
    const { history } = this.props;

    this.hideAlert();

    history.push({
      pathname: '/',
      state: { aAlertMessages: { cType: 'success', cMsg } }
    });
  };

  handleModalSuccess = cMessage => {
    this.setState({
      rcmpAlert: (
        <SweetAlert success onConfirm={() => this.hideAlert()}>
          {' '}
          {cMessage}{' '}
        </SweetAlert>
      )
    });
  };

  handleModalError = cMessage => {
    this.setState({
      rcmpAlert: (
        <SweetAlert error onConfirm={() => this.hideAlert()}>
          {' '}
          {cMessage}{' '}
        </SweetAlert>
      )
    });
  };

  handleDocumentModal = (evtEditing, fnOnSuccess, fnOnError) => {
    const { nVersionId } = this.state;
    this.setState({
      rcmpAlert: (
        <SweetAlert
          customClass="modal-edit modal-edit-v2 lg scrollBar"
          title=""
          onConfirm={this.hideAlert}
          showConfirm={false}
        >
          <div className="head-modal">
            {intl.get('DocumentDetails.editar_documento')}
            <span
              role="button"
              tabIndex="0"
              aria-labelledby="head-modal"
              onKeyDown={this.handleKeyDown}
              onClick={this.hideAlert}
              className="close"
              href=""
            />
          </div>
          <ScrollBar>
            <Document
              bNewDocument={false}
              nVersionId={nVersionId}
              onSuccess={fnOnSuccess}
              onError={fnOnError}
              closeModal={this.hideAlert}
            />
          </ScrollBar>
        </SweetAlert>
      )
    });
  };

  getOptionMenu = () => {
    const { oDocument, oVersion, bAmResponsible } = this.state;
    const { history } = this.props;
    const cModule = 'documents';

    return (
      oDocument &&
      oVersion && (
        <OptionMenu>
          <li>
            <NavLink title={intl.get('DocumentDetails.interacoes')} to={`/document/interaction/${oVersion.ver_nid}`}>
              <i className="icon-clock" />
              {intl.get('DocumentDetails.interacoes')}
            </NavLink>
          </li>
          {hasModule(cModule) && (bAmResponsible || can('admin', cModule)) && (
            <>
              <li>
                <span
                  role="button"
                  tabIndex="0"
                  onKeyPress={evtKeyPress =>
                    this.handleDocumentModal(evtKeyPress, this.handleModalSuccess, this.handleModalError)
                  }
                  onClick={evtClick =>
                    this.handleDocumentModal(evtClick, this.handleModalSuccess, this.handleModalError)
                  }
                  title={intl.get('DocumentDetails.editar_documento')}
                >
                  <i className="icon-edit" />
                  {intl.get('DocumentDetails.editar_documento')}
                </span>
              </li>
            </>
          )}
          {bAmResponsible && (
            <li>
              <SendDocumentToTrashBin oVersion={oVersion} oDocument={oDocument} history={history} />
            </li>
          )}
        </OptionMenu>
      )
    );
  };

  render() {
    const {
      bIsSending,
      bLoading,
      aBreadcrumbFolders,
      rcmpAlert,
      bAmReviewer,
      bAmWriter,
      oVersion,
      oDocument,
      aAttachments,
      bShowSpinner
    } = this.state;

    const { history, getAlert } = this.props;

    const rcmpBreadcrumb = oDocument && (
      <Breadcrumb bLastLink aFolders={aBreadcrumbFolders} cType="document" oDocument={oDocument} />
    );

    return (
      <Page
        loading={bLoading ? 1 : 0}
        rcmpBreadcrumb={rcmpBreadcrumb}
        cTitle={oDocument ? oDocument.doc_ctitle : ''}
        cImage={documentImg}
        rcmpCardHeader={
          <CardHeader rcmpOptionMenu={this.getOptionMenu()} history={history} cHtml={this.getDocumentHeader()} />
        }
        cCurrentSideMenuOption={intl.get('Nav.document')}
      >
        {rcmpAlert}
        {bShowSpinner && (
          <div style={{
            position: 'fixed', // Fixa em relação ao viewport
            top: 0,            // Começa no topo do viewport
            left: 0,           // Alinhado à esquerda do viewport
            right: 0,          // Estende até a direita do viewport
            bottom: 0,         // Estende até o fundo do viewport
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Cor de fundo semi-transparente
            display: 'flex',
            justifyContent: 'center', // Centraliza horizontalmente o conteúdo
            alignItems: 'center',     // Centraliza verticalmente o conteúdo
            zIndex: 1000              // Garante que a sobreposição esteja acima de outros elementos
          }}>
            {/* <TailSpin
              type="Puff"
              color="#00BFFF"
              height={100}
              width={100}
            /> */}
          </div>
        )}
        {getAlert()}
        {oVersion && (
          <div className="containerJustification ">
            <h2>{intl.get('justificativa')}</h2>
            <p style={{ whiteSpace: 'pre-wrap' }}>{oVersion.ver_cjustification}</p>
          </div>
        )}

        <form className="form">
          <div className="fr-wrapper">
            <article
              className="fr-element fr-view"
              dangerouslySetInnerHTML={{ __html: oVersion ? oVersion.ver_ctext : '' }}
            />
          </div>

          <div className="attachments-container">
            <div className="attachments-wrapper">
              {aAttachments.length > 0 && (
                <>
                  <span>{intl.get('anexos')}:</span>
                  <ul className="attachments">
                    {aAttachments.map((oAttachment, i) => (
                      <li key={oAttachment.atch_nid}>
                        <a
                          className="download-file-attachment"
                          onClick={evt => this.handleDownloadAttachment(evt, oAttachment)}
                          download
                        >
                          <img
                            className="align clip-icon"
                            src={require('../../assets/img/clip.svg')}
                            alt="attachment icon"
                          />
                          <span>{oAttachment.atch_cname}</span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </div>
          </div>

          <div className="button-stack">
            {bAmReviewer && (
              <>
                <Button className="btn alternate back" onClick={history.goBack} disabled={bIsSending}>
                  {intl.get('cancel')}
                </Button>

                <Button
                  className="btn alternate"
                  type="button"
                  onClick={evt => this.changeRequest(evt)}
                  disabled={bIsSending}
                >
                  {!bAmWriter && intl.get('suggest_editing')}
                  {bAmWriter && intl.get('edit', { cType: '' })}
                </Button>
                <Button
                  disabled={bIsSending}
                  onClick={evt => this.approve(evt)}
                  name="action"
                  value="approve"
                  type="submit"
                  className="btn"
                >
                  {intl.get('aprovar')}
                </Button>
              </>
            )}
          </div>
        </form>
      </Page>
    );
  }
}

export default DocumentReview;
